import { ApolloError } from "@apollo/client";
import React, { useState } from "react";
import { Debug } from "../../components/Debug/Debug";
import { P } from "../../components/Paragraph/Paragraph";
import { TextButton } from "../../components/TextButton/TextButton";
import { View } from "../../components/View/View";

export interface ServerDownViewProps {
  error: ApolloError;
}

export const ServerDownView: React.FC<ServerDownViewProps> = ({ error }) => {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <View center pad>
      <P>Could not communicate with the server</P>
      <P small>The server or network seems to be down</P>
      {showDetails ? (
        <Debug title="Error details">{error}</Debug>
      ) : (
        <TextButton small onClick={() => setShowDetails(true)}>
          Show details
        </TextButton>
      )}
    </View>
  );
};
