import { JSONSchema7 } from "json-schema";

export const SurveyMetadataSchema: JSONSchema7 = {
  description: "Recommends the user to take a survey.",
  type: "object",
  properties: {
    imageUrl: {
      description: "Feed item image url",
      minLength: 1,
      type: "string",
    },
    title: {
      description: "Survey title",
      minLength: 1,
      type: "string",
    },
    description: {
      description: "Survey description text",
      minLength: 1,
      type: "string",
    },
    surveyId: {
      description: "Unique identifier of the survey to open",
      minLength: 1,
      type: "string",
    },
  },
  required: ["description", "imageUrl", "surveyId", "title"],
  $schema: "http://json-schema.org/draft-07/schema#",
};
