import { WithCollaboration } from "../../components/Collaboration/Collaboration";
import { NameValueList, NameValueListItem } from "../../components/NameValuePair/NameValuePair";
import { CollaborationTypeEnum, EventSettingInfoFragment } from "../../schema";
import { EventSettingDetailsProps } from "./EventSettingDetailsView";

export const EventSettingDetailsInfo: React.FC<EventSettingDetailsProps> = ({ eventSetting, viewer }) => {
  return (
    <WithCollaboration referenceId={eventSetting.id} type={CollaborationTypeEnum.EVENT_SETTING} viewer={viewer}>
      <NameValueList items={getEventSettingNameValueList(eventSetting)} />
    </WithCollaboration>
  );
};

export function getEventSettingNameValueList(eventSetting: EventSettingInfoFragment): NameValueListItem[] {
  return [
    {
      name: "Unique id",
      value: eventSetting.id,
    },
    {
      name: "Name",
      value: eventSetting.name,
    },
    {
      name: "Value",
      value: eventSetting.value,
    },
    {
      name: "Comment",
      value: eventSetting.comment,
    },
    {
      name: "Created",
      value: eventSetting.createdDate,
    },
    {
      name: "Updated",
      value: eventSetting.updatedDate,
    },
  ];
}
