import { DataTableAction } from "../components/DataTable/DataTable";
import { UserScopeEnum } from "../schema";
import { hasAnyScopes } from "./hasAnyScopes";

/** Filter out null actions and ones that the user is not authorized for.
 *
 * @returns Filtered actions
 */
export const getFilteredDataTableActions = <T>(
  actions?: (DataTableAction<T> | undefined | null)[],
  viewerScopes?: UserScopeEnum[],
) => {
  return actions
    ? (actions.filter((action) => {
        // actions can be undefined/null to skip them
        if (!action) {
          return false;
        }

        // throw if authorizedScopes is set but not viewerScopes
        if (action.authorizedScopes !== undefined && viewerScopes === undefined) {
          throw new Error(
            `Data table action "${action.label}" has authorizedScopes of ${action.authorizedScopes.join(
              ", ",
            )} but viewerScopes is not set, this is a programmer mistake`,
          );
        }

        if (action.authorizedScopes !== undefined && viewerScopes !== undefined) {
          return hasAnyScopes(action.authorizedScopes, viewerScopes);
        }

        return true;
      }) as DataTableAction<T>[])
    : [];
};
