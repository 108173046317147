import { JSONSchema7 } from "json-schema";

export const BackgroundMusicMetadataSchema: JSONSchema7 = {
  description: "Link to background music section of the app",
  type: "object",
  properties: {
    playlistTitle: {
      description: "Recommended playlist title",
      minLength: 1,
      type: "string",
    },
    imageUrl: {
      description: "Feed item image url",
      minLength: 1,
      type: "string",
    },
  },
  required: ["imageUrl", "playlistTitle"],
  $schema: "http://json-schema.org/draft-07/schema#",
};
