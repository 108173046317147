import { Fragment, ReactElement } from "react";
import { Column } from "../components/Column/Column";
import { P } from "../components/Paragraph/Paragraph";
import { TimeAgo } from "../components/TimeAgo/TimeAgo";
import { formatDatetime } from "./formatDatetime";

export type FormatValueType =
  | string
  | number
  | boolean
  | Date
  | React.ReactNode
  | null
  | undefined
  | Array<FormatValueType>;

export function formatValue(value: FormatValueType): ReactElement {
  // handle not defined
  if (value === undefined || value === null) {
    return <P small>n/a</P>;
  }

  // handle numbers
  if (typeof value === "number") {
    return <P small>{value.toString()}</P>;
  }

  // handle strings
  if (typeof value === "string") {
    // handle date strings
    if (value.substring(value.length - 1) === "Z") {
      const valueAsDate = new Date(value);

      return formatValue(valueAsDate);
    }

    // normal strings
    return <P small>{value}</P>;
  }

  // handle booleans
  if (typeof value === "boolean") {
    return <P small>{value ? "Yes" : "No"}</P>;
  }

  // handle dates
  if (value instanceof Date) {
    return (
      <P small>
        {formatDatetime(value)} (<TimeAgo date={value} />)
      </P>
    );
  }

  // handle arrays
  if (Array.isArray(value)) {
    return (
      <Column>
        {value.map((item, index) => (
          <Fragment key={index}>{formatValue(item)}</Fragment>
        ))}
      </Column>
    );
  }

  return value as ReactElement;
}
