import { sentenceCase } from "change-case";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Action } from "../../components/ActionBar/ActionBar";
import { Header } from "../../components/Header/Header";
import { Title } from "../../components/Title/Title";
import { useAdminUrlInfo } from "../../hooks/useAdminUrlInfo";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import { UserScopeEnum } from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { ClarityTestConclusionImageDetailsProps } from "./ClarityTestConclusionImageDetailsView";

export const ClarityTestConclusionImageDetailsHeader: React.FC<ClarityTestConclusionImageDetailsProps> = ({
  clarityTestConclusionImage,
  ...rest
}) => {
  const navigate = useNavigate();
  const { modifier } = useAdminUrlInfo();

  // detect edit mode
  const isEditMode = modifier === "edit";

  // header buttons configuration
  const actions: Action[] = useMemo(
    () => [
      isEditMode
        ? {
            label: "Details",
            onClick: () =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "clarity-score",
                  page: "conclusion-images",
                  id: clarityTestConclusionImage.id,
                }),
              }),
          }
        : {
            label: "Edit",
            authorizedScopes: [
              UserScopeEnum.SUPERADMIN,
              UserScopeEnum.CLARITY_TEST,
              UserScopeEnum.CLARITY_TEST_CONCLUSION_IMAGE,
              UserScopeEnum.CLARITY_TEST_UPDATE_CONCLUSION_IMAGE,
            ],
            onClick: () =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "clarity-score",
                  page: "conclusion-images",
                  id: clarityTestConclusionImage.id,
                  modifier: "edit",
                }),
              }),
          },
    ],
    [isEditMode, navigate, clarityTestConclusionImage.id],
  );

  // header navigation configuration
  const navigation: Action[] = useMemo(() => [], []);

  return (
    <>
      <Header actions={actions} navigation={navigation} {...rest}>
        <Title>Conclusion image for "{sentenceCase(clarityTestConclusionImage.topic)}"</Title>
      </Header>
    </>
  );
};
