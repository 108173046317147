import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { Action } from "../../components/ActionBar/ActionBar";
import { DataTable, DataTableAction, DataTableHeader, DataTableRow } from "../../components/DataTable/DataTable";
import { Filter, FilterBaseData } from "../../components/Filter/Filter";
import { FormFieldOptions } from "../../components/GeneratedForm/GeneratedForm";
import { Pagination } from "../../components/Pagination/Pagination";
import { toastState, ToastType } from "../../components/Toast/Toast";
import { View } from "../../components/View/View";
import { useUrlParams } from "../../hooks/useUrlParams";
import { CreateFeedTestUserModal } from "../../modals/CreateFeedTestUser/CreateFeedTestUserModal";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import { ConditionModeEnum, MatchModeEnum, SortingInput, useFeedTestUsersQuery, UserScopeEnum } from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getPageCount } from "../../services/getPageCount";
import { getSkipTake } from "../../services/getSkipTake";
import { getSortDirectionFromParams } from "../../services/getSortDirectionFromParams";
import { getSortOptionsFromSearchParams } from "../../services/getSortOptionsFromSearchParams";
import { getUpdatedSortOptions } from "../../services/getUpdatedSortOptions";
import { getUrlSearchParamsString } from "../../services/getUrlSearchParamsString";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";

interface FeedTestUserFilterData extends FilterBaseData {
  userId: string;
  sort: SortingInput[] | null;
}

export const FeedTestUserListView: React.FC<AdminViewProps> = () => {
  const navigate = useNavigate();
  const setToast = useSetRecoilState(toastState);
  const [isCreateNewFeedTestUserModalOpen, setIsCreateNewFeedTestUserModalOpen] = useState(false);

  const params = useUrlParams<FeedTestUserFilterData>((params) => ({
    userId: params.userId ?? "",
    matchMode: params.matchMode ? (params.matchMode as MatchModeEnum) : MatchModeEnum.STARTS_WITH,
    conditionMode: params.conditionMode ? (params.conditionMode as ConditionModeEnum) : ConditionModeEnum.AND,
    page: params.page ? parseInt(params.page, 10) : 1,
    sort: params.sort ? getSortOptionsFromSearchParams(params.sort) : null,
  }));

  const { data, loading, error } = useFeedTestUsersQuery({
    variables: {
      filter: {
        userId: params.userId ? parseInt(params.userId, 10) : undefined,
      },
      pagination: {
        ...getSkipTake(params.page),
      },
      match: {
        matchMode: params.matchMode,
        conditionMode: params.conditionMode,
      },
      sort: params.sort ?? null,
    },
  });

  const filters = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "text",
        type: "text",
        name: "userId",
        label: "User ID",
        defaultValue: params.userId.toString(),
      },
    ],
    [params.userId],
  );

  const feedTestUsers = useMemo(() => data?.feedTestUsers.feedTestUsers ?? [], [data?.feedTestUsers.feedTestUsers]);
  const total = data?.feedTestUsers.total ?? 0;
  const pageCount = getPageCount(total);

  // handle sorting by column
  const onHeaderClick = useCallback(
    (databaseColumnName: string) =>
      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "admin-feed", page: "test-users" }),
        search: getUrlSearchParamsString({ ...params, sort: getUpdatedSortOptions(databaseColumnName, params.sort) }),
      }),
    [navigate, params],
  );

  const headers = useMemo<DataTableHeader[]>(
    () => [
      {
        label: "User Id",
        onClick: () => onHeaderClick("userId"),
        sortdirection: getSortDirectionFromParams("userId", params.sort),
      },
      {
        label: "Is Active",
        onClick: () => onHeaderClick("deletedDate"),
        sortdirection: getSortDirectionFromParams("deletedDate", params.sort),
      },
    ],
    [onHeaderClick, params.sort],
  );

  const rows = useMemo<DataTableRow[]>(
    () =>
      feedTestUsers.map((feedTestUser) => {
        return {
          id: feedTestUser.id,
          cells: [
            {
              content: feedTestUser.userId,
            },
            {
              content: feedTestUser.isActive ? "Yes" : "No",
            },
          ],
          actions: [
            {
              label: "View details",
              authorizedScopes: [UserScopeEnum.SUPERADMIN],
              onClick: (testUserId) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "admin-feed",
                    page: "test-users",
                    id: testUserId,
                  }),
                }),
            },
            {
              label: "Edit",
              authorizedScopes: [UserScopeEnum.SUPERADMIN],
              onClick: (testUserId) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "admin-feed",
                    page: "test-users",
                    id: testUserId,
                    modifier: "edit",
                  }),
                }),
            },
          ],
        };
      }),
    [navigate, feedTestUsers],
  );

  const viewActions: Action[] = useMemo(
    () => [
      {
        label: "Create Test user",
        authorizedScopes: [UserScopeEnum.SUPERADMIN],
        onClick: () => setIsCreateNewFeedTestUserModalOpen(true),
      },
    ],
    [setIsCreateNewFeedTestUserModalOpen],
  );

  const onFilterSubmit = useCallback(
    (data: FeedTestUserFilterData) => {
      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "admin-feed", page: "test-users" }),
        search: getUrlSearchParamsString(data),
      });
    },
    [navigate],
  );

  const bulkActions = useMemo<DataTableAction<string[]>[]>(() => [], []);

  if (error) {
    return <ErrorView title="Fetching test users failed" error={error} />;
  }

  return (
    <>
      <View scrollable>
        <Filter
          title="Feed test users"
          fields={filters}
          actions={viewActions}
          loading={loading}
          matchMode={params.matchMode}
          conditionMode={params.conditionMode}
          onSubmit={onFilterSubmit}
        />
        <DataTable
          headers={headers}
          rows={rows}
          loading={loading}
          stats={{ resultCount: total, pageCount }}
          bulkActions={bulkActions}
          openAuthorizedScopes={[UserScopeEnum.SUPERADMIN]}
          onOpen={(row) =>
            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                menu: "admin-feed",
                page: "test-users",
                modifier: row.id,
              }),
            })
          }
        />
        <Pagination
          sticky
          pageCount={pageCount}
          currentPage={params.page}
          onPageChange={(page) =>
            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "admin-feed", page: "test-users" }),
              search: getUrlSearchParamsString({ ...params, page }),
            })
          }
        />
      </View>

      <CreateFeedTestUserModal
        open={isCreateNewFeedTestUserModalOpen}
        onClickOutside={() => setIsCreateNewFeedTestUserModalOpen(false)}
        onCompleted={(data) => {
          // show success toast message
          setToast({
            type: ToastType.SUCCESS,
            title: "Feed test user created",
            message: "",
            isOpen: true,
          });

          // hide modal
          setIsCreateNewFeedTestUserModalOpen(false);

          navigate({
            pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
              menu: "admin-feed",
              page: "test-users",
              modifier: data.createFeedTestUser.id,
            }),
          });
        }}
      />
    </>
  );
};
