import { JSONSchema7 } from "json-schema";

export const ReviewMetadataSchema: JSONSchema7 = {
  description: "Positive review entered by admins.",
  type: "object",
  properties: {
    authorName: {
      description: "Author name",
      minLength: 1,
      type: "string",
    },
    authorAvatarUrl: {
      description: "Avatar URL of the author",
      minLength: 1,
      type: "string",
    },
    reviewScore: {
      description: "Review score (1-5)",
      type: "number",
    },
    reviewText: {
      description: "Review text to show",
      minLength: 1,
      type: "string",
    },
    authorWebpageUrl: {
      description: "Optional author webpage url (null if does not exist)",
      type: ["null", "string"],
    },
  },
  required: ["authorAvatarUrl", "authorName", "authorWebpageUrl", "reviewScore", "reviewText"],
  $schema: "http://json-schema.org/draft-07/schema#",
};
