import { Operation } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { OperationDefinitionNode } from "graphql";
import { isOperationDefinitionNode } from "./isOperationDefinitionNode";

export function isSubscriptionOperation({ query }: Operation) {
  const node = getMainDefinition(query) as OperationDefinitionNode;

  if (!isOperationDefinitionNode(node)) {
    return false;
  }

  return node.operation === "subscription";
}
