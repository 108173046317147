import { JSONSchema7 } from "json-schema";

export const ClarityTopicScoreChangedMetadataSettingSchema: JSONSchema7 = {
  description: "clarity topic score change metadata setting parameters to display to admin user",
  type: "object",
  properties: {
    HAPPINESS: {
      description: "happyness topic score text",
      type: "string",
    },
    POSITIVITY: {
      description: "positivity topic score text",
      type: "string",
    },
    SLEEP_QUALITY: {
      description: "sleep quality topic score text",
      type: "string",
    },
    ENERGY: {
      description: "energy topic score text",
      type: "string",
    },
    MENTAL_POWER: {
      description: "mental power topic score text",
      type: "string",
    },
    CONFIDENCE: {
      description: "confidence topic score text",
      type: "string",
    },
    GRATITUDE: {
      description: "gratitude topic score text",
      type: "string",
    },
    LAW_OF_ATTRACTION: {
      description: "law of attraction topic score text",
      type: "string",
    },
  },
  required: [
    "CONFIDENCE",
    "ENERGY",
    "GRATITUDE",
    "HAPPINESS",
    "LAW_OF_ATTRACTION",
    "MENTAL_POWER",
    "POSITIVITY",
    "SLEEP_QUALITY",
  ],
  $schema: "http://json-schema.org/draft-07/schema#",
};
