import { SortingInput } from "../schema";

export function getSortDirectionFromParams(columnName: string, sortParam: SortingInput[] | null) {
  if (!sortParam) {
    return null;
  }

  const currentSortForColumn = sortParam.filter((sortInput) => sortInput.orderBy === columnName);

  if (currentSortForColumn.length === 0) {
    return null;
  }

  return currentSortForColumn[0].direction;
}
