import { SortDirectionEnum, SortingInput } from "../schema";

export function getUpdatedSortOptions(columnName: string, sortParam: SortingInput[] | null) {
  let newSort: SortingInput[] = [];
  let addToNewSort = true;

  if (!sortParam) {
    addToNewSort = false;
    newSort.push({
      orderBy: columnName,
      direction: SortDirectionEnum.DESC,
    });
  }

  for (const sortOption of sortParam ?? []) {
    // existing sort option
    if (sortOption.orderBy === columnName && sortOption.direction !== SortDirectionEnum.ASC) {
      newSort.push({
        ...sortOption,
        direction: SortDirectionEnum.ASC,
      });

      addToNewSort = false;
    }

    // should remove sorting since on first click = DESC
    // second = ASC
    // third click should remove
    else if (sortOption.orderBy === columnName && sortOption.direction === SortDirectionEnum.ASC) {
      addToNewSort = false;
    }

    // keep existing sorting options
    else {
      newSort.push(sortOption);
    }
  }

  if (addToNewSort) {
    newSort.push({ orderBy: columnName, direction: SortDirectionEnum.DESC });
  }

  return newSort;
}
