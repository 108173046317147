export function getFileUploadById(fileInputId: string) {
  const fileInputElement = document.getElementById(fileInputId) as HTMLInputElement | null;

  if (!fileInputElement) {
    return undefined;
  }

  const files = fileInputElement.files;
  const file = files != null && files?.length > 0 ? files[0] : undefined;

  return file;
}
