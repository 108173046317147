import { useLocation } from "react-router-dom";
import { AdminViewMenu, AdminViewParams } from "../routes";

export function useAdminUrlInfo(): AdminViewParams {
  const location = useLocation();
  const [_abs, _module, menu, page, id, modifier] = location.pathname.split("/");

  return {
    menu: menu ? (menu as AdminViewMenu) : undefined,
    page,
    id,
    modifier,
  };
}
