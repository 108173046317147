import classNames from "classnames";
import React from "react";
import { Container } from "../Container/Container";
import { FlexProps } from "../Flex/Flex";
import styles from "./Tag.module.scss";

export interface TagProps extends FlexProps {
  tiny?: boolean;
  small?: boolean;
  large?: boolean;
}

export const Tag: React.FC<TagProps> = ({ tiny, small, large, secondary, className, children, ...rest }) => (
  <Container
    className={classNames(
      styles["tag"],
      {
        [styles["tag--tiny"]]: tiny,
        [styles["tag--small"]]: small,
        [styles["tag--large"]]: large,
        [styles["tag--secondary"]]: secondary,
      },
      className,
    )}
    {...rest}
  >
    {children}
  </Container>
);
