import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { split } from "@apollo/client";
import { authLink } from "../apollo-links/auth-link";
import { contextLink } from "../apollo-links/context-link";
import { errorLink } from "../apollo-links/error-link";
import { httpLink } from "../apollo-links/http-link";
import { uploadLink } from "../apollo-links/upload-link";
import { websocketLink } from "../apollo-links/websocket-link";
import introspection from "../introspection";
import { isSubscriptionOperation } from "./isSubscriptionOperation";
import { isUpload } from "./isUpload";

export function setupApolloClient() {
  // resolve terminal link to use

  const requestLink = split(isSubscriptionOperation, websocketLink, authLink.concat(httpLink));
  const terminalLink = split(isUpload, authLink.concat(uploadLink), requestLink);

  // setup apollo graphql client
  const client = new ApolloClient({
    link: ApolloLink.from([contextLink, errorLink]).concat(terminalLink),
    cache: new InMemoryCache({
      possibleTypes: introspection.possibleTypes,
      // setup type policies (see https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects)
      typePolicies: {
        Query: {
          fields: {
            // merge query admin fields cache
            admin: {
              merge(existing, incoming) {
                return { ...existing, ...incoming };
              },
            },
          },
        },
      },
    }),
    defaultOptions: {
      // mutate: {
      //   errorPolicy: "all",
      // },
    },
    // https://github.com/apollographql/apollo-client/blob/master/CHANGELOG.md#apollo-client-260-1
    assumeImmutableResults: true,
  });

  return client;
}
