import { JSONSchema7 } from "json-schema";

export const IntroductionMetadataSchema: JSONSchema7 = {
  description: "Introduction video shown to users after registering.",
  type: "object",
  properties: {
    introductionVideoImageUrl: {
      description: "The video placeholder image url",
      minLength: 1,
      type: "string",
    },
    introductionVideoId: {
      description: "The vimeo video id to show as the introduction video",
      minLength: 1,
      type: "string",
    },
  },
  required: ["introductionVideoId", "introductionVideoImageUrl"],
  $schema: "http://json-schema.org/draft-07/schema#",
};
