import React from "react";
import { WithCollaboration } from "../../components/Collaboration/Collaboration";
import { NameValueList, NameValueListItem } from "../../components/NameValuePair/NameValuePair";
import { CollaborationTypeEnum } from "../../schema";
import {
  QuestionOptionDetailsProps,
  QuestionOptionType,
} from "../TestQuestionOptionListView/TestQuestionOptionListView";

export const TestQuestionOptionDetailsInfo: React.FC<QuestionOptionDetailsProps> = ({ questionOption, viewer }) => (
  <WithCollaboration referenceId={questionOption.id} type={CollaborationTypeEnum.QUESTION_OPTION} viewer={viewer}>
    <NameValueList items={getTestQuestionNameValueList(questionOption)} />
  </WithCollaboration>
);

export function getTestQuestionNameValueList(questionOption: QuestionOptionType): NameValueListItem[] {
  return [
    {
      name: "Question",
      value: questionOption.question.question.EN,
    },
    {
      name: "Answer",
      value: questionOption.answer.EN,
    },
    {
      name: "Conclusion text",
      value: questionOption.conclusionText.EN,
    },
    {
      name: "Score",
      value: questionOption.score,
    },
    {
      name: "Weight",
      value: questionOption.weight,
    },
  ];
}
