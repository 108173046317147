/**
 * These route name and params definitions are declared in a separate file to be able to build type safe
 * paths and url's while enabling code splitting so respective declarations do not need to be imported from
 * the components that we want to split into separate bundles.
 */

import { ViewerQuery } from "./schema";

// login view
export const LOGIN_VIEW_PATH = "/login";

// admin view
export const ADMIN_VIEW_PATH = "/main/:menu?/:page?/:id?/:modifier?";

export type AdminViewMenu = "users" | "admin-feed" | "user-feed" | "clarity-score" | "media";

export type AdminViewParams = {
  menu?: AdminViewMenu;
  page?: string;
  id?: string;
  modifier?: string;
};

// experiments view
export const EXPERIMENTS_VIEW_PATH = "/experiments/:experiment?/:page?/:modifier?";

export type ExperimentsViewParams = {
  experiment?: string;
  page?: string;
  modifier?: string;
};

// logged in viewer info
export type ViewerInfo = NonNullable<ViewerQuery["viewer"]>;
