import { JSONSchema7 } from "json-schema";

export const PollMetadataSchema: JSONSchema7 = {
  description: "Poll shown to user.",
  type: "object",
  properties: {
    imageUrl: {
      description: "Feed item image url",
      type: ["null", "string"],
    },
    pollId: {
      description: "Unique identifier of the poll",
      minLength: 1,
      type: "string",
    },
    pollQuestion: {
      $ref: "#/definitions/TranslationsMap",
      description: "Poll question text",
    },
    totalCount: {
      description: "Total number of answers",
      minimum: 1,
      type: "number",
    },
    answerOptions: {
      description: "List of poll options",
      type: "array",
      items: {
        type: "object",
        properties: {
          adminFeedPollOptionId: {
            description: "Option unique identifier",
            minLength: 1,
            type: "string",
          },
          adminFeedPollOptionAnswer: {
            $ref: "#/definitions/TranslationsMap",
            description: "Text of the option",
          },
          count: {
            description: "Count of how many people have chosen this option",
            type: "number",
          },
          ordinal: {
            description: "Ordinal of the option",
            type: "number",
          },
          percentage: {
            description: "Percentage of how many people have chosen this option",
            minimum: 0,
            maximum: 100,
            type: "number",
          },
        },
        required: ["adminFeedPollOptionAnswer", "adminFeedPollOptionId", "count", "ordinal", "percentage"],
      },
    },
  },
  required: ["answerOptions", "imageUrl", "pollId", "pollQuestion", "totalCount"],
  definitions: {
    TranslationsMap: {
      type: "object",
      properties: {
        EN: {
          type: ["null", "string"],
        },
        ET: {
          type: ["null", "string"],
        },
      },
    },
  },
  $schema: "http://json-schema.org/draft-07/schema#",
};
