import React, { useRef } from "react";
import { useRecoilState, atom } from "recoil";
import { changelog } from "../../changelog";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { GridBox } from "../GridBox/GridBox";
import { Modal } from "../Modal/Modal";
import { P } from "../Paragraph/Paragraph";

const { seen, unseen } = getChangelogItems();

export const showChangelogState = atom({
  key: "showChangelogState",
  default: unseen.length > 0,
});

export const Changelog: React.FC = () => {
  // use global state to decide whether to show changelog
  const [showChangelog, setShowChangelog] = useRecoilState(showChangelogState);

  const modalRef = useRef<HTMLDivElement>(null);

  // hide changelog when clicked outside
  useOnClickOutside(modalRef, () => {
    setShowChangelog(false);
  });

  // render nothing if there are no unseen items
  if (!showChangelog) {
    return null;
  }

  // marks current items seen and closes changelog
  const closeChangelog = () => {
    markChangelogItemsSeen();
    setShowChangelog(false);
  };

  return (
    <Modal open title="Changelog" onClickOutside={closeChangelog}>
      {unseen.length > 0 && (
        <P darker marginBottom={2}>
          Changes since last visit
        </P>
      )}
      {unseen.map((item, index) => (
        <ChangelogItem key={index} index={unseen.length - index + seen.length} text={item} />
      ))}
      {unseen.length > 0 && <GridBox size={2} />}
      {seen.length > 0 && (
        <P darker marginBottom={2}>
          Previous changes
        </P>
      )}
      {seen.map((item, index) => (
        <ChangelogItem key={index} index={seen.length - index} text={item} />
      ))}
    </Modal>
  );
};

export interface ChangelogItemProps {
  index: number;
  text: string;
}

export const ChangelogItem: React.FC<ChangelogItemProps> = ({ text, index }) => (
  <P small marginBottom={1}>
    {index}. {text}
  </P>
);

function markChangelogItemsSeen() {
  localStorage.seenChangelogItems = JSON.stringify(changelog);
}

function getSeenChangelogItems(): string[] {
  const data = localStorage.seenChangelogItems;

  return data !== undefined ? JSON.parse(data) : [];
}

function getChangelogItems() {
  const seen = getSeenChangelogItems();
  const unseen = changelog.filter((item) => !seen.includes(item));

  return {
    seen,
    unseen,
  };
}
