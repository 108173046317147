import classNames from "classnames";
import React from "react";
import { Container } from "../Container/Container";
import { FlexProps } from "../Flex/Flex";
import styles from "./Panel.module.scss";

export interface PanelProps extends FlexProps {
  raised?: boolean;
}

export const Panel: React.FC<PanelProps> = ({ raised, className, children, ...rest }) => (
  <Container className={classNames(styles.panel, { [styles["panel--raised"]]: raised }, className)} {...rest}>
    {children}
  </Container>
);
