import React from "react";
import { Route, Routes, Navigate } from "react-router";
import { RequireAuth } from "./components/RequireAuth/RequireAuth";
import { ADMIN_VIEW_PATH, LOGIN_VIEW_PATH, AdminViewParams, ViewerInfo } from "./routes";
import { useViewerQuery } from "./schema";
import { buildUrl } from "./services/buildUrl";
import { AdminView } from "./views/AdminView/AdminView";
import { ChangePasswordView } from "./views/ChangePasswordView/ChangePasswordView";
import { ErrorView } from "./views/ErrorView/ErrorView";
import { LoadingView } from "./views/LoadingView/LoadingView";
import { LoginView } from "./views/LoginView/LoginView";
import { NotFoundView } from "./views/NotFoundView/NotFoundView";

// initiate lazy-loaded views
const ExperimentsView = React.lazy(() =>
  import(/* webpackChunkName: "ExperimentsView" */ "./views/ExperimentsView/ExperimentsView").then((module) => ({
    default: module.ExperimentsView,
  })),
);

export const App: React.FC = () => {
  // attempt to get logged in user (viewer) info
  const { data, loading, error } = useViewerQuery();

  // handle error
  if (error) {
    return <ErrorView title="Requesting logged in user info failed" error={error} />;
  }

  // handle loading
  if (loading || !data) {
    return <LoadingView />;
  }

  // get viewer info and check whether the user is logged in
  const viewer = data.viewer;
  const isLoggedIn = viewer !== null;

  // decide path to redirect to from root path based on whether the user is logged in
  const indexPath = isLoggedIn ? buildUrl<AdminViewParams>(ADMIN_VIEW_PATH) : buildUrl(LOGIN_VIEW_PATH);

  // login path used when user tries to access protected route requiring being logged in
  const loginPath = buildUrl(LOGIN_VIEW_PATH);

  // admin view requires authentication
  const authenticatedAdminView = (
    <RequireAuth allowed={isLoggedIn} redirectNotAllowed={loginPath}>
      <AdminView viewer={viewer as ViewerInfo} />
    </RequireAuth>
  );

  if (viewer && !viewer.hasChangedPassword) {
    return <ChangePasswordView viewer={viewer} />;
  }

  return (
    <Routes>
      <Route path="/" element={<Navigate to={indexPath} />} />
      <Route path="login" element={<LoginView />} />
      <Route path="experiments/*" element={<ExperimentsView />} />
      <Route path="main" element={authenticatedAdminView} />
      <Route path="main/:menu/*" element={authenticatedAdminView} />
      {viewer && <Route path="change-password" element={<ChangePasswordView viewer={viewer} />} />}
      <Route path="*" element={<NotFoundView />} />
    </Routes>
  );
};
