import classNames from "classnames";
import React, { useState } from "react";
import { HiDotsVertical } from "react-icons/hi";
import { Container } from "../Container/Container";
import { DropdownMenu } from "../DropdownMenu/DropdownMenu";
import { FlexProps } from "../Flex/Flex";
import styles from "./ActionsDropdown.module.scss";

export interface ActionsDropdownProps extends FlexProps {
  disabled?: boolean;
}

export const ActionsDropdown: React.FC<ActionsDropdownProps> = ({ disabled, title, className, children, ...rest }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <Container
      relative
      overflow
      className={classNames(
        {
          [styles["actions-dropdown--open"]]: isDropdownOpen,
          [styles["actions-dropdown--disabled"]]: disabled,
        },
        className,
      )}
      {...rest}
    >
      <Container
        title={title ?? "Actions"}
        className={styles.icon}
        onClick={() => !disabled && setIsDropdownOpen(!isDropdownOpen)}
      >
        <HiDotsVertical />
      </Container>
      <DropdownMenu open={isDropdownOpen} onClickOutside={() => setIsDropdownOpen(false)}>
        {children}
      </DropdownMenu>
    </Container>
  );
};
