import { JSONSchema7 } from "json-schema";

export const VideoMetadataSchema: JSONSchema7 = {
  description: "Video that can be watched by the user.",
  type: "object",
  properties: {
    videoTitle: {
      description: "Title of the video",
      minLength: 1,
      type: "string",
    },
    videoImageUrl: {
      description: "The video placeholder image url",
      minLength: 1,
      type: "string",
    },
    videoId: {
      description: "The vimeo video id to show",
      minLength: 1,
      type: "string",
    },
  },
  required: ["videoId", "videoImageUrl", "videoTitle"],
  $schema: "http://json-schema.org/draft-07/schema#",
};
