import classNames from "classnames";
import React from "react";
import ReactMarkdown from "react-markdown";
import { Column } from "../Column/Column";
import { FlexProps } from "../Flex/Flex";
import styles from "./Markdown.module.scss";

export const Markdown: React.FC<FlexProps> = ({ className, children, ...rest }) => {
  if (typeof children !== "string") {
    throw new Error(`Expected markdwn children to be a string but got ${typeof children}`);
  }

  return (
    <Column className={classNames(styles.markdown, className)} {...rest}>
      <ReactMarkdown>{children}</ReactMarkdown>
    </Column>
  );
};
