import classNames from "classnames";
import colorInterpolate from "color-interpolate";
import React from "react";
import { Container } from "../Container/Container";
import { FlexProps } from "../Flex/Flex";
import { Row } from "../Row/Row";
import styles from "./ScoreModifier.module.scss";

export interface ScoreModifierProps extends FlexProps {
  scoreModifier: number;
  label: string;
  title: string;
}

export const ScoreModifier: React.FC<ScoreModifierProps> = ({ scoreModifier, label, title, className, ...rest }) => (
  <Container
    center
    title={`${title}: ${scoreModifier > 0 ? "+" : ""}${scoreModifier * 100}%`}
    className={classNames(styles["score-modifier"], className)}
    style={{ backgroundColor: colorInterpolate(["#ff0000", "#ffffff", "#00ff00"])((scoreModifier + 1.0) / 2.0) }}
    {...rest}
  >
    {label}
  </Container>
);

export interface ScoreModifiersProps extends FlexProps {
  feeling: {
    scoreModifierHappiness: number;
    scoreModifierPositivity: number;
    scoreModifierEnergy: number;
    scoreModifierSleepQuality: number;
    scoreModifierMentalPower: number;
    scoreModifierConfidence: number;
    scoreModifierGratitude: number;
    scoreModifierAttraction: number;
  };
}

export const ScoreModifiers: React.FC<ScoreModifiersProps> = ({ feeling, className, ...rest }) => (
  <Row className={classNames(styles["score-modifiers"], className)} {...rest}>
    <ScoreModifier label="H" title="Happyness" scoreModifier={feeling.scoreModifierHappiness} />
    <ScoreModifier label="P" title="Positivity" scoreModifier={feeling.scoreModifierPositivity} />
    <ScoreModifier label="E" title="Energy" scoreModifier={feeling.scoreModifierEnergy} />
    <ScoreModifier label="S" title="Sleep quality" scoreModifier={feeling.scoreModifierSleepQuality} />
    <ScoreModifier label="M" title="Mental power" scoreModifier={feeling.scoreModifierMentalPower} />
    <ScoreModifier label="C" title="Confidence" scoreModifier={feeling.scoreModifierConfidence} />
    <ScoreModifier label="G" title="Gratitude" scoreModifier={feeling.scoreModifierGratitude} />
    <ScoreModifier label="A" title="Attraction" scoreModifier={feeling.scoreModifierAttraction} />
  </Row>
);
