import React, { useMemo, useState } from "react";
import { useSetRecoilState } from "recoil";
import { Action } from "../../components/ActionBar/ActionBar";
import { Header } from "../../components/Header/Header";
import { Title } from "../../components/Title/Title";
import { toastState, ToastType } from "../../components/Toast/Toast";
import { FeedTestUserStatusModal } from "../../modals/FeedTestUserStatusModal/FeedTestUserStatusModal";
import { FeedTestUserDetailsProps } from "./FeedTestUserDetailsView";

export const FeedTestUserDetailsHeader: React.FC<FeedTestUserDetailsProps> = ({ feedTestUser, ...rest }) => {
  const setToast = useSetRecoilState(toastState);
  const [isFeedTestUserStatusModalOpen, setIsFeedTestUserStatusModalOpen] = useState(false);

  // header buttons configuration
  const actions: Action[] = useMemo(
    () => [
      {
        label: feedTestUser.isActive ? "Disable" : "Enable",
        onClick: () => setIsFeedTestUserStatusModalOpen(true),
      },
    ],
    [feedTestUser.isActive],
  );

  // header navigation configuration
  const navigation: Action[] = useMemo(() => [], []);

  return (
    <>
      <Header actions={actions} navigation={navigation} {...rest}>
        <Title>Test user {feedTestUser.userId}</Title>
      </Header>

      <FeedTestUserStatusModal
        feedTestUser={feedTestUser}
        open={isFeedTestUserStatusModalOpen}
        onClickOutside={() => setIsFeedTestUserStatusModalOpen(false)}
        onCompleted={() => {
          // show success toast message
          setToast({
            type: ToastType.SUCCESS,
            title: "Feed test user status updated",
            message: "",
            isOpen: true,
          });

          // hide modal
          setIsFeedTestUserStatusModalOpen(false);
        }}
      />
    </>
  );
};
