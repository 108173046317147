import { SortDirectionEnum, SortingInput } from "../schema";

export function getSortOptionsFromSearchParams(sortString: string): SortingInput[] {
  const sortingOptions: SortingInput[] = [];

  const sortParts = sortString.split(";");

  for (const sortPart of sortParts) {
    if (sortPart.length !== 0) {
      const parts = sortPart.split(",");

      sortingOptions.push({ orderBy: parts[0], direction: parts[1] as SortDirectionEnum });
    }
  }

  return sortingOptions;
}
