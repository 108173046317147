import { JSONSchema7 } from "json-schema";

export const NextJourneyPreviewMetadataSchema: JSONSchema7 = {
  description:
    "Recomments watching the trailer of the next journey.\n\nTriggered by scheduler daily.\n\nCreated by `NEXT_JOURNEY_PREVIEW` event.",
  type: "object",
  properties: {
    nextJourneyId: {
      description: "Id of the next journey",
      type: "number",
    },
    nextJourneyName: {
      description: "Name of the next journey",
      type: "number",
    },
    nextJourneyTrailerVideoId: {
      description: "The vimeo video id to show",
      type: "string",
    },
    nextJourneyTrailerImageUrl: {
      description: "The video poster image url to show before starting the video",
      type: "string",
    },
  },
  required: ["nextJourneyId", "nextJourneyName", "nextJourneyTrailerImageUrl", "nextJourneyTrailerVideoId"],
  $schema: "http://json-schema.org/draft-07/schema#",
};
