import { FormFieldOptions } from "../components/GeneratedForm/GeneratedForm";
import { AdminFeedItemTypeEnum, UserFeedItemTypeEnum } from "../schema";
import { unifiedMetadataSchemaMap } from "./getMetadataSchema";

export type MetadataFormFields = Array<MetadataFormFieldOptions>;

export type MetadataFormFieldOptions = Pick<FormFieldOptions, "name" | "field">;

export const getMetadataFromFields = (data: Array<Object>, itemType: AdminFeedItemTypeEnum | UserFeedItemTypeEnum) => {
  const result = {};
  const schema = unifiedMetadataSchemaMap[itemType];

  data.flatMap(Object.entries).forEach((formField) => {
    const [key, value] = formField;
    const properties = schema?.properties;

    let fieldProperty = null;

    if (properties && key in properties) {
      fieldProperty = properties[key] as { description: string; type: string };
    }

    if (Array.isArray(value)) {
      const valuesArray = value.flatMap(Object.entries).reduce((acc, cur) => {
        acc.push(cur[1]);

        return acc;
      }, [] as string[]);
      Object.assign(result, { [key]: valuesArray });
    } else {
      const parsedValue =
        fieldProperty && fieldProperty.type === "number"
          ? parseInt(value)
          : transformMetadataFieldValue(itemType, formField);
      Object.assign(result, { [key]: parsedValue });
    }
  });

  return result;
};

// transform any type of feed item metadata to the format that will be sent to database
function transformMetadataFieldValue(type: AdminFeedItemTypeEnum | UserFeedItemTypeEnum, formField: [string, any]) {
  const [key, value] = formField;

  // handle quote metadata transformation
  if (type === AdminFeedItemTypeEnum.QUOTE) {
    if (key === "quoteAuthorName") {
      return value === "" ? null : value;
    }
    if (key === "quoteText") {
      return value === "" ? null : value;
    }
  }

  return value;
}
