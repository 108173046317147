import React, { useEffect } from "react";

export function useOnAnimationEnd(ref: React.RefObject<HTMLElement>, handler: VoidFunction) {
  useEffect(() => {
    const element = ref.current;

    if (!element) {
      return;
    }

    element.addEventListener("animationend", handler);

    return () => {
      if (!element) {
        return;
      }

      element.removeEventListener("animationend", handler);
    };
  }, [handler, ref]);
}
