import classNames from "classnames";
import React from "react";
import { Container } from "../Container/Container";
import { FlexProps } from "../Flex/Flex";
import styles from "./Logo.module.scss";

export interface LogoProps extends FlexProps {
  left?: boolean;
  right?: boolean;
}

export const Logo: React.FC<LogoProps> = ({ left, right, small, className, ...rest }) => (
  <Container
    className={classNames(
      styles.logo,
      {
        [styles["logo--left"]]: left,
        [styles["logo--right"]]: right,
        [styles["logo--small"]]: small,
      },
      className,
    )}
    {...rest}
  ></Container>
);
