import { sentenceCase } from "change-case";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { DataTable, DataTableAction, DataTableHeader, DataTableRow } from "../../components/DataTable/DataTable";
import { FilterBaseData } from "../../components/Filter/Filter";
import { Pagination } from "../../components/Pagination/Pagination";
import { View } from "../../components/View/View";
import { useUrlParams } from "../../hooks/useUrlParams";
import { ADMIN_VIEW_PATH, AdminViewParams } from "../../routes";
import { ConditionModeEnum, MatchModeEnum, UserScopeEnum, useUserFeedItemMetadataSettingsQuery } from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getPageCount } from "../../services/getPageCount";
import { getSkipTake } from "../../services/getSkipTake";
import { getUrlSearchParamsString } from "../../services/getUrlSearchParamsString";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";

interface UserFeedItemMetadataSettingsFilterData extends FilterBaseData {}

export const UserFeedItemMetadataSettingsListView: React.FC<AdminViewProps> = () => {
  const navigate = useNavigate();

  const params = useUrlParams<UserFeedItemMetadataSettingsFilterData>((params) => ({
    matchMode: MatchModeEnum.STARTS_WITH,
    conditionMode: ConditionModeEnum.AND,
    page: params.page ? parseInt(params.page, 10) : 1,
  }));

  const { data, loading, error } = useUserFeedItemMetadataSettingsQuery({
    variables: {
      pagination: {
        ...getSkipTake(params.page),
      },
    },
  });

  const userFeedItemMetadataSetting = useMemo(
    () => data?.userFeedItemMetadataSettings.userFeedItemMetadataSettings ?? [],
    [data?.userFeedItemMetadataSettings.userFeedItemMetadataSettings],
  );
  const total = data?.userFeedItemMetadataSettings.total ?? 0;
  const pageCount = getPageCount(total);

  const headers = useMemo<DataTableHeader[]>(
    () => [
      {
        label: "Type",
      },
      {
        label: "Comment",
      },
    ],
    [],
  );

  const rows = useMemo<DataTableRow[]>(
    () =>
      userFeedItemMetadataSetting.map((setting) => {
        return {
          id: setting.id,
          cells: [
            {
              content: sentenceCase(setting.type),
            },
            {
              content: setting.comment,
            },
          ],
          actions: [
            {
              label: "View details",
              authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.USER_FEED_ITEM_METADATA_INFO],
              onClick: (settingId) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "user-feed",
                    page: "metadata",
                    id: settingId,
                  }),
                }),
            },
            {
              label: "Edit",
              authorizedScopes: [
                UserScopeEnum.SUPERADMIN,
                UserScopeEnum.USER_FEED_ITEM_METADATA_INFO,
                UserScopeEnum.USER_FEED_ITEM_METADATA_UPDATE,
              ],
              onClick: (settingId) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "user-feed",
                    page: "metadata",
                    id: settingId,
                    modifier: "edit",
                  }),
                }),
            },
          ],
        };
      }),
    [navigate, userFeedItemMetadataSetting],
  );

  const bulkActions = useMemo<DataTableAction<string[]>[]>(() => [], []);

  if (error) {
    return <ErrorView title="Fetching metadata settings failed" error={error} />;
  }

  return (
    <>
      <View scrollable>
        <DataTable
          headers={headers}
          rows={rows}
          loading={loading}
          stats={{ resultCount: total, pageCount }}
          bulkActions={bulkActions}
          openAuthorizedScopes={[UserScopeEnum.SUPERADMIN, UserScopeEnum.USER_FEED_ITEM_METADATA_INFO]}
          onOpen={(row) =>
            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                menu: "user-feed",
                page: "metadata",
                modifier: row.id,
              }),
            })
          }
        />
        <Pagination
          sticky
          pageCount={pageCount}
          currentPage={params.page}
          onPageChange={(page) =>
            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "user-feed", page: "metadata" }),
              search: getUrlSearchParamsString({ ...params, page }),
            })
          }
        />
      </View>
    </>
  );
};
