import classNames from "classnames";
import React, { useState, useEffect } from "react";
import { FieldError, FieldErrors, Merge, UseFormRegisterReturn } from "react-hook-form";
import { Flex } from "../Flex/Flex";
import { P } from "../Paragraph/Paragraph";
import { Row } from "../Row/Row";
import styles from "./TextArea.module.scss";

// TODO: make textarea auto grow
export interface TextAreaProps extends React.ComponentPropsWithoutRef<"textarea"> {
  name: string;
  label?: string;
  defaultValue?: string;
  error?: FieldError | Merge<FieldError, FieldErrors<any>>;
  register?: UseFormRegisterReturn;
  rows?: number;
  maxCharacters?: number;
  onClear?(): void;
}

export const TextArea = React.forwardRef<HTMLInputElement, TextAreaProps>(function TextArea(
  {
    name,
    label,
    defaultValue,
    error,
    register,
    rows = 5,
    maxCharacters,
    onClear: _onClear, // TODO: implement clearing
    ...rest
  },
  ref,
) {
  const [hasValue, setHasValue] = useState(typeof defaultValue === "string" && defaultValue.length > 0);
  const [hasFocus, setHasFocus] = useState(false);
  const [charactersRemaining, setCharactersRemaining] = useState(maxCharacters ?? 0);
  const [value, setValue] = useState<string>();

  useEffect(() => {
    if (maxCharacters) {
      const defaultValueLength = defaultValue ? defaultValue.length : 0;

      setCharactersRemaining(maxCharacters - defaultValueLength);
    }
  }, [maxCharacters, defaultValue]);

  const handleValueChange = (value: string) => {
    const hasValueNow = value.length > 0;

    // only update state if having value has changed
    if (hasValueNow !== hasValue) {
      setHasValue(hasValueNow);
    }

    if (maxCharacters && maxCharacters >= value.length) {
      setCharactersRemaining(maxCharacters - value.length);
      setValue(value);
    }
  };

  return (
    <Flex
      column
      ref={ref}
      className={classNames({
        [styles["textarea--has-value"]]: hasValue,
        [styles["textarea--has-focus"]]: hasFocus,
        [styles["textarea--has-error"]]: error !== undefined,
      })}
    >
      <label htmlFor={name} className={styles.label}>
        {label}
      </label>
      <textarea
        id={name}
        name={name}
        defaultValue={defaultValue}
        value={value}
        className={styles.input}
        rows={rows}
        onChange={(e) => handleValueChange(e.target.value)}
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
        maxLength={maxCharacters}
        {...register}
        {...rest}
      />
      <div className={styles.border}></div>
      <Row mainAxisAlignment="space-between">
        <P inline tiny className={styles.error}>
          {error && error.message}
        </P>
        {maxCharacters && <P>{charactersRemaining.toString()}</P>}
      </Row>
    </Flex>
  );
});
