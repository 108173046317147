import { useMemo } from "react";
import { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { WithCollaboration } from "../../components/Collaboration/Collaboration";
import { FormFieldOptions, GeneratedForm } from "../../components/GeneratedForm/GeneratedForm";
import { toastState, ToastType } from "../../components/Toast/Toast";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import { CollaborationTypeEnum, useUpdateConclusionOptionMutation } from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getTranslationInput } from "../../services/getTranslationInput";
import { ConclusionOptionDetailsProps } from "./ConclusionOptionDetailsView";

interface EditConclusionOptionFormData {
  text: string;
  comment: string;
}

export const ConclusionOptionDetailsEdit: React.FC<ConclusionOptionDetailsProps> = ({ conclusionOption, viewer }) => {
  const navigate = useNavigate();
  const setToast = useSetRecoilState(toastState);

  const [updateConclusionOption, updateConclusionOptionResult] = useUpdateConclusionOptionMutation({
    refetchQueries: ["CollaborationById", "ConclusionOptionById"],
    awaitRefetchQueries: true,
    onCompleted: () => {
      setToast({
        type: ToastType.SUCCESS,
        title: "Conclusion option has been updated",
        message: `Updated data for item ${conclusionOption.id}`,
        isOpen: true,
      });

      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
          menu: "clarity-score",
          page: "conclusion-options",
          id: conclusionOption.id,
        }),
      });
    },
  });

  const fields = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "translation",
        name: "text",
        label: "Text",
        rules: { required: "Please provide Text" },
        defaultValue: conclusionOption.textTranslations,
      },
      {
        field: "textarea",
        type: "text",
        name: "comment",
        label: "Collaboration comment",
        rules: {
          required: "Please describe what and why was updated",
        },
        defaultValue: "Updated conclusion option",
      },
    ],
    [conclusionOption.textTranslations],
  );

  const onSubmit: SubmitHandler<EditConclusionOptionFormData> = async (data) => {
    updateConclusionOption({
      variables: {
        ...conclusionOption,
        ...data,
        conclusionOptionId: conclusionOption.id,
        text: getTranslationInput("text", { ...data }),
        comment: data.comment,
      },
    });
  };

  return (
    <WithCollaboration referenceId={conclusionOption.id} type={CollaborationTypeEnum.CONCLUSION_OPTION} viewer={viewer}>
      <GeneratedForm
        title="Edit conclusion option"
        error={updateConclusionOptionResult.error}
        loading={updateConclusionOptionResult.loading}
        submitText="Update"
        onSubmit={onSubmit}
      >
        <GeneratedForm.Fields fields={fields} />
      </GeneratedForm>
    </WithCollaboration>
  );
};
