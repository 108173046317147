/* eslint-disable */

export type PossibleTypesResultData = {
  possibleTypes: {
    UserInterface: ["User", "Viewer"];
  };
};
const result: PossibleTypesResultData = {
  possibleTypes: {
    UserInterface: ["User", "Viewer"],
  },
};
export default result;
