import { useMemo } from "react";
import { Modal, ModalAction, ModalProps } from "../../components/Modal/Modal";
import { P } from "../../components/Paragraph/Paragraph";
import {
  AdminFeedItemStatusEnum,
  UpdateAdminFeedItemStatusInteractionMutation,
  useUpdateAdminFeedItemStatusInteractionMutation,
} from "../../schema";

export interface AdminFeedItemStatusRejectionModalProps extends ModalProps {
  previousStatus?: AdminFeedItemStatusEnum;
  adminFeedItemId: string;
  onCompleted: (data: UpdateAdminFeedItemStatusInteractionMutation) => void;
}

export const AdminFeedItemStatusRejectionModal: React.FC<AdminFeedItemStatusRejectionModalProps> = ({
  adminFeedItemId,
  onCompleted,
  onClickOutside,
  ...rest
}) => {
  const [updateAdminFeedItemStatusInteraction, updateAdminFeedItemStatusInteractionResult] =
    useUpdateAdminFeedItemStatusInteractionMutation({
      refetchQueries: [
        "CollaborationById",
        "AdminFeedItemById",
        "UserAdminFeedItemStatusInteraction",
        "AdminFeedPollById",
      ],
      awaitRefetchQueries: true,
      onCompleted,
    });

  const actions = useMemo<ModalAction[]>(
    () => [
      {
        label: "Cancel",
        onClick: onClickOutside,
      },
      {
        label: "Confirm",
        danger: true,
        onClick: () =>
          updateAdminFeedItemStatusInteraction({
            variables: {
              adminFeedItemId: adminFeedItemId,
              status: AdminFeedItemStatusEnum.REJECTED,
            },
          }),
      },
    ],
    [onClickOutside, updateAdminFeedItemStatusInteraction, adminFeedItemId],
  );

  return (
    <Modal
      {...rest}
      title="Reject admin feed item"
      error={updateAdminFeedItemStatusInteractionResult.error}
      onClickOutside={onClickOutside}
      actions={actions}
    >
      <P center>Are you sure you want to reject feed item?</P>
      <P center small padTop="half">
        This action will remove feed item from all user feeds who are eligible to see it.
      </P>
    </Modal>
  );
};
