export enum NumericOptionDecimalPrecision {
  TENTHS = "TENTHS",
  HUNDRETHS = "HUNDRETHS",
}

export interface ValidateNumericOptions {
  min?: number;
  max?: number;
  optional?: boolean;
  message?: string;
  disableEmptyValue?: boolean;
  maxDecimalPrecision?: NumericOptionDecimalPrecision;
}

export const validateNumeric =
  (options: ValidateNumericOptions = {}) =>
  (value: string) => {
    if (options.disableEmptyValue && value === "") {
      return `Field must not be empty`;
    }

    if (value === "" && options.optional) {
      return true;
    }

    const numeric = parseFloat(value);

    if (isNaN(numeric)) {
      return options.message ?? `Expected a numeric value`;
    }

    if (options.min !== undefined && numeric < options.min) {
      return options.message ?? `Expected minimum value of ${options.min}`;
    }

    if (options.max !== undefined && numeric > options.max) {
      return options.message ?? `Expected maximum value of ${options.max}`;
    }

    if (options.maxDecimalPrecision === NumericOptionDecimalPrecision.TENTHS) {
      const splitValue = value.split(".");

      if (splitValue.length > 1 && splitValue[1].length > 1) {
        return options.message ?? `Expected maximum decimal precision is tenths`;
      }
    }

    if (options.maxDecimalPrecision === NumericOptionDecimalPrecision.HUNDRETHS) {
      const splitValue = value.split(".");

      if (splitValue.length > 1 && splitValue[1].length > 2) {
        return options.message ?? `Expected maximum decimal precision is hundredths`;
      }
    }

    return true;
  };
