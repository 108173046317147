import React, { useMemo } from "react";
import { FormFieldOptions, GeneratedForm } from "../../components/GeneratedForm/GeneratedForm";
import { Modal, ModalProps } from "../../components/Modal/Modal";
import {
  CreateQuestionMutation,
  QuestionTypeEnum,
  TopicTypeEnum,
  TranslationMap,
  useCreateQuestionMutation,
} from "../../schema";
import { getEnumFormOptions } from "../../services/getEnumFormOptions";
import { getTranslationInput } from "../../services/getTranslationInput";

type CreateQuestionData = {
  topic: TopicTypeEnum;
  type: QuestionTypeEnum;
  question: TranslationMap;
};

export interface CreateQuestionModalProps extends ModalProps {
  onCompleted: (data: CreateQuestionMutation) => void;
}

export const CreateQuestionModal: React.FC<CreateQuestionModalProps> = ({ onCompleted, ...rest }) => {
  const [createQuestion, createQuestionResult] = useCreateQuestionMutation({
    refetchQueries: ["Questions"],
    awaitRefetchQueries: true,
    onCompleted,
  });

  const fields = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "radio",
        name: "topic",
        label: "Topic",
        options: getEnumFormOptions(TopicTypeEnum),
        rules: { required: "Please provide question topic" },
      },
      {
        field: "radio",
        name: "type",
        label: "Type",
        options: getEnumFormOptions(QuestionTypeEnum),
        rules: { required: "Please provide question type" },
      },
      {
        field: "translation",
        type: "text",
        name: "question",
        label: "Question",
        rules: { required: "Please provide Question" },
      },
    ],
    [],
  );

  return (
    <Modal title="Create new Question" error={createQuestionResult.error} {...rest}>
      <GeneratedForm
        loading={createQuestionResult.loading}
        error={createQuestionResult.error}
        onSubmit={(data: CreateQuestionData) =>
          createQuestion({
            variables: {
              ...data,
              question: getTranslationInput("question", data),
              topic: data.topic,
              type: data.type,
            },
          })
        }
      >
        <GeneratedForm.Fields fields={fields} />
      </GeneratedForm>
    </Modal>
  );
};
