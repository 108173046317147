import React from "react";
import { FlexBase, FlexTag, FlexProps } from "../Flex/Flex";

export type FormProps = FlexProps<"form">;

class FormFlex extends FlexBase<"form"> {
  tag: FlexTag = "form";
}

export const Form = React.forwardRef<HTMLFormElement, FormProps>(function Form(props, ref) {
  return <FormFlex overflow {...props} flexRef={ref} />;
});
