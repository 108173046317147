import React from "react";
import { WithCollaboration } from "../../components/Collaboration/Collaboration";
import { NameValueList, NameValueListItem } from "../../components/NameValuePair/NameValuePair";
import { CollaborationTypeEnum, ConclusionOptionInfoFragment } from "../../schema";
import { renderTranslationMap } from "../../services/renderTranslationMap";
import { ConclusionOptionDetailsProps } from "./ConclusionOptionDetailsView";

export const ConclusionOptionDetailsInfo: React.FC<ConclusionOptionDetailsProps> = ({ conclusionOption, viewer }) => (
  <WithCollaboration referenceId={conclusionOption.id} type={CollaborationTypeEnum.CONCLUSION_OPTION} viewer={viewer}>
    <NameValueList items={getConclusionOptionNameValueList(conclusionOption)} />
  </WithCollaboration>
);

export function getConclusionOptionNameValueList(conclusionOption: ConclusionOptionInfoFragment): NameValueListItem[] {
  return [
    {
      name: "Unique id",
      value: conclusionOption.id,
    },
    {
      name: "Min score",
      value: conclusionOption.minClarityScore,
    },
    {
      name: "Max score",
      value: conclusionOption.maxClarityScore,
    },
    {
      name: "Version",
      value: conclusionOption.version,
    },
    {
      name: "Text",
      value: renderTranslationMap(conclusionOption.textTranslations),
    },
  ];
}
