import { Flex, FlexProps } from "../Flex/Flex";

export interface ThumbnailProps extends FlexProps {
  src: string;
  alt?: string;
}

export const Thumbnail: React.FC<ThumbnailProps> = ({ src, alt, ...rest }) => {
  if (!src) {
    return null;
  }

  return (
    <Flex {...rest}>
      <a target="_blank" rel="noreferrer" href={src}>
        <img src={src} style={{ width: "150px" }} alt={alt ?? ""} />
      </a>
    </Flex>
  );
};
