import { sentenceCase } from "change-case";
import React from "react";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Column } from "../../components/Column/Column";
import { DataTable, DataTableHeader, DataTableRow, DataTableAction } from "../../components/DataTable/DataTable";
import { ScoreModifiers } from "../../components/ScoreModifier/ScoreModifier";
import { Title } from "../../components/Title/Title";
import { View } from "../../components/View/View";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import { UserScopeEnum, useFeelingsQuery } from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";

export const FeelingListView: React.FC<AdminViewProps> = () => {
  const navigate = useNavigate();

  // fetch items
  const { data, loading, error } = useFeelingsQuery();

  // get items
  const feelings = useMemo(() => data?.feelings ?? [], [data?.feelings]);

  // data table headers
  const headers = useMemo<DataTableHeader[]>(
    () => [
      {
        label: "Type",
      },
      {
        label: "Name",
      },
      {
        label: "Modifiers",
        compact: true,
      },
    ],
    [],
  );

  // data table rows
  const rows = useMemo<DataTableRow[]>(
    () =>
      feelings.map((feeling) => {
        return {
          id: feeling.id,
          cells: [
            {
              content: sentenceCase(feeling.type),
            },
            {
              content: feeling.name,
            },
            {
              content: <ScoreModifiers feeling={feeling} />,
            },
          ],
          actions: [
            {
              label: "View details",
              authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.FEELINGS, UserScopeEnum.FEELINGS_INFO],
              onClick: (feelingId) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "clarity-score",
                    page: "feelings",
                    id: feelingId,
                  }),
                }),
            },
            {
              label: "Edit",
              authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.FEELINGS, UserScopeEnum.FEELINGS_UPDATE_INFO],
              onClick: (feelingId) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "clarity-score",
                    page: "feelings",
                    id: feelingId,
                    modifier: "edit",
                  }),
                }),
            },
          ],
        };
      }),
    [navigate, feelings],
  );

  // data table bulk actions
  const bulkActions = useMemo<DataTableAction<string[]>[]>(() => [], []);

  // handle errors
  if (error) {
    return <ErrorView title="Fetching feelings failed" error={error} />;
  }

  // render view
  return (
    <View scrollable>
      <Column pad="half">
        <Title>Feelings</Title>
      </Column>
      <DataTable
        headers={headers}
        rows={rows}
        loading={loading}
        bulkActions={bulkActions}
        openAuthorizedScopes={[UserScopeEnum.SUPERADMIN, UserScopeEnum.FEELINGS, UserScopeEnum.FEELINGS_INFO]}
        onOpen={(row) =>
          navigate({
            pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
              menu: "clarity-score",
              page: "feelings",
              modifier: row.id,
            }),
          })
        }
      />
    </View>
  );
};
