import React, { useMemo } from "react";
import { FormFieldOptions, GeneratedForm } from "../../components/GeneratedForm/GeneratedForm";
import { Modal, ModalProps } from "../../components/Modal/Modal";
import { P } from "../../components/Paragraph/Paragraph";
import { CreateAdminUserMutation, useCreateAdminUserMutation, UserRoleEnum } from "../../schema";
import { getEnumFormOptions } from "../../services/getEnumFormOptions";
import { validateEmail } from "../../validators/validateEmail";

type CreateAdminUserData = {
  name: string;
  email: string;
  roles: UserRoleEnum[];
};

export interface CreateAdminUserModalProps extends ModalProps {
  onCompleted: (data: CreateAdminUserMutation) => void;
}

export const CreateAdminUserModal: React.FC<CreateAdminUserModalProps> = ({ onCompleted, ...rest }) => {
  const [createAdminUser, createAdminUserResult] = useCreateAdminUserMutation({
    refetchQueries: ["Admins"],
    awaitRefetchQueries: true,
    onCompleted,
  });

  const fields = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "text",
        type: "text",
        name: "name",
        label: "Name",
        rules: { required: "Please provide name" },
      },
      {
        field: "text",
        type: "email",
        name: "email",
        label: "Email",
        rules: {
          required: "Please provide email address",
          validate: validateEmail,
        },
      },
      {
        field: "checkbox",
        name: "roles",
        label: "Roles",
        options: getEnumFormOptions(UserRoleEnum),
        rules: { required: "Please choose at least one role" },
      },
    ],
    [],
  );

  return (
    <Modal title="Create new admin user" error={createAdminUserResult.error} {...rest}>
      <P small>
        The created admin user will receive a generated password by email. Upon logging in for the first time, the user
        will be asked to change the password.
      </P>
      <GeneratedForm
        loading={createAdminUserResult.loading}
        error={createAdminUserResult.error}
        onSubmit={(data: CreateAdminUserData) =>
          createAdminUser({
            variables: data,
          })
        }
      >
        <GeneratedForm.Fields fields={fields} />
      </GeneratedForm>
    </Modal>
  );
};
