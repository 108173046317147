import React from "react";
import { FlexProps } from "../../components/Flex/Flex";
import { Loading } from "../../components/Loading/Loading";
import { P } from "../../components/Paragraph/Paragraph";
import { View } from "../../components/View/View";

export interface LoadingViewProps extends FlexProps {
  description?: string;
}

export const LoadingView: React.FC<LoadingViewProps> = React.memo(function LoadingView({ description, ...rest }) {
  return (
    <View center {...rest}>
      <Loading large />
      {description && <P padTop>{description}</P>}
    </View>
  );
});
