/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date only (ISO8601) */
  Date: string;
  /** Date and time (ISO8601) */
  DateTime: string;
  /** Json custom scalar type */
  JSON: any;
  /** Time only (ISO8601) */
  Time: string;
  /** File upload */
  Upload: any;
};

export type AdminFeedItem = {
  __typename?: 'AdminFeedItem';
  /** Feed item target audience */
  audience: Array<AdminFeedItemAudienceEnum>;
  /** Date of creation */
  createdDate: Scalars['DateTime'];
  /** Feed item description */
  description: Scalars['String'];
  /** Optional date to stop showing the item */
  endDate?: Maybe<Scalars['DateTime']>;
  /** Optional number of days since registering to stop showing the item */
  endDay?: Maybe<Scalars['Int']>;
  /** Feeling unique identifier */
  id: Scalars['ID'];
  /** Is the item pinned */
  isPinned: Scalars['Boolean'];
  /** Is the item recommended */
  isRecommended: Scalars['Boolean'];
  /** Number of feed item likes */
  likeCount: Scalars['Int'];
  /** Feed item metadata */
  metadata: Scalars['JSON'];
  /** Number of feed item shares */
  shareCount: Scalars['Int'];
  /** Optional date to start showing the item */
  startDate: Scalars['DateTime'];
  /** Optional number of days since registering to start showing the item */
  startDay?: Maybe<Scalars['Int']>;
  /** Feed item status */
  status: AdminFeedItemStatusEnum;
  /** Feed item topics */
  topics: Array<AdminFeedTopic>;
  /** Feed item type */
  type: AdminFeedItemTypeEnum;
  /** Date of update */
  updatedDate: Scalars['DateTime'];
  /** Metadata version */
  version: Scalars['Int'];
};

export enum AdminFeedItemAudienceEnum {
  FREE = 'FREE',
  PAID = 'PAID'
}

export enum AdminFeedItemStatusEnum {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  STAGING = 'STAGING'
}

export type AdminFeedItemStatusInteraction = {
  __typename?: 'AdminFeedItemStatusInteraction';
  /** Admin feed item id */
  adminFeedItemId: Scalars['ID'];
  /** Admin user id who conducted the review */
  adminUserId: Scalars['ID'];
  /** Date of creation */
  createdDate: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Feed item status */
  status: AdminFeedItemStatusEnum;
  /** Date of update */
  updatedDate: Scalars['DateTime'];
};

export enum AdminFeedItemTypeEnum {
  BACKGROUND_MUSIC = 'BACKGROUND_MUSIC',
  BLOG = 'BLOG',
  CONSULTATION = 'CONSULTATION',
  COURSE = 'COURSE',
  EXPLAIN_AUDIO = 'EXPLAIN_AUDIO',
  EXPLAIN_CLARITY = 'EXPLAIN_CLARITY',
  EXPLAIN_CLARITY_TEST = 'EXPLAIN_CLARITY_TEST',
  EXPLAIN_CUSTOMIZE_FEED = 'EXPLAIN_CUSTOMIZE_FEED',
  EXPLAIN_MORE = 'EXPLAIN_MORE',
  EXTERNAL_LINK = 'EXTERNAL_LINK',
  FACT = 'FACT',
  IMAGE_CAROUSEL = 'IMAGE_CAROUSEL',
  INTRODUCTION = 'INTRODUCTION',
  NEW_LEVEL_AVAILABLE = 'NEW_LEVEL_AVAILABLE',
  NEXT_JOURNEY_PREVIEW = 'NEXT_JOURNEY_PREVIEW',
  POLL = 'POLL',
  QUOTE = 'QUOTE',
  REMIND_CLARITY_TEST = 'REMIND_CLARITY_TEST',
  REVIEW = 'REVIEW',
  SALE = 'SALE',
  SUBSCRIBE_NEWSLETTER = 'SUBSCRIBE_NEWSLETTER',
  SURVEY = 'SURVEY',
  VIDEO = 'VIDEO',
  WELCOME = 'WELCOME'
}

export type AdminFeedItems = {
  __typename?: 'AdminFeedItems';
  /** List of paginated admin feed items */
  adminFeedItems: Array<AdminFeedItem>;
  skip: Scalars['Int'];
  take: Scalars['Int'];
  total: Scalars['Int'];
};

export type AdminFeedItemsFilterInput = {
  /** Filter admin feed items by id */
  adminFeedItemId?: InputMaybe<Scalars['String']>;
  /** Filter admin feed items by audiences */
  audience?: InputMaybe<Array<AdminFeedItemAudienceEnum>>;
  /** Filter admin feed items by description */
  description?: InputMaybe<Scalars['String']>;
  /** Filter admin feed items by item end showing date */
  endDate?: InputMaybe<Scalars['Date']>;
  /** Filter admin feed items end showing day number */
  endDay?: InputMaybe<Scalars['Int']>;
  /** Filter admin feed items by whether they are pinned or not */
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** Filter admin feed items by whether they are recommended or not */
  isRecommended?: InputMaybe<Scalars['Boolean']>;
  /** Filter admin feed items by item start showing date */
  startDate?: InputMaybe<Scalars['Date']>;
  /** Filter admin feed items start showing day number */
  startDay?: InputMaybe<Scalars['Int']>;
  /** Filter admin feed items by status */
  status?: InputMaybe<Array<AdminFeedItemStatusEnum>>;
  /** Filter admin feed items by types */
  type?: InputMaybe<Array<AdminFeedItemTypeEnum>>;
};

export type AdminFeedMediaCategory = {
  __typename?: 'AdminFeedMediaCategory';
  mediaCategoryCode: Scalars['String'];
  product: Array<ProductItemType>;
};

export type AdminFeedPoll = {
  __typename?: 'AdminFeedPoll';
  adminFeedItem: AdminFeedItem;
  adminFeedItemId: Scalars['String'];
  adminFeedPollOptions: Array<AdminFeedPollOption>;
  createdDate: Scalars['Date'];
  /** Poll unique identifier */
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  question: Scalars['JSON'];
  updatedDate: Scalars['Date'];
};

export type AdminFeedPollAnswer = {
  __typename?: 'AdminFeedPollAnswer';
  adminFeedPollId: Scalars['String'];
  adminFeedPollOptionId: Scalars['String'];
  createdDate: Scalars['Date'];
  /** Poll answer unique identifier */
  id: Scalars['ID'];
  updatedDate: Scalars['Date'];
  userId: Scalars['Int'];
};

export type AdminFeedPollOption = {
  __typename?: 'AdminFeedPollOption';
  adminFeedPollId: Scalars['String'];
  answer: Scalars['JSON'];
  createdDate: Scalars['Date'];
  /** Admin feed poll option unique identifier */
  id: Scalars['ID'];
  ordinal: Scalars['Int'];
  updatedDate: Scalars['Date'];
};

export type AdminFeedPollOptionInput = {
  answer: TranslationInput;
  id?: InputMaybe<Scalars['ID']>;
  ordinal: Scalars['Int'];
};

export type AdminFeedPollResult = {
  __typename?: 'AdminFeedPollResult';
  /** Translation map of the poll answer option */
  adminFeedPollOptionAnswer: Scalars['JSON'];
  /** Poll answer option unique identifier */
  adminFeedPollOptionId: Scalars['ID'];
  /** Number of times the option was picked */
  count: Scalars['Int'];
  /** Percentage of total options picked */
  percentage: Scalars['Float'];
};

export type AdminFeedPollResults = {
  __typename?: 'AdminFeedPollResults';
  answerOptions: Array<AdminFeedPollResult>;
  /** Poll unique identifier */
  pollId: Scalars['ID'];
  /** Number of times poll was answered */
  totalCount: Scalars['Int'];
};

export type AdminFeedPolls = {
  __typename?: 'AdminFeedPolls';
  /** List of paginated admin feed polls */
  adminFeedPolls: Array<AdminFeedPoll>;
  skip: Scalars['Int'];
  take: Scalars['Int'];
  total: Scalars['Int'];
};

export type AdminFeedPollsFilterInput = {
  /** Filter polls by id */
  adminFeedPollId?: InputMaybe<Scalars['String']>;
  /** Filter admin feed items by audiences */
  audience?: InputMaybe<Array<AdminFeedItemAudienceEnum>>;
  /** Filter admin feed items by item end showing date */
  endDate?: InputMaybe<Scalars['Date']>;
  /** Filter admin feed items end showing day number */
  endDay?: InputMaybe<Scalars['Int']>;
  /** Filter admin feed items by whether they are pinned or not */
  isPinned?: InputMaybe<Scalars['Boolean']>;
  /** Poll question text in english */
  question?: InputMaybe<Scalars['String']>;
  /** Filter admin feed items by item start showing date */
  startDate?: InputMaybe<Scalars['Date']>;
  /** Filter admin feed items start showing day number */
  startDay?: InputMaybe<Scalars['Int']>;
  /** Filter admin feed items by status */
  status?: InputMaybe<Array<AdminFeedItemStatusEnum>>;
};

export type AdminFeedTopic = {
  __typename?: 'AdminFeedTopic';
  /** Date of creation */
  createdDate: Scalars['DateTime'];
  /** Topic icon url */
  iconUrl: Scalars['String'];
  /** Topic unique identifier */
  id: Scalars['ID'];
  /** Is the topic currently active */
  isActive: Scalars['Boolean'];
  /** Is this topic permanent */
  isPermanent: Scalars['Boolean'];
  /** Topic name */
  name: Scalars['String'];
  /** Date of update */
  updatedDate: Scalars['DateTime'];
};

export type AdminUsers = {
  __typename?: 'AdminUsers';
  skip: Scalars['Int'];
  take: Scalars['Int'];
  total: Scalars['Int'];
  /** List of paginated users */
  users: Array<User>;
};

export type AdminsFilterInput = {
  /** Filter users by email */
  email?: InputMaybe<Scalars['String']>;
  /** Filter users by name */
  name?: InputMaybe<Scalars['String']>;
  /** Filter users registered after given date */
  registeredAfter?: InputMaybe<Scalars['Date']>;
  /** Filter users registered before given date */
  registeredBefore?: InputMaybe<Scalars['Date']>;
  /** Filter users by role */
  role?: InputMaybe<Array<UserRoleEnum>>;
  /** Filter users by status */
  status?: InputMaybe<Array<UserStatusEnum>>;
  /** Filter users by id */
  userId?: InputMaybe<Scalars['String']>;
};

export type ClarityTestConclusionImage = {
  __typename?: 'ClarityTestConclusionImage';
  /** Date of creation */
  createdDate: Scalars['DateTime'];
  /** Conclusion image unique id */
  id: Scalars['ID'];
  /** Url to large size image */
  imageLarge: Scalars['String'];
  /** Url to medium size image */
  imageMedium: Scalars['String'];
  /** Url to small size image */
  imageSmall: Scalars['String'];
  /** Max score value */
  maxScoreValue?: Maybe<Scalars['Float']>;
  /** Min score value */
  minScoreValue: Scalars['Float'];
  /** Image topic */
  topic: TopicTypeEnum;
  /** Date of update */
  updatedDate: Scalars['DateTime'];
};

export type ClarityTestConclusionImages = {
  __typename?: 'ClarityTestConclusionImages';
  /** List of paginated clarity test conclusion images */
  clarityTestConclusionImages: Array<ClarityTestConclusionImage>;
  skip: Scalars['Int'];
  take: Scalars['Int'];
  total: Scalars['Int'];
};

export type ClarityTestConclusionImagesFilterInput = {
  /** Filter clarity test conclusion image by max score */
  maxScoreValue?: InputMaybe<Scalars['Float']>;
  /** Filter clarity test conclusion image by min score */
  minScoreValue?: InputMaybe<Scalars['Float']>;
  /** Filter clarity test conclusion image by topic */
  topic?: InputMaybe<Array<TopicTypeEnum>>;
};

export type Collaboration = {
  __typename?: 'Collaboration';
  /** Serialized entity values before and after the change */
  changes?: Maybe<CollaborationChanges>;
  /** Collaboration comment */
  comment: Scalars['String'];
  /** Date of creation */
  createdDate: Scalars['DateTime'];
  /** Collaboration entry creator */
  creator: User;
  /** Id of the user that created the entry */
  creatorId: Scalars['String'];
  /** Collaboration entry unique id */
  id: Scalars['ID'];
  /** Id of the reference entity */
  referenceId: Scalars['String'];
  /** Type of reference entity */
  type: CollaborationTypeEnum;
  /** Date of update */
  updatedDate: Scalars['DateTime'];
};

export type CollaborationChanges = {
  __typename?: 'CollaborationChanges';
  /** Serialized entity after update */
  after: Scalars['JSON'];
  /** Serialized entity before update */
  before: Scalars['JSON'];
};

export enum CollaborationTypeEnum {
  ADMIN_FEED_ITEM = 'ADMIN_FEED_ITEM',
  ADMIN_FEED_TOPIC = 'ADMIN_FEED_TOPIC',
  CLARITY_TEST_CONCLUSION_IMAGE = 'CLARITY_TEST_CONCLUSION_IMAGE',
  CONCLUSION_OPTION = 'CONCLUSION_OPTION',
  EVENT_SETTING = 'EVENT_SETTING',
  FEED_TEST_USER = 'FEED_TEST_USER',
  FEELING = 'FEELING',
  FILE = 'FILE',
  INFORMATIVE_CONTENT = 'INFORMATIVE_CONTENT',
  QUESTION = 'QUESTION',
  QUESTION_OPTION = 'QUESTION_OPTION',
  USER = 'USER',
  USER_FEED_EVENT = 'USER_FEED_EVENT',
  USER_FEED_ITEM_METADATA_SETTING = 'USER_FEED_ITEM_METADATA_SETTING'
}

export type ConclusionOption = {
  __typename?: 'ConclusionOption';
  /** Date of creation */
  createdDate: Scalars['DateTime'];
  /** Conclusion option unique id */
  id: Scalars['ID'];
  /** Maximum clarity score to show conclusion */
  maxClarityScore?: Maybe<Scalars['Float']>;
  /** Minimum clarity score to show conclusion */
  minClarityScore: Scalars['Float'];
  /** conclusion option text */
  text: Scalars['String'];
  /** Map of text translations */
  textTranslations: TranslationMap;
  /** Type of reference entity */
  type: ConclusionOptionTypeEnum;
  /** Date of update */
  updatedDate: Scalars['DateTime'];
  /** Version of the conclusion */
  version: Scalars['Int'];
};

export type ConclusionOptionFilterInput = {
  /** Filter conclusion option by max score */
  maxClarityScore?: InputMaybe<Scalars['Float']>;
  /** Filter conclusion option by min score */
  minClarityScore?: InputMaybe<Scalars['Float']>;
  /** conclusion option text */
  text?: InputMaybe<Scalars['String']>;
  /** conclusion option version */
  version?: InputMaybe<Scalars['Float']>;
};

export enum ConclusionOptionTypeEnum {
  CONCLUSION = 'CONCLUSION',
  INTRO = 'INTRO'
}

export type ConclusionOptions = {
  __typename?: 'ConclusionOptions';
  /** List of paginated conclusion options */
  conclusionOptions: Array<ConclusionOption>;
  skip: Scalars['Int'];
  take: Scalars['Int'];
  total: Scalars['Int'];
};

export enum ConditionModeEnum {
  AND = 'AND',
  OR = 'OR'
}

export type EventSetting = {
  __typename?: 'EventSetting';
  /** Setting comment */
  comment?: Maybe<Scalars['String']>;
  /** Date of creation */
  createdDate: Scalars['DateTime'];
  /** Setting value data type */
  dataType: EventSettingDataTypeEnum;
  /** Date of delete */
  deletedDate?: Maybe<Scalars['DateTime']>;
  /** Setting unique identifier */
  id: Scalars['ID'];
  /** Setting name */
  name: EventSettingNameEnum;
  /** Date of update */
  updatedDate: Scalars['DateTime'];
  /** Setting value */
  value: Scalars['String'];
};

export enum EventSettingDataTypeEnum {
  DATE = 'DATE',
  FLOAT = 'FLOAT',
  INT = 'INT',
  JSON = 'JSON',
  STRING = 'STRING'
}

export enum EventSettingNameEnum {
  CLARITY_TOPIC_SCORE_CHANGE_THRESHOLD = 'CLARITY_TOPIC_SCORE_CHANGE_THRESHOLD',
  CLARITY_TOTAL_SCORE_CHANGE_THRESHOLD = 'CLARITY_TOTAL_SCORE_CHANGE_THRESHOLD',
  CUSTOMIZE_FEED_ITEM_THRESHOLD = 'CUSTOMIZE_FEED_ITEM_THRESHOLD',
  HIGH_CLARITY_SCORE_THRESHOLD = 'HIGH_CLARITY_SCORE_THRESHOLD',
  LOW_CLARITY_SCORE_THRESHOLD = 'LOW_CLARITY_SCORE_THRESHOLD'
}

export type EventSettings = {
  __typename?: 'EventSettings';
  /** List of paginated event settings */
  eventSettings: Array<EventSetting>;
  skip: Scalars['Int'];
  take: Scalars['Int'];
  total: Scalars['Int'];
};

export type Experiment = {
  __typename?: 'Experiment';
  /** Returns request count for admin users (SUPERADMIN) */
  authorized: Scalars['String'];
  error: Scalars['String'];
  locale: Scalars['String'];
  session: Scalars['String'];
  /** List of all users (SUPERADMIN) */
  users: Array<User>;
};

export type FeedTestUser = {
  __typename?: 'FeedTestUser';
  /** Date of creation */
  createdDate: Scalars['DateTime'];
  /** User unique identifier */
  id: Scalars['String'];
  /** Is test user disabled */
  isActive: Scalars['Boolean'];
  /** Date of update */
  updatedDate: Scalars['DateTime'];
  /** User id */
  userId: Scalars['Int'];
};

export type FeedTestUsers = {
  __typename?: 'FeedTestUsers';
  /** List of paginated test users */
  feedTestUsers: Array<FeedTestUser>;
  skip: Scalars['Int'];
  take: Scalars['Int'];
  total: Scalars['Int'];
};

export type FeedTestUsersFilterInput = {
  /** Filter users by id */
  userId?: InputMaybe<Scalars['Int']>;
};

export type Feeling = {
  __typename?: 'Feeling';
  /** Date of creation */
  createdDate: Scalars['DateTime'];
  /** Feeling unique identifier */
  id: Scalars['ID'];
  /** Feeling name */
  name: Scalars['String'];
  /** Map of name translations */
  nameTranslations: TranslationMap;
  /** Attraction score modifier */
  scoreModifierAttraction: Scalars['Float'];
  /** Confidence score modifier */
  scoreModifierConfidence: Scalars['Float'];
  /** Energy score modifier */
  scoreModifierEnergy: Scalars['Float'];
  /** Gratitude score modifier */
  scoreModifierGratitude: Scalars['Float'];
  /** Happiness score modifier */
  scoreModifierHappiness: Scalars['Float'];
  /** Mental power score modifier */
  scoreModifierMentalPower: Scalars['Float'];
  /** Positivity score modifier */
  scoreModifierPositivity: Scalars['Float'];
  /** Sleep quality score modifier */
  scoreModifierSleepQuality: Scalars['Float'];
  /** Feeling type */
  type: FeelingTypeEnum;
  /** Date of update */
  updatedDate: Scalars['DateTime'];
};

export enum FeelingTypeEnum {
  ANGRY = 'ANGRY',
  ANXIOUS = 'ANXIOUS',
  BLESSED = 'BLESSED',
  BLISSFUL = 'BLISSFUL',
  CALM = 'CALM',
  CONFUSED = 'CONFUSED',
  DEPRESSED = 'DEPRESSED',
  ENERGETIC = 'ENERGETIC',
  EUPHORIC = 'EUPHORIC',
  GRATEFUL = 'GRATEFUL',
  GRIEVING = 'GRIEVING',
  HAPPY = 'HAPPY',
  INSPIRED = 'INSPIRED',
  LOVELY = 'LOVELY',
  MOODY = 'MOODY',
  MOTIVATED = 'MOTIVATED',
  NEUTRAL = 'NEUTRAL',
  PANIC = 'PANIC',
  RELAXED = 'RELAXED',
  SAD = 'SAD',
  SCARED = 'SCARED',
  STRESSED = 'STRESSED',
  WONDERFUL = 'WONDERFUL',
  WORRIED = 'WORRIED'
}

export type File = {
  __typename?: 'File';
  adminUser: User;
  /** Uploader admin user id */
  adminUserId: Scalars['String'];
  /** Date of creation */
  createdDate: Scalars['DateTime'];
  /** File unique identifier */
  fileId: Scalars['String'];
  /** Entity unique identifier */
  id: Scalars['ID'];
  /** File key */
  key: Scalars['String'];
  /** Image size */
  size: Scalars['String'];
  /** Date of update */
  updatedDate: Scalars['DateTime'];
  /** File URL */
  url: Scalars['String'];
};

export type Files = {
  __typename?: 'Files';
  /** List of paginated file references */
  files: Array<File>;
  skip: Scalars['Int'];
  take: Scalars['Int'];
  total: Scalars['Int'];
};

export type FilesFilterInput = {
  /** Filter files added later than this date */
  dateFrom?: InputMaybe<Scalars['Date']>;
  /** Filter files added before this date */
  dateTo?: InputMaybe<Scalars['Date']>;
  /** Filter by uploader user email */
  email?: InputMaybe<Scalars['String']>;
  /** File key */
  key?: InputMaybe<Scalars['String']>;
  /** Filter by uploader user name */
  name?: InputMaybe<Scalars['String']>;
};

export type InformativeContent = {
  __typename?: 'InformativeContent';
  /** Date of creation */
  createdDate: Scalars['DateTime'];
  /** Informative content unique identifier */
  id: Scalars['ID'];
  /** Url to original size image */
  imageLarge: Scalars['String'];
  /** Url to 2/3 size image */
  imageMedium: Scalars['String'];
  /** Url to 1/3 size image */
  imageSmall: Scalars['String'];
  /** Ordinal of the content */
  ordinal: Scalars['Int'];
  /** Map of subTitles translations */
  subTitle: TranslationMap;
  /** Map of text translations */
  text: TranslationMap;
  /** Map of title translations */
  title: TranslationMap;
  /** Informative content type */
  type: InformativeContentEnum;
  /** Date of update */
  updatedDate: Scalars['DateTime'];
};

export enum InformativeContentEnum {
  ABOUT_CLARITY = 'ABOUT_CLARITY',
  CLARITY_TEST_INTRO = 'CLARITY_TEST_INTRO'
}

export type InformativeContentFilterInput = {
  /** Filter informative content by id */
  informativeContentId?: InputMaybe<Scalars['String']>;
  /** Filter informative content ty subTitle */
  subTitle?: InputMaybe<Scalars['String']>;
  /** Filter informative content ty content text */
  text?: InputMaybe<Scalars['String']>;
  /** Filter informative content ty title */
  title?: InputMaybe<Scalars['String']>;
  /** Filter informative content by types */
  type?: InputMaybe<Array<InformativeContentEnum>>;
};

export type InformativeContentResponse = {
  __typename?: 'InformativeContentResponse';
  /** List of paginated informative content results */
  informativeContent: Array<InformativeContent>;
  skip: Scalars['Int'];
  take: Scalars['Int'];
  total: Scalars['Int'];
};

export enum LocaleEnum {
  EN = 'EN',
  ET = 'ET'
}

export type MatchInput = {
  conditionMode?: InputMaybe<ConditionModeEnum>;
  matchMode?: InputMaybe<MatchModeEnum>;
};

export enum MatchModeEnum {
  CONTAINS = 'CONTAINS',
  EXACT = 'EXACT',
  STARTS_WITH = 'STARTS_WITH'
}

export type Mutation = {
  __typename?: 'Mutation';
  /** Adds new collaboration entry (SUPERADMIN, COLLABORATION, COLLABORATION_ADD_COMMENT) */
  addCollaborationComment: Collaboration;
  /** Changes current user password */
  changePassword: Viewer;
  /** Creates a new admin feed item (SUPERADMIN, ADMIN_FEED, ADMIN_FEED_CREATE_ITEM) */
  createAdminFeedItem: AdminFeedItem;
  /** Creates a new admin feed poll (SUPERADMIN, ADMIN_FEED, ADMIN_FEED_CREATE_POLL) */
  createAdminFeedPoll: AdminFeedPoll;
  /** Creates a new admin feed topic (SUPERADMIN, ADMIN_FEED, ADMIN_FEED_CREATE_TOPIC) */
  createAdminFeedTopic: AdminFeedTopic;
  /** Creates a new admin user (SUPERADMIN, ADMINS, ADMINS_CREATE_ADMIN_USER) */
  createAdminUser: User;
  /** Creates a new feed test user (SUPERADMIN) */
  createFeedTestUser: FeedTestUser;
  /** Creates a new Informative content item (SUPERADMIN, CLARITY_TEST_INFORMATIVE_CONTENT, CLARITY_TEST_CREATE_INFORMATIVE_CONTENT) */
  createInformativeContent: InformativeContent;
  /** Creates a new Question (SUPERADMIN, CLARITY_TEST, CLARITY_TEST_CREATE_QUESTION) */
  createQuestion: Question;
  /** Creates a new question option (SUPERADMIN, CLARITY_TEST, CLARITY_TEST_CREATE_QUESTION_OPTION) */
  createQuestionOption: QuestionOption;
  /** Creates a new user feed event (SUPERADMIN, USER_FEED, USER_FEED_CREATE_EVENT) */
  createUserFeedEvent: UserFeedEvent;
  /** Attempts to log user in */
  login: Viewer;
  /** Logs admin in as given user (ADMINS_LOGIN_AS_USER) */
  loginAsAdmin: User;
  /** Logs out signed-in user if any */
  logout: Scalars['Boolean'];
  /** Resets admin user password (SUPERADMIN, ADMINS, ADMINS_UPDATE_INFO, ADMINS_RESET_PASSWORD) */
  resetAdminPassword: User;
  /** Updates users avatar picture (SUPERADMIN, ADMINS, ADMINS_UPDATE_INFO) */
  updateAdminAvatar: User;
  /** Updates admin feed item (SUPERADMIN, ADMIN_FEED, ADMIN_FEED_UPDATE_ITEM) */
  updateAdminFeedItem: AdminFeedItem;
  /** updates approval or rejection of admin feed item by reviewer (SUPERADMIN, ADMIN_FEED, ADMIN_FEED_ITEM_REVIEW) */
  updateAdminFeedItemStatusInteraction: AdminFeedItemStatusInteraction;
  /** Updates admin feed poll (SUPERADMIN, ADMIN_FEED_UPDATE_POLL) */
  updateAdminFeedPoll: AdminFeedPoll;
  /** Updates admin feed topic (SUPERADMIN, ADMIN_FEED, ADMIN_FEED_UPDATE_TOPIC) */
  updateAdminFeedTopic: AdminFeedTopic;
  /** Updates admin user information (SUPERADMIN, ADMINS, ADMINS_UPDATE_INFO) */
  updateAdminInfo: User;
  /** Updates admin user status (SUPERADMIN, ADMINS, ADMINS_UPDATE_INFO, ADMINS_UPDATE_STATUS) */
  updateAdminStatus: Array<User>;
  /** Updates users avatar picture */
  updateAvatar: Viewer;
  /** Updates clarity test conclusion image (SUPERADMIN, CLARITY_TEST, CLARITY_TEST_UPDATE_CONCLUSION_IMAGE) */
  updateClarityTestConclusionImage: ClarityTestConclusionImage;
  /** Updates conclusion option text (SUPERADMIN, CLARITY_TEST, CLARITY_TEST_UPDATE_CONCLUSION_OPTION) */
  updateConclusionOption: ConclusionOption;
  /** Updates event setting (SUPERADMIN, SETTINGS, SETTINGS_UPDATE_INFO) */
  updateEventSetting: EventSetting;
  /** update feed test user (SUPERADMIN) */
  updateFeedTestUser: FeedTestUser;
  /** Updates feeling information (SUPERADMIN, FEELINGS, FEELINGS_UPDATE_INFO) */
  updateFeeling: Feeling;
  /** Updates informative content (SUPERADMIN, CLARITY_TEST_INFORMATIVE_CONTENT, CLARITY_TEST_UPDATE_INFORMATIVE_CONTENT) */
  updateInformativeContent: InformativeContent;
  /** Updates question (SUPERADMIN, CLARITY_TEST, CLARITY_TEST_UPDATE_INFO) */
  updateQuestion: Question;
  /** Updates question option (SUPERADMIN, CLARITY_TEST, CLARITY_TEST_UPDATE_INFO) */
  updateQuestionOption: QuestionOption;
  /** Updates user feed event (SUPERADMIN, USER_FEED, USER_FEED_UPDATE_EVENT) */
  updateUserFeedEvent: UserFeedEvent;
  /** updates user feed item metadata setting (SUPERADMIN, USER_FEED_ITEM_METADATA_INFO, USER_FEED_ITEM_METADATA_UPDATE) */
  updateUserFeedItemMetadataSetting: UserFeedItemMetadataSetting;
  /** Upload image to S3 (SUPERADMIN, FILES) */
  uploadImage: File;
};


export type MutationAddCollaborationCommentArgs = {
  comment: Scalars['String'];
  referenceId: Scalars['ID'];
  type: CollaborationTypeEnum;
};


export type MutationChangePasswordArgs = {
  confirmPassword: Scalars['String'];
  currentPassword?: InputMaybe<Scalars['String']>;
  newPassword: Scalars['String'];
};


export type MutationCreateAdminFeedItemArgs = {
  audience: Array<AdminFeedItemAudienceEnum>;
  description: Scalars['String'];
  endDate?: InputMaybe<Scalars['DateTime']>;
  endDay?: InputMaybe<Scalars['Int']>;
  isPinned: Scalars['Boolean'];
  isRecommended: Scalars['Boolean'];
  likeCount?: InputMaybe<Scalars['Int']>;
  metadata: Scalars['JSON'];
  shareCount?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  startDay?: InputMaybe<Scalars['Int']>;
  topicIds: Array<Scalars['ID']>;
  type: AdminFeedItemTypeEnum;
  version?: InputMaybe<Scalars['Int']>;
};


export type MutationCreateAdminFeedPollArgs = {
  audience: Array<AdminFeedItemAudienceEnum>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  endDay?: InputMaybe<Scalars['Int']>;
  image?: InputMaybe<Scalars['Upload']>;
  isPinned: Scalars['Boolean'];
  isRecommended: Scalars['Boolean'];
  likeCount: Scalars['Int'];
  options: Array<AdminFeedPollOptionInput>;
  question: TranslationInput;
  shareCount: Scalars['Int'];
  startDate?: InputMaybe<Scalars['DateTime']>;
  startDay?: InputMaybe<Scalars['Int']>;
  topicIds: Array<Scalars['ID']>;
  version: Scalars['Int'];
};


export type MutationCreateAdminFeedTopicArgs = {
  icon: Scalars['Upload'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
};


export type MutationCreateAdminUserArgs = {
  email: Scalars['String'];
  name: Scalars['String'];
  roles: Array<UserRoleEnum>;
};


export type MutationCreateFeedTestUserArgs = {
  userId: Scalars['Int'];
};


export type MutationCreateInformativeContentArgs = {
  image?: InputMaybe<Scalars['Upload']>;
  ordinal: Scalars['Int'];
  subTitle: TranslationInput;
  text: TranslationInput;
  title: TranslationInput;
  type: InformativeContentEnum;
};


export type MutationCreateQuestionArgs = {
  question: TranslationInput;
  topic: TopicTypeEnum;
  type: QuestionTypeEnum;
};


export type MutationCreateQuestionOptionArgs = {
  answer: TranslationInput;
  conclusionText: TranslationInput;
  questionId: Scalars['String'];
  score: Scalars['Int'];
  weight: Scalars['Int'];
};


export type MutationCreateUserFeedEventArgs = {
  event: UserFeedEventNameEnum;
  isPinned: Scalars['Boolean'];
  metadata: Scalars['JSON'];
  type: UserFeedItemTypeEnum;
  version: Scalars['Int'];
  visibility: UserFeedItemVisibilityEnum;
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationLoginAsAdminArgs = {
  userId: Scalars['ID'];
};


export type MutationResetAdminPasswordArgs = {
  userId: Scalars['ID'];
};


export type MutationUpdateAdminAvatarArgs = {
  avatar: Scalars['Upload'];
  userId: Scalars['ID'];
};


export type MutationUpdateAdminFeedItemArgs = {
  adminFeedItemId: Scalars['ID'];
  audience: Array<AdminFeedItemAudienceEnum>;
  comment: Scalars['String'];
  description: Scalars['String'];
  endDate?: InputMaybe<Scalars['DateTime']>;
  endDay?: InputMaybe<Scalars['Int']>;
  isPinned: Scalars['Boolean'];
  isRecommended: Scalars['Boolean'];
  likeCount: Scalars['Int'];
  metadata: Scalars['JSON'];
  shareCount: Scalars['Int'];
  startDate?: InputMaybe<Scalars['DateTime']>;
  startDay?: InputMaybe<Scalars['Int']>;
  topicIds: Array<Scalars['ID']>;
  type: AdminFeedItemTypeEnum;
  version: Scalars['Int'];
};


export type MutationUpdateAdminFeedItemStatusInteractionArgs = {
  adminFeedItemId: Scalars['ID'];
  status: AdminFeedItemStatusEnum;
};


export type MutationUpdateAdminFeedPollArgs = {
  adminFeedPollId: Scalars['ID'];
  audience: Array<AdminFeedItemAudienceEnum>;
  comment: Scalars['String'];
  endDate?: InputMaybe<Scalars['DateTime']>;
  endDay?: InputMaybe<Scalars['Int']>;
  image?: InputMaybe<Scalars['Upload']>;
  isPinned: Scalars['Boolean'];
  isRecommended: Scalars['Boolean'];
  likeCount: Scalars['Int'];
  options: Array<AdminFeedPollOptionInput>;
  question: TranslationInput;
  shareCount: Scalars['Int'];
  startDate?: InputMaybe<Scalars['DateTime']>;
  startDay?: InputMaybe<Scalars['Int']>;
  topicIds: Array<Scalars['ID']>;
  version: Scalars['Int'];
};


export type MutationUpdateAdminFeedTopicArgs = {
  adminFeedTopicId: Scalars['ID'];
  comment: Scalars['String'];
  icon?: InputMaybe<Scalars['Upload']>;
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
};


export type MutationUpdateAdminInfoArgs = {
  comment: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  roles: Array<UserRoleEnum>;
  status: UserStatusEnum;
  userId: Scalars['ID'];
};


export type MutationUpdateAdminStatusArgs = {
  status: UserStatusEnum;
  userIds: Array<Scalars['ID']>;
};


export type MutationUpdateAvatarArgs = {
  avatar: Scalars['String'];
};


export type MutationUpdateClarityTestConclusionImageArgs = {
  comment: Scalars['String'];
  conclusionImageId: Scalars['ID'];
  image?: InputMaybe<Scalars['Upload']>;
};


export type MutationUpdateConclusionOptionArgs = {
  comment: Scalars['String'];
  conclusionOptionId: Scalars['ID'];
  text: TranslationInput;
};


export type MutationUpdateEventSettingArgs = {
  collaborationComment: Scalars['String'];
  comment: Scalars['String'];
  eventSettingId: Scalars['ID'];
  value: Scalars['String'];
};


export type MutationUpdateFeedTestUserArgs = {
  comment: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationUpdateFeelingArgs = {
  comment: Scalars['String'];
  feelingId: Scalars['ID'];
  name: TranslationInput;
  scoreModifierAttraction: Scalars['Float'];
  scoreModifierConfidence: Scalars['Float'];
  scoreModifierEnergy: Scalars['Float'];
  scoreModifierGratitude: Scalars['Float'];
  scoreModifierHappiness: Scalars['Float'];
  scoreModifierMentalPower: Scalars['Float'];
  scoreModifierPositivity: Scalars['Float'];
  scoreModifierSleepQuality: Scalars['Float'];
};


export type MutationUpdateInformativeContentArgs = {
  comment: Scalars['String'];
  image?: InputMaybe<Scalars['Upload']>;
  informativeContentId: Scalars['ID'];
  ordinal: Scalars['Int'];
  subTitle: TranslationInput;
  text: TranslationInput;
  title: TranslationInput;
  type: InformativeContentEnum;
};


export type MutationUpdateQuestionArgs = {
  comment: Scalars['String'];
  question: TranslationInput;
  questionId: Scalars['ID'];
  topic: TopicTypeEnum;
  type: QuestionTypeEnum;
};


export type MutationUpdateQuestionOptionArgs = {
  answer: TranslationInput;
  comment: Scalars['String'];
  conclusionText: TranslationInput;
  questionOptionId: Scalars['ID'];
  score: Scalars['Int'];
  weight: Scalars['Int'];
};


export type MutationUpdateUserFeedEventArgs = {
  comment: Scalars['String'];
  event: UserFeedEventNameEnum;
  isPinned: Scalars['Boolean'];
  metadata: Scalars['JSON'];
  type: UserFeedItemTypeEnum;
  userFeedEventId: Scalars['ID'];
  version: Scalars['Int'];
  visibility: UserFeedItemVisibilityEnum;
};


export type MutationUpdateUserFeedItemMetadataSettingArgs = {
  metadata: Scalars['JSON'];
  type: UserFeedItemTypeEnum;
  userFeedItemMetadataSettingId: Scalars['ID'];
};


export type MutationUploadImageArgs = {
  image: Scalars['Upload'];
};

export type PaginationInput = {
  /** Number of items to skip */
  skip?: InputMaybe<Scalars['Int']>;
  /** Number of items to take */
  take?: InputMaybe<Scalars['Int']>;
};

export type ProductItemType = {
  __typename?: 'ProductItemType';
  consumableCode: Array<Scalars['String']>;
  productCode: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  /** Returns admin user by id (SUPERADMIN, ADMINS, ADMINS_INFO) */
  admin: User;
  /** Returns admin feed item by id (SUPERADMIN, ADMIN_FEED, ADMIN_FEED_ITEM_INFO) */
  adminFeedItem: AdminFeedItem;
  /** Returns list of admin feed items (SUPERADMIN, ADMIN_FEED, ADMIN_FEED_ITEM_LIST) */
  adminFeedItems: AdminFeedItems;
  /** Returns list of media category ids (SUPERADMIN, ADMIN_FEED, ADMIN_FEED_ITEM_INFO) */
  adminFeedMediaCategory: Array<AdminFeedMediaCategory>;
  /** Returns admin feed poll by id (SUPERADMIN, ADMIN_FEED_POLL_INFO) */
  adminFeedPoll: AdminFeedPoll;
  /** Returns admin feed poll results by poll id (SUPERADMIN, ADMIN_FEED_POLL_INFO) */
  adminFeedPollResults: AdminFeedPollResults;
  /** Returns list of admin feed polls (SUPERADMIN, ADMIN_FEED_POLL_LIST) */
  adminFeedPolls: AdminFeedPolls;
  /** Returns admin feed topic by id (SUPERADMIN, ADMIN_FEED, ADMIN_FEED_TOPIC_INFO) */
  adminFeedTopic: AdminFeedTopic;
  /** Returns list of admin feed topics (SUPERADMIN, ADMIN_FEED, ADMIN_FEED_TOPIC_LIST) */
  adminFeedTopics: Array<AdminFeedTopic>;
  /** List of users (SUPERADMIN, ADMINS, ADMINS_LIST) */
  admins: AdminUsers;
  /** Returns clarity test conclusion image (SUPERADMIN, CLARITY_TEST, CLARITY_TEST_CONCLUSION_IMAGE) */
  clarityTestConclusionImage: ClarityTestConclusionImage;
  /** Returns list of clarity test conclusion images (SUPERADMIN, CLARITY_TEST_CONCLUSION_IMAGE) */
  clarityTestConclusionImages: ClarityTestConclusionImages;
  /** Returns list of collaboration entries by reference entity id (SUPERADMIN, COLLABORATION, COLLABORATION_ENTRIES) */
  collaboration: Array<Collaboration>;
  /** Returns conclusion option by id (SUPERADMIN, CLARITY_TEST, CLARITY_TEST_CONCLUSION_OPTION) */
  conclusionOption: ConclusionOption;
  /** Returns list of conclusion option texts (SUPERADMIN, CLARITY_TEST, CLARITY_TEST_CONCLUSION_OPTION) */
  conclusionOptions: ConclusionOptions;
  /** Returns event setting by id (SUPERADMIN, SETTINGS, SETTINGS_INFO) */
  eventSetting: EventSetting;
  /** Returns list of settings (SUPERADMIN, SETTINGS, SETTINGS_INFO) */
  eventSettings: EventSettings;
  /** Experiments (SUPERADMIN) */
  experiment: Experiment;
  /** returns feed test user (SUPERADMIN) */
  feedTestUser: FeedTestUser;
  /** Returns list of feed test users (SUPERADMIN) */
  feedTestUsers: FeedTestUsers;
  /** Returns feeling by id (SUPERADMIN, FEELINGS, FEELINGS_INFO) */
  feeling: Feeling;
  /** Returns list of all possible feelings (SUPERADMIN, FEELINGS, FEELINGS_LIST) */
  feelings: Array<Feeling>;
  /** Returns file info by id (SUPERADMIN, FILES) */
  file: File;
  /** Returns list of all available file references (SUPERADMIN, FILES) */
  files: Files;
  /** Returns informative content by id (SUPERADMIN, CLARITY_TEST_INFORMATIVE_CONTENT) */
  informativeContent: InformativeContentResponse;
  /** Returns list of all clarity test question Options (SUPERADMIN, CLARITY_TEST, CLARITY_TEST_UPDATE_INFO) */
  questionOptions: QuestionOptions;
  /** Returns list of all clarity test questions (SUPERADMIN, CLARITY_TEST, CLARITY_TEST_UPDATE_INFO) */
  questions: Questions;
  /** Returns admin user interaction data of asked admin feed item (SUPERADMIN, ADMIN_FEED, ADMIN_FEED_ITEM_REVIEW) */
  userAdminFeedItemStatusInteraction: UserAdminFeedItemStatusInteraction;
  /** Returns user feed event by id (SUPERADMIN, USER_FEED, USER_FEED_EVENT_INFO) */
  userFeedEvent: UserFeedEvent;
  /** Returns list of user feed events (SUPERADMIN, USER_FEED, USER_FEED_EVENT_LIST) */
  userFeedEvents: Array<UserFeedEvent>;
  /** Returns user feed item metadata setting info by id (SUPERADMIN, USER_FEED_ITEM_METADATA_INFO) */
  userFeedItemMetadataSetting: UserFeedItemMetadataSetting;
  /** Returns list of all available user feed item metadata settings references (SUPERADMIN, USER_FEED_ITEM_METADATA_LIST) */
  userFeedItemMetadataSettings: UserFeedItemMetadataSettings;
  /** Server version */
  version: Scalars['String'];
  /** Logged in user */
  viewer?: Maybe<Viewer>;
};


export type QueryAdminArgs = {
  userId: Scalars['ID'];
};


export type QueryAdminFeedItemArgs = {
  adminFeedItemId: Scalars['ID'];
};


export type QueryAdminFeedItemsArgs = {
  filter?: InputMaybe<AdminFeedItemsFilterInput>;
  match?: InputMaybe<MatchInput>;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<Array<SortingInput>>;
};


export type QueryAdminFeedPollArgs = {
  adminFeedPollId: Scalars['ID'];
};


export type QueryAdminFeedPollResultsArgs = {
  adminFeedPollId: Scalars['ID'];
};


export type QueryAdminFeedPollsArgs = {
  filter?: InputMaybe<AdminFeedPollsFilterInput>;
  match?: InputMaybe<MatchInput>;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<Array<SortingInput>>;
};


export type QueryAdminFeedTopicArgs = {
  adminFeedTopicId: Scalars['ID'];
};


export type QueryAdminsArgs = {
  filter?: InputMaybe<AdminsFilterInput>;
  match?: InputMaybe<MatchInput>;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<Array<SortingInput>>;
};


export type QueryClarityTestConclusionImageArgs = {
  conclusionImageId: Scalars['ID'];
};


export type QueryClarityTestConclusionImagesArgs = {
  filter?: InputMaybe<ClarityTestConclusionImagesFilterInput>;
  match?: InputMaybe<MatchInput>;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<Array<SortingInput>>;
};


export type QueryCollaborationArgs = {
  referenceId: Scalars['ID'];
};


export type QueryConclusionOptionArgs = {
  conclusionOptionId: Scalars['ID'];
};


export type QueryConclusionOptionsArgs = {
  filter?: InputMaybe<ConclusionOptionFilterInput>;
  match?: InputMaybe<MatchInput>;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<Array<SortingInput>>;
};


export type QueryEventSettingArgs = {
  eventSettingId: Scalars['ID'];
};


export type QueryEventSettingsArgs = {
  match?: InputMaybe<MatchInput>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryFeedTestUserArgs = {
  id: Scalars['ID'];
};


export type QueryFeedTestUsersArgs = {
  filter?: InputMaybe<FeedTestUsersFilterInput>;
  match?: InputMaybe<MatchInput>;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<Array<SortingInput>>;
};


export type QueryFeelingArgs = {
  feelingId: Scalars['ID'];
};


export type QueryFileArgs = {
  fileId: Scalars['ID'];
};


export type QueryFilesArgs = {
  filter?: InputMaybe<FilesFilterInput>;
  match?: InputMaybe<MatchInput>;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<Array<SortingInput>>;
};


export type QueryInformativeContentArgs = {
  filter?: InputMaybe<InformativeContentFilterInput>;
  match?: InputMaybe<MatchInput>;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<Array<SortingInput>>;
};


export type QueryQuestionOptionsArgs = {
  filter?: InputMaybe<QuestionOptionFilterInput>;
  match?: InputMaybe<MatchInput>;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<Array<SortingInput>>;
};


export type QueryQuestionsArgs = {
  filter?: InputMaybe<QuestionFilterInput>;
  match?: InputMaybe<MatchInput>;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<Array<SortingInput>>;
};


export type QueryUserAdminFeedItemStatusInteractionArgs = {
  adminFeedItemId: Scalars['ID'];
};


export type QueryUserFeedEventArgs = {
  userFeedEventId: Scalars['ID'];
};


export type QueryUserFeedItemMetadataSettingArgs = {
  userFeedItemMetadataSettingId: Scalars['ID'];
};


export type QueryUserFeedItemMetadataSettingsArgs = {
  pagination?: InputMaybe<PaginationInput>;
};

export type Question = {
  __typename?: 'Question';
  /** Question unique identifier */
  id: Scalars['ID'];
  /** Map of question translations */
  question: TranslationMap;
  questionOption: Array<QuestionOption>;
  /** Question topic */
  topic: TopicTypeEnum;
  /** Question Type */
  type: QuestionTypeEnum;
};

export type QuestionFilterInput = {
  /** Filter questions by question text */
  question?: InputMaybe<Scalars['String']>;
  /** Filter questions by id */
  questionId?: InputMaybe<Scalars['String']>;
  /** Filter questions by Topics */
  topic?: InputMaybe<Array<TopicTypeEnum>>;
  /** Filter questions by types */
  type?: InputMaybe<Array<QuestionTypeEnum>>;
};

export type QuestionOption = {
  __typename?: 'QuestionOption';
  /** Map of question answer */
  answer: TranslationMap;
  /** Map of conclusion text */
  conclusionText: TranslationMap;
  /** Question option unique identifier */
  id: Scalars['ID'];
  /** Question option belongs to */
  question: Question;
  questionId: Scalars['String'];
  score: Scalars['Float'];
  weight: Scalars['Float'];
};

export type QuestionOptionFilterInput = {
  /** Filter question by answer */
  answer?: InputMaybe<Scalars['String']>;
  /** Filter question by conclusion text */
  conclusionText?: InputMaybe<Scalars['String']>;
  /** Filter question Option by id */
  questionOptionId?: InputMaybe<Scalars['String']>;
  /** Filter question by score value */
  score?: InputMaybe<Scalars['Int']>;
  /** Filter question by weight value */
  weight?: InputMaybe<Scalars['Int']>;
};

export type QuestionOptions = {
  __typename?: 'QuestionOptions';
  /** List of paginated questions options */
  questionOptions: Array<QuestionOption>;
  skip: Scalars['Int'];
  take: Scalars['Int'];
  total: Scalars['Int'];
};

export enum QuestionTypeEnum {
  CHOICE = 'CHOICE',
  RANGE = 'RANGE'
}

export type Questions = {
  __typename?: 'Questions';
  /** List of paginated questions */
  questions: Array<Question>;
  skip: Scalars['Int'];
  take: Scalars['Int'];
  total: Scalars['Int'];
};

export enum SortDirectionEnum {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type SortingInput = {
  /** Sort rows in DESC or ASC direction */
  direction: SortDirectionEnum;
  /** Sort rows by this column */
  orderBy: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  /** Example timer subscription */
  timer: Scalars['String'];
};

export enum TopicTypeEnum {
  CONFIDENCE = 'CONFIDENCE',
  ENERGY = 'ENERGY',
  GRATITUDE = 'GRATITUDE',
  HAPPINESS = 'HAPPINESS',
  LAW_OF_ATTRACTION = 'LAW_OF_ATTRACTION',
  MENTAL_POWER = 'MENTAL_POWER',
  POSITIVITY = 'POSITIVITY',
  SLEEP_QUALITY = 'SLEEP_QUALITY'
}

export type TranslationInput = {
  /** EN translation */
  EN?: InputMaybe<Scalars['String']>;
  /** ET translation */
  ET?: InputMaybe<Scalars['String']>;
};

export type TranslationMap = {
  __typename?: 'TranslationMap';
  /** EN translation */
  EN?: Maybe<Scalars['String']>;
  /** ET translation */
  ET?: Maybe<Scalars['String']>;
};

export type User = UserInterface & {
  __typename?: 'User';
  /** User avatar url */
  avatarUrl?: Maybe<Scalars['String']>;
  /** Date of creation */
  createdDate: Scalars['DateTime'];
  /** User email address */
  email: Scalars['String'];
  /** Has the password been changed since created */
  hasChangedPassword: Scalars['Boolean'];
  /** User unique id */
  id: Scalars['ID'];
  /** User name */
  name: Scalars['String'];
  /** User roles */
  roles: Array<UserRoleEnum>;
  /** User scopes */
  scopes: Array<UserScopeEnum>;
  /** User status */
  status: UserStatusEnum;
  /** Date of update */
  updatedDate: Scalars['DateTime'];
};

export type UserAdminFeedItemStatusInteraction = {
  __typename?: 'UserAdminFeedItemStatusInteraction';
  /** Is current user creator of the admin feed item */
  isCreator: Scalars['Boolean'];
  /** Current user admin feed item status interaction */
  userInteraction?: Maybe<AdminFeedItemStatusInteraction>;
};

export type UserFeedEvent = {
  __typename?: 'UserFeedEvent';
  /** Date of creation */
  createdDate: Scalars['DateTime'];
  /** Feed event name */
  event: UserFeedEventNameEnum;
  /** Feeling unique identifier */
  id: Scalars['ID'];
  /** Should the generated feed item be pinned */
  isPinned: Scalars['Boolean'];
  /** Feed item metadata */
  metadata: Scalars['JSON'];
  /** Feed item type */
  type: UserFeedItemTypeEnum;
  /** Date of update */
  updatedDate: Scalars['DateTime'];
  /** Metadata version */
  version: Scalars['Int'];
  /** Feed item visibility */
  visibility: UserFeedItemVisibilityEnum;
};

export enum UserFeedEventNameEnum {
  ALL_JOURNEY_LEVELS_COMPLETED = 'ALL_JOURNEY_LEVELS_COMPLETED',
  BIRTHDAY = 'BIRTHDAY',
  CLARITY_SUMMARY = 'CLARITY_SUMMARY',
  CLARITY_TOPIC_SCORE_CHANGED = 'CLARITY_TOPIC_SCORE_CHANGED',
  CLARITY_TOTAL_SCORE_CHANGED = 'CLARITY_TOTAL_SCORE_CHANGED',
  COURSES_SUMMARY = 'COURSES_SUMMARY',
  FEELINGS_SUMMARY = 'FEELINGS_SUMMARY',
  FIRST_REVIEW = 'FIRST_REVIEW',
  HIGH_CLARITY_SCORE = 'HIGH_CLARITY_SCORE',
  JOURNEYS_SUMMARY = 'JOURNEYS_SUMMARY',
  LOW_CLARITY_SCORE = 'LOW_CLARITY_SCORE',
  MINDFUL_MINUTES_MILESTONE = 'MINDFUL_MINUTES_MILESTONE',
  NEXT_JOURNEY_PREVIEW = 'NEXT_JOURNEY_PREVIEW',
  QUESTION_ANSWERED = 'QUESTION_ANSWERED',
  RECOMMEND_MOVIE = 'RECOMMEND_MOVIE',
  REMIND_BIRTHDAY = 'REMIND_BIRTHDAY',
  REMIND_CLARITY_TEST = 'REMIND_CLARITY_TEST',
  REMIND_CUSTOMIZE_FEED = 'REMIND_CUSTOMIZE_FEED',
  REMIND_DIARY = 'REMIND_DIARY',
  REMIND_GIFT_CARD = 'REMIND_GIFT_CARD',
  REMIND_MOVIE = 'REMIND_MOVIE',
  REMIND_PUSH_NOTIFICATIONS = 'REMIND_PUSH_NOTIFICATIONS',
  REMIND_RATE_APP = 'REMIND_RATE_APP',
  REMIND_RECORD_VOICE = 'REMIND_RECORD_VOICE',
  REMIND_REVIEW = 'REMIND_REVIEW',
  REMIND_SHARE_JOURNEY = 'REMIND_SHARE_JOURNEY',
  REMIND_SHARE_STORY = 'REMIND_SHARE_STORY',
  REMIND_SHARE_TRIAL = 'REMIND_SHARE_TRIAL',
  REMIND_UNLOCK = 'REMIND_UNLOCK',
  SUBSCRIPTION_ENDING = 'SUBSCRIPTION_ENDING',
  TEST_TAKEN = 'TEST_TAKEN',
  USER_JOURNEY_LEVEL_COMPLETED = 'USER_JOURNEY_LEVEL_COMPLETED'
}

export type UserFeedItemMetadataSetting = {
  __typename?: 'UserFeedItemMetadataSetting';
  /** Setting comment */
  comment: Scalars['String'];
  /** Date of creation */
  createdDate: Scalars['DateTime'];
  /** User feed item metadata setting unique identifier */
  id: Scalars['ID'];
  /** Setting metadata */
  metadata: Scalars['JSON'];
  /** Feed item type */
  type: UserFeedItemTypeEnum;
  /** Date of update */
  updatedDate: Scalars['DateTime'];
};

export type UserFeedItemMetadataSettings = {
  __typename?: 'UserFeedItemMetadataSettings';
  skip: Scalars['Int'];
  take: Scalars['Int'];
  total: Scalars['Int'];
  /** List of paginated user feed items metadata settings */
  userFeedItemMetadataSettings: Array<UserFeedItemMetadataSetting>;
};

export enum UserFeedItemTypeEnum {
  ALL_JOURNEY_LEVELS_COMPLETED = 'ALL_JOURNEY_LEVELS_COMPLETED',
  BIRTHDAY = 'BIRTHDAY',
  CLARITY_SUMMARY = 'CLARITY_SUMMARY',
  CLARITY_TOPIC_SCORE_CHANGED = 'CLARITY_TOPIC_SCORE_CHANGED',
  CLARITY_TOTAL_SCORE_CHANGED = 'CLARITY_TOTAL_SCORE_CHANGED',
  COURSES_SUMMARY = 'COURSES_SUMMARY',
  FEELINGS_SUMMARY = 'FEELINGS_SUMMARY',
  FIRST_REVIEW = 'FIRST_REVIEW',
  HIGH_CLARITY_SCORE = 'HIGH_CLARITY_SCORE',
  JOURNEYS_SUMMARY = 'JOURNEYS_SUMMARY',
  LOW_CLARITY_SCORE = 'LOW_CLARITY_SCORE',
  MINDFUL_MINUTES_MILESTONE = 'MINDFUL_MINUTES_MILESTONE',
  NEXT_JOURNEY_PREVIEW = 'NEXT_JOURNEY_PREVIEW',
  QUESTION_ANSWERED = 'QUESTION_ANSWERED',
  RECOMMEND_MOVIE = 'RECOMMEND_MOVIE',
  REMIND_BIRTHDAY = 'REMIND_BIRTHDAY',
  REMIND_CLARITY_TEST = 'REMIND_CLARITY_TEST',
  REMIND_CUSTOMIZE_FEED = 'REMIND_CUSTOMIZE_FEED',
  REMIND_DIARY = 'REMIND_DIARY',
  REMIND_GIFT_CARD = 'REMIND_GIFT_CARD',
  REMIND_MOVIE = 'REMIND_MOVIE',
  REMIND_PUSH_NOTIFICATIONS = 'REMIND_PUSH_NOTIFICATIONS',
  REMIND_RATE_APP = 'REMIND_RATE_APP',
  REMIND_RECORD_VOICE = 'REMIND_RECORD_VOICE',
  REMIND_REVIEW = 'REMIND_REVIEW',
  REMIND_SHARE_STORY = 'REMIND_SHARE_STORY',
  REMIND_SHARE_TRIAL = 'REMIND_SHARE_TRIAL',
  REMIND_UNLOCK = 'REMIND_UNLOCK',
  SUBSCRIPTION_ENDING = 'SUBSCRIPTION_ENDING',
  TEST_TAKEN = 'TEST_TAKEN',
  USER_JOURNEY_LEVEL_COMPLETED = 'USER_JOURNEY_LEVEL_COMPLETED'
}

export enum UserFeedItemVisibilityEnum {
  NORMAL = 'NORMAL',
  PERSONAL = 'PERSONAL',
  SHARED = 'SHARED'
}

export type UserInterface = {
  /** User avatar url */
  avatarUrl?: Maybe<Scalars['String']>;
  /** Date of creation */
  createdDate: Scalars['DateTime'];
  /** User email address */
  email: Scalars['String'];
  /** Has the password been changed since created */
  hasChangedPassword: Scalars['Boolean'];
  /** User unique id */
  id: Scalars['ID'];
  /** User name */
  name: Scalars['String'];
  /** User roles */
  roles: Array<UserRoleEnum>;
  /** User scopes */
  scopes: Array<UserScopeEnum>;
  /** User status */
  status: UserStatusEnum;
  /** Date of update */
  updatedDate: Scalars['DateTime'];
};

export enum UserRoleEnum {
  ADMIN = 'ADMIN',
  CLARITY_CONTENT_MANAGER = 'CLARITY_CONTENT_MANAGER',
  FEED_CONTENT_MANAGER = 'FEED_CONTENT_MANAGER',
  FEED_CONTENT_MODERATOR = 'FEED_CONTENT_MODERATOR',
  SUPERADMIN = 'SUPERADMIN'
}

export enum UserScopeEnum {
  ADMINS = 'ADMINS',
  ADMINS_CREATE_ADMIN_USER = 'ADMINS_CREATE_ADMIN_USER',
  ADMINS_INFO = 'ADMINS_INFO',
  ADMINS_LIST = 'ADMINS_LIST',
  ADMINS_LOGIN_AS_USER = 'ADMINS_LOGIN_AS_USER',
  ADMINS_RESET_PASSWORD = 'ADMINS_RESET_PASSWORD',
  ADMINS_UPDATE_INFO = 'ADMINS_UPDATE_INFO',
  ADMINS_UPDATE_STATUS = 'ADMINS_UPDATE_STATUS',
  ADMIN_FEED = 'ADMIN_FEED',
  ADMIN_FEED_COMMENT_INFO = 'ADMIN_FEED_COMMENT_INFO',
  ADMIN_FEED_COMMENT_LIST = 'ADMIN_FEED_COMMENT_LIST',
  ADMIN_FEED_CREATE_COMMENT = 'ADMIN_FEED_CREATE_COMMENT',
  ADMIN_FEED_CREATE_ITEM = 'ADMIN_FEED_CREATE_ITEM',
  ADMIN_FEED_CREATE_POLL = 'ADMIN_FEED_CREATE_POLL',
  ADMIN_FEED_CREATE_TOPIC = 'ADMIN_FEED_CREATE_TOPIC',
  ADMIN_FEED_ITEM_INFO = 'ADMIN_FEED_ITEM_INFO',
  ADMIN_FEED_ITEM_LIST = 'ADMIN_FEED_ITEM_LIST',
  ADMIN_FEED_ITEM_REVIEW = 'ADMIN_FEED_ITEM_REVIEW',
  ADMIN_FEED_POLL_INFO = 'ADMIN_FEED_POLL_INFO',
  ADMIN_FEED_POLL_LIST = 'ADMIN_FEED_POLL_LIST',
  ADMIN_FEED_TOPIC_INFO = 'ADMIN_FEED_TOPIC_INFO',
  ADMIN_FEED_TOPIC_LIST = 'ADMIN_FEED_TOPIC_LIST',
  ADMIN_FEED_UPDATE_COMMENT = 'ADMIN_FEED_UPDATE_COMMENT',
  ADMIN_FEED_UPDATE_ITEM = 'ADMIN_FEED_UPDATE_ITEM',
  ADMIN_FEED_UPDATE_POLL = 'ADMIN_FEED_UPDATE_POLL',
  ADMIN_FEED_UPDATE_TOPIC = 'ADMIN_FEED_UPDATE_TOPIC',
  CLARITY_TEST = 'CLARITY_TEST',
  CLARITY_TEST_CONCLUSION_IMAGE = 'CLARITY_TEST_CONCLUSION_IMAGE',
  CLARITY_TEST_CONCLUSION_OPTION = 'CLARITY_TEST_CONCLUSION_OPTION',
  CLARITY_TEST_CREATE_INFORMATIVE_CONTENT = 'CLARITY_TEST_CREATE_INFORMATIVE_CONTENT',
  CLARITY_TEST_CREATE_QUESTION = 'CLARITY_TEST_CREATE_QUESTION',
  CLARITY_TEST_CREATE_QUESTION_OPTION = 'CLARITY_TEST_CREATE_QUESTION_OPTION',
  CLARITY_TEST_INFO = 'CLARITY_TEST_INFO',
  CLARITY_TEST_INFORMATIVE_CONTENT = 'CLARITY_TEST_INFORMATIVE_CONTENT',
  CLARITY_TEST_LIST = 'CLARITY_TEST_LIST',
  CLARITY_TEST_UPDATE_CONCLUSION_IMAGE = 'CLARITY_TEST_UPDATE_CONCLUSION_IMAGE',
  CLARITY_TEST_UPDATE_CONCLUSION_OPTION = 'CLARITY_TEST_UPDATE_CONCLUSION_OPTION',
  CLARITY_TEST_UPDATE_INFO = 'CLARITY_TEST_UPDATE_INFO',
  CLARITY_TEST_UPDATE_INFORMATIVE_CONTENT = 'CLARITY_TEST_UPDATE_INFORMATIVE_CONTENT',
  COLLABORATION = 'COLLABORATION',
  COLLABORATION_ADD_COMMENT = 'COLLABORATION_ADD_COMMENT',
  COLLABORATION_ENTRIES = 'COLLABORATION_ENTRIES',
  FEELINGS = 'FEELINGS',
  FEELINGS_INFO = 'FEELINGS_INFO',
  FEELINGS_LIST = 'FEELINGS_LIST',
  FEELINGS_UPDATE_INFO = 'FEELINGS_UPDATE_INFO',
  FILES = 'FILES',
  SETTINGS = 'SETTINGS',
  SETTINGS_INFO = 'SETTINGS_INFO',
  SETTINGS_UPDATE_INFO = 'SETTINGS_UPDATE_INFO',
  SUPERADMIN = 'SUPERADMIN',
  USER_FEED = 'USER_FEED',
  USER_FEED_CREATE_EVENT = 'USER_FEED_CREATE_EVENT',
  USER_FEED_EVENT_INFO = 'USER_FEED_EVENT_INFO',
  USER_FEED_EVENT_LIST = 'USER_FEED_EVENT_LIST',
  USER_FEED_ITEM_METADATA_INFO = 'USER_FEED_ITEM_METADATA_INFO',
  USER_FEED_ITEM_METADATA_LIST = 'USER_FEED_ITEM_METADATA_LIST',
  USER_FEED_ITEM_METADATA_UPDATE = 'USER_FEED_ITEM_METADATA_UPDATE',
  USER_FEED_UPDATE_EVENT = 'USER_FEED_UPDATE_EVENT'
}

export enum UserStatusEnum {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
  DISABLED = 'DISABLED'
}

export type Viewer = UserInterface & {
  __typename?: 'Viewer';
  /** User avatar url */
  avatarUrl?: Maybe<Scalars['String']>;
  /** Date of creation */
  createdDate: Scalars['DateTime'];
  /** User email address */
  email: Scalars['String'];
  /** Has the password been changed since created */
  hasChangedPassword: Scalars['Boolean'];
  /** User unique id */
  id: Scalars['ID'];
  /** User name */
  name: Scalars['String'];
  /** User roles */
  roles: Array<UserRoleEnum>;
  /** User scopes */
  scopes: Array<UserScopeEnum>;
  /** User status */
  status: UserStatusEnum;
  /** Date of update */
  updatedDate: Scalars['DateTime'];
  /** JwtToken */
  jwt: Scalars['String'];
};

export type AddCollaborationCommentMutationVariables = Exact<{
  referenceId: Scalars['ID'];
  type: CollaborationTypeEnum;
  comment: Scalars['String'];
}>;


export type AddCollaborationCommentMutation = { __typename?: 'Mutation', addCollaborationComment: { __typename?: 'Collaboration', id: string, creatorId: string, referenceId: string, type: CollaborationTypeEnum, comment: string, createdDate: string, updatedDate: string, changes?: { __typename?: 'CollaborationChanges', before: any, after: any } | null | undefined } };

export type CollaborationByIdQueryVariables = Exact<{
  referenceId: Scalars['ID'];
}>;


export type CollaborationByIdQuery = { __typename?: 'Query', collaboration: Array<{ __typename?: 'Collaboration', id: string, creatorId: string, referenceId: string, type: CollaborationTypeEnum, comment: string, createdDate: string, updatedDate: string, creator: { __typename?: 'User', id: string, name: string, email: string, avatarUrl?: string | null | undefined }, changes?: { __typename?: 'CollaborationChanges', before: any, after: any } | null | undefined }> };

export type MediaCategoryQueryVariables = Exact<{ [key: string]: never; }>;


export type MediaCategoryQuery = { __typename?: 'Query', adminFeedMediaCategory: Array<{ __typename?: 'AdminFeedMediaCategory', mediaCategoryCode: string, product: Array<{ __typename?: 'ProductItemType', productCode: string, consumableCode: Array<string> }> }> };

export type VersionQueryVariables = Exact<{ [key: string]: never; }>;


export type VersionQuery = { __typename?: 'Query', version: string };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: boolean };

export type AdminFeedItemInfoFragment = { __typename?: 'AdminFeedItem', id: string, type: AdminFeedItemTypeEnum, version: number, metadata: any, startDate: string, endDate?: string | null | undefined, startDay?: number | null | undefined, endDay?: number | null | undefined, audience: Array<AdminFeedItemAudienceEnum>, isPinned: boolean, isRecommended: boolean, likeCount: number, shareCount: number, createdDate: string, updatedDate: string, status: AdminFeedItemStatusEnum, description: string, topics: Array<{ __typename?: 'AdminFeedTopic', id: string, name: string, iconUrl: string, isActive: boolean, isPermanent: boolean, createdDate: string, updatedDate: string }> };

export type AdminFeedItemStatusInteractionInfoFragment = { __typename?: 'AdminFeedItemStatusInteraction', id: string, status: AdminFeedItemStatusEnum, adminUserId: string, adminFeedItemId: string };

export type AdminFeedPollInfoFragment = { __typename?: 'AdminFeedPoll', id: string, question: any, adminFeedItemId: string, imageUrl?: string | null | undefined, createdDate: string, updatedDate: string, adminFeedPollOptions: Array<{ __typename?: 'AdminFeedPollOption', id: string, answer: any, ordinal: number, createdDate: string, updatedDate: string }>, adminFeedItem: { __typename?: 'AdminFeedItem', id: string, type: AdminFeedItemTypeEnum, version: number, metadata: any, startDate: string, endDate?: string | null | undefined, startDay?: number | null | undefined, endDay?: number | null | undefined, audience: Array<AdminFeedItemAudienceEnum>, isPinned: boolean, isRecommended: boolean, likeCount: number, shareCount: number, createdDate: string, updatedDate: string, status: AdminFeedItemStatusEnum, description: string, topics: Array<{ __typename?: 'AdminFeedTopic', id: string, name: string, iconUrl: string, isActive: boolean, isPermanent: boolean, createdDate: string, updatedDate: string }> } };

export type AdminFeedTopicInfoFragment = { __typename?: 'AdminFeedTopic', id: string, name: string, iconUrl: string, isActive: boolean, isPermanent: boolean, createdDate: string, updatedDate: string };

export type ClarityTestConclusionImageInfoFragment = { __typename?: 'ClarityTestConclusionImage', id: string, minScoreValue: number, maxScoreValue?: number | null | undefined, topic: TopicTypeEnum, imageSmall: string, imageMedium: string, imageLarge: string };

export type CollaborationInfoFragment = { __typename?: 'Collaboration', id: string, creatorId: string, referenceId: string, type: CollaborationTypeEnum, comment: string, createdDate: string, updatedDate: string, changes?: { __typename?: 'CollaborationChanges', before: any, after: any } | null | undefined };

export type ConclusionOptionInfoFragment = { __typename?: 'ConclusionOption', id: string, minClarityScore: number, maxClarityScore?: number | null | undefined, type: ConclusionOptionTypeEnum, version: number, text: string, createdDate: string, updatedDate: string, textTranslations: { __typename?: 'TranslationMap', EN?: string | null | undefined, ET?: string | null | undefined } };

export type EventSettingInfoFragment = { __typename?: 'EventSetting', id: string, name: EventSettingNameEnum, value: string, comment?: string | null | undefined, createdDate: string, updatedDate: string };

export type FeelingInfoFragment = { __typename?: 'Feeling', id: string, type: FeelingTypeEnum, name: string, scoreModifierHappiness: number, scoreModifierPositivity: number, scoreModifierEnergy: number, scoreModifierSleepQuality: number, scoreModifierMentalPower: number, scoreModifierConfidence: number, scoreModifierGratitude: number, scoreModifierAttraction: number, createdDate: string, updatedDate: string, nameTranslations: { __typename?: 'TranslationMap', EN?: string | null | undefined, ET?: string | null | undefined } };

export type FileInfoFragment = { __typename?: 'File', id: string, url: string, key: string, size: string, createdDate: string, updatedDate: string, adminUser: { __typename?: 'User', id: string, name: string } };

export type InformativeContentInfoFragment = { __typename?: 'InformativeContent', id: string, imageSmall: string, imageMedium: string, imageLarge: string, type: InformativeContentEnum, ordinal: number, createdDate: string, updatedDate: string, title: { __typename?: 'TranslationMap', EN?: string | null | undefined, ET?: string | null | undefined }, subTitle: { __typename?: 'TranslationMap', EN?: string | null | undefined, ET?: string | null | undefined }, text: { __typename?: 'TranslationMap', EN?: string | null | undefined, ET?: string | null | undefined } };

export type TestQuestionInfoFragment = { __typename?: 'Question', id: string, topic: TopicTypeEnum, type: QuestionTypeEnum, question: { __typename?: 'TranslationMap', EN?: string | null | undefined, ET?: string | null | undefined } };

export type TestQuestionOptionInfoFragment = { __typename?: 'QuestionOption', id: string, questionId: string, score: number, weight: number, answer: { __typename?: 'TranslationMap', EN?: string | null | undefined, ET?: string | null | undefined }, conclusionText: { __typename?: 'TranslationMap', ET?: string | null | undefined, EN?: string | null | undefined } };

export type TranslationMapInfoFragment = { __typename?: 'TranslationMap', EN?: string | null | undefined, ET?: string | null | undefined };

export type UserFeedEventInfoFragment = { __typename?: 'UserFeedEvent', id: string, event: UserFeedEventNameEnum, type: UserFeedItemTypeEnum, visibility: UserFeedItemVisibilityEnum, version: number, metadata: any, isPinned: boolean, createdDate: string, updatedDate: string };

export type UserFeedItemMetadataSettingInfoFragment = { __typename?: 'UserFeedItemMetadataSetting', id: string, type: UserFeedItemTypeEnum, metadata: any, comment: string, createdDate: string, updatedDate: string };

type UserInfo_User_Fragment = { __typename?: 'User', id: string, email: string, name: string, avatarUrl?: string | null | undefined, roles: Array<UserRoleEnum>, scopes: Array<UserScopeEnum>, status: UserStatusEnum, createdDate: string, updatedDate: string};

type UserInfo_Viewer_Fragment = { __typename?: 'Viewer', id: string, email: string, name: string, avatarUrl?: string | null | undefined, roles: Array<UserRoleEnum>, scopes: Array<UserScopeEnum>, status: UserStatusEnum, createdDate: string, updatedDate: string, jwt: string};

export type UserInfoFragment = UserInfo_User_Fragment | UserInfo_Viewer_Fragment;

export type ViewerQueryVariables = Exact<{ [key: string]: never; }>;


export type ViewerQuery = { __typename?: 'Query', viewer?: { __typename?: 'Viewer', id: string, email: string, name: string, avatarUrl?: string | null | undefined, roles: Array<UserRoleEnum>, scopes: Array<UserScopeEnum>, status: UserStatusEnum, hasChangedPassword: boolean, createdDate: string, updatedDate: string, jwt:string } | null | undefined };

export type ViewerInfoFragment = { __typename?: 'Viewer', id: string, email: string, name: string, avatarUrl?: string | null | undefined, roles: Array<UserRoleEnum>, scopes: Array<UserScopeEnum>, status: UserStatusEnum, hasChangedPassword: boolean, createdDate: string, updatedDate: string, jwt: string};

export type UpdateAdminFeedItemStatusInteractionMutationVariables = Exact<{
  adminFeedItemId: Scalars['ID'];
  status: AdminFeedItemStatusEnum;
}>;


export type UpdateAdminFeedItemStatusInteractionMutation = { __typename?: 'Mutation', updateAdminFeedItemStatusInteraction: { __typename?: 'AdminFeedItemStatusInteraction', id: string, status: AdminFeedItemStatusEnum, adminUserId: string, adminFeedItemId: string } };

export type CreateAdminFeedTopicMutationVariables = Exact<{
  name: Scalars['String'];
  icon: Scalars['Upload'];
  isActive: Scalars['Boolean'];
}>;


export type CreateAdminFeedTopicMutation = { __typename?: 'Mutation', createAdminFeedTopic: { __typename?: 'AdminFeedTopic', id: string, name: string, iconUrl: string, isActive: boolean, isPermanent: boolean, createdDate: string, updatedDate: string } };

export type CreateAdminUserMutationVariables = Exact<{
  name: Scalars['String'];
  email: Scalars['String'];
  roles: Array<UserRoleEnum> | UserRoleEnum;
}>;


export type CreateAdminUserMutation = { __typename?: 'Mutation', createAdminUser: { __typename?: 'User', id: string, email: string, name: string, avatarUrl?: string | null | undefined, roles: Array<UserRoleEnum>, scopes: Array<UserScopeEnum>, status: UserStatusEnum, createdDate: string, updatedDate: string } };

export type CreateFeedTestUserMutationVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type CreateFeedTestUserMutation = { __typename?: 'Mutation', createFeedTestUser: { __typename?: 'FeedTestUser', id: string, userId: number, isActive: boolean } };

export type CreateInformativeContentMutationVariables = Exact<{
  image?: InputMaybe<Scalars['Upload']>;
  title: TranslationInput;
  subTitle: TranslationInput;
  text: TranslationInput;
  type: InformativeContentEnum;
  ordinal: Scalars['Int'];
}>;


export type CreateInformativeContentMutation = { __typename?: 'Mutation', createInformativeContent: { __typename?: 'InformativeContent', id: string, imageSmall: string, imageMedium: string, imageLarge: string, type: InformativeContentEnum, ordinal: number, createdDate: string, updatedDate: string, title: { __typename?: 'TranslationMap', EN?: string | null | undefined, ET?: string | null | undefined }, subTitle: { __typename?: 'TranslationMap', EN?: string | null | undefined, ET?: string | null | undefined }, text: { __typename?: 'TranslationMap', EN?: string | null | undefined, ET?: string | null | undefined } } };

export type CreateQuestionMutationVariables = Exact<{
  topic: TopicTypeEnum;
  type: QuestionTypeEnum;
  question: TranslationInput;
}>;


export type CreateQuestionMutation = { __typename?: 'Mutation', createQuestion: { __typename?: 'Question', id: string, topic: TopicTypeEnum, type: QuestionTypeEnum, question: { __typename?: 'TranslationMap', EN?: string | null | undefined, ET?: string | null | undefined } } };

export type CreateQuestionOptionMutationVariables = Exact<{
  questionId: Scalars['String'];
  answer: TranslationInput;
  conclusionText: TranslationInput;
  score: Scalars['Int'];
  weight: Scalars['Int'];
}>;


export type CreateQuestionOptionMutation = { __typename?: 'Mutation', createQuestionOption: { __typename?: 'QuestionOption', id: string, questionId: string, score: number, weight: number, answer: { __typename?: 'TranslationMap', EN?: string | null | undefined, ET?: string | null | undefined }, conclusionText: { __typename?: 'TranslationMap', ET?: string | null | undefined, EN?: string | null | undefined } } };

export type CreateUserFeedEventMutationVariables = Exact<{
  event: UserFeedEventNameEnum;
  type: UserFeedItemTypeEnum;
  visibility: UserFeedItemVisibilityEnum;
  version: Scalars['Int'];
  isPinned: Scalars['Boolean'];
  metadata: Scalars['JSON'];
}>;


export type CreateUserFeedEventMutation = { __typename?: 'Mutation', createUserFeedEvent: { __typename?: 'UserFeedEvent', id: string, event: UserFeedEventNameEnum, type: UserFeedItemTypeEnum, visibility: UserFeedItemVisibilityEnum, version: number, metadata: any, isPinned: boolean, createdDate: string, updatedDate: string } };

export type UpdateAdminStatusMutationVariables = Exact<{
  userIds: Array<Scalars['ID']> | Scalars['ID'];
  status: UserStatusEnum;
}>;


export type UpdateAdminStatusMutation = { __typename?: 'Mutation', updateAdminStatus: Array<{ __typename?: 'User', id: string, email: string, name: string, avatarUrl?: string | null | undefined, roles: Array<UserRoleEnum>, scopes: Array<UserScopeEnum>, status: UserStatusEnum, createdDate: string, updatedDate: string }> };

export type UpdateFeedTestUserMutationVariables = Exact<{
  id: Scalars['ID'];
  comment: Scalars['String'];
}>;


export type UpdateFeedTestUserMutation = { __typename?: 'Mutation', updateFeedTestUser: { __typename?: 'FeedTestUser', id: string, userId: number, isActive: boolean } };

export type LoginAsAdminMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type LoginAsAdminMutation = { __typename?: 'Mutation', loginAsAdmin: { __typename?: 'User', id: string, email: string, name: string, avatarUrl?: string | null | undefined, roles: Array<UserRoleEnum>, scopes: Array<UserScopeEnum>, status: UserStatusEnum, createdDate: string, updatedDate: string } };

export type ResetAdminPasswordMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type ResetAdminPasswordMutation = { __typename?: 'Mutation', resetAdminPassword: { __typename?: 'User', id: string, email: string, name: string, avatarUrl?: string | null | undefined, roles: Array<UserRoleEnum>, scopes: Array<UserScopeEnum>, status: UserStatusEnum, createdDate: string, updatedDate: string } };

export type UploadImageMutationVariables = Exact<{
  image: Scalars['Upload'];
}>;


export type UploadImageMutation = { __typename?: 'Mutation', uploadImage: { __typename?: 'File', id: string, url: string, key: string, size: string, createdDate: string, updatedDate: string, adminUser: { __typename?: 'User', id: string, name: string } } };

export type AdminByIdQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type AdminByIdQuery = { __typename?: 'Query', admin: { __typename?: 'User', id: string, email: string, name: string, avatarUrl?: string | null | undefined, roles: Array<UserRoleEnum>, scopes: Array<UserScopeEnum>, status: UserStatusEnum, createdDate: string, updatedDate: string } };

export type UpdateAdminAvatarMutationVariables = Exact<{
  userId: Scalars['ID'];
  avatar: Scalars['Upload'];
}>;


export type UpdateAdminAvatarMutation = { __typename?: 'Mutation', updateAdminAvatar: { __typename?: 'User', id: string, email: string, name: string, avatarUrl?: string | null | undefined, roles: Array<UserRoleEnum>, scopes: Array<UserScopeEnum>, status: UserStatusEnum, createdDate: string, updatedDate: string } };

export type UpdateAdminInfoMutationVariables = Exact<{
  userId: Scalars['ID'];
  email: Scalars['String'];
  name: Scalars['String'];
  status: UserStatusEnum;
  roles: Array<UserRoleEnum> | UserRoleEnum;
  comment: Scalars['String'];
}>;


export type UpdateAdminInfoMutation = { __typename?: 'Mutation', updateAdminInfo: { __typename?: 'User', id: string, email: string, name: string, avatarUrl?: string | null | undefined, roles: Array<UserRoleEnum>, scopes: Array<UserScopeEnum>, status: UserStatusEnum, createdDate: string, updatedDate: string } };

export type CreateAdminFeedItemMutationVariables = Exact<{
  type: AdminFeedItemTypeEnum;
  audience: Array<AdminFeedItemAudienceEnum> | AdminFeedItemAudienceEnum;
  isPinned: Scalars['Boolean'];
  isRecommended: Scalars['Boolean'];
  metadata: Scalars['JSON'];
  startDate?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDay?: InputMaybe<Scalars['Int']>;
  endDay?: InputMaybe<Scalars['Int']>;
  likeCount?: InputMaybe<Scalars['Int']>;
  shareCount?: InputMaybe<Scalars['Int']>;
  version?: InputMaybe<Scalars['Int']>;
  topicIds: Array<Scalars['ID']> | Scalars['ID'];
  description: Scalars['String'];
}>;


export type CreateAdminFeedItemMutation = { __typename?: 'Mutation', createAdminFeedItem: { __typename?: 'AdminFeedItem', id: string, type: AdminFeedItemTypeEnum, version: number, metadata: any, startDate: string, endDate?: string | null | undefined, startDay?: number | null | undefined, endDay?: number | null | undefined, audience: Array<AdminFeedItemAudienceEnum>, isPinned: boolean, isRecommended: boolean, likeCount: number, shareCount: number, createdDate: string, updatedDate: string, status: AdminFeedItemStatusEnum, description: string, topics: Array<{ __typename?: 'AdminFeedTopic', id: string, name: string, iconUrl: string, isActive: boolean, isPermanent: boolean, createdDate: string, updatedDate: string }> } };

export type AdminFeedItemByIdQueryVariables = Exact<{
  adminFeedItemId: Scalars['ID'];
}>;


export type AdminFeedItemByIdQuery = { __typename?: 'Query', adminFeedItem: { __typename?: 'AdminFeedItem', id: string, type: AdminFeedItemTypeEnum, version: number, metadata: any, startDate: string, endDate?: string | null | undefined, startDay?: number | null | undefined, endDay?: number | null | undefined, audience: Array<AdminFeedItemAudienceEnum>, isPinned: boolean, isRecommended: boolean, likeCount: number, shareCount: number, createdDate: string, updatedDate: string, status: AdminFeedItemStatusEnum, description: string, topics: Array<{ __typename?: 'AdminFeedTopic', id: string, name: string, iconUrl: string, isActive: boolean, isPermanent: boolean, createdDate: string, updatedDate: string }> } };

export type UpdateAdminFeedItemMutationVariables = Exact<{
  adminFeedItemId: Scalars['ID'];
  type: AdminFeedItemTypeEnum;
  audience: Array<AdminFeedItemAudienceEnum> | AdminFeedItemAudienceEnum;
  isPinned: Scalars['Boolean'];
  isRecommended: Scalars['Boolean'];
  metadata: Scalars['JSON'];
  startDate?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDay?: InputMaybe<Scalars['Int']>;
  endDay?: InputMaybe<Scalars['Int']>;
  likeCount: Scalars['Int'];
  shareCount: Scalars['Int'];
  version: Scalars['Int'];
  topicIds: Array<Scalars['ID']> | Scalars['ID'];
  description: Scalars['String'];
  comment: Scalars['String'];
}>;


export type UpdateAdminFeedItemMutation = { __typename?: 'Mutation', updateAdminFeedItem: { __typename?: 'AdminFeedItem', id: string, type: AdminFeedItemTypeEnum, version: number, metadata: any, startDate: string, endDate?: string | null | undefined, startDay?: number | null | undefined, endDay?: number | null | undefined, audience: Array<AdminFeedItemAudienceEnum>, isPinned: boolean, isRecommended: boolean, likeCount: number, shareCount: number, createdDate: string, updatedDate: string, status: AdminFeedItemStatusEnum, description: string, topics: Array<{ __typename?: 'AdminFeedTopic', id: string, name: string, iconUrl: string, isActive: boolean, isPermanent: boolean, createdDate: string, updatedDate: string }> } };

export type UserAdminFeedItemStatusInteractionQueryVariables = Exact<{
  adminFeedItemId: Scalars['ID'];
}>;


export type UserAdminFeedItemStatusInteractionQuery = { __typename?: 'Query', userAdminFeedItemStatusInteraction: { __typename?: 'UserAdminFeedItemStatusInteraction', isCreator: boolean, userInteraction?: { __typename?: 'AdminFeedItemStatusInteraction', id: string, status: AdminFeedItemStatusEnum, adminUserId: string, adminFeedItemId: string } | null | undefined } };

export type AdminFeedItemsQueryVariables = Exact<{
  filter?: InputMaybe<AdminFeedItemsFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  match?: InputMaybe<MatchInput>;
  sort?: InputMaybe<Array<SortingInput> | SortingInput>;
}>;


export type AdminFeedItemsQuery = { __typename?: 'Query', adminFeedItems: { __typename?: 'AdminFeedItems', skip: number, take: number, total: number, adminFeedItems: Array<{ __typename?: 'AdminFeedItem', id: string, type: AdminFeedItemTypeEnum, version: number, metadata: any, startDate: string, endDate?: string | null | undefined, startDay?: number | null | undefined, endDay?: number | null | undefined, audience: Array<AdminFeedItemAudienceEnum>, isPinned: boolean, isRecommended: boolean, likeCount: number, shareCount: number, createdDate: string, updatedDate: string, status: AdminFeedItemStatusEnum, description: string, topics: Array<{ __typename?: 'AdminFeedTopic', id: string, name: string, iconUrl: string, isActive: boolean, isPermanent: boolean, createdDate: string, updatedDate: string }> }> } };

export type CreateAdminFeedPollMutationVariables = Exact<{
  question: TranslationInput;
  options: Array<AdminFeedPollOptionInput> | AdminFeedPollOptionInput;
  audience: Array<AdminFeedItemAudienceEnum> | AdminFeedItemAudienceEnum;
  isPinned: Scalars['Boolean'];
  isRecommended: Scalars['Boolean'];
  startDate?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDay?: InputMaybe<Scalars['Int']>;
  endDay?: InputMaybe<Scalars['Int']>;
  likeCount: Scalars['Int'];
  shareCount: Scalars['Int'];
  version: Scalars['Int'];
  topicIds: Array<Scalars['ID']> | Scalars['ID'];
  image?: InputMaybe<Scalars['Upload']>;
}>;


export type CreateAdminFeedPollMutation = { __typename?: 'Mutation', createAdminFeedPoll: { __typename?: 'AdminFeedPoll', id: string, question: any, adminFeedItemId: string, imageUrl?: string | null | undefined, createdDate: string, updatedDate: string, adminFeedPollOptions: Array<{ __typename?: 'AdminFeedPollOption', id: string, answer: any, ordinal: number, createdDate: string, updatedDate: string }>, adminFeedItem: { __typename?: 'AdminFeedItem', id: string, type: AdminFeedItemTypeEnum, version: number, metadata: any, startDate: string, endDate?: string | null | undefined, startDay?: number | null | undefined, endDay?: number | null | undefined, audience: Array<AdminFeedItemAudienceEnum>, isPinned: boolean, isRecommended: boolean, likeCount: number, shareCount: number, createdDate: string, updatedDate: string, status: AdminFeedItemStatusEnum, description: string, topics: Array<{ __typename?: 'AdminFeedTopic', id: string, name: string, iconUrl: string, isActive: boolean, isPermanent: boolean, createdDate: string, updatedDate: string }> } } };

export type AdminFeedPollByIdQueryVariables = Exact<{
  adminFeedPollId: Scalars['ID'];
}>;


export type AdminFeedPollByIdQuery = { __typename?: 'Query', adminFeedPoll: { __typename?: 'AdminFeedPoll', id: string, question: any, adminFeedItemId: string, imageUrl?: string | null | undefined, createdDate: string, updatedDate: string, adminFeedPollOptions: Array<{ __typename?: 'AdminFeedPollOption', id: string, answer: any, ordinal: number, createdDate: string, updatedDate: string }>, adminFeedItem: { __typename?: 'AdminFeedItem', id: string, type: AdminFeedItemTypeEnum, version: number, metadata: any, startDate: string, endDate?: string | null | undefined, startDay?: number | null | undefined, endDay?: number | null | undefined, audience: Array<AdminFeedItemAudienceEnum>, isPinned: boolean, isRecommended: boolean, likeCount: number, shareCount: number, createdDate: string, updatedDate: string, status: AdminFeedItemStatusEnum, description: string, topics: Array<{ __typename?: 'AdminFeedTopic', id: string, name: string, iconUrl: string, isActive: boolean, isPermanent: boolean, createdDate: string, updatedDate: string }> } } };

export type AdminFeedPollResultsByIdQueryVariables = Exact<{
  adminFeedPollId: Scalars['ID'];
}>;


export type AdminFeedPollResultsByIdQuery = { __typename?: 'Query', adminFeedPollResults: { __typename?: 'AdminFeedPollResults', pollId: string, totalCount: number, answerOptions: Array<{ __typename?: 'AdminFeedPollResult', adminFeedPollOptionId: string, adminFeedPollOptionAnswer: any, count: number, percentage: number }> } };

export type UpdateAdminFeedPollMutationVariables = Exact<{
  adminFeedPollId: Scalars['ID'];
  question: TranslationInput;
  options: Array<AdminFeedPollOptionInput> | AdminFeedPollOptionInput;
  audience: Array<AdminFeedItemAudienceEnum> | AdminFeedItemAudienceEnum;
  isPinned: Scalars['Boolean'];
  isRecommended: Scalars['Boolean'];
  startDate?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDay?: InputMaybe<Scalars['Int']>;
  endDay?: InputMaybe<Scalars['Int']>;
  likeCount: Scalars['Int'];
  shareCount: Scalars['Int'];
  version: Scalars['Int'];
  topicIds: Array<Scalars['ID']> | Scalars['ID'];
  image?: InputMaybe<Scalars['Upload']>;
  comment: Scalars['String'];
}>;


export type UpdateAdminFeedPollMutation = { __typename?: 'Mutation', updateAdminFeedPoll: { __typename?: 'AdminFeedPoll', id: string, question: any, adminFeedItemId: string, imageUrl?: string | null | undefined, createdDate: string, updatedDate: string, adminFeedPollOptions: Array<{ __typename?: 'AdminFeedPollOption', id: string, answer: any, ordinal: number, createdDate: string, updatedDate: string }>, adminFeedItem: { __typename?: 'AdminFeedItem', id: string, type: AdminFeedItemTypeEnum, version: number, metadata: any, startDate: string, endDate?: string | null | undefined, startDay?: number | null | undefined, endDay?: number | null | undefined, audience: Array<AdminFeedItemAudienceEnum>, isPinned: boolean, isRecommended: boolean, likeCount: number, shareCount: number, createdDate: string, updatedDate: string, status: AdminFeedItemStatusEnum, description: string, topics: Array<{ __typename?: 'AdminFeedTopic', id: string, name: string, iconUrl: string, isActive: boolean, isPermanent: boolean, createdDate: string, updatedDate: string }> } } };

export type AdminFeedPollsQueryVariables = Exact<{
  filter?: InputMaybe<AdminFeedPollsFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  match?: InputMaybe<MatchInput>;
  sort?: InputMaybe<Array<SortingInput> | SortingInput>;
}>;


export type AdminFeedPollsQuery = { __typename?: 'Query', adminFeedPolls: { __typename?: 'AdminFeedPolls', skip: number, take: number, total: number, adminFeedPolls: Array<{ __typename?: 'AdminFeedPoll', id: string, question: any, adminFeedItemId: string, imageUrl?: string | null | undefined, createdDate: string, updatedDate: string, adminFeedPollOptions: Array<{ __typename?: 'AdminFeedPollOption', id: string, answer: any, ordinal: number, createdDate: string, updatedDate: string }>, adminFeedItem: { __typename?: 'AdminFeedItem', id: string, type: AdminFeedItemTypeEnum, version: number, metadata: any, startDate: string, endDate?: string | null | undefined, startDay?: number | null | undefined, endDay?: number | null | undefined, audience: Array<AdminFeedItemAudienceEnum>, isPinned: boolean, isRecommended: boolean, likeCount: number, shareCount: number, createdDate: string, updatedDate: string, status: AdminFeedItemStatusEnum, description: string, topics: Array<{ __typename?: 'AdminFeedTopic', id: string, name: string, iconUrl: string, isActive: boolean, isPermanent: boolean, createdDate: string, updatedDate: string }> } }> } };

export type AdminFeedTopicByIdQueryVariables = Exact<{
  adminFeedTopicId: Scalars['ID'];
}>;


export type AdminFeedTopicByIdQuery = { __typename?: 'Query', adminFeedTopic: { __typename?: 'AdminFeedTopic', id: string, name: string, iconUrl: string, isActive: boolean, isPermanent: boolean, createdDate: string, updatedDate: string } };

export type UpdateAdminFeedTopicMutationVariables = Exact<{
  adminFeedTopicId: Scalars['ID'];
  name: Scalars['String'];
  icon?: InputMaybe<Scalars['Upload']>;
  isActive: Scalars['Boolean'];
  comment: Scalars['String'];
}>;


export type UpdateAdminFeedTopicMutation = { __typename?: 'Mutation', updateAdminFeedTopic: { __typename?: 'AdminFeedTopic', id: string, name: string, iconUrl: string, isActive: boolean, isPermanent: boolean, createdDate: string, updatedDate: string } };

export type AdminFeedTopicsQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminFeedTopicsQuery = { __typename?: 'Query', adminFeedTopics: Array<{ __typename?: 'AdminFeedTopic', id: string, name: string, iconUrl: string, isActive: boolean, isPermanent: boolean, createdDate: string, updatedDate: string }> };

export type AdminsQueryVariables = Exact<{
  filter?: InputMaybe<AdminsFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  match?: InputMaybe<MatchInput>;
  sort?: InputMaybe<Array<SortingInput> | SortingInput>;
}>;


export type AdminsQuery = { __typename?: 'Query', admins: { __typename?: 'AdminUsers', skip: number, take: number, total: number, users: Array<{ __typename?: 'User', id: string, email: string, name: string, avatarUrl?: string | null | undefined, roles: Array<UserRoleEnum>, scopes: Array<UserScopeEnum>, status: UserStatusEnum, createdDate: string, updatedDate: string }> } };

export type ChangePasswordMutationVariables = Exact<{
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
  confirmPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = { __typename?: 'Mutation', changePassword: { __typename?: 'Viewer', id: string, email: string, name: string, avatarUrl?: string | null | undefined, roles: Array<UserRoleEnum>, scopes: Array<UserScopeEnum>, status: UserStatusEnum, hasChangedPassword: boolean, createdDate: string, updatedDate: string, jwt: string } };

export type ClarityTestConclusionImageByIdQueryVariables = Exact<{
  conclusionImageId: Scalars['ID'];
}>;


export type ClarityTestConclusionImageByIdQuery = { __typename?: 'Query', clarityTestConclusionImage: { __typename?: 'ClarityTestConclusionImage', id: string, minScoreValue: number, maxScoreValue?: number | null | undefined, topic: TopicTypeEnum, imageSmall: string, imageMedium: string, imageLarge: string } };

export type UpdateClarityTestConclusionImageMutationVariables = Exact<{
  conclusionImageId: Scalars['ID'];
  image?: InputMaybe<Scalars['Upload']>;
  comment: Scalars['String'];
}>;


export type UpdateClarityTestConclusionImageMutation = { __typename?: 'Mutation', updateClarityTestConclusionImage: { __typename?: 'ClarityTestConclusionImage', id: string, minScoreValue: number, maxScoreValue?: number | null | undefined, topic: TopicTypeEnum, imageSmall: string, imageMedium: string, imageLarge: string } };

export type ClarityTestConclusionImagesQueryVariables = Exact<{
  filter?: InputMaybe<ClarityTestConclusionImagesFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  match?: InputMaybe<MatchInput>;
  sort?: InputMaybe<Array<SortingInput> | SortingInput>;
}>;


export type ClarityTestConclusionImagesQuery = { __typename?: 'Query', clarityTestConclusionImages: { __typename?: 'ClarityTestConclusionImages', skip: number, take: number, total: number, clarityTestConclusionImages: Array<{ __typename?: 'ClarityTestConclusionImage', id: string, minScoreValue: number, maxScoreValue?: number | null | undefined, topic: TopicTypeEnum, imageSmall: string, imageMedium: string, imageLarge: string }> } };

export type ConclusionOptionByIdQueryVariables = Exact<{
  conclusionOptionId: Scalars['ID'];
}>;


export type ConclusionOptionByIdQuery = { __typename?: 'Query', conclusionOption: { __typename?: 'ConclusionOption', id: string, minClarityScore: number, maxClarityScore?: number | null | undefined, type: ConclusionOptionTypeEnum, version: number, text: string, createdDate: string, updatedDate: string, textTranslations: { __typename?: 'TranslationMap', EN?: string | null | undefined, ET?: string | null | undefined } } };

export type UpdateConclusionOptionMutationVariables = Exact<{
  conclusionOptionId: Scalars['ID'];
  text: TranslationInput;
  comment: Scalars['String'];
}>;


export type UpdateConclusionOptionMutation = { __typename?: 'Mutation', updateConclusionOption: { __typename?: 'ConclusionOption', id: string, minClarityScore: number, maxClarityScore?: number | null | undefined, type: ConclusionOptionTypeEnum, version: number, text: string, createdDate: string, updatedDate: string, textTranslations: { __typename?: 'TranslationMap', EN?: string | null | undefined, ET?: string | null | undefined } } };

export type ConclusionOptionsQueryVariables = Exact<{
  filter?: InputMaybe<ConclusionOptionFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  match?: InputMaybe<MatchInput>;
  sort?: InputMaybe<Array<SortingInput> | SortingInput>;
}>;


export type ConclusionOptionsQuery = { __typename?: 'Query', conclusionOptions: { __typename?: 'ConclusionOptions', skip: number, take: number, total: number, conclusionOptions: Array<{ __typename?: 'ConclusionOption', id: string, minClarityScore: number, maxClarityScore?: number | null | undefined, type: ConclusionOptionTypeEnum, version: number, text: string, createdDate: string, updatedDate: string, textTranslations: { __typename?: 'TranslationMap', EN?: string | null | undefined, ET?: string | null | undefined } }> } };

export type EventSettingByIdQueryVariables = Exact<{
  eventSettingId: Scalars['ID'];
}>;


export type EventSettingByIdQuery = { __typename?: 'Query', eventSetting: { __typename?: 'EventSetting', id: string, name: EventSettingNameEnum, value: string, comment?: string | null | undefined, createdDate: string, updatedDate: string } };

export type UpdateEventSettingMutationVariables = Exact<{
  eventSettingId: Scalars['ID'];
  value: Scalars['String'];
  comment: Scalars['String'];
  collaborationComment: Scalars['String'];
}>;


export type UpdateEventSettingMutation = { __typename?: 'Mutation', updateEventSetting: { __typename?: 'EventSetting', id: string, name: EventSettingNameEnum, value: string, comment?: string | null | undefined, createdDate: string, updatedDate: string } };

export type EventSettingsQueryVariables = Exact<{
  pagination?: InputMaybe<PaginationInput>;
  match?: InputMaybe<MatchInput>;
}>;


export type EventSettingsQuery = { __typename?: 'Query', eventSettings: { __typename?: 'EventSettings', skip: number, take: number, total: number, eventSettings: Array<{ __typename?: 'EventSetting', id: string, name: EventSettingNameEnum, value: string, comment?: string | null | undefined, createdDate: string, updatedDate: string }> } };

export type UpdateAvatarMutationVariables = Exact<{
  avatar: Scalars['String'];
}>;


export type UpdateAvatarMutation = { __typename?: 'Mutation', updateAvatar: { __typename?: 'Viewer', id: string, email: string, name: string, avatarUrl?: string | null | undefined } };

export type UploadExperimentViewerQueryVariables = Exact<{ [key: string]: never; }>;


export type UploadExperimentViewerQuery = { __typename?: 'Query', viewer?: { __typename?: 'Viewer', id: string, email: string, name: string, avatarUrl?: string | null | undefined, roles: Array<UserRoleEnum>, scopes: Array<UserScopeEnum>, status: UserStatusEnum } | null | undefined };

export type FeedTestUserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FeedTestUserQuery = { __typename?: 'Query', feedTestUser: { __typename?: 'FeedTestUser', id: string, userId: number, isActive: boolean } };

export type FeedTestUsersQueryVariables = Exact<{
  filter?: InputMaybe<FeedTestUsersFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  match?: InputMaybe<MatchInput>;
  sort?: InputMaybe<Array<SortingInput> | SortingInput>;
}>;


export type FeedTestUsersQuery = { __typename?: 'Query', feedTestUsers: { __typename?: 'FeedTestUsers', skip: number, take: number, total: number, feedTestUsers: Array<{ __typename?: 'FeedTestUser', id: string, userId: number, isActive: boolean }> } };

export type FeelingByIdQueryVariables = Exact<{
  feelingId: Scalars['ID'];
}>;


export type FeelingByIdQuery = { __typename?: 'Query', feeling: { __typename?: 'Feeling', id: string, type: FeelingTypeEnum, name: string, scoreModifierHappiness: number, scoreModifierPositivity: number, scoreModifierEnergy: number, scoreModifierSleepQuality: number, scoreModifierMentalPower: number, scoreModifierConfidence: number, scoreModifierGratitude: number, scoreModifierAttraction: number, createdDate: string, updatedDate: string, nameTranslations: { __typename?: 'TranslationMap', EN?: string | null | undefined, ET?: string | null | undefined } } };

export type UpdateFeelingMutationVariables = Exact<{
  feelingId: Scalars['ID'];
  name: TranslationInput;
  scoreModifierHappiness: Scalars['Float'];
  scoreModifierPositivity: Scalars['Float'];
  scoreModifierEnergy: Scalars['Float'];
  scoreModifierSleepQuality: Scalars['Float'];
  scoreModifierMentalPower: Scalars['Float'];
  scoreModifierConfidence: Scalars['Float'];
  scoreModifierGratitude: Scalars['Float'];
  scoreModifierAttraction: Scalars['Float'];
  comment: Scalars['String'];
}>;


export type UpdateFeelingMutation = { __typename?: 'Mutation', updateFeeling: { __typename?: 'Feeling', id: string, type: FeelingTypeEnum, name: string, scoreModifierHappiness: number, scoreModifierPositivity: number, scoreModifierEnergy: number, scoreModifierSleepQuality: number, scoreModifierMentalPower: number, scoreModifierConfidence: number, scoreModifierGratitude: number, scoreModifierAttraction: number, createdDate: string, updatedDate: string, nameTranslations: { __typename?: 'TranslationMap', EN?: string | null | undefined, ET?: string | null | undefined } } };

export type FeelingsQueryVariables = Exact<{ [key: string]: never; }>;


export type FeelingsQuery = { __typename?: 'Query', feelings: Array<{ __typename?: 'Feeling', id: string, type: FeelingTypeEnum, name: string, scoreModifierHappiness: number, scoreModifierPositivity: number, scoreModifierEnergy: number, scoreModifierSleepQuality: number, scoreModifierMentalPower: number, scoreModifierConfidence: number, scoreModifierGratitude: number, scoreModifierAttraction: number, createdDate: string, updatedDate: string, nameTranslations: { __typename?: 'TranslationMap', EN?: string | null | undefined, ET?: string | null | undefined } }> };

export type FileByIdQueryVariables = Exact<{
  fileId: Scalars['ID'];
}>;


export type FileByIdQuery = { __typename?: 'Query', file: { __typename?: 'File', id: string, url: string, key: string, size: string, createdDate: string, updatedDate: string, adminUser: { __typename?: 'User', id: string, name: string } } };

export type FilesQueryVariables = Exact<{
  filter?: InputMaybe<FilesFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  match?: InputMaybe<MatchInput>;
  sort?: InputMaybe<Array<SortingInput> | SortingInput>;
}>;


export type FilesQuery = { __typename?: 'Query', files: { __typename?: 'Files', skip: number, take: number, total: number, files: Array<{ __typename?: 'File', id: string, url: string, key: string, size: string, createdDate: string, updatedDate: string, adminUser: { __typename?: 'User', id: string, name: string } }> } };

export type InformativeContentByIdQueryVariables = Exact<{
  filter?: InputMaybe<InformativeContentFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  match?: InputMaybe<MatchInput>;
}>;


export type InformativeContentByIdQuery = { __typename?: 'Query', informativeContent: { __typename?: 'InformativeContentResponse', informativeContent: Array<{ __typename?: 'InformativeContent', id: string, imageSmall: string, imageMedium: string, imageLarge: string, type: InformativeContentEnum, ordinal: number, createdDate: string, updatedDate: string, title: { __typename?: 'TranslationMap', EN?: string | null | undefined, ET?: string | null | undefined }, subTitle: { __typename?: 'TranslationMap', EN?: string | null | undefined, ET?: string | null | undefined }, text: { __typename?: 'TranslationMap', EN?: string | null | undefined, ET?: string | null | undefined } }> } };

export type UpdateInformativeContentMutationVariables = Exact<{
  informativeContentId: Scalars['ID'];
  image?: InputMaybe<Scalars['Upload']>;
  title: TranslationInput;
  subTitle: TranslationInput;
  text: TranslationInput;
  type: InformativeContentEnum;
  ordinal: Scalars['Int'];
  comment: Scalars['String'];
}>;


export type UpdateInformativeContentMutation = { __typename?: 'Mutation', updateInformativeContent: { __typename?: 'InformativeContent', id: string, imageSmall: string, imageMedium: string, imageLarge: string, type: InformativeContentEnum, ordinal: number, createdDate: string, updatedDate: string, title: { __typename?: 'TranslationMap', EN?: string | null | undefined, ET?: string | null | undefined }, subTitle: { __typename?: 'TranslationMap', EN?: string | null | undefined, ET?: string | null | undefined }, text: { __typename?: 'TranslationMap', EN?: string | null | undefined, ET?: string | null | undefined } } };

export type InformativeContentQueryVariables = Exact<{
  filter?: InputMaybe<InformativeContentFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  match?: InputMaybe<MatchInput>;
  sort?: InputMaybe<Array<SortingInput> | SortingInput>;
}>;


export type InformativeContentQuery = { __typename?: 'Query', informativeContent: { __typename?: 'InformativeContentResponse', skip: number, take: number, total: number, informativeContent: Array<{ __typename?: 'InformativeContent', id: string, imageSmall: string, imageMedium: string, imageLarge: string, type: InformativeContentEnum, ordinal: number, createdDate: string, updatedDate: string, title: { __typename?: 'TranslationMap', EN?: string | null | undefined, ET?: string | null | undefined }, subTitle: { __typename?: 'TranslationMap', EN?: string | null | undefined, ET?: string | null | undefined }, text: { __typename?: 'TranslationMap', EN?: string | null | undefined, ET?: string | null | undefined } }> } };

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'Viewer', id: string, email: string, name: string, avatarUrl?: string | null | undefined, roles: Array<UserRoleEnum>, scopes: Array<UserScopeEnum>, status: UserStatusEnum, hasChangedPassword: boolean, createdDate: string, updatedDate: string, jwt: string } };

export type QuestionsByIdQueryVariables = Exact<{
  filter?: InputMaybe<QuestionFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  match?: InputMaybe<MatchInput>;
}>;


export type QuestionsByIdQuery = { __typename?: 'Query', questions: { __typename?: 'Questions', questions: Array<{ __typename?: 'Question', id: string, topic: TopicTypeEnum, type: QuestionTypeEnum, questionOption: Array<{ __typename?: 'QuestionOption', id: string, questionId: string, score: number, weight: number, answer: { __typename?: 'TranslationMap', EN?: string | null | undefined, ET?: string | null | undefined }, conclusionText: { __typename?: 'TranslationMap', ET?: string | null | undefined, EN?: string | null | undefined } }>, question: { __typename?: 'TranslationMap', EN?: string | null | undefined, ET?: string | null | undefined } }> } };

export type UpdateQuestionMutationVariables = Exact<{
  questionId: Scalars['ID'];
  question: TranslationInput;
  type: QuestionTypeEnum;
  topic: TopicTypeEnum;
  comment: Scalars['String'];
}>;


export type UpdateQuestionMutation = { __typename?: 'Mutation', updateQuestion: { __typename?: 'Question', id: string, topic: TopicTypeEnum, type: QuestionTypeEnum, question: { __typename?: 'TranslationMap', EN?: string | null | undefined, ET?: string | null | undefined } } };

export type QuestionsQueryVariables = Exact<{
  filter?: InputMaybe<QuestionFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  match?: InputMaybe<MatchInput>;
  sort?: InputMaybe<Array<SortingInput> | SortingInput>;
}>;


export type QuestionsQuery = { __typename?: 'Query', questions: { __typename?: 'Questions', skip: number, take: number, total: number, questions: Array<{ __typename?: 'Question', id: string, topic: TopicTypeEnum, type: QuestionTypeEnum, question: { __typename?: 'TranslationMap', EN?: string | null | undefined, ET?: string | null | undefined } }> } };

export type QuestionOptionByIdQueryVariables = Exact<{
  filter?: InputMaybe<QuestionOptionFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  match?: InputMaybe<MatchInput>;
}>;


export type QuestionOptionByIdQuery = { __typename?: 'Query', questionOptions: { __typename?: 'QuestionOptions', questionOptions: Array<{ __typename?: 'QuestionOption', id: string, questionId: string, score: number, weight: number, question: { __typename?: 'Question', id: string, question: { __typename?: 'TranslationMap', ET?: string | null | undefined, EN?: string | null | undefined } }, answer: { __typename?: 'TranslationMap', EN?: string | null | undefined, ET?: string | null | undefined }, conclusionText: { __typename?: 'TranslationMap', ET?: string | null | undefined, EN?: string | null | undefined } }> } };

export type UpdateQuestionOptionMutationVariables = Exact<{
  questionOptionId: Scalars['ID'];
  answer: TranslationInput;
  conclusionText: TranslationInput;
  score: Scalars['Int'];
  weight: Scalars['Int'];
  comment: Scalars['String'];
}>;


export type UpdateQuestionOptionMutation = { __typename?: 'Mutation', updateQuestionOption: { __typename?: 'QuestionOption', id: string, questionId: string, score: number, weight: number, answer: { __typename?: 'TranslationMap', EN?: string | null | undefined, ET?: string | null | undefined }, conclusionText: { __typename?: 'TranslationMap', ET?: string | null | undefined, EN?: string | null | undefined } } };

export type QuestionOptionsQueryVariables = Exact<{
  filter?: InputMaybe<QuestionOptionFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
  match?: InputMaybe<MatchInput>;
  sort?: InputMaybe<Array<SortingInput> | SortingInput>;
}>;


export type QuestionOptionsQuery = { __typename?: 'Query', questionOptions: { __typename?: 'QuestionOptions', skip: number, take: number, total: number, questionOptions: Array<{ __typename?: 'QuestionOption', id: string, questionId: string, score: number, weight: number, question: { __typename?: 'Question', id: string, question: { __typename?: 'TranslationMap', ET?: string | null | undefined, EN?: string | null | undefined } }, answer: { __typename?: 'TranslationMap', EN?: string | null | undefined, ET?: string | null | undefined }, conclusionText: { __typename?: 'TranslationMap', ET?: string | null | undefined, EN?: string | null | undefined } }> } };

export type UpdateUserFeedEventMutationVariables = Exact<{
  userFeedEventId: Scalars['ID'];
  event: UserFeedEventNameEnum;
  type: UserFeedItemTypeEnum;
  visibility: UserFeedItemVisibilityEnum;
  version: Scalars['Int'];
  isPinned: Scalars['Boolean'];
  metadata: Scalars['JSON'];
  comment: Scalars['String'];
}>;


export type UpdateUserFeedEventMutation = { __typename?: 'Mutation', updateUserFeedEvent: { __typename?: 'UserFeedEvent', id: string, event: UserFeedEventNameEnum, type: UserFeedItemTypeEnum, visibility: UserFeedItemVisibilityEnum, version: number, metadata: any, isPinned: boolean, createdDate: string, updatedDate: string } };

export type UserFeedEventByIdQueryVariables = Exact<{
  userFeedEventId: Scalars['ID'];
}>;


export type UserFeedEventByIdQuery = { __typename?: 'Query', userFeedEvent: { __typename?: 'UserFeedEvent', id: string, event: UserFeedEventNameEnum, type: UserFeedItemTypeEnum, visibility: UserFeedItemVisibilityEnum, version: number, metadata: any, isPinned: boolean, createdDate: string, updatedDate: string } };

export type UserFeedEventsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserFeedEventsQuery = { __typename?: 'Query', userFeedEvents: Array<{ __typename?: 'UserFeedEvent', id: string, event: UserFeedEventNameEnum, type: UserFeedItemTypeEnum, visibility: UserFeedItemVisibilityEnum, version: number, metadata: any, isPinned: boolean, createdDate: string, updatedDate: string }> };

export type UpdateUserFeedItemMetadataSettingMutationVariables = Exact<{
  userFeedItemMetadataSettingId: Scalars['ID'];
  type: UserFeedItemTypeEnum;
  metadata: Scalars['JSON'];
}>;


export type UpdateUserFeedItemMetadataSettingMutation = { __typename?: 'Mutation', updateUserFeedItemMetadataSetting: { __typename?: 'UserFeedItemMetadataSetting', id: string, type: UserFeedItemTypeEnum, metadata: any, comment: string, createdDate: string, updatedDate: string } };

export type UserFeedItemMetadataSettingByIdQueryVariables = Exact<{
  userFeedItemMetadataSettingId: Scalars['ID'];
}>;


export type UserFeedItemMetadataSettingByIdQuery = { __typename?: 'Query', userFeedItemMetadataSetting: { __typename?: 'UserFeedItemMetadataSetting', id: string, type: UserFeedItemTypeEnum, metadata: any, comment: string, createdDate: string, updatedDate: string } };

export type UserFeedItemMetadataSettingsQueryVariables = Exact<{
  pagination?: InputMaybe<PaginationInput>;
}>;


export type UserFeedItemMetadataSettingsQuery = { __typename?: 'Query', userFeedItemMetadataSettings: { __typename?: 'UserFeedItemMetadataSettings', skip: number, take: number, total: number, userFeedItemMetadataSettings: Array<{ __typename?: 'UserFeedItemMetadataSetting', id: string, type: UserFeedItemTypeEnum, metadata: any, comment: string, createdDate: string, updatedDate: string }> } };

export const AdminFeedItemStatusInteractionInfoFragmentDoc = gql`
    fragment AdminFeedItemStatusInteractionInfo on AdminFeedItemStatusInteraction {
  id
  status
  adminUserId
  adminFeedItemId
}
    `;
export const AdminFeedTopicInfoFragmentDoc = gql`
    fragment AdminFeedTopicInfo on AdminFeedTopic {
  id
  name
  iconUrl
  isActive
  isPermanent
  createdDate
  updatedDate
}
    `;
export const AdminFeedItemInfoFragmentDoc = gql`
    fragment AdminFeedItemInfo on AdminFeedItem {
  id
  type
  version
  metadata
  startDate
  endDate
  startDay
  endDay
  audience
  isPinned
  isRecommended
  likeCount
  shareCount
  createdDate
  updatedDate
  status
  topics {
    ...AdminFeedTopicInfo
  }
  description
}
    ${AdminFeedTopicInfoFragmentDoc}`;
export const AdminFeedPollInfoFragmentDoc = gql`
    fragment AdminFeedPollInfo on AdminFeedPoll {
  id
  question
  adminFeedItemId
  imageUrl
  createdDate
  updatedDate
  adminFeedPollOptions {
    id
    answer
    ordinal
    createdDate
    updatedDate
  }
  adminFeedItem {
    ...AdminFeedItemInfo
  }
}
    ${AdminFeedItemInfoFragmentDoc}`;
export const ClarityTestConclusionImageInfoFragmentDoc = gql`
    fragment ClarityTestConclusionImageInfo on ClarityTestConclusionImage {
  id
  minScoreValue
  maxScoreValue
  topic
  imageSmall
  imageMedium
  imageLarge
}
    `;
export const CollaborationInfoFragmentDoc = gql`
    fragment CollaborationInfo on Collaboration {
  id
  creatorId
  referenceId
  type
  comment
  changes {
    before
    after
  }
  createdDate
  updatedDate
}
    `;
export const ConclusionOptionInfoFragmentDoc = gql`
    fragment ConclusionOptionInfo on ConclusionOption {
  id
  minClarityScore
  maxClarityScore
  type
  version
  text
  textTranslations {
    EN
    ET
  }
  createdDate
  updatedDate
}
    `;
export const EventSettingInfoFragmentDoc = gql`
    fragment EventSettingInfo on EventSetting {
  id
  name
  value
  comment
  createdDate
  updatedDate
}
    `;
export const FeelingInfoFragmentDoc = gql`
    fragment FeelingInfo on Feeling {
  id
  type
  name
  nameTranslations {
    EN
    ET
  }
  scoreModifierHappiness
  scoreModifierPositivity
  scoreModifierEnergy
  scoreModifierSleepQuality
  scoreModifierMentalPower
  scoreModifierConfidence
  scoreModifierGratitude
  scoreModifierAttraction
  createdDate
  updatedDate
}
    `;
export const FileInfoFragmentDoc = gql`
    fragment FileInfo on File {
  id
  url
  key
  size
  createdDate
  updatedDate
  adminUser {
    id
    name
  }
}
    `;
export const InformativeContentInfoFragmentDoc = gql`
    fragment InformativeContentInfo on InformativeContent {
  id
  imageSmall
  imageMedium
  imageLarge
  title {
    EN
    ET
  }
  subTitle {
    EN
    ET
  }
  text {
    EN
    ET
  }
  type
  ordinal
  createdDate
  updatedDate
}
    `;
export const TestQuestionInfoFragmentDoc = gql`
    fragment TestQuestionInfo on Question {
  id
  topic
  type
  question {
    EN
    ET
  }
}
    `;
export const TestQuestionOptionInfoFragmentDoc = gql`
    fragment TestQuestionOptionInfo on QuestionOption {
  id
  questionId
  answer {
    EN
    ET
  }
  score
  weight
  conclusionText {
    ET
    EN
  }
}
    `;
export const TranslationMapInfoFragmentDoc = gql`
    fragment TranslationMapInfo on TranslationMap {
  EN
  ET
}
    `;
export const UserFeedEventInfoFragmentDoc = gql`
    fragment UserFeedEventInfo on UserFeedEvent {
  id
  event
  type
  visibility
  version
  metadata
  isPinned
  createdDate
  updatedDate
}
    `;
export const UserFeedItemMetadataSettingInfoFragmentDoc = gql`
    fragment UserFeedItemMetadataSettingInfo on UserFeedItemMetadataSetting {
  id
  type
  metadata
  comment
  createdDate
  updatedDate
}
    `;
export const UserInfoFragmentDoc = gql`
    fragment UserInfo on UserInterface {
  id
  email
  name
  avatarUrl
  roles
  scopes
  status
  createdDate
  updatedDate
}
    `;
export const ViewerInfoFragmentDoc = gql`

    fragment ViewerInfo on Viewer {
  id
  email
  name
  avatarUrl
  roles
  scopes
  status
  hasChangedPassword
  createdDate
  updatedDate
  jwt
}
    `;
export const AddCollaborationCommentDocument = gql`
    mutation AddCollaborationComment($referenceId: ID!, $type: CollaborationTypeEnum!, $comment: String!) {
  addCollaborationComment(
    referenceId: $referenceId
    type: $type
    comment: $comment
  ) {
    id
    creatorId
    referenceId
    type
    comment
    changes {
      before
      after
    }
    createdDate
    updatedDate
  }
}
    `;
export type AddCollaborationCommentMutationFn = Apollo.MutationFunction<AddCollaborationCommentMutation, AddCollaborationCommentMutationVariables>;

/**
 * __useAddCollaborationCommentMutation__
 *
 * To run a mutation, you first call `useAddCollaborationCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCollaborationCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCollaborationCommentMutation, { data, loading, error }] = useAddCollaborationCommentMutation({
 *   variables: {
 *      referenceId: // value for 'referenceId'
 *      type: // value for 'type'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useAddCollaborationCommentMutation(baseOptions?: Apollo.MutationHookOptions<AddCollaborationCommentMutation, AddCollaborationCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCollaborationCommentMutation, AddCollaborationCommentMutationVariables>(AddCollaborationCommentDocument, options);
      }
export type AddCollaborationCommentMutationHookResult = ReturnType<typeof useAddCollaborationCommentMutation>;
export type AddCollaborationCommentMutationResult = Apollo.MutationResult<AddCollaborationCommentMutation>;
export type AddCollaborationCommentMutationOptions = Apollo.BaseMutationOptions<AddCollaborationCommentMutation, AddCollaborationCommentMutationVariables>;
export const CollaborationByIdDocument = gql`
    query CollaborationById($referenceId: ID!) {
  collaboration(referenceId: $referenceId) {
    ...CollaborationInfo
    creator {
      id
      name
      email
      avatarUrl
    }
  }
}
    ${CollaborationInfoFragmentDoc}`;

/**
 * __useCollaborationByIdQuery__
 *
 * To run a query within a React component, call `useCollaborationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollaborationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollaborationByIdQuery({
 *   variables: {
 *      referenceId: // value for 'referenceId'
 *   },
 * });
 */
export function useCollaborationByIdQuery(baseOptions: Apollo.QueryHookOptions<CollaborationByIdQuery, CollaborationByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CollaborationByIdQuery, CollaborationByIdQueryVariables>(CollaborationByIdDocument, options);
      }
export function useCollaborationByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CollaborationByIdQuery, CollaborationByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CollaborationByIdQuery, CollaborationByIdQueryVariables>(CollaborationByIdDocument, options);
        }
export type CollaborationByIdQueryHookResult = ReturnType<typeof useCollaborationByIdQuery>;
export type CollaborationByIdLazyQueryHookResult = ReturnType<typeof useCollaborationByIdLazyQuery>;
export type CollaborationByIdQueryResult = Apollo.QueryResult<CollaborationByIdQuery, CollaborationByIdQueryVariables>;
export const MediaCategoryDocument = gql`
    query MediaCategory {
  adminFeedMediaCategory {
    mediaCategoryCode
    product {
      productCode
      consumableCode
    }
  }
}
    `;

/**
 * __useMediaCategoryQuery__
 *
 * To run a query within a React component, call `useMediaCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaCategoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useMediaCategoryQuery(baseOptions?: Apollo.QueryHookOptions<MediaCategoryQuery, MediaCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MediaCategoryQuery, MediaCategoryQueryVariables>(MediaCategoryDocument, options);
      }
export function useMediaCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MediaCategoryQuery, MediaCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MediaCategoryQuery, MediaCategoryQueryVariables>(MediaCategoryDocument, options);
        }
export type MediaCategoryQueryHookResult = ReturnType<typeof useMediaCategoryQuery>;
export type MediaCategoryLazyQueryHookResult = ReturnType<typeof useMediaCategoryLazyQuery>;
export type MediaCategoryQueryResult = Apollo.QueryResult<MediaCategoryQuery, MediaCategoryQueryVariables>;
export const VersionDocument = gql`
    query Version {
  version
}
    `;

/**
 * __useVersionQuery__
 *
 * To run a query within a React component, call `useVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVersionQuery({
 *   variables: {
 *   },
 * });
 */
export function useVersionQuery(baseOptions?: Apollo.QueryHookOptions<VersionQuery, VersionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VersionQuery, VersionQueryVariables>(VersionDocument, options);
      }
export function useVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VersionQuery, VersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VersionQuery, VersionQueryVariables>(VersionDocument, options);
        }
export type VersionQueryHookResult = ReturnType<typeof useVersionQuery>;
export type VersionLazyQueryHookResult = ReturnType<typeof useVersionLazyQuery>;
export type VersionQueryResult = Apollo.QueryResult<VersionQuery, VersionQueryVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const ViewerDocument = gql`
    query Viewer {
  viewer {
    ...ViewerInfo
  }
}
    ${ViewerInfoFragmentDoc}`;

/**
 * __useViewerQuery__
 *
 * To run a query within a React component, call `useViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerQuery(baseOptions?: Apollo.QueryHookOptions<ViewerQuery, ViewerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ViewerQuery, ViewerQueryVariables>(ViewerDocument, options);
      }
export function useViewerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewerQuery, ViewerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ViewerQuery, ViewerQueryVariables>(ViewerDocument, options);
        }
export type ViewerQueryHookResult = ReturnType<typeof useViewerQuery>;
export type ViewerLazyQueryHookResult = ReturnType<typeof useViewerLazyQuery>;
export type ViewerQueryResult = Apollo.QueryResult<ViewerQuery, ViewerQueryVariables>;
export const UpdateAdminFeedItemStatusInteractionDocument = gql`
    mutation UpdateAdminFeedItemStatusInteraction($adminFeedItemId: ID!, $status: AdminFeedItemStatusEnum!) {
  updateAdminFeedItemStatusInteraction(
    adminFeedItemId: $adminFeedItemId
    status: $status
  ) {
    ...AdminFeedItemStatusInteractionInfo
  }
}
    ${AdminFeedItemStatusInteractionInfoFragmentDoc}`;
export type UpdateAdminFeedItemStatusInteractionMutationFn = Apollo.MutationFunction<UpdateAdminFeedItemStatusInteractionMutation, UpdateAdminFeedItemStatusInteractionMutationVariables>;

/**
 * __useUpdateAdminFeedItemStatusInteractionMutation__
 *
 * To run a mutation, you first call `useUpdateAdminFeedItemStatusInteractionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminFeedItemStatusInteractionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminFeedItemStatusInteractionMutation, { data, loading, error }] = useUpdateAdminFeedItemStatusInteractionMutation({
 *   variables: {
 *      adminFeedItemId: // value for 'adminFeedItemId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateAdminFeedItemStatusInteractionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdminFeedItemStatusInteractionMutation, UpdateAdminFeedItemStatusInteractionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAdminFeedItemStatusInteractionMutation, UpdateAdminFeedItemStatusInteractionMutationVariables>(UpdateAdminFeedItemStatusInteractionDocument, options);
      }
export type UpdateAdminFeedItemStatusInteractionMutationHookResult = ReturnType<typeof useUpdateAdminFeedItemStatusInteractionMutation>;
export type UpdateAdminFeedItemStatusInteractionMutationResult = Apollo.MutationResult<UpdateAdminFeedItemStatusInteractionMutation>;
export type UpdateAdminFeedItemStatusInteractionMutationOptions = Apollo.BaseMutationOptions<UpdateAdminFeedItemStatusInteractionMutation, UpdateAdminFeedItemStatusInteractionMutationVariables>;
export const CreateAdminFeedTopicDocument = gql`
    mutation CreateAdminFeedTopic($name: String!, $icon: Upload!, $isActive: Boolean!) {
  createAdminFeedTopic(name: $name, icon: $icon, isActive: $isActive) {
    ...AdminFeedTopicInfo
  }
}
    ${AdminFeedTopicInfoFragmentDoc}`;
export type CreateAdminFeedTopicMutationFn = Apollo.MutationFunction<CreateAdminFeedTopicMutation, CreateAdminFeedTopicMutationVariables>;

/**
 * __useCreateAdminFeedTopicMutation__
 *
 * To run a mutation, you first call `useCreateAdminFeedTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdminFeedTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdminFeedTopicMutation, { data, loading, error }] = useCreateAdminFeedTopicMutation({
 *   variables: {
 *      name: // value for 'name'
 *      icon: // value for 'icon'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useCreateAdminFeedTopicMutation(baseOptions?: Apollo.MutationHookOptions<CreateAdminFeedTopicMutation, CreateAdminFeedTopicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAdminFeedTopicMutation, CreateAdminFeedTopicMutationVariables>(CreateAdminFeedTopicDocument, options);
      }
export type CreateAdminFeedTopicMutationHookResult = ReturnType<typeof useCreateAdminFeedTopicMutation>;
export type CreateAdminFeedTopicMutationResult = Apollo.MutationResult<CreateAdminFeedTopicMutation>;
export type CreateAdminFeedTopicMutationOptions = Apollo.BaseMutationOptions<CreateAdminFeedTopicMutation, CreateAdminFeedTopicMutationVariables>;
export const CreateAdminUserDocument = gql`
    mutation CreateAdminUser($name: String!, $email: String!, $roles: [UserRoleEnum!]!) {
  createAdminUser(name: $name, email: $email, roles: $roles) {
    ...UserInfo
  }
}
    ${UserInfoFragmentDoc}`;
export type CreateAdminUserMutationFn = Apollo.MutationFunction<CreateAdminUserMutation, CreateAdminUserMutationVariables>;

/**
 * __useCreateAdminUserMutation__
 *
 * To run a mutation, you first call `useCreateAdminUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdminUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdminUserMutation, { data, loading, error }] = useCreateAdminUserMutation({
 *   variables: {
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      roles: // value for 'roles'
 *   },
 * });
 */
export function useCreateAdminUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateAdminUserMutation, CreateAdminUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAdminUserMutation, CreateAdminUserMutationVariables>(CreateAdminUserDocument, options);
      }
export type CreateAdminUserMutationHookResult = ReturnType<typeof useCreateAdminUserMutation>;
export type CreateAdminUserMutationResult = Apollo.MutationResult<CreateAdminUserMutation>;
export type CreateAdminUserMutationOptions = Apollo.BaseMutationOptions<CreateAdminUserMutation, CreateAdminUserMutationVariables>;
export const CreateFeedTestUserDocument = gql`
    mutation CreateFeedTestUser($userId: Int!) {
  createFeedTestUser(userId: $userId) {
    id
    userId
    isActive
  }
}
    `;
export type CreateFeedTestUserMutationFn = Apollo.MutationFunction<CreateFeedTestUserMutation, CreateFeedTestUserMutationVariables>;

/**
 * __useCreateFeedTestUserMutation__
 *
 * To run a mutation, you first call `useCreateFeedTestUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeedTestUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeedTestUserMutation, { data, loading, error }] = useCreateFeedTestUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCreateFeedTestUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateFeedTestUserMutation, CreateFeedTestUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFeedTestUserMutation, CreateFeedTestUserMutationVariables>(CreateFeedTestUserDocument, options);
      }
export type CreateFeedTestUserMutationHookResult = ReturnType<typeof useCreateFeedTestUserMutation>;
export type CreateFeedTestUserMutationResult = Apollo.MutationResult<CreateFeedTestUserMutation>;
export type CreateFeedTestUserMutationOptions = Apollo.BaseMutationOptions<CreateFeedTestUserMutation, CreateFeedTestUserMutationVariables>;
export const CreateInformativeContentDocument = gql`
    mutation CreateInformativeContent($image: Upload, $title: TranslationInput!, $subTitle: TranslationInput!, $text: TranslationInput!, $type: InformativeContentEnum!, $ordinal: Int!) {
  createInformativeContent(
    image: $image
    title: $title
    subTitle: $subTitle
    text: $text
    type: $type
    ordinal: $ordinal
  ) {
    ...InformativeContentInfo
  }
}
    ${InformativeContentInfoFragmentDoc}`;
export type CreateInformativeContentMutationFn = Apollo.MutationFunction<CreateInformativeContentMutation, CreateInformativeContentMutationVariables>;

/**
 * __useCreateInformativeContentMutation__
 *
 * To run a mutation, you first call `useCreateInformativeContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInformativeContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInformativeContentMutation, { data, loading, error }] = useCreateInformativeContentMutation({
 *   variables: {
 *      image: // value for 'image'
 *      title: // value for 'title'
 *      subTitle: // value for 'subTitle'
 *      text: // value for 'text'
 *      type: // value for 'type'
 *      ordinal: // value for 'ordinal'
 *   },
 * });
 */
export function useCreateInformativeContentMutation(baseOptions?: Apollo.MutationHookOptions<CreateInformativeContentMutation, CreateInformativeContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInformativeContentMutation, CreateInformativeContentMutationVariables>(CreateInformativeContentDocument, options);
      }
export type CreateInformativeContentMutationHookResult = ReturnType<typeof useCreateInformativeContentMutation>;
export type CreateInformativeContentMutationResult = Apollo.MutationResult<CreateInformativeContentMutation>;
export type CreateInformativeContentMutationOptions = Apollo.BaseMutationOptions<CreateInformativeContentMutation, CreateInformativeContentMutationVariables>;
export const CreateQuestionDocument = gql`
    mutation CreateQuestion($topic: TopicTypeEnum!, $type: QuestionTypeEnum!, $question: TranslationInput!) {
  createQuestion(topic: $topic, type: $type, question: $question) {
    ...TestQuestionInfo
  }
}
    ${TestQuestionInfoFragmentDoc}`;
export type CreateQuestionMutationFn = Apollo.MutationFunction<CreateQuestionMutation, CreateQuestionMutationVariables>;

/**
 * __useCreateQuestionMutation__
 *
 * To run a mutation, you first call `useCreateQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuestionMutation, { data, loading, error }] = useCreateQuestionMutation({
 *   variables: {
 *      topic: // value for 'topic'
 *      type: // value for 'type'
 *      question: // value for 'question'
 *   },
 * });
 */
export function useCreateQuestionMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuestionMutation, CreateQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuestionMutation, CreateQuestionMutationVariables>(CreateQuestionDocument, options);
      }
export type CreateQuestionMutationHookResult = ReturnType<typeof useCreateQuestionMutation>;
export type CreateQuestionMutationResult = Apollo.MutationResult<CreateQuestionMutation>;
export type CreateQuestionMutationOptions = Apollo.BaseMutationOptions<CreateQuestionMutation, CreateQuestionMutationVariables>;
export const CreateQuestionOptionDocument = gql`
    mutation CreateQuestionOption($questionId: String!, $answer: TranslationInput!, $conclusionText: TranslationInput!, $score: Int!, $weight: Int!) {
  createQuestionOption(
    questionId: $questionId
    answer: $answer
    conclusionText: $conclusionText
    score: $score
    weight: $weight
  ) {
    ...TestQuestionOptionInfo
  }
}
    ${TestQuestionOptionInfoFragmentDoc}`;
export type CreateQuestionOptionMutationFn = Apollo.MutationFunction<CreateQuestionOptionMutation, CreateQuestionOptionMutationVariables>;

/**
 * __useCreateQuestionOptionMutation__
 *
 * To run a mutation, you first call `useCreateQuestionOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuestionOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuestionOptionMutation, { data, loading, error }] = useCreateQuestionOptionMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *      answer: // value for 'answer'
 *      conclusionText: // value for 'conclusionText'
 *      score: // value for 'score'
 *      weight: // value for 'weight'
 *   },
 * });
 */
export function useCreateQuestionOptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuestionOptionMutation, CreateQuestionOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuestionOptionMutation, CreateQuestionOptionMutationVariables>(CreateQuestionOptionDocument, options);
      }
export type CreateQuestionOptionMutationHookResult = ReturnType<typeof useCreateQuestionOptionMutation>;
export type CreateQuestionOptionMutationResult = Apollo.MutationResult<CreateQuestionOptionMutation>;
export type CreateQuestionOptionMutationOptions = Apollo.BaseMutationOptions<CreateQuestionOptionMutation, CreateQuestionOptionMutationVariables>;
export const CreateUserFeedEventDocument = gql`
    mutation CreateUserFeedEvent($event: UserFeedEventNameEnum!, $type: UserFeedItemTypeEnum!, $visibility: UserFeedItemVisibilityEnum!, $version: Int!, $isPinned: Boolean!, $metadata: JSON!) {
  createUserFeedEvent(
    event: $event
    type: $type
    visibility: $visibility
    version: $version
    isPinned: $isPinned
    metadata: $metadata
  ) {
    ...UserFeedEventInfo
  }
}
    ${UserFeedEventInfoFragmentDoc}`;
export type CreateUserFeedEventMutationFn = Apollo.MutationFunction<CreateUserFeedEventMutation, CreateUserFeedEventMutationVariables>;

/**
 * __useCreateUserFeedEventMutation__
 *
 * To run a mutation, you first call `useCreateUserFeedEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserFeedEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserFeedEventMutation, { data, loading, error }] = useCreateUserFeedEventMutation({
 *   variables: {
 *      event: // value for 'event'
 *      type: // value for 'type'
 *      visibility: // value for 'visibility'
 *      version: // value for 'version'
 *      isPinned: // value for 'isPinned'
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useCreateUserFeedEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserFeedEventMutation, CreateUserFeedEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserFeedEventMutation, CreateUserFeedEventMutationVariables>(CreateUserFeedEventDocument, options);
      }
export type CreateUserFeedEventMutationHookResult = ReturnType<typeof useCreateUserFeedEventMutation>;
export type CreateUserFeedEventMutationResult = Apollo.MutationResult<CreateUserFeedEventMutation>;
export type CreateUserFeedEventMutationOptions = Apollo.BaseMutationOptions<CreateUserFeedEventMutation, CreateUserFeedEventMutationVariables>;
export const UpdateAdminStatusDocument = gql`
    mutation UpdateAdminStatus($userIds: [ID!]!, $status: UserStatusEnum!) {
  updateAdminStatus(userIds: $userIds, status: $status) {
    ...UserInfo
  }
}
    ${UserInfoFragmentDoc}`;
export type UpdateAdminStatusMutationFn = Apollo.MutationFunction<UpdateAdminStatusMutation, UpdateAdminStatusMutationVariables>;

/**
 * __useUpdateAdminStatusMutation__
 *
 * To run a mutation, you first call `useUpdateAdminStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminStatusMutation, { data, loading, error }] = useUpdateAdminStatusMutation({
 *   variables: {
 *      userIds: // value for 'userIds'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateAdminStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdminStatusMutation, UpdateAdminStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAdminStatusMutation, UpdateAdminStatusMutationVariables>(UpdateAdminStatusDocument, options);
      }
export type UpdateAdminStatusMutationHookResult = ReturnType<typeof useUpdateAdminStatusMutation>;
export type UpdateAdminStatusMutationResult = Apollo.MutationResult<UpdateAdminStatusMutation>;
export type UpdateAdminStatusMutationOptions = Apollo.BaseMutationOptions<UpdateAdminStatusMutation, UpdateAdminStatusMutationVariables>;
export const UpdateFeedTestUserDocument = gql`
    mutation UpdateFeedTestUser($id: ID!, $comment: String!) {
  updateFeedTestUser(id: $id, comment: $comment) {
    id
    userId
    isActive
  }
}
    `;
export type UpdateFeedTestUserMutationFn = Apollo.MutationFunction<UpdateFeedTestUserMutation, UpdateFeedTestUserMutationVariables>;

/**
 * __useUpdateFeedTestUserMutation__
 *
 * To run a mutation, you first call `useUpdateFeedTestUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFeedTestUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFeedTestUserMutation, { data, loading, error }] = useUpdateFeedTestUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useUpdateFeedTestUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFeedTestUserMutation, UpdateFeedTestUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFeedTestUserMutation, UpdateFeedTestUserMutationVariables>(UpdateFeedTestUserDocument, options);
      }
export type UpdateFeedTestUserMutationHookResult = ReturnType<typeof useUpdateFeedTestUserMutation>;
export type UpdateFeedTestUserMutationResult = Apollo.MutationResult<UpdateFeedTestUserMutation>;
export type UpdateFeedTestUserMutationOptions = Apollo.BaseMutationOptions<UpdateFeedTestUserMutation, UpdateFeedTestUserMutationVariables>;
export const LoginAsAdminDocument = gql`
    mutation LoginAsAdmin($userId: ID!) {
  loginAsAdmin(userId: $userId) {
    ...UserInfo
  }
}
    ${UserInfoFragmentDoc}`;
export type LoginAsAdminMutationFn = Apollo.MutationFunction<LoginAsAdminMutation, LoginAsAdminMutationVariables>;

/**
 * __useLoginAsAdminMutation__
 *
 * To run a mutation, you first call `useLoginAsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginAsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginAsAdminMutation, { data, loading, error }] = useLoginAsAdminMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useLoginAsAdminMutation(baseOptions?: Apollo.MutationHookOptions<LoginAsAdminMutation, LoginAsAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginAsAdminMutation, LoginAsAdminMutationVariables>(LoginAsAdminDocument, options);
      }
export type LoginAsAdminMutationHookResult = ReturnType<typeof useLoginAsAdminMutation>;
export type LoginAsAdminMutationResult = Apollo.MutationResult<LoginAsAdminMutation>;
export type LoginAsAdminMutationOptions = Apollo.BaseMutationOptions<LoginAsAdminMutation, LoginAsAdminMutationVariables>;
export const ResetAdminPasswordDocument = gql`
    mutation ResetAdminPassword($userId: ID!) {
  resetAdminPassword(userId: $userId) {
    ...UserInfo
  }
}
    ${UserInfoFragmentDoc}`;
export type ResetAdminPasswordMutationFn = Apollo.MutationFunction<ResetAdminPasswordMutation, ResetAdminPasswordMutationVariables>;

/**
 * __useResetAdminPasswordMutation__
 *
 * To run a mutation, you first call `useResetAdminPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetAdminPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetAdminPasswordMutation, { data, loading, error }] = useResetAdminPasswordMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useResetAdminPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetAdminPasswordMutation, ResetAdminPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetAdminPasswordMutation, ResetAdminPasswordMutationVariables>(ResetAdminPasswordDocument, options);
      }
export type ResetAdminPasswordMutationHookResult = ReturnType<typeof useResetAdminPasswordMutation>;
export type ResetAdminPasswordMutationResult = Apollo.MutationResult<ResetAdminPasswordMutation>;
export type ResetAdminPasswordMutationOptions = Apollo.BaseMutationOptions<ResetAdminPasswordMutation, ResetAdminPasswordMutationVariables>;
export const UploadImageDocument = gql`
    mutation UploadImage($image: Upload!) {
  uploadImage(image: $image) {
    ...FileInfo
  }
}
    ${FileInfoFragmentDoc}`;
export type UploadImageMutationFn = Apollo.MutationFunction<UploadImageMutation, UploadImageMutationVariables>;

/**
 * __useUploadImageMutation__
 *
 * To run a mutation, you first call `useUploadImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadImageMutation, { data, loading, error }] = useUploadImageMutation({
 *   variables: {
 *      image: // value for 'image'
 *   },
 * });
 */
export function useUploadImageMutation(baseOptions?: Apollo.MutationHookOptions<UploadImageMutation, UploadImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadImageMutation, UploadImageMutationVariables>(UploadImageDocument, options);
      }
export type UploadImageMutationHookResult = ReturnType<typeof useUploadImageMutation>;
export type UploadImageMutationResult = Apollo.MutationResult<UploadImageMutation>;
export type UploadImageMutationOptions = Apollo.BaseMutationOptions<UploadImageMutation, UploadImageMutationVariables>;
export const AdminByIdDocument = gql`
    query AdminById($userId: ID!) {
  admin(userId: $userId) {
    ...UserInfo
  }
}
    ${UserInfoFragmentDoc}`;

/**
 * __useAdminByIdQuery__
 *
 * To run a query within a React component, call `useAdminByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminByIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAdminByIdQuery(baseOptions: Apollo.QueryHookOptions<AdminByIdQuery, AdminByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminByIdQuery, AdminByIdQueryVariables>(AdminByIdDocument, options);
      }
export function useAdminByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminByIdQuery, AdminByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminByIdQuery, AdminByIdQueryVariables>(AdminByIdDocument, options);
        }
export type AdminByIdQueryHookResult = ReturnType<typeof useAdminByIdQuery>;
export type AdminByIdLazyQueryHookResult = ReturnType<typeof useAdminByIdLazyQuery>;
export type AdminByIdQueryResult = Apollo.QueryResult<AdminByIdQuery, AdminByIdQueryVariables>;
export const UpdateAdminAvatarDocument = gql`
    mutation UpdateAdminAvatar($userId: ID!, $avatar: Upload!) {
  updateAdminAvatar(userId: $userId, avatar: $avatar) {
    ...UserInfo
  }
}
    ${UserInfoFragmentDoc}`;
export type UpdateAdminAvatarMutationFn = Apollo.MutationFunction<UpdateAdminAvatarMutation, UpdateAdminAvatarMutationVariables>;

/**
 * __useUpdateAdminAvatarMutation__
 *
 * To run a mutation, you first call `useUpdateAdminAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminAvatarMutation, { data, loading, error }] = useUpdateAdminAvatarMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      avatar: // value for 'avatar'
 *   },
 * });
 */
export function useUpdateAdminAvatarMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdminAvatarMutation, UpdateAdminAvatarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAdminAvatarMutation, UpdateAdminAvatarMutationVariables>(UpdateAdminAvatarDocument, options);
      }
export type UpdateAdminAvatarMutationHookResult = ReturnType<typeof useUpdateAdminAvatarMutation>;
export type UpdateAdminAvatarMutationResult = Apollo.MutationResult<UpdateAdminAvatarMutation>;
export type UpdateAdminAvatarMutationOptions = Apollo.BaseMutationOptions<UpdateAdminAvatarMutation, UpdateAdminAvatarMutationVariables>;
export const UpdateAdminInfoDocument = gql`
    mutation UpdateAdminInfo($userId: ID!, $email: String!, $name: String!, $status: UserStatusEnum!, $roles: [UserRoleEnum!]!, $comment: String!) {
  updateAdminInfo(
    userId: $userId
    email: $email
    name: $name
    status: $status
    roles: $roles
    comment: $comment
  ) {
    ...UserInfo
  }
}
    ${UserInfoFragmentDoc}`;
export type UpdateAdminInfoMutationFn = Apollo.MutationFunction<UpdateAdminInfoMutation, UpdateAdminInfoMutationVariables>;

/**
 * __useUpdateAdminInfoMutation__
 *
 * To run a mutation, you first call `useUpdateAdminInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminInfoMutation, { data, loading, error }] = useUpdateAdminInfoMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      email: // value for 'email'
 *      name: // value for 'name'
 *      status: // value for 'status'
 *      roles: // value for 'roles'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useUpdateAdminInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdminInfoMutation, UpdateAdminInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAdminInfoMutation, UpdateAdminInfoMutationVariables>(UpdateAdminInfoDocument, options);
      }
export type UpdateAdminInfoMutationHookResult = ReturnType<typeof useUpdateAdminInfoMutation>;
export type UpdateAdminInfoMutationResult = Apollo.MutationResult<UpdateAdminInfoMutation>;
export type UpdateAdminInfoMutationOptions = Apollo.BaseMutationOptions<UpdateAdminInfoMutation, UpdateAdminInfoMutationVariables>;
export const CreateAdminFeedItemDocument = gql`
    mutation CreateAdminFeedItem($type: AdminFeedItemTypeEnum!, $audience: [AdminFeedItemAudienceEnum!]!, $isPinned: Boolean!, $isRecommended: Boolean!, $metadata: JSON!, $startDate: DateTime, $endDate: DateTime, $startDay: Int, $endDay: Int, $likeCount: Int, $shareCount: Int, $version: Int, $topicIds: [ID!]!, $description: String!) {
  createAdminFeedItem(
    type: $type
    audience: $audience
    isPinned: $isPinned
    isRecommended: $isRecommended
    metadata: $metadata
    startDate: $startDate
    endDate: $endDate
    startDay: $startDay
    endDay: $endDay
    likeCount: $likeCount
    shareCount: $shareCount
    version: $version
    topicIds: $topicIds
    description: $description
  ) {
    ...AdminFeedItemInfo
  }
}
    ${AdminFeedItemInfoFragmentDoc}`;
export type CreateAdminFeedItemMutationFn = Apollo.MutationFunction<CreateAdminFeedItemMutation, CreateAdminFeedItemMutationVariables>;

/**
 * __useCreateAdminFeedItemMutation__
 *
 * To run a mutation, you first call `useCreateAdminFeedItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdminFeedItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdminFeedItemMutation, { data, loading, error }] = useCreateAdminFeedItemMutation({
 *   variables: {
 *      type: // value for 'type'
 *      audience: // value for 'audience'
 *      isPinned: // value for 'isPinned'
 *      isRecommended: // value for 'isRecommended'
 *      metadata: // value for 'metadata'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      startDay: // value for 'startDay'
 *      endDay: // value for 'endDay'
 *      likeCount: // value for 'likeCount'
 *      shareCount: // value for 'shareCount'
 *      version: // value for 'version'
 *      topicIds: // value for 'topicIds'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateAdminFeedItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateAdminFeedItemMutation, CreateAdminFeedItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAdminFeedItemMutation, CreateAdminFeedItemMutationVariables>(CreateAdminFeedItemDocument, options);
      }
export type CreateAdminFeedItemMutationHookResult = ReturnType<typeof useCreateAdminFeedItemMutation>;
export type CreateAdminFeedItemMutationResult = Apollo.MutationResult<CreateAdminFeedItemMutation>;
export type CreateAdminFeedItemMutationOptions = Apollo.BaseMutationOptions<CreateAdminFeedItemMutation, CreateAdminFeedItemMutationVariables>;
export const AdminFeedItemByIdDocument = gql`
    query AdminFeedItemById($adminFeedItemId: ID!) {
  adminFeedItem(adminFeedItemId: $adminFeedItemId) {
    ...AdminFeedItemInfo
  }
}
    ${AdminFeedItemInfoFragmentDoc}`;

/**
 * __useAdminFeedItemByIdQuery__
 *
 * To run a query within a React component, call `useAdminFeedItemByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminFeedItemByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminFeedItemByIdQuery({
 *   variables: {
 *      adminFeedItemId: // value for 'adminFeedItemId'
 *   },
 * });
 */
export function useAdminFeedItemByIdQuery(baseOptions: Apollo.QueryHookOptions<AdminFeedItemByIdQuery, AdminFeedItemByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminFeedItemByIdQuery, AdminFeedItemByIdQueryVariables>(AdminFeedItemByIdDocument, options);
      }
export function useAdminFeedItemByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminFeedItemByIdQuery, AdminFeedItemByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminFeedItemByIdQuery, AdminFeedItemByIdQueryVariables>(AdminFeedItemByIdDocument, options);
        }
export type AdminFeedItemByIdQueryHookResult = ReturnType<typeof useAdminFeedItemByIdQuery>;
export type AdminFeedItemByIdLazyQueryHookResult = ReturnType<typeof useAdminFeedItemByIdLazyQuery>;
export type AdminFeedItemByIdQueryResult = Apollo.QueryResult<AdminFeedItemByIdQuery, AdminFeedItemByIdQueryVariables>;
export const UpdateAdminFeedItemDocument = gql`
    mutation UpdateAdminFeedItem($adminFeedItemId: ID!, $type: AdminFeedItemTypeEnum!, $audience: [AdminFeedItemAudienceEnum!]!, $isPinned: Boolean!, $isRecommended: Boolean!, $metadata: JSON!, $startDate: DateTime, $endDate: DateTime, $startDay: Int, $endDay: Int, $likeCount: Int!, $shareCount: Int!, $version: Int!, $topicIds: [ID!]!, $description: String!, $comment: String!) {
  updateAdminFeedItem(
    adminFeedItemId: $adminFeedItemId
    type: $type
    audience: $audience
    isPinned: $isPinned
    isRecommended: $isRecommended
    metadata: $metadata
    startDate: $startDate
    endDate: $endDate
    startDay: $startDay
    endDay: $endDay
    likeCount: $likeCount
    shareCount: $shareCount
    version: $version
    topicIds: $topicIds
    description: $description
    comment: $comment
  ) {
    ...AdminFeedItemInfo
  }
}
    ${AdminFeedItemInfoFragmentDoc}`;
export type UpdateAdminFeedItemMutationFn = Apollo.MutationFunction<UpdateAdminFeedItemMutation, UpdateAdminFeedItemMutationVariables>;

/**
 * __useUpdateAdminFeedItemMutation__
 *
 * To run a mutation, you first call `useUpdateAdminFeedItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminFeedItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminFeedItemMutation, { data, loading, error }] = useUpdateAdminFeedItemMutation({
 *   variables: {
 *      adminFeedItemId: // value for 'adminFeedItemId'
 *      type: // value for 'type'
 *      audience: // value for 'audience'
 *      isPinned: // value for 'isPinned'
 *      isRecommended: // value for 'isRecommended'
 *      metadata: // value for 'metadata'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      startDay: // value for 'startDay'
 *      endDay: // value for 'endDay'
 *      likeCount: // value for 'likeCount'
 *      shareCount: // value for 'shareCount'
 *      version: // value for 'version'
 *      topicIds: // value for 'topicIds'
 *      description: // value for 'description'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useUpdateAdminFeedItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdminFeedItemMutation, UpdateAdminFeedItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAdminFeedItemMutation, UpdateAdminFeedItemMutationVariables>(UpdateAdminFeedItemDocument, options);
      }
export type UpdateAdminFeedItemMutationHookResult = ReturnType<typeof useUpdateAdminFeedItemMutation>;
export type UpdateAdminFeedItemMutationResult = Apollo.MutationResult<UpdateAdminFeedItemMutation>;
export type UpdateAdminFeedItemMutationOptions = Apollo.BaseMutationOptions<UpdateAdminFeedItemMutation, UpdateAdminFeedItemMutationVariables>;
export const UserAdminFeedItemStatusInteractionDocument = gql`
    query UserAdminFeedItemStatusInteraction($adminFeedItemId: ID!) {
  userAdminFeedItemStatusInteraction(adminFeedItemId: $adminFeedItemId) {
    userInteraction {
      ...AdminFeedItemStatusInteractionInfo
    }
    isCreator
  }
}
    ${AdminFeedItemStatusInteractionInfoFragmentDoc}`;

/**
 * __useUserAdminFeedItemStatusInteractionQuery__
 *
 * To run a query within a React component, call `useUserAdminFeedItemStatusInteractionQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAdminFeedItemStatusInteractionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAdminFeedItemStatusInteractionQuery({
 *   variables: {
 *      adminFeedItemId: // value for 'adminFeedItemId'
 *   },
 * });
 */
export function useUserAdminFeedItemStatusInteractionQuery(baseOptions: Apollo.QueryHookOptions<UserAdminFeedItemStatusInteractionQuery, UserAdminFeedItemStatusInteractionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserAdminFeedItemStatusInteractionQuery, UserAdminFeedItemStatusInteractionQueryVariables>(UserAdminFeedItemStatusInteractionDocument, options);
      }
export function useUserAdminFeedItemStatusInteractionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserAdminFeedItemStatusInteractionQuery, UserAdminFeedItemStatusInteractionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserAdminFeedItemStatusInteractionQuery, UserAdminFeedItemStatusInteractionQueryVariables>(UserAdminFeedItemStatusInteractionDocument, options);
        }
export type UserAdminFeedItemStatusInteractionQueryHookResult = ReturnType<typeof useUserAdminFeedItemStatusInteractionQuery>;
export type UserAdminFeedItemStatusInteractionLazyQueryHookResult = ReturnType<typeof useUserAdminFeedItemStatusInteractionLazyQuery>;
export type UserAdminFeedItemStatusInteractionQueryResult = Apollo.QueryResult<UserAdminFeedItemStatusInteractionQuery, UserAdminFeedItemStatusInteractionQueryVariables>;
export const AdminFeedItemsDocument = gql`
    query AdminFeedItems($filter: AdminFeedItemsFilterInput, $pagination: PaginationInput, $match: MatchInput, $sort: [SortingInput!]) {
  adminFeedItems(
    filter: $filter
    pagination: $pagination
    match: $match
    sort: $sort
  ) {
    skip
    take
    total
    adminFeedItems {
      ...AdminFeedItemInfo
    }
  }
}
    ${AdminFeedItemInfoFragmentDoc}`;

/**
 * __useAdminFeedItemsQuery__
 *
 * To run a query within a React component, call `useAdminFeedItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminFeedItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminFeedItemsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      match: // value for 'match'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useAdminFeedItemsQuery(baseOptions?: Apollo.QueryHookOptions<AdminFeedItemsQuery, AdminFeedItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminFeedItemsQuery, AdminFeedItemsQueryVariables>(AdminFeedItemsDocument, options);
      }
export function useAdminFeedItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminFeedItemsQuery, AdminFeedItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminFeedItemsQuery, AdminFeedItemsQueryVariables>(AdminFeedItemsDocument, options);
        }
export type AdminFeedItemsQueryHookResult = ReturnType<typeof useAdminFeedItemsQuery>;
export type AdminFeedItemsLazyQueryHookResult = ReturnType<typeof useAdminFeedItemsLazyQuery>;
export type AdminFeedItemsQueryResult = Apollo.QueryResult<AdminFeedItemsQuery, AdminFeedItemsQueryVariables>;
export const CreateAdminFeedPollDocument = gql`
    mutation CreateAdminFeedPoll($question: TranslationInput!, $options: [AdminFeedPollOptionInput!]!, $audience: [AdminFeedItemAudienceEnum!]!, $isPinned: Boolean!, $isRecommended: Boolean!, $startDate: DateTime, $endDate: DateTime, $startDay: Int, $endDay: Int, $likeCount: Int!, $shareCount: Int!, $version: Int!, $topicIds: [ID!]!, $image: Upload) {
  createAdminFeedPoll(
    question: $question
    options: $options
    audience: $audience
    isPinned: $isPinned
    isRecommended: $isRecommended
    startDate: $startDate
    endDate: $endDate
    startDay: $startDay
    endDay: $endDay
    likeCount: $likeCount
    shareCount: $shareCount
    version: $version
    topicIds: $topicIds
    image: $image
  ) {
    ...AdminFeedPollInfo
  }
}
    ${AdminFeedPollInfoFragmentDoc}`;
export type CreateAdminFeedPollMutationFn = Apollo.MutationFunction<CreateAdminFeedPollMutation, CreateAdminFeedPollMutationVariables>;

/**
 * __useCreateAdminFeedPollMutation__
 *
 * To run a mutation, you first call `useCreateAdminFeedPollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdminFeedPollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdminFeedPollMutation, { data, loading, error }] = useCreateAdminFeedPollMutation({
 *   variables: {
 *      question: // value for 'question'
 *      options: // value for 'options'
 *      audience: // value for 'audience'
 *      isPinned: // value for 'isPinned'
 *      isRecommended: // value for 'isRecommended'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      startDay: // value for 'startDay'
 *      endDay: // value for 'endDay'
 *      likeCount: // value for 'likeCount'
 *      shareCount: // value for 'shareCount'
 *      version: // value for 'version'
 *      topicIds: // value for 'topicIds'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useCreateAdminFeedPollMutation(baseOptions?: Apollo.MutationHookOptions<CreateAdminFeedPollMutation, CreateAdminFeedPollMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAdminFeedPollMutation, CreateAdminFeedPollMutationVariables>(CreateAdminFeedPollDocument, options);
      }
export type CreateAdminFeedPollMutationHookResult = ReturnType<typeof useCreateAdminFeedPollMutation>;
export type CreateAdminFeedPollMutationResult = Apollo.MutationResult<CreateAdminFeedPollMutation>;
export type CreateAdminFeedPollMutationOptions = Apollo.BaseMutationOptions<CreateAdminFeedPollMutation, CreateAdminFeedPollMutationVariables>;
export const AdminFeedPollByIdDocument = gql`
    query AdminFeedPollById($adminFeedPollId: ID!) {
  adminFeedPoll(adminFeedPollId: $adminFeedPollId) {
    ...AdminFeedPollInfo
  }
}
    ${AdminFeedPollInfoFragmentDoc}`;

/**
 * __useAdminFeedPollByIdQuery__
 *
 * To run a query within a React component, call `useAdminFeedPollByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminFeedPollByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminFeedPollByIdQuery({
 *   variables: {
 *      adminFeedPollId: // value for 'adminFeedPollId'
 *   },
 * });
 */
export function useAdminFeedPollByIdQuery(baseOptions: Apollo.QueryHookOptions<AdminFeedPollByIdQuery, AdminFeedPollByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminFeedPollByIdQuery, AdminFeedPollByIdQueryVariables>(AdminFeedPollByIdDocument, options);
      }
export function useAdminFeedPollByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminFeedPollByIdQuery, AdminFeedPollByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminFeedPollByIdQuery, AdminFeedPollByIdQueryVariables>(AdminFeedPollByIdDocument, options);
        }
export type AdminFeedPollByIdQueryHookResult = ReturnType<typeof useAdminFeedPollByIdQuery>;
export type AdminFeedPollByIdLazyQueryHookResult = ReturnType<typeof useAdminFeedPollByIdLazyQuery>;
export type AdminFeedPollByIdQueryResult = Apollo.QueryResult<AdminFeedPollByIdQuery, AdminFeedPollByIdQueryVariables>;
export const AdminFeedPollResultsByIdDocument = gql`
    query AdminFeedPollResultsById($adminFeedPollId: ID!) {
  adminFeedPollResults(adminFeedPollId: $adminFeedPollId) {
    pollId
    totalCount
    answerOptions {
      adminFeedPollOptionId
      adminFeedPollOptionAnswer
      count
      percentage
    }
  }
}
    `;

/**
 * __useAdminFeedPollResultsByIdQuery__
 *
 * To run a query within a React component, call `useAdminFeedPollResultsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminFeedPollResultsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminFeedPollResultsByIdQuery({
 *   variables: {
 *      adminFeedPollId: // value for 'adminFeedPollId'
 *   },
 * });
 */
export function useAdminFeedPollResultsByIdQuery(baseOptions: Apollo.QueryHookOptions<AdminFeedPollResultsByIdQuery, AdminFeedPollResultsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminFeedPollResultsByIdQuery, AdminFeedPollResultsByIdQueryVariables>(AdminFeedPollResultsByIdDocument, options);
      }
export function useAdminFeedPollResultsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminFeedPollResultsByIdQuery, AdminFeedPollResultsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminFeedPollResultsByIdQuery, AdminFeedPollResultsByIdQueryVariables>(AdminFeedPollResultsByIdDocument, options);
        }
export type AdminFeedPollResultsByIdQueryHookResult = ReturnType<typeof useAdminFeedPollResultsByIdQuery>;
export type AdminFeedPollResultsByIdLazyQueryHookResult = ReturnType<typeof useAdminFeedPollResultsByIdLazyQuery>;
export type AdminFeedPollResultsByIdQueryResult = Apollo.QueryResult<AdminFeedPollResultsByIdQuery, AdminFeedPollResultsByIdQueryVariables>;
export const UpdateAdminFeedPollDocument = gql`
    mutation UpdateAdminFeedPoll($adminFeedPollId: ID!, $question: TranslationInput!, $options: [AdminFeedPollOptionInput!]!, $audience: [AdminFeedItemAudienceEnum!]!, $isPinned: Boolean!, $isRecommended: Boolean!, $startDate: DateTime, $endDate: DateTime, $startDay: Int, $endDay: Int, $likeCount: Int!, $shareCount: Int!, $version: Int!, $topicIds: [ID!]!, $image: Upload, $comment: String!) {
  updateAdminFeedPoll(
    adminFeedPollId: $adminFeedPollId
    question: $question
    options: $options
    comment: $comment
    audience: $audience
    isPinned: $isPinned
    isRecommended: $isRecommended
    startDate: $startDate
    endDate: $endDate
    startDay: $startDay
    endDay: $endDay
    likeCount: $likeCount
    shareCount: $shareCount
    version: $version
    topicIds: $topicIds
    image: $image
  ) {
    ...AdminFeedPollInfo
  }
}
    ${AdminFeedPollInfoFragmentDoc}`;
export type UpdateAdminFeedPollMutationFn = Apollo.MutationFunction<UpdateAdminFeedPollMutation, UpdateAdminFeedPollMutationVariables>;

/**
 * __useUpdateAdminFeedPollMutation__
 *
 * To run a mutation, you first call `useUpdateAdminFeedPollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminFeedPollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminFeedPollMutation, { data, loading, error }] = useUpdateAdminFeedPollMutation({
 *   variables: {
 *      adminFeedPollId: // value for 'adminFeedPollId'
 *      question: // value for 'question'
 *      options: // value for 'options'
 *      audience: // value for 'audience'
 *      isPinned: // value for 'isPinned'
 *      isRecommended: // value for 'isRecommended'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      startDay: // value for 'startDay'
 *      endDay: // value for 'endDay'
 *      likeCount: // value for 'likeCount'
 *      shareCount: // value for 'shareCount'
 *      version: // value for 'version'
 *      topicIds: // value for 'topicIds'
 *      image: // value for 'image'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useUpdateAdminFeedPollMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdminFeedPollMutation, UpdateAdminFeedPollMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAdminFeedPollMutation, UpdateAdminFeedPollMutationVariables>(UpdateAdminFeedPollDocument, options);
      }
export type UpdateAdminFeedPollMutationHookResult = ReturnType<typeof useUpdateAdminFeedPollMutation>;
export type UpdateAdminFeedPollMutationResult = Apollo.MutationResult<UpdateAdminFeedPollMutation>;
export type UpdateAdminFeedPollMutationOptions = Apollo.BaseMutationOptions<UpdateAdminFeedPollMutation, UpdateAdminFeedPollMutationVariables>;
export const AdminFeedPollsDocument = gql`
    query AdminFeedPolls($filter: AdminFeedPollsFilterInput, $pagination: PaginationInput, $match: MatchInput, $sort: [SortingInput!]) {
  adminFeedPolls(
    filter: $filter
    pagination: $pagination
    match: $match
    sort: $sort
  ) {
    skip
    take
    total
    adminFeedPolls {
      ...AdminFeedPollInfo
    }
  }
}
    ${AdminFeedPollInfoFragmentDoc}`;

/**
 * __useAdminFeedPollsQuery__
 *
 * To run a query within a React component, call `useAdminFeedPollsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminFeedPollsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminFeedPollsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      match: // value for 'match'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useAdminFeedPollsQuery(baseOptions?: Apollo.QueryHookOptions<AdminFeedPollsQuery, AdminFeedPollsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminFeedPollsQuery, AdminFeedPollsQueryVariables>(AdminFeedPollsDocument, options);
      }
export function useAdminFeedPollsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminFeedPollsQuery, AdminFeedPollsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminFeedPollsQuery, AdminFeedPollsQueryVariables>(AdminFeedPollsDocument, options);
        }
export type AdminFeedPollsQueryHookResult = ReturnType<typeof useAdminFeedPollsQuery>;
export type AdminFeedPollsLazyQueryHookResult = ReturnType<typeof useAdminFeedPollsLazyQuery>;
export type AdminFeedPollsQueryResult = Apollo.QueryResult<AdminFeedPollsQuery, AdminFeedPollsQueryVariables>;
export const AdminFeedTopicByIdDocument = gql`
    query AdminFeedTopicById($adminFeedTopicId: ID!) {
  adminFeedTopic(adminFeedTopicId: $adminFeedTopicId) {
    ...AdminFeedTopicInfo
  }
}
    ${AdminFeedTopicInfoFragmentDoc}`;

/**
 * __useAdminFeedTopicByIdQuery__
 *
 * To run a query within a React component, call `useAdminFeedTopicByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminFeedTopicByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminFeedTopicByIdQuery({
 *   variables: {
 *      adminFeedTopicId: // value for 'adminFeedTopicId'
 *   },
 * });
 */
export function useAdminFeedTopicByIdQuery(baseOptions: Apollo.QueryHookOptions<AdminFeedTopicByIdQuery, AdminFeedTopicByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminFeedTopicByIdQuery, AdminFeedTopicByIdQueryVariables>(AdminFeedTopicByIdDocument, options);
      }
export function useAdminFeedTopicByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminFeedTopicByIdQuery, AdminFeedTopicByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminFeedTopicByIdQuery, AdminFeedTopicByIdQueryVariables>(AdminFeedTopicByIdDocument, options);
        }
export type AdminFeedTopicByIdQueryHookResult = ReturnType<typeof useAdminFeedTopicByIdQuery>;
export type AdminFeedTopicByIdLazyQueryHookResult = ReturnType<typeof useAdminFeedTopicByIdLazyQuery>;
export type AdminFeedTopicByIdQueryResult = Apollo.QueryResult<AdminFeedTopicByIdQuery, AdminFeedTopicByIdQueryVariables>;
export const UpdateAdminFeedTopicDocument = gql`
    mutation UpdateAdminFeedTopic($adminFeedTopicId: ID!, $name: String!, $icon: Upload, $isActive: Boolean!, $comment: String!) {
  updateAdminFeedTopic(
    adminFeedTopicId: $adminFeedTopicId
    name: $name
    icon: $icon
    isActive: $isActive
    comment: $comment
  ) {
    ...AdminFeedTopicInfo
  }
}
    ${AdminFeedTopicInfoFragmentDoc}`;
export type UpdateAdminFeedTopicMutationFn = Apollo.MutationFunction<UpdateAdminFeedTopicMutation, UpdateAdminFeedTopicMutationVariables>;

/**
 * __useUpdateAdminFeedTopicMutation__
 *
 * To run a mutation, you first call `useUpdateAdminFeedTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminFeedTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminFeedTopicMutation, { data, loading, error }] = useUpdateAdminFeedTopicMutation({
 *   variables: {
 *      adminFeedTopicId: // value for 'adminFeedTopicId'
 *      name: // value for 'name'
 *      icon: // value for 'icon'
 *      isActive: // value for 'isActive'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useUpdateAdminFeedTopicMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdminFeedTopicMutation, UpdateAdminFeedTopicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAdminFeedTopicMutation, UpdateAdminFeedTopicMutationVariables>(UpdateAdminFeedTopicDocument, options);
      }
export type UpdateAdminFeedTopicMutationHookResult = ReturnType<typeof useUpdateAdminFeedTopicMutation>;
export type UpdateAdminFeedTopicMutationResult = Apollo.MutationResult<UpdateAdminFeedTopicMutation>;
export type UpdateAdminFeedTopicMutationOptions = Apollo.BaseMutationOptions<UpdateAdminFeedTopicMutation, UpdateAdminFeedTopicMutationVariables>;
export const AdminFeedTopicsDocument = gql`
    query AdminFeedTopics {
  adminFeedTopics {
    ...AdminFeedTopicInfo
  }
}
    ${AdminFeedTopicInfoFragmentDoc}`;

/**
 * __useAdminFeedTopicsQuery__
 *
 * To run a query within a React component, call `useAdminFeedTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminFeedTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminFeedTopicsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminFeedTopicsQuery(baseOptions?: Apollo.QueryHookOptions<AdminFeedTopicsQuery, AdminFeedTopicsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminFeedTopicsQuery, AdminFeedTopicsQueryVariables>(AdminFeedTopicsDocument, options);
      }
export function useAdminFeedTopicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminFeedTopicsQuery, AdminFeedTopicsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminFeedTopicsQuery, AdminFeedTopicsQueryVariables>(AdminFeedTopicsDocument, options);
        }
export type AdminFeedTopicsQueryHookResult = ReturnType<typeof useAdminFeedTopicsQuery>;
export type AdminFeedTopicsLazyQueryHookResult = ReturnType<typeof useAdminFeedTopicsLazyQuery>;
export type AdminFeedTopicsQueryResult = Apollo.QueryResult<AdminFeedTopicsQuery, AdminFeedTopicsQueryVariables>;
export const AdminsDocument = gql`
    query Admins($filter: AdminsFilterInput, $pagination: PaginationInput, $match: MatchInput, $sort: [SortingInput!]) {
  admins(filter: $filter, pagination: $pagination, match: $match, sort: $sort) {
    skip
    take
    total
    users {
      ...UserInfo
    }
  }
}
    ${UserInfoFragmentDoc}`;

/**
 * __useAdminsQuery__
 *
 * To run a query within a React component, call `useAdminsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      match: // value for 'match'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useAdminsQuery(baseOptions?: Apollo.QueryHookOptions<AdminsQuery, AdminsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminsQuery, AdminsQueryVariables>(AdminsDocument, options);
      }
export function useAdminsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminsQuery, AdminsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminsQuery, AdminsQueryVariables>(AdminsDocument, options);
        }
export type AdminsQueryHookResult = ReturnType<typeof useAdminsQuery>;
export type AdminsLazyQueryHookResult = ReturnType<typeof useAdminsLazyQuery>;
export type AdminsQueryResult = Apollo.QueryResult<AdminsQuery, AdminsQueryVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($currentPassword: String!, $newPassword: String!, $confirmPassword: String!) {
  changePassword(
    currentPassword: $currentPassword
    newPassword: $newPassword
    confirmPassword: $confirmPassword
  ) {
    ...ViewerInfo
  }
}
    ${ViewerInfoFragmentDoc}`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      currentPassword: // value for 'currentPassword'
 *      newPassword: // value for 'newPassword'
 *      confirmPassword: // value for 'confirmPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const ClarityTestConclusionImageByIdDocument = gql`
    query ClarityTestConclusionImageById($conclusionImageId: ID!) {
  clarityTestConclusionImage(conclusionImageId: $conclusionImageId) {
    ...ClarityTestConclusionImageInfo
  }
}
    ${ClarityTestConclusionImageInfoFragmentDoc}`;

/**
 * __useClarityTestConclusionImageByIdQuery__
 *
 * To run a query within a React component, call `useClarityTestConclusionImageByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useClarityTestConclusionImageByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClarityTestConclusionImageByIdQuery({
 *   variables: {
 *      conclusionImageId: // value for 'conclusionImageId'
 *   },
 * });
 */
export function useClarityTestConclusionImageByIdQuery(baseOptions: Apollo.QueryHookOptions<ClarityTestConclusionImageByIdQuery, ClarityTestConclusionImageByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClarityTestConclusionImageByIdQuery, ClarityTestConclusionImageByIdQueryVariables>(ClarityTestConclusionImageByIdDocument, options);
      }
export function useClarityTestConclusionImageByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClarityTestConclusionImageByIdQuery, ClarityTestConclusionImageByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClarityTestConclusionImageByIdQuery, ClarityTestConclusionImageByIdQueryVariables>(ClarityTestConclusionImageByIdDocument, options);
        }
export type ClarityTestConclusionImageByIdQueryHookResult = ReturnType<typeof useClarityTestConclusionImageByIdQuery>;
export type ClarityTestConclusionImageByIdLazyQueryHookResult = ReturnType<typeof useClarityTestConclusionImageByIdLazyQuery>;
export type ClarityTestConclusionImageByIdQueryResult = Apollo.QueryResult<ClarityTestConclusionImageByIdQuery, ClarityTestConclusionImageByIdQueryVariables>;
export const UpdateClarityTestConclusionImageDocument = gql`
    mutation UpdateClarityTestConclusionImage($conclusionImageId: ID!, $image: Upload, $comment: String!) {
  updateClarityTestConclusionImage(
    conclusionImageId: $conclusionImageId
    image: $image
    comment: $comment
  ) {
    ...ClarityTestConclusionImageInfo
  }
}
    ${ClarityTestConclusionImageInfoFragmentDoc}`;
export type UpdateClarityTestConclusionImageMutationFn = Apollo.MutationFunction<UpdateClarityTestConclusionImageMutation, UpdateClarityTestConclusionImageMutationVariables>;

/**
 * __useUpdateClarityTestConclusionImageMutation__
 *
 * To run a mutation, you first call `useUpdateClarityTestConclusionImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClarityTestConclusionImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClarityTestConclusionImageMutation, { data, loading, error }] = useUpdateClarityTestConclusionImageMutation({
 *   variables: {
 *      conclusionImageId: // value for 'conclusionImageId'
 *      image: // value for 'image'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useUpdateClarityTestConclusionImageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClarityTestConclusionImageMutation, UpdateClarityTestConclusionImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClarityTestConclusionImageMutation, UpdateClarityTestConclusionImageMutationVariables>(UpdateClarityTestConclusionImageDocument, options);
      }
export type UpdateClarityTestConclusionImageMutationHookResult = ReturnType<typeof useUpdateClarityTestConclusionImageMutation>;
export type UpdateClarityTestConclusionImageMutationResult = Apollo.MutationResult<UpdateClarityTestConclusionImageMutation>;
export type UpdateClarityTestConclusionImageMutationOptions = Apollo.BaseMutationOptions<UpdateClarityTestConclusionImageMutation, UpdateClarityTestConclusionImageMutationVariables>;
export const ClarityTestConclusionImagesDocument = gql`
    query ClarityTestConclusionImages($filter: ClarityTestConclusionImagesFilterInput, $pagination: PaginationInput, $match: MatchInput, $sort: [SortingInput!]) {
  clarityTestConclusionImages(
    filter: $filter
    pagination: $pagination
    match: $match
    sort: $sort
  ) {
    skip
    take
    total
    clarityTestConclusionImages {
      ...ClarityTestConclusionImageInfo
    }
  }
}
    ${ClarityTestConclusionImageInfoFragmentDoc}`;

/**
 * __useClarityTestConclusionImagesQuery__
 *
 * To run a query within a React component, call `useClarityTestConclusionImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClarityTestConclusionImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClarityTestConclusionImagesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      match: // value for 'match'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useClarityTestConclusionImagesQuery(baseOptions?: Apollo.QueryHookOptions<ClarityTestConclusionImagesQuery, ClarityTestConclusionImagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClarityTestConclusionImagesQuery, ClarityTestConclusionImagesQueryVariables>(ClarityTestConclusionImagesDocument, options);
      }
export function useClarityTestConclusionImagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClarityTestConclusionImagesQuery, ClarityTestConclusionImagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClarityTestConclusionImagesQuery, ClarityTestConclusionImagesQueryVariables>(ClarityTestConclusionImagesDocument, options);
        }
export type ClarityTestConclusionImagesQueryHookResult = ReturnType<typeof useClarityTestConclusionImagesQuery>;
export type ClarityTestConclusionImagesLazyQueryHookResult = ReturnType<typeof useClarityTestConclusionImagesLazyQuery>;
export type ClarityTestConclusionImagesQueryResult = Apollo.QueryResult<ClarityTestConclusionImagesQuery, ClarityTestConclusionImagesQueryVariables>;
export const ConclusionOptionByIdDocument = gql`
    query ConclusionOptionById($conclusionOptionId: ID!) {
  conclusionOption(conclusionOptionId: $conclusionOptionId) {
    ...ConclusionOptionInfo
  }
}
    ${ConclusionOptionInfoFragmentDoc}`;

/**
 * __useConclusionOptionByIdQuery__
 *
 * To run a query within a React component, call `useConclusionOptionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useConclusionOptionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConclusionOptionByIdQuery({
 *   variables: {
 *      conclusionOptionId: // value for 'conclusionOptionId'
 *   },
 * });
 */
export function useConclusionOptionByIdQuery(baseOptions: Apollo.QueryHookOptions<ConclusionOptionByIdQuery, ConclusionOptionByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConclusionOptionByIdQuery, ConclusionOptionByIdQueryVariables>(ConclusionOptionByIdDocument, options);
      }
export function useConclusionOptionByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConclusionOptionByIdQuery, ConclusionOptionByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConclusionOptionByIdQuery, ConclusionOptionByIdQueryVariables>(ConclusionOptionByIdDocument, options);
        }
export type ConclusionOptionByIdQueryHookResult = ReturnType<typeof useConclusionOptionByIdQuery>;
export type ConclusionOptionByIdLazyQueryHookResult = ReturnType<typeof useConclusionOptionByIdLazyQuery>;
export type ConclusionOptionByIdQueryResult = Apollo.QueryResult<ConclusionOptionByIdQuery, ConclusionOptionByIdQueryVariables>;
export const UpdateConclusionOptionDocument = gql`
    mutation UpdateConclusionOption($conclusionOptionId: ID!, $text: TranslationInput!, $comment: String!) {
  updateConclusionOption(
    conclusionOptionId: $conclusionOptionId
    text: $text
    comment: $comment
  ) {
    ...ConclusionOptionInfo
  }
}
    ${ConclusionOptionInfoFragmentDoc}`;
export type UpdateConclusionOptionMutationFn = Apollo.MutationFunction<UpdateConclusionOptionMutation, UpdateConclusionOptionMutationVariables>;

/**
 * __useUpdateConclusionOptionMutation__
 *
 * To run a mutation, you first call `useUpdateConclusionOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConclusionOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConclusionOptionMutation, { data, loading, error }] = useUpdateConclusionOptionMutation({
 *   variables: {
 *      conclusionOptionId: // value for 'conclusionOptionId'
 *      text: // value for 'text'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useUpdateConclusionOptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateConclusionOptionMutation, UpdateConclusionOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateConclusionOptionMutation, UpdateConclusionOptionMutationVariables>(UpdateConclusionOptionDocument, options);
      }
export type UpdateConclusionOptionMutationHookResult = ReturnType<typeof useUpdateConclusionOptionMutation>;
export type UpdateConclusionOptionMutationResult = Apollo.MutationResult<UpdateConclusionOptionMutation>;
export type UpdateConclusionOptionMutationOptions = Apollo.BaseMutationOptions<UpdateConclusionOptionMutation, UpdateConclusionOptionMutationVariables>;
export const ConclusionOptionsDocument = gql`
    query ConclusionOptions($filter: ConclusionOptionFilterInput, $pagination: PaginationInput, $match: MatchInput, $sort: [SortingInput!]) {
  conclusionOptions(
    filter: $filter
    pagination: $pagination
    match: $match
    sort: $sort
  ) {
    skip
    take
    total
    conclusionOptions {
      ...ConclusionOptionInfo
    }
  }
}
    ${ConclusionOptionInfoFragmentDoc}`;

/**
 * __useConclusionOptionsQuery__
 *
 * To run a query within a React component, call `useConclusionOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConclusionOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConclusionOptionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      match: // value for 'match'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useConclusionOptionsQuery(baseOptions?: Apollo.QueryHookOptions<ConclusionOptionsQuery, ConclusionOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConclusionOptionsQuery, ConclusionOptionsQueryVariables>(ConclusionOptionsDocument, options);
      }
export function useConclusionOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConclusionOptionsQuery, ConclusionOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConclusionOptionsQuery, ConclusionOptionsQueryVariables>(ConclusionOptionsDocument, options);
        }
export type ConclusionOptionsQueryHookResult = ReturnType<typeof useConclusionOptionsQuery>;
export type ConclusionOptionsLazyQueryHookResult = ReturnType<typeof useConclusionOptionsLazyQuery>;
export type ConclusionOptionsQueryResult = Apollo.QueryResult<ConclusionOptionsQuery, ConclusionOptionsQueryVariables>;
export const EventSettingByIdDocument = gql`
    query EventSettingById($eventSettingId: ID!) {
  eventSetting(eventSettingId: $eventSettingId) {
    ...EventSettingInfo
  }
}
    ${EventSettingInfoFragmentDoc}`;

/**
 * __useEventSettingByIdQuery__
 *
 * To run a query within a React component, call `useEventSettingByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventSettingByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventSettingByIdQuery({
 *   variables: {
 *      eventSettingId: // value for 'eventSettingId'
 *   },
 * });
 */
export function useEventSettingByIdQuery(baseOptions: Apollo.QueryHookOptions<EventSettingByIdQuery, EventSettingByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventSettingByIdQuery, EventSettingByIdQueryVariables>(EventSettingByIdDocument, options);
      }
export function useEventSettingByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventSettingByIdQuery, EventSettingByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventSettingByIdQuery, EventSettingByIdQueryVariables>(EventSettingByIdDocument, options);
        }
export type EventSettingByIdQueryHookResult = ReturnType<typeof useEventSettingByIdQuery>;
export type EventSettingByIdLazyQueryHookResult = ReturnType<typeof useEventSettingByIdLazyQuery>;
export type EventSettingByIdQueryResult = Apollo.QueryResult<EventSettingByIdQuery, EventSettingByIdQueryVariables>;
export const UpdateEventSettingDocument = gql`
    mutation UpdateEventSetting($eventSettingId: ID!, $value: String!, $comment: String!, $collaborationComment: String!) {
  updateEventSetting(
    eventSettingId: $eventSettingId
    value: $value
    comment: $comment
    collaborationComment: $collaborationComment
  ) {
    ...EventSettingInfo
  }
}
    ${EventSettingInfoFragmentDoc}`;
export type UpdateEventSettingMutationFn = Apollo.MutationFunction<UpdateEventSettingMutation, UpdateEventSettingMutationVariables>;

/**
 * __useUpdateEventSettingMutation__
 *
 * To run a mutation, you first call `useUpdateEventSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventSettingMutation, { data, loading, error }] = useUpdateEventSettingMutation({
 *   variables: {
 *      eventSettingId: // value for 'eventSettingId'
 *      value: // value for 'value'
 *      comment: // value for 'comment'
 *      collaborationComment: // value for 'collaborationComment'
 *   },
 * });
 */
export function useUpdateEventSettingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventSettingMutation, UpdateEventSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventSettingMutation, UpdateEventSettingMutationVariables>(UpdateEventSettingDocument, options);
      }
export type UpdateEventSettingMutationHookResult = ReturnType<typeof useUpdateEventSettingMutation>;
export type UpdateEventSettingMutationResult = Apollo.MutationResult<UpdateEventSettingMutation>;
export type UpdateEventSettingMutationOptions = Apollo.BaseMutationOptions<UpdateEventSettingMutation, UpdateEventSettingMutationVariables>;
export const EventSettingsDocument = gql`
    query EventSettings($pagination: PaginationInput, $match: MatchInput) {
  eventSettings(pagination: $pagination, match: $match) {
    skip
    take
    total
    eventSettings {
      ...EventSettingInfo
    }
  }
}
    ${EventSettingInfoFragmentDoc}`;

/**
 * __useEventSettingsQuery__
 *
 * To run a query within a React component, call `useEventSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventSettingsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      match: // value for 'match'
 *   },
 * });
 */
export function useEventSettingsQuery(baseOptions?: Apollo.QueryHookOptions<EventSettingsQuery, EventSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventSettingsQuery, EventSettingsQueryVariables>(EventSettingsDocument, options);
      }
export function useEventSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventSettingsQuery, EventSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventSettingsQuery, EventSettingsQueryVariables>(EventSettingsDocument, options);
        }
export type EventSettingsQueryHookResult = ReturnType<typeof useEventSettingsQuery>;
export type EventSettingsLazyQueryHookResult = ReturnType<typeof useEventSettingsLazyQuery>;
export type EventSettingsQueryResult = Apollo.QueryResult<EventSettingsQuery, EventSettingsQueryVariables>;
export const UpdateAvatarDocument = gql`
    mutation UpdateAvatar($avatar: String!) {
  updateAvatar(avatar: $avatar) {
    id
    email
    name
    avatarUrl
  }
}
    `;
export type UpdateAvatarMutationFn = Apollo.MutationFunction<UpdateAvatarMutation, UpdateAvatarMutationVariables>;

/**
 * __useUpdateAvatarMutation__
 *
 * To run a mutation, you first call `useUpdateAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAvatarMutation, { data, loading, error }] = useUpdateAvatarMutation({
 *   variables: {
 *      avatar: // value for 'avatar'
 *   },
 * });
 */
export function useUpdateAvatarMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAvatarMutation, UpdateAvatarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAvatarMutation, UpdateAvatarMutationVariables>(UpdateAvatarDocument, options);
      }
export type UpdateAvatarMutationHookResult = ReturnType<typeof useUpdateAvatarMutation>;
export type UpdateAvatarMutationResult = Apollo.MutationResult<UpdateAvatarMutation>;
export type UpdateAvatarMutationOptions = Apollo.BaseMutationOptions<UpdateAvatarMutation, UpdateAvatarMutationVariables>;
export const UploadExperimentViewerDocument = gql`
    query UploadExperimentViewer {
  viewer {
    id
    email
    name
    avatarUrl
    roles
    scopes
    status
  }
}
    `;

/**
 * __useUploadExperimentViewerQuery__
 *
 * To run a query within a React component, call `useUploadExperimentViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useUploadExperimentViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUploadExperimentViewerQuery({
 *   variables: {
 *   },
 * });
 */
export function useUploadExperimentViewerQuery(baseOptions?: Apollo.QueryHookOptions<UploadExperimentViewerQuery, UploadExperimentViewerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UploadExperimentViewerQuery, UploadExperimentViewerQueryVariables>(UploadExperimentViewerDocument, options);
      }
export function useUploadExperimentViewerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UploadExperimentViewerQuery, UploadExperimentViewerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UploadExperimentViewerQuery, UploadExperimentViewerQueryVariables>(UploadExperimentViewerDocument, options);
        }
export type UploadExperimentViewerQueryHookResult = ReturnType<typeof useUploadExperimentViewerQuery>;
export type UploadExperimentViewerLazyQueryHookResult = ReturnType<typeof useUploadExperimentViewerLazyQuery>;
export type UploadExperimentViewerQueryResult = Apollo.QueryResult<UploadExperimentViewerQuery, UploadExperimentViewerQueryVariables>;
export const FeedTestUserDocument = gql`
    query FeedTestUser($id: ID!) {
  feedTestUser(id: $id) {
    id
    userId
    isActive
  }
}
    `;

/**
 * __useFeedTestUserQuery__
 *
 * To run a query within a React component, call `useFeedTestUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedTestUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedTestUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFeedTestUserQuery(baseOptions: Apollo.QueryHookOptions<FeedTestUserQuery, FeedTestUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeedTestUserQuery, FeedTestUserQueryVariables>(FeedTestUserDocument, options);
      }
export function useFeedTestUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeedTestUserQuery, FeedTestUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeedTestUserQuery, FeedTestUserQueryVariables>(FeedTestUserDocument, options);
        }
export type FeedTestUserQueryHookResult = ReturnType<typeof useFeedTestUserQuery>;
export type FeedTestUserLazyQueryHookResult = ReturnType<typeof useFeedTestUserLazyQuery>;
export type FeedTestUserQueryResult = Apollo.QueryResult<FeedTestUserQuery, FeedTestUserQueryVariables>;
export const FeedTestUsersDocument = gql`
    query FeedTestUsers($filter: FeedTestUsersFilterInput, $pagination: PaginationInput, $match: MatchInput, $sort: [SortingInput!]) {
  feedTestUsers(
    filter: $filter
    pagination: $pagination
    match: $match
    sort: $sort
  ) {
    skip
    take
    total
    feedTestUsers {
      id
      userId
      isActive
    }
  }
}
    `;

/**
 * __useFeedTestUsersQuery__
 *
 * To run a query within a React component, call `useFeedTestUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedTestUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedTestUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      match: // value for 'match'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useFeedTestUsersQuery(baseOptions?: Apollo.QueryHookOptions<FeedTestUsersQuery, FeedTestUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeedTestUsersQuery, FeedTestUsersQueryVariables>(FeedTestUsersDocument, options);
      }
export function useFeedTestUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeedTestUsersQuery, FeedTestUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeedTestUsersQuery, FeedTestUsersQueryVariables>(FeedTestUsersDocument, options);
        }
export type FeedTestUsersQueryHookResult = ReturnType<typeof useFeedTestUsersQuery>;
export type FeedTestUsersLazyQueryHookResult = ReturnType<typeof useFeedTestUsersLazyQuery>;
export type FeedTestUsersQueryResult = Apollo.QueryResult<FeedTestUsersQuery, FeedTestUsersQueryVariables>;
export const FeelingByIdDocument = gql`
    query FeelingById($feelingId: ID!) {
  feeling(feelingId: $feelingId) {
    ...FeelingInfo
  }
}
    ${FeelingInfoFragmentDoc}`;

/**
 * __useFeelingByIdQuery__
 *
 * To run a query within a React component, call `useFeelingByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeelingByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeelingByIdQuery({
 *   variables: {
 *      feelingId: // value for 'feelingId'
 *   },
 * });
 */
export function useFeelingByIdQuery(baseOptions: Apollo.QueryHookOptions<FeelingByIdQuery, FeelingByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeelingByIdQuery, FeelingByIdQueryVariables>(FeelingByIdDocument, options);
      }
export function useFeelingByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeelingByIdQuery, FeelingByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeelingByIdQuery, FeelingByIdQueryVariables>(FeelingByIdDocument, options);
        }
export type FeelingByIdQueryHookResult = ReturnType<typeof useFeelingByIdQuery>;
export type FeelingByIdLazyQueryHookResult = ReturnType<typeof useFeelingByIdLazyQuery>;
export type FeelingByIdQueryResult = Apollo.QueryResult<FeelingByIdQuery, FeelingByIdQueryVariables>;
export const UpdateFeelingDocument = gql`
    mutation UpdateFeeling($feelingId: ID!, $name: TranslationInput!, $scoreModifierHappiness: Float!, $scoreModifierPositivity: Float!, $scoreModifierEnergy: Float!, $scoreModifierSleepQuality: Float!, $scoreModifierMentalPower: Float!, $scoreModifierConfidence: Float!, $scoreModifierGratitude: Float!, $scoreModifierAttraction: Float!, $comment: String!) {
  updateFeeling(
    feelingId: $feelingId
    name: $name
    scoreModifierHappiness: $scoreModifierHappiness
    scoreModifierPositivity: $scoreModifierPositivity
    scoreModifierEnergy: $scoreModifierEnergy
    scoreModifierSleepQuality: $scoreModifierSleepQuality
    scoreModifierMentalPower: $scoreModifierMentalPower
    scoreModifierConfidence: $scoreModifierConfidence
    scoreModifierGratitude: $scoreModifierGratitude
    scoreModifierAttraction: $scoreModifierAttraction
    comment: $comment
  ) {
    ...FeelingInfo
  }
}
    ${FeelingInfoFragmentDoc}`;
export type UpdateFeelingMutationFn = Apollo.MutationFunction<UpdateFeelingMutation, UpdateFeelingMutationVariables>;

/**
 * __useUpdateFeelingMutation__
 *
 * To run a mutation, you first call `useUpdateFeelingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFeelingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFeelingMutation, { data, loading, error }] = useUpdateFeelingMutation({
 *   variables: {
 *      feelingId: // value for 'feelingId'
 *      name: // value for 'name'
 *      scoreModifierHappiness: // value for 'scoreModifierHappiness'
 *      scoreModifierPositivity: // value for 'scoreModifierPositivity'
 *      scoreModifierEnergy: // value for 'scoreModifierEnergy'
 *      scoreModifierSleepQuality: // value for 'scoreModifierSleepQuality'
 *      scoreModifierMentalPower: // value for 'scoreModifierMentalPower'
 *      scoreModifierConfidence: // value for 'scoreModifierConfidence'
 *      scoreModifierGratitude: // value for 'scoreModifierGratitude'
 *      scoreModifierAttraction: // value for 'scoreModifierAttraction'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useUpdateFeelingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFeelingMutation, UpdateFeelingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFeelingMutation, UpdateFeelingMutationVariables>(UpdateFeelingDocument, options);
      }
export type UpdateFeelingMutationHookResult = ReturnType<typeof useUpdateFeelingMutation>;
export type UpdateFeelingMutationResult = Apollo.MutationResult<UpdateFeelingMutation>;
export type UpdateFeelingMutationOptions = Apollo.BaseMutationOptions<UpdateFeelingMutation, UpdateFeelingMutationVariables>;
export const FeelingsDocument = gql`
    query Feelings {
  feelings {
    ...FeelingInfo
  }
}
    ${FeelingInfoFragmentDoc}`;

/**
 * __useFeelingsQuery__
 *
 * To run a query within a React component, call `useFeelingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeelingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeelingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeelingsQuery(baseOptions?: Apollo.QueryHookOptions<FeelingsQuery, FeelingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeelingsQuery, FeelingsQueryVariables>(FeelingsDocument, options);
      }
export function useFeelingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeelingsQuery, FeelingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeelingsQuery, FeelingsQueryVariables>(FeelingsDocument, options);
        }
export type FeelingsQueryHookResult = ReturnType<typeof useFeelingsQuery>;
export type FeelingsLazyQueryHookResult = ReturnType<typeof useFeelingsLazyQuery>;
export type FeelingsQueryResult = Apollo.QueryResult<FeelingsQuery, FeelingsQueryVariables>;
export const FileByIdDocument = gql`
    query FileById($fileId: ID!) {
  file(fileId: $fileId) {
    ...FileInfo
  }
}
    ${FileInfoFragmentDoc}`;

/**
 * __useFileByIdQuery__
 *
 * To run a query within a React component, call `useFileByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileByIdQuery({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useFileByIdQuery(baseOptions: Apollo.QueryHookOptions<FileByIdQuery, FileByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FileByIdQuery, FileByIdQueryVariables>(FileByIdDocument, options);
      }
export function useFileByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FileByIdQuery, FileByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FileByIdQuery, FileByIdQueryVariables>(FileByIdDocument, options);
        }
export type FileByIdQueryHookResult = ReturnType<typeof useFileByIdQuery>;
export type FileByIdLazyQueryHookResult = ReturnType<typeof useFileByIdLazyQuery>;
export type FileByIdQueryResult = Apollo.QueryResult<FileByIdQuery, FileByIdQueryVariables>;
export const FilesDocument = gql`
    query Files($filter: FilesFilterInput, $pagination: PaginationInput, $match: MatchInput, $sort: [SortingInput!]) {
  files(filter: $filter, pagination: $pagination, match: $match, sort: $sort) {
    skip
    take
    total
    files {
      ...FileInfo
    }
  }
}
    ${FileInfoFragmentDoc}`;

/**
 * __useFilesQuery__
 *
 * To run a query within a React component, call `useFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      match: // value for 'match'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useFilesQuery(baseOptions?: Apollo.QueryHookOptions<FilesQuery, FilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FilesQuery, FilesQueryVariables>(FilesDocument, options);
      }
export function useFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilesQuery, FilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FilesQuery, FilesQueryVariables>(FilesDocument, options);
        }
export type FilesQueryHookResult = ReturnType<typeof useFilesQuery>;
export type FilesLazyQueryHookResult = ReturnType<typeof useFilesLazyQuery>;
export type FilesQueryResult = Apollo.QueryResult<FilesQuery, FilesQueryVariables>;
export const InformativeContentByIdDocument = gql`
    query InformativeContentById($filter: InformativeContentFilterInput, $pagination: PaginationInput, $match: MatchInput) {
  informativeContent(filter: $filter, pagination: $pagination, match: $match) {
    informativeContent {
      ...InformativeContentInfo
    }
  }
}
    ${InformativeContentInfoFragmentDoc}`;

/**
 * __useInformativeContentByIdQuery__
 *
 * To run a query within a React component, call `useInformativeContentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useInformativeContentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInformativeContentByIdQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      match: // value for 'match'
 *   },
 * });
 */
export function useInformativeContentByIdQuery(baseOptions?: Apollo.QueryHookOptions<InformativeContentByIdQuery, InformativeContentByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InformativeContentByIdQuery, InformativeContentByIdQueryVariables>(InformativeContentByIdDocument, options);
      }
export function useInformativeContentByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InformativeContentByIdQuery, InformativeContentByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InformativeContentByIdQuery, InformativeContentByIdQueryVariables>(InformativeContentByIdDocument, options);
        }
export type InformativeContentByIdQueryHookResult = ReturnType<typeof useInformativeContentByIdQuery>;
export type InformativeContentByIdLazyQueryHookResult = ReturnType<typeof useInformativeContentByIdLazyQuery>;
export type InformativeContentByIdQueryResult = Apollo.QueryResult<InformativeContentByIdQuery, InformativeContentByIdQueryVariables>;
export const UpdateInformativeContentDocument = gql`
    mutation UpdateInformativeContent($informativeContentId: ID!, $image: Upload, $title: TranslationInput!, $subTitle: TranslationInput!, $text: TranslationInput!, $type: InformativeContentEnum!, $ordinal: Int!, $comment: String!) {
  updateInformativeContent(
    informativeContentId: $informativeContentId
    image: $image
    title: $title
    subTitle: $subTitle
    text: $text
    type: $type
    ordinal: $ordinal
    comment: $comment
  ) {
    ...InformativeContentInfo
  }
}
    ${InformativeContentInfoFragmentDoc}`;
export type UpdateInformativeContentMutationFn = Apollo.MutationFunction<UpdateInformativeContentMutation, UpdateInformativeContentMutationVariables>;

/**
 * __useUpdateInformativeContentMutation__
 *
 * To run a mutation, you first call `useUpdateInformativeContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInformativeContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInformativeContentMutation, { data, loading, error }] = useUpdateInformativeContentMutation({
 *   variables: {
 *      informativeContentId: // value for 'informativeContentId'
 *      image: // value for 'image'
 *      title: // value for 'title'
 *      subTitle: // value for 'subTitle'
 *      text: // value for 'text'
 *      type: // value for 'type'
 *      ordinal: // value for 'ordinal'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useUpdateInformativeContentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInformativeContentMutation, UpdateInformativeContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInformativeContentMutation, UpdateInformativeContentMutationVariables>(UpdateInformativeContentDocument, options);
      }
export type UpdateInformativeContentMutationHookResult = ReturnType<typeof useUpdateInformativeContentMutation>;
export type UpdateInformativeContentMutationResult = Apollo.MutationResult<UpdateInformativeContentMutation>;
export type UpdateInformativeContentMutationOptions = Apollo.BaseMutationOptions<UpdateInformativeContentMutation, UpdateInformativeContentMutationVariables>;
export const InformativeContentDocument = gql`
    query InformativeContent($filter: InformativeContentFilterInput, $pagination: PaginationInput, $match: MatchInput, $sort: [SortingInput!]) {
  informativeContent(
    filter: $filter
    pagination: $pagination
    match: $match
    sort: $sort
  ) {
    skip
    take
    total
    informativeContent {
      ...InformativeContentInfo
    }
  }
}
    ${InformativeContentInfoFragmentDoc}`;

/**
 * __useInformativeContentQuery__
 *
 * To run a query within a React component, call `useInformativeContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useInformativeContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInformativeContentQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      match: // value for 'match'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useInformativeContentQuery(baseOptions?: Apollo.QueryHookOptions<InformativeContentQuery, InformativeContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InformativeContentQuery, InformativeContentQueryVariables>(InformativeContentDocument, options);
      }
export function useInformativeContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InformativeContentQuery, InformativeContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InformativeContentQuery, InformativeContentQueryVariables>(InformativeContentDocument, options);
        }
export type InformativeContentQueryHookResult = ReturnType<typeof useInformativeContentQuery>;
export type InformativeContentLazyQueryHookResult = ReturnType<typeof useInformativeContentLazyQuery>;
export type InformativeContentQueryResult = Apollo.QueryResult<InformativeContentQuery, InformativeContentQueryVariables>;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    ...ViewerInfo
  }
}
    ${ViewerInfoFragmentDoc}`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const QuestionsByIdDocument = gql`
    query QuestionsById($filter: QuestionFilterInput, $pagination: PaginationInput, $match: MatchInput) {
  questions(filter: $filter, pagination: $pagination, match: $match) {
    questions {
      ...TestQuestionInfo
      questionOption {
        ...TestQuestionOptionInfo
      }
    }
  }
}
    ${TestQuestionInfoFragmentDoc}
${TestQuestionOptionInfoFragmentDoc}`;

/**
 * __useQuestionsByIdQuery__
 *
 * To run a query within a React component, call `useQuestionsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionsByIdQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      match: // value for 'match'
 *   },
 * });
 */
export function useQuestionsByIdQuery(baseOptions?: Apollo.QueryHookOptions<QuestionsByIdQuery, QuestionsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionsByIdQuery, QuestionsByIdQueryVariables>(QuestionsByIdDocument, options);
      }
export function useQuestionsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionsByIdQuery, QuestionsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionsByIdQuery, QuestionsByIdQueryVariables>(QuestionsByIdDocument, options);
        }
export type QuestionsByIdQueryHookResult = ReturnType<typeof useQuestionsByIdQuery>;
export type QuestionsByIdLazyQueryHookResult = ReturnType<typeof useQuestionsByIdLazyQuery>;
export type QuestionsByIdQueryResult = Apollo.QueryResult<QuestionsByIdQuery, QuestionsByIdQueryVariables>;
export const UpdateQuestionDocument = gql`
    mutation UpdateQuestion($questionId: ID!, $question: TranslationInput!, $type: QuestionTypeEnum!, $topic: TopicTypeEnum!, $comment: String!) {
  updateQuestion(
    questionId: $questionId
    question: $question
    type: $type
    topic: $topic
    comment: $comment
  ) {
    ...TestQuestionInfo
  }
}
    ${TestQuestionInfoFragmentDoc}`;
export type UpdateQuestionMutationFn = Apollo.MutationFunction<UpdateQuestionMutation, UpdateQuestionMutationVariables>;

/**
 * __useUpdateQuestionMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionMutation, { data, loading, error }] = useUpdateQuestionMutation({
 *   variables: {
 *      questionId: // value for 'questionId'
 *      question: // value for 'question'
 *      type: // value for 'type'
 *      topic: // value for 'topic'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useUpdateQuestionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuestionMutation, UpdateQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQuestionMutation, UpdateQuestionMutationVariables>(UpdateQuestionDocument, options);
      }
export type UpdateQuestionMutationHookResult = ReturnType<typeof useUpdateQuestionMutation>;
export type UpdateQuestionMutationResult = Apollo.MutationResult<UpdateQuestionMutation>;
export type UpdateQuestionMutationOptions = Apollo.BaseMutationOptions<UpdateQuestionMutation, UpdateQuestionMutationVariables>;
export const QuestionsDocument = gql`
    query Questions($filter: QuestionFilterInput, $pagination: PaginationInput, $match: MatchInput, $sort: [SortingInput!]) {
  questions(filter: $filter, pagination: $pagination, match: $match, sort: $sort) {
    skip
    take
    total
    questions {
      ...TestQuestionInfo
    }
  }
}
    ${TestQuestionInfoFragmentDoc}`;

/**
 * __useQuestionsQuery__
 *
 * To run a query within a React component, call `useQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      match: // value for 'match'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useQuestionsQuery(baseOptions?: Apollo.QueryHookOptions<QuestionsQuery, QuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionsQuery, QuestionsQueryVariables>(QuestionsDocument, options);
      }
export function useQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionsQuery, QuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionsQuery, QuestionsQueryVariables>(QuestionsDocument, options);
        }
export type QuestionsQueryHookResult = ReturnType<typeof useQuestionsQuery>;
export type QuestionsLazyQueryHookResult = ReturnType<typeof useQuestionsLazyQuery>;
export type QuestionsQueryResult = Apollo.QueryResult<QuestionsQuery, QuestionsQueryVariables>;
export const QuestionOptionByIdDocument = gql`
    query QuestionOptionById($filter: QuestionOptionFilterInput, $pagination: PaginationInput, $match: MatchInput) {
  questionOptions(filter: $filter, pagination: $pagination, match: $match) {
    questionOptions {
      ...TestQuestionOptionInfo
      question {
        id
        question {
          ET
          EN
        }
      }
    }
  }
}
    ${TestQuestionOptionInfoFragmentDoc}`;

/**
 * __useQuestionOptionByIdQuery__
 *
 * To run a query within a React component, call `useQuestionOptionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionOptionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionOptionByIdQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      match: // value for 'match'
 *   },
 * });
 */
export function useQuestionOptionByIdQuery(baseOptions?: Apollo.QueryHookOptions<QuestionOptionByIdQuery, QuestionOptionByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionOptionByIdQuery, QuestionOptionByIdQueryVariables>(QuestionOptionByIdDocument, options);
      }
export function useQuestionOptionByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionOptionByIdQuery, QuestionOptionByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionOptionByIdQuery, QuestionOptionByIdQueryVariables>(QuestionOptionByIdDocument, options);
        }
export type QuestionOptionByIdQueryHookResult = ReturnType<typeof useQuestionOptionByIdQuery>;
export type QuestionOptionByIdLazyQueryHookResult = ReturnType<typeof useQuestionOptionByIdLazyQuery>;
export type QuestionOptionByIdQueryResult = Apollo.QueryResult<QuestionOptionByIdQuery, QuestionOptionByIdQueryVariables>;
export const UpdateQuestionOptionDocument = gql`
    mutation UpdateQuestionOption($questionOptionId: ID!, $answer: TranslationInput!, $conclusionText: TranslationInput!, $score: Int!, $weight: Int!, $comment: String!) {
  updateQuestionOption(
    questionOptionId: $questionOptionId
    answer: $answer
    conclusionText: $conclusionText
    score: $score
    weight: $weight
    comment: $comment
  ) {
    ...TestQuestionOptionInfo
  }
}
    ${TestQuestionOptionInfoFragmentDoc}`;
export type UpdateQuestionOptionMutationFn = Apollo.MutationFunction<UpdateQuestionOptionMutation, UpdateQuestionOptionMutationVariables>;

/**
 * __useUpdateQuestionOptionMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionOptionMutation, { data, loading, error }] = useUpdateQuestionOptionMutation({
 *   variables: {
 *      questionOptionId: // value for 'questionOptionId'
 *      answer: // value for 'answer'
 *      conclusionText: // value for 'conclusionText'
 *      score: // value for 'score'
 *      weight: // value for 'weight'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useUpdateQuestionOptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuestionOptionMutation, UpdateQuestionOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQuestionOptionMutation, UpdateQuestionOptionMutationVariables>(UpdateQuestionOptionDocument, options);
      }
export type UpdateQuestionOptionMutationHookResult = ReturnType<typeof useUpdateQuestionOptionMutation>;
export type UpdateQuestionOptionMutationResult = Apollo.MutationResult<UpdateQuestionOptionMutation>;
export type UpdateQuestionOptionMutationOptions = Apollo.BaseMutationOptions<UpdateQuestionOptionMutation, UpdateQuestionOptionMutationVariables>;
export const QuestionOptionsDocument = gql`
    query QuestionOptions($filter: QuestionOptionFilterInput, $pagination: PaginationInput, $match: MatchInput, $sort: [SortingInput!]) {
  questionOptions(
    filter: $filter
    pagination: $pagination
    match: $match
    sort: $sort
  ) {
    skip
    take
    total
    questionOptions {
      ...TestQuestionOptionInfo
      question {
        id
        question {
          ET
          EN
        }
      }
    }
  }
}
    ${TestQuestionOptionInfoFragmentDoc}`;

/**
 * __useQuestionOptionsQuery__
 *
 * To run a query within a React component, call `useQuestionOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionOptionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      match: // value for 'match'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useQuestionOptionsQuery(baseOptions?: Apollo.QueryHookOptions<QuestionOptionsQuery, QuestionOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionOptionsQuery, QuestionOptionsQueryVariables>(QuestionOptionsDocument, options);
      }
export function useQuestionOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionOptionsQuery, QuestionOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionOptionsQuery, QuestionOptionsQueryVariables>(QuestionOptionsDocument, options);
        }
export type QuestionOptionsQueryHookResult = ReturnType<typeof useQuestionOptionsQuery>;
export type QuestionOptionsLazyQueryHookResult = ReturnType<typeof useQuestionOptionsLazyQuery>;
export type QuestionOptionsQueryResult = Apollo.QueryResult<QuestionOptionsQuery, QuestionOptionsQueryVariables>;
export const UpdateUserFeedEventDocument = gql`
    mutation UpdateUserFeedEvent($userFeedEventId: ID!, $event: UserFeedEventNameEnum!, $type: UserFeedItemTypeEnum!, $visibility: UserFeedItemVisibilityEnum!, $version: Int!, $isPinned: Boolean!, $metadata: JSON!, $comment: String!) {
  updateUserFeedEvent(
    userFeedEventId: $userFeedEventId
    event: $event
    type: $type
    visibility: $visibility
    version: $version
    isPinned: $isPinned
    metadata: $metadata
    comment: $comment
  ) {
    ...UserFeedEventInfo
  }
}
    ${UserFeedEventInfoFragmentDoc}`;
export type UpdateUserFeedEventMutationFn = Apollo.MutationFunction<UpdateUserFeedEventMutation, UpdateUserFeedEventMutationVariables>;

/**
 * __useUpdateUserFeedEventMutation__
 *
 * To run a mutation, you first call `useUpdateUserFeedEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserFeedEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserFeedEventMutation, { data, loading, error }] = useUpdateUserFeedEventMutation({
 *   variables: {
 *      userFeedEventId: // value for 'userFeedEventId'
 *      event: // value for 'event'
 *      type: // value for 'type'
 *      visibility: // value for 'visibility'
 *      version: // value for 'version'
 *      isPinned: // value for 'isPinned'
 *      metadata: // value for 'metadata'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useUpdateUserFeedEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserFeedEventMutation, UpdateUserFeedEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserFeedEventMutation, UpdateUserFeedEventMutationVariables>(UpdateUserFeedEventDocument, options);
      }
export type UpdateUserFeedEventMutationHookResult = ReturnType<typeof useUpdateUserFeedEventMutation>;
export type UpdateUserFeedEventMutationResult = Apollo.MutationResult<UpdateUserFeedEventMutation>;
export type UpdateUserFeedEventMutationOptions = Apollo.BaseMutationOptions<UpdateUserFeedEventMutation, UpdateUserFeedEventMutationVariables>;
export const UserFeedEventByIdDocument = gql`
    query UserFeedEventById($userFeedEventId: ID!) {
  userFeedEvent(userFeedEventId: $userFeedEventId) {
    ...UserFeedEventInfo
  }
}
    ${UserFeedEventInfoFragmentDoc}`;

/**
 * __useUserFeedEventByIdQuery__
 *
 * To run a query within a React component, call `useUserFeedEventByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserFeedEventByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserFeedEventByIdQuery({
 *   variables: {
 *      userFeedEventId: // value for 'userFeedEventId'
 *   },
 * });
 */
export function useUserFeedEventByIdQuery(baseOptions: Apollo.QueryHookOptions<UserFeedEventByIdQuery, UserFeedEventByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserFeedEventByIdQuery, UserFeedEventByIdQueryVariables>(UserFeedEventByIdDocument, options);
      }
export function useUserFeedEventByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserFeedEventByIdQuery, UserFeedEventByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserFeedEventByIdQuery, UserFeedEventByIdQueryVariables>(UserFeedEventByIdDocument, options);
        }
export type UserFeedEventByIdQueryHookResult = ReturnType<typeof useUserFeedEventByIdQuery>;
export type UserFeedEventByIdLazyQueryHookResult = ReturnType<typeof useUserFeedEventByIdLazyQuery>;
export type UserFeedEventByIdQueryResult = Apollo.QueryResult<UserFeedEventByIdQuery, UserFeedEventByIdQueryVariables>;
export const UserFeedEventsDocument = gql`
    query UserFeedEvents {
  userFeedEvents {
    ...UserFeedEventInfo
  }
}
    ${UserFeedEventInfoFragmentDoc}`;

/**
 * __useUserFeedEventsQuery__
 *
 * To run a query within a React component, call `useUserFeedEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserFeedEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserFeedEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserFeedEventsQuery(baseOptions?: Apollo.QueryHookOptions<UserFeedEventsQuery, UserFeedEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserFeedEventsQuery, UserFeedEventsQueryVariables>(UserFeedEventsDocument, options);
      }
export function useUserFeedEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserFeedEventsQuery, UserFeedEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserFeedEventsQuery, UserFeedEventsQueryVariables>(UserFeedEventsDocument, options);
        }
export type UserFeedEventsQueryHookResult = ReturnType<typeof useUserFeedEventsQuery>;
export type UserFeedEventsLazyQueryHookResult = ReturnType<typeof useUserFeedEventsLazyQuery>;
export type UserFeedEventsQueryResult = Apollo.QueryResult<UserFeedEventsQuery, UserFeedEventsQueryVariables>;
export const UpdateUserFeedItemMetadataSettingDocument = gql`
    mutation UpdateUserFeedItemMetadataSetting($userFeedItemMetadataSettingId: ID!, $type: UserFeedItemTypeEnum!, $metadata: JSON!) {
  updateUserFeedItemMetadataSetting(
    userFeedItemMetadataSettingId: $userFeedItemMetadataSettingId
    type: $type
    metadata: $metadata
  ) {
    ...UserFeedItemMetadataSettingInfo
  }
}
    ${UserFeedItemMetadataSettingInfoFragmentDoc}`;
export type UpdateUserFeedItemMetadataSettingMutationFn = Apollo.MutationFunction<UpdateUserFeedItemMetadataSettingMutation, UpdateUserFeedItemMetadataSettingMutationVariables>;

/**
 * __useUpdateUserFeedItemMetadataSettingMutation__
 *
 * To run a mutation, you first call `useUpdateUserFeedItemMetadataSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserFeedItemMetadataSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserFeedItemMetadataSettingMutation, { data, loading, error }] = useUpdateUserFeedItemMetadataSettingMutation({
 *   variables: {
 *      userFeedItemMetadataSettingId: // value for 'userFeedItemMetadataSettingId'
 *      type: // value for 'type'
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useUpdateUserFeedItemMetadataSettingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserFeedItemMetadataSettingMutation, UpdateUserFeedItemMetadataSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserFeedItemMetadataSettingMutation, UpdateUserFeedItemMetadataSettingMutationVariables>(UpdateUserFeedItemMetadataSettingDocument, options);
      }
export type UpdateUserFeedItemMetadataSettingMutationHookResult = ReturnType<typeof useUpdateUserFeedItemMetadataSettingMutation>;
export type UpdateUserFeedItemMetadataSettingMutationResult = Apollo.MutationResult<UpdateUserFeedItemMetadataSettingMutation>;
export type UpdateUserFeedItemMetadataSettingMutationOptions = Apollo.BaseMutationOptions<UpdateUserFeedItemMetadataSettingMutation, UpdateUserFeedItemMetadataSettingMutationVariables>;
export const UserFeedItemMetadataSettingByIdDocument = gql`
    query UserFeedItemMetadataSettingById($userFeedItemMetadataSettingId: ID!) {
  userFeedItemMetadataSetting(
    userFeedItemMetadataSettingId: $userFeedItemMetadataSettingId
  ) {
    ...UserFeedItemMetadataSettingInfo
  }
}
    ${UserFeedItemMetadataSettingInfoFragmentDoc}`;

/**
 * __useUserFeedItemMetadataSettingByIdQuery__
 *
 * To run a query within a React component, call `useUserFeedItemMetadataSettingByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserFeedItemMetadataSettingByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserFeedItemMetadataSettingByIdQuery({
 *   variables: {
 *      userFeedItemMetadataSettingId: // value for 'userFeedItemMetadataSettingId'
 *   },
 * });
 */
export function useUserFeedItemMetadataSettingByIdQuery(baseOptions: Apollo.QueryHookOptions<UserFeedItemMetadataSettingByIdQuery, UserFeedItemMetadataSettingByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserFeedItemMetadataSettingByIdQuery, UserFeedItemMetadataSettingByIdQueryVariables>(UserFeedItemMetadataSettingByIdDocument, options);
      }
export function useUserFeedItemMetadataSettingByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserFeedItemMetadataSettingByIdQuery, UserFeedItemMetadataSettingByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserFeedItemMetadataSettingByIdQuery, UserFeedItemMetadataSettingByIdQueryVariables>(UserFeedItemMetadataSettingByIdDocument, options);
        }
export type UserFeedItemMetadataSettingByIdQueryHookResult = ReturnType<typeof useUserFeedItemMetadataSettingByIdQuery>;
export type UserFeedItemMetadataSettingByIdLazyQueryHookResult = ReturnType<typeof useUserFeedItemMetadataSettingByIdLazyQuery>;
export type UserFeedItemMetadataSettingByIdQueryResult = Apollo.QueryResult<UserFeedItemMetadataSettingByIdQuery, UserFeedItemMetadataSettingByIdQueryVariables>;
export const UserFeedItemMetadataSettingsDocument = gql`
    query UserFeedItemMetadataSettings($pagination: PaginationInput) {
  userFeedItemMetadataSettings(pagination: $pagination) {
    skip
    take
    total
    userFeedItemMetadataSettings {
      ...UserFeedItemMetadataSettingInfo
    }
  }
}
    ${UserFeedItemMetadataSettingInfoFragmentDoc}`;

/**
 * __useUserFeedItemMetadataSettingsQuery__
 *
 * To run a query within a React component, call `useUserFeedItemMetadataSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserFeedItemMetadataSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserFeedItemMetadataSettingsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useUserFeedItemMetadataSettingsQuery(baseOptions?: Apollo.QueryHookOptions<UserFeedItemMetadataSettingsQuery, UserFeedItemMetadataSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserFeedItemMetadataSettingsQuery, UserFeedItemMetadataSettingsQueryVariables>(UserFeedItemMetadataSettingsDocument, options);
      }
export function useUserFeedItemMetadataSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserFeedItemMetadataSettingsQuery, UserFeedItemMetadataSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserFeedItemMetadataSettingsQuery, UserFeedItemMetadataSettingsQueryVariables>(UserFeedItemMetadataSettingsDocument, options);
        }
export type UserFeedItemMetadataSettingsQueryHookResult = ReturnType<typeof useUserFeedItemMetadataSettingsQuery>;
export type UserFeedItemMetadataSettingsLazyQueryHookResult = ReturnType<typeof useUserFeedItemMetadataSettingsLazyQuery>;
export type UserFeedItemMetadataSettingsQueryResult = Apollo.QueryResult<UserFeedItemMetadataSettingsQuery, UserFeedItemMetadataSettingsQueryVariables>;