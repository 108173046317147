import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { useViewer } from "../../hooks/useViewer";
import { UserScopeEnum } from "../../schema";
import { hasAnyScopes } from "../../services/hasAnyScopes";
import { showChangelogState } from "../Changelog/Changelog";
import { Column } from "../Column/Column";
import { Container } from "../Container/Container";
import { Expanded } from "../Expanded/Expanded";
import { FlexProps } from "../Flex/Flex";
import styles from "./MainMenu.module.scss";

export interface MainMenuItemInfo<TContentProps = unknown> {
  title: string;
  icon: React.ReactElement;
  url: string;
  authorizedScopes: UserScopeEnum[];
  content?: (info: TContentProps) => React.ReactNode;
}

export interface MainMenuGroupInfo {
  title: string;
}

export type MainMenuItem<TContentProps = unknown> = MainMenuItemInfo<TContentProps> | MainMenuGroupInfo;

export type OnMenuItemClickCallback = (index: number) => void;

export interface MainMenuProps extends FlexProps {
  items: MainMenuItem[];
  activeItemIndex: number;
}

export const MainMenu: React.FC<MainMenuProps> = ({ items, activeItemIndex, className, ...rest }) => {
  const setShowChangelog = useSetRecoilState(showChangelogState);

  // get viewer scopes
  const viewerScopes = useViewer()?.scopes;

  return (
    <Column scrollable className={classNames(styles.menu, className)} {...rest}>
      {items.map((item, index) => {
        // handle main menu items
        if (isMainMenuItem(item)) {
          if (!hasAnyScopes(item.authorizedScopes, viewerScopes)) {
            return null;
          }

          const isActive = index === activeItemIndex;

          return (
            <Link
              key={index}
              to={item.url}
              className={classNames(styles.item, {
                [styles["item--active"]]: isActive,
                [styles["item--not-implemented"]]: item.content === undefined,
              })}
            >
              {item.icon} {item.title}
            </Link>
          );
        }

        // handle item groups
        return (
          <Container key={index} className={styles.group}>
            {item.title}
          </Container>
        );
      })}
      <Expanded />
      <Container onClick={() => setShowChangelog(true)} className={styles["show-changelog"]}>
        Show changelog
      </Container>
    </Column>
  );
};

export function isMainMenuItem(info: any): info is MainMenuItemInfo {
  return info && info?.url;
}
