import classNames from "classnames";
import React from "react";
import { Container } from "../Container/Container";
import { FlexProps } from "../Flex/Flex";
import styles from "./Alert.module.scss";

export type AlertSeverity = "error" | "warning" | "info" | "success";

export interface AlertProps extends FlexProps {
  severity: AlertSeverity;
}

export const Alert: React.FC<AlertProps> = ({ severity, children, ...rest }) => (
  <Container
    className={classNames(styles.alert, {
      [styles["alert--error"]]: severity === "error",
      [styles["alert--info"]]: severity === "info",
      [styles["alert--warning"]]: severity === "warning",
      [styles["alert--success"]]: severity === "success",
    })}
    {...rest}
  >
    {children}
  </Container>
);
