import { zeroPad } from "./zeroPad";

export function formatDate(date: Date | string | undefined | null) {
  if (date === null || date === undefined) {
    return "n/a";
  }

  const useDate = typeof date === "string" ? new Date(date) : date;

  return `${zeroPad(useDate.getDate())}.${zeroPad(useDate.getMonth() + 1)}.${useDate.getFullYear()}`;
}
