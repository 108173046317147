import React from "react";
import { WithCollaboration } from "../../components/Collaboration/Collaboration";
import { NameValueList, NameValueListItem } from "../../components/NameValuePair/NameValuePair";
import { CollaborationTypeEnum } from "../../schema";
import { FeedTestUserDetailsProps, FeedTestUserInfo } from "./FeedTestUserDetailsView";

export const FeedTestUserDetailsInfo: React.FC<FeedTestUserDetailsProps> = ({ feedTestUser, viewer }) => (
  <WithCollaboration referenceId={feedTestUser.id} type={CollaborationTypeEnum.FEED_TEST_USER} viewer={viewer}>
    <NameValueList items={getAdminFeedItemNameValueList(feedTestUser)} />
  </WithCollaboration>
);

export function getAdminFeedItemNameValueList(feedTestUser: FeedTestUserInfo): NameValueListItem[] {
  return [
    {
      name: "User Id",
      value: feedTestUser.userId,
    },
    {
      name: "Is active",
      value: feedTestUser.isActive ? "Yes" : "No",
    },
  ];
}
