import { sentenceCase } from "change-case";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { DataTable, DataTableAction, DataTableHeader, DataTableRow } from "../../components/DataTable/DataTable";
import { FilterBaseData } from "../../components/Filter/Filter";
import { Pagination } from "../../components/Pagination/Pagination";
import { View } from "../../components/View/View";
import { useUrlParams } from "../../hooks/useUrlParams";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import { ConditionModeEnum, MatchModeEnum, useEventSettingsQuery, UserScopeEnum } from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getPageCount } from "../../services/getPageCount";
import { getSkipTake } from "../../services/getSkipTake";
import { getUrlSearchParamsString } from "../../services/getUrlSearchParamsString";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";

interface EventSettingsFilterData extends FilterBaseData {}

export const EventSettingsListView: React.FC<AdminViewProps> = () => {
  const navigate = useNavigate();

  const params = useUrlParams<EventSettingsFilterData>((params) => ({
    matchMode: params.matchMode ? (params.matchMode as MatchModeEnum) : MatchModeEnum.STARTS_WITH,
    conditionMode: params.conditionMode ? (params.conditionMode as ConditionModeEnum) : ConditionModeEnum.AND,
    page: params.page ? parseInt(params.page, 10) : 1,
  }));

  const { data, loading, error } = useEventSettingsQuery({
    variables: {
      pagination: {
        ...getSkipTake(params.page),
      },
      match: {
        matchMode: params.matchMode,
        conditionMode: params.conditionMode,
      },
    },
  });

  const eventSettings = useMemo(() => data?.eventSettings.eventSettings ?? [], [data?.eventSettings.eventSettings]);
  const total = data?.eventSettings.total ?? 0;
  const pageCount = getPageCount(total);

  const headers = useMemo<DataTableHeader[]>(
    () => [
      {
        label: "Name",
      },
      {
        label: "Value",
      },
      {
        label: "Comment",
      },
    ],
    [],
  );

  const rows = useMemo<DataTableRow[]>(
    () =>
      eventSettings.map((eventSetting) => {
        return {
          id: eventSetting.id,
          cells: [
            {
              content: sentenceCase(eventSetting.name),
            },
            {
              content: eventSetting.value,
            },
            {
              content: eventSetting.comment,
            },
          ],
          actions: [
            {
              label: "View details",
              authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.SETTINGS, UserScopeEnum.SETTINGS_INFO],
              onClick: (eventSettingId) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "user-feed",
                    page: "settings",
                    id: eventSettingId,
                  }),
                }),
            },
            {
              label: "Edit",
              authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.SETTINGS, UserScopeEnum.SETTINGS_UPDATE_INFO],
              onClick: (eventSettingId) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "user-feed",
                    page: "settings",
                    id: eventSettingId,
                    modifier: "edit",
                  }),
                }),
            },
          ],
        };
      }),
    [navigate, eventSettings],
  );

  const bulkActions = useMemo<DataTableAction<string[]>[]>(() => [], []);

  if (error) {
    return <ErrorView title="Fetching settings failed" error={error} />;
  }

  return (
    <>
      <View scrollable>
        <DataTable
          headers={headers}
          rows={rows}
          loading={loading}
          stats={{ resultCount: total, pageCount }}
          bulkActions={bulkActions}
          openAuthorizedScopes={[UserScopeEnum.SUPERADMIN, UserScopeEnum.SETTINGS, UserScopeEnum.SETTINGS_INFO]}
          onOpen={(row) =>
            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                menu: "user-feed",
                page: "settings",
                modifier: row.id,
              }),
            })
          }
        />
        <Pagination
          sticky
          pageCount={pageCount}
          currentPage={params.page}
          onPageChange={(page) =>
            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "user-feed", page: "settings" }),
              search: getUrlSearchParamsString({ ...params, page }),
            })
          }
        />
      </View>
    </>
  );
};
