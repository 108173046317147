import { sentenceCase } from "change-case";
import { WithCollaboration } from "../../components/Collaboration/Collaboration";
import { GridBox } from "../../components/GridBox/GridBox";
import { NameValueList, NameValueListItem } from "../../components/NameValuePair/NameValuePair";
import { Title } from "../../components/Title/Title";
import { AdminFeedPollInfoFragment, CollaborationTypeEnum } from "../../schema";
import { formatDate } from "../../services/formatDate";
import { renderTranslationMap } from "../../services/renderTranslationMap";
import { AdminFeedPollDetailsResults } from "./AdminFeedPollDetailsResults";
import { AdminFeedPollDetailsProps } from "./AdminFeedPollDetailsView";

export const AdminFeedPollDetailsInfo: React.FC<AdminFeedPollDetailsProps> = ({ adminFeedPoll, viewer }) => (
  <WithCollaboration
    referenceId={adminFeedPoll.adminFeedItem.id}
    type={CollaborationTypeEnum.ADMIN_FEED_ITEM}
    viewer={viewer}
  >
    {<NameValueList items={getAdminFeedPollNameValueList(adminFeedPoll)} />}
    <GridBox />
    <Title>Results</Title>
    {<AdminFeedPollDetailsResults adminFeedPoll={adminFeedPoll} viewer={viewer} />}
  </WithCollaboration>
);

export function getAdminFeedPollNameValueList(adminFeedPoll: AdminFeedPollInfoFragment): NameValueListItem[] {
  // sort poll options by ordinal
  const sortedPollOptions = adminFeedPoll.adminFeedPollOptions.slice().sort((a, b) => a.ordinal - b.ordinal);

  return [
    {
      name: "Question",
      value: renderTranslationMap(adminFeedPoll.question),
    },
    {
      name: "Poll ID",
      value: adminFeedPoll.id,
    },
    {
      name: "Image",
      value: adminFeedPoll.adminFeedItem.metadata.imageUrl ? (
        <img src={adminFeedPoll.adminFeedItem.metadata.imageUrl} alt="Poll item" style={{ width: "20rem" }} />
      ) : (
        "n/a"
      ),
    },
    {
      name: "Status",
      value: adminFeedPoll.adminFeedItem.status,
    },
    {
      name: "Audience",
      value: adminFeedPoll.adminFeedItem.audience.map((audience) => sentenceCase(audience)).join(", "),
    },
    {
      name: "Start showing from date",
      value: formatDate(adminFeedPoll.adminFeedItem.startDate),
    },
    {
      name: "Stop showing from date",
      value: formatDate(adminFeedPoll.adminFeedItem.endDate),
    },
    {
      name: "Start showing from days after registration",
      value: adminFeedPoll.adminFeedItem.startDay,
    },
    {
      name: "Stop showing from days after registration",
      value: adminFeedPoll.adminFeedItem.endDay,
    },
    {
      name: "Is pinned (showed first on top)",
      value: adminFeedPoll.adminFeedItem.isPinned,
    },
    {
      name: "Is recommended",
      value: adminFeedPoll.adminFeedItem.isRecommended,
    },
    {
      name: "Number of likes",
      value: adminFeedPoll.adminFeedItem.likeCount,
    },
    {
      name: "Number of shares",
      value: adminFeedPoll.adminFeedItem.shareCount,
    },
    {
      name: "Topics",
      value: adminFeedPoll.adminFeedItem.topics.map((topic) => topic.name).join(", "),
    },
    {
      name: "Metadata version",
      value: adminFeedPoll.adminFeedItem.version,
    },
    {
      name: "Created",
      value: adminFeedPoll.createdDate,
    },
    {
      name: "Updated",
      value: adminFeedPoll.updatedDate,
    },
    ...sortedPollOptions.map((adminFeedPollOption, index) => ({
      name: `Option ${index + 1}`,
      value: renderTranslationMap(adminFeedPollOption.answer),
    })),
  ];
}
