import { JSONSchema7 } from "json-schema";

export const NewLevelAvailableMetadataSchema: JSONSchema7 = {
  description: "Notifies the user that a new journeys level has been added to the system.",
  type: "object",
  properties: {
    levelNumber: {
      description: "Number of the level that become available",
      minimum: 1,
      type: "number",
    },
    imageUrl: {
      description: "New level image URL",
      minLength: 1,
      type: "string",
    },
  },
  required: ["imageUrl", "levelNumber"],
  $schema: "http://json-schema.org/draft-07/schema#",
};
