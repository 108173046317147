import classNames from "classnames";
import React from "react";
import { Container } from "../Container/Container";
import { FlexProps } from "../Flex/Flex";
import { Row } from "../Row/Row";
import styles from "./Checkbox.module.scss";

export interface CheckboxProps extends FlexProps {
  active?: boolean;
  radio?: boolean;
}

export const Checkbox: React.FC<CheckboxProps> = ({ active, radio, className, children, ...rest }) => (
  <Row
    className={classNames(
      styles["checkbox"],
      {
        [styles["checkbox--active"]]: active,
        [styles["checkbox--radio"]]: radio,
        [styles["checkbox--with-children"]]: children !== undefined && children !== null,
      },
      className,
    )}
    {...rest}
  >
    <Container className={styles["box"]}>
      <Container className={styles["indicator"]} />
    </Container>
    {children && <Container className={styles["children"]}>{children}</Container>}
  </Row>
);

export const RadioButton: React.FC<CheckboxProps> = ({ ...rest }) => <Checkbox radio {...rest} />;
