import { useNavigate } from "react-router-dom";

// returns function that returns to path stored by useStoreCurrentPath() hook
export function useReturnToStoredPath(fallbackPath?: string) {
  const navigate = useNavigate();

  return () => {
    // attempt to get stored path
    const storedPath = window.localStorage.returnPath as string | undefined;

    // handle no stored path
    if (!storedPath) {
      // redirect to fallback path if available
      if (fallbackPath) {
        navigate(fallbackPath);
      }

      return;
    }

    // clear stored path
    delete window.localStorage.returnPath;

    // redirect to stored path
    navigate(storedPath);
  };
}
