import classNames from "classnames";
import React from "react";
import defaultAvatar from "../../theme/images/default-avatar.png";
import { Container } from "../Container/Container";
import { FlexProps } from "../Flex/Flex";
import { Loading } from "../Loading/Loading";
import styles from "./Avatar.module.scss";

export interface AvatarProps extends FlexProps {
  large?: boolean;
  loading?: boolean;
  image?: string | null;
}

export const Avatar: React.FC<AvatarProps> = ({ small, large, loading, image, className, ...rest }) => (
  <Container {...rest}>
    <Container
      center
      className={classNames(
        styles.avatar,
        {
          [styles["avatar--small"]]: small,
          [styles["avatar--large"]]: large,
        },
        className,
      )}
      style={loading ? undefined : { backgroundImage: `url("${image ?? defaultAvatar}")` }}
    >
      {loading && <Loading />}
    </Container>
  </Container>
);
