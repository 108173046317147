import React from "react";
import { Container } from "../components/Container/Container";
import { Row } from "../components/Row/Row";
import { Tag } from "../components/Tag/Tag";
import { LocaleEnum, TranslationMap } from "../schema";
import { getLocaleName } from "./getLocaleName";

export function renderTranslationMap(translations?: TranslationMap) {
  if (!translations) {
    return "n/a";
  }

  return (
    <>
      {Object.keys(LocaleEnum).map((locale) => {
        return (
          <Row key={locale} crossAxisAlignment="center" marginTop={1}>
            <Tag tiny secondary title={getLocaleName(locale)} marginRight={1}>
              {locale}
            </Tag>
            <Container small expanded>
              {translations[locale as keyof TranslationMap] ?? "n/a"}
            </Container>
          </Row>
        );
      })}
    </>
  );
}
