import { Route, Routes, useParams } from "react-router-dom";
import { GridBox } from "../../components/GridBox/GridBox";
import { View } from "../../components/View/View";
import { AdminViewParams } from "../../routes";
import { EventSettingByIdQueryResult, useEventSettingByIdQuery } from "../../schema";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";
import { EventSettingDetailsEdit } from "./EventSettingDetailsEdit";
import { EventSettingDetailsHeader } from "./EventSettingDetailsHeader";
import { EventSettingDetailsInfo } from "./EventSettingDetailsInfo";

export interface EventSettingDetailsProps extends AdminViewProps {
  eventSetting: NonNullable<EventSettingByIdQueryResult["data"]>["eventSetting"];
}

export const EventSettingDetailsView: React.FC<AdminViewProps> = ({ viewer }) => {
  const { id } = useParams<AdminViewParams>();

  const { data, loading, error } = useEventSettingByIdQuery({
    variables: {
      eventSettingId: id ?? "",
    },
  });

  if (!id) {
    return <ErrorView error="Expected identifier is not provided" />;
  }

  const eventSetting = data?.eventSetting;

  if (error) {
    return <ErrorView title="Loading info failed" error={error} />;
  }

  if (loading || !eventSetting) {
    return <LoadingView />;
  }

  return (
    <View pad="half">
      <EventSettingDetailsHeader eventSetting={eventSetting} viewer={viewer} />
      <GridBox />
      <Routes>
        <Route index element={<EventSettingDetailsInfo eventSetting={eventSetting} viewer={viewer} />} />
        <Route path="edit" element={<EventSettingDetailsEdit eventSetting={eventSetting} viewer={viewer} />} />
      </Routes>
    </View>
  );
};
