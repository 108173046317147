import React from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { GridBox } from "../../components/GridBox/GridBox";
import { View } from "../../components/View/View";
import { AdminViewParams } from "../../routes";
import { ClarityTestConclusionImageByIdQueryResult, useClarityTestConclusionImageByIdQuery } from "../../schema";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";
import { ClarityTestConclusionImageDetailsHeader } from "./ClarityTestConclusionImageDetailsHeader";
import { ClarityTestConclusionImageDetailsInfo } from "./ClarityTestConclusionImageDetailsInfo";
import { ClarityTestConclusionImageDetailsEdit } from "./ClarityTestConclusionImageEdit";

export type ClarityTestConclusionImageInfo = NonNullable<
  ClarityTestConclusionImageByIdQueryResult["data"]
>["clarityTestConclusionImage"];

export interface ClarityTestConclusionImageDetailsProps extends AdminViewProps {
  clarityTestConclusionImage: ClarityTestConclusionImageInfo;
}

export const ClarityTestConclusionImageDetailsView: React.FC<AdminViewProps> = ({ viewer }) => {
  // get identifier from url parameters
  const { id } = useParams<AdminViewParams>();

  // fetch info
  const { data, loading, error } = useClarityTestConclusionImageByIdQuery({
    variables: {
      conclusionImageId: id ?? "",
    },
  });

  // expecting the id parameter to be provided
  if (!id) {
    return <ErrorView error="Expected identifier is not provided" />;
  }

  // get item info
  const clarityTestConclusionImage = data?.clarityTestConclusionImage;

  // handle error
  if (error) {
    return <ErrorView title="Loading info failed" error={error || "Something went wrong, this should not happen"} />;
  }

  // handle loading
  if (loading || !clarityTestConclusionImage) {
    return <LoadingView />;
  }

  // render details
  return (
    <View pad="half">
      <ClarityTestConclusionImageDetailsHeader
        clarityTestConclusionImage={clarityTestConclusionImage}
        viewer={viewer}
      />
      <GridBox />
      <Routes>
        <Route
          index
          element={
            <ClarityTestConclusionImageDetailsInfo
              clarityTestConclusionImage={clarityTestConclusionImage}
              viewer={viewer}
            />
          }
        />
        <Route
          path="edit"
          element={
            <ClarityTestConclusionImageDetailsEdit
              clarityTestConclusionImage={clarityTestConclusionImage}
              viewer={viewer}
            />
          }
        />
      </Routes>
    </View>
  );
};
