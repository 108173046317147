import { JSONSchema7 } from "json-schema";

export const SaleMetadataSchema: JSONSchema7 = {
  description: "Notifies the user about a sale.",
  type: "object",
  properties: {
    imageUrl: {
      description: "Feed item image url",
      minLength: 1,
      type: "string",
    },
    saleTitle: {
      description: "Sale title",
      minLength: 1,
      type: "string",
    },
    saleText: {
      description: "Sale text",
      minLength: 1,
      type: "string",
    },
  },
  required: ["imageUrl", "saleText", "saleTitle"],
  $schema: "http://json-schema.org/draft-07/schema#",
};
