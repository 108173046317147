import { JSONSchema7 } from "json-schema";

export const RemindClarityTestMetadataSchema: JSONSchema7 = {
  description:
    "Reminds to take the clarity test agan.\n\nThe previous clarity test date is null if the user has never taken the clarity test.\n\nTriggered by scheduler when the user has not taken the clarity test for 30 days (or every 10 days if never).\n\nCreated by `REMIND_CLARITY_TEST` event.",
  type: "object",
  properties: {
    previousClarityTestDate: {
      description: "Date when the user last took their clarity test (null if never)",
      anyOf: [
        {
          type: "string",
          format: "date-time",
        },
        {
          type: "null",
        },
      ],
    },
  },
  required: ["previousClarityTestDate"],
  $schema: "http://json-schema.org/draft-07/schema#",
};
