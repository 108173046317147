import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { MdPassword } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { AppHeader } from "../../components/AppHeader/AppHeader";
import { BlockButton } from "../../components/BlockButton/BlockButton";
import { Column } from "../../components/Column/Column";
import { Form } from "../../components/Form/Form";
import { GridBox } from "../../components/GridBox/GridBox";
import { Panel } from "../../components/Panel/Panel";
import { P } from "../../components/Paragraph/Paragraph";
import { TextField } from "../../components/TextField/TextField";
import { View } from "../../components/View/View";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import { useChangePasswordMutation } from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getFieldErrors } from "../../services/getFieldErrors";
import { validateMinimumLength } from "../../validators/validateMinimumLength";
import { AdminViewProps } from "../AdminView/AdminView";
import styles from "./ChangePasswordView.module.scss";

interface ChangePasswordFormValues {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export const ChangePasswordView: React.FC<AdminViewProps> = ({ viewer }) => {
  const { register, handleSubmit, formState } = useForm<ChangePasswordFormValues>();
  const navigate = useNavigate();

  // setup login mutation
  const [changePassword, changePasswordResult] = useChangePasswordMutation({
    refetchQueries: ["Viewer"],
    awaitRefetchQueries: true,
    onCompleted: () => navigate(buildUrl<AdminViewParams>(ADMIN_VIEW_PATH)),
  });

  // get combined client and server side field errors
  const fieldError = getFieldErrors(changePasswordResult.error, formState.errors);

  // login user on submit
  const onSubmit: SubmitHandler<ChangePasswordFormValues> = async (variables) =>
    changePassword({
      variables,
    });

  return (
    <View>
      <AppHeader viewer={viewer} />
      <Column expanded center className={styles.content}>
        <Panel pad raised className={styles.panel}>
          <P>Change password</P>
          {!viewer.hasChangedPassword && <P small>You logged in using generated password, please set a new secret.</P>}
          <Form padTop="half" onSubmit={handleSubmit(onSubmit)}>
            <TextField
              type="password"
              name="currentPassword"
              label="Current password"
              leading={<MdPassword />}
              error={fieldError.currentPassword}
              register={register("currentPassword", { validate: validateMinimumLength(8) })}
            />
            <TextField
              type="password"
              name="newPassword"
              label="New password"
              leading={<MdPassword />}
              error={fieldError.newPassword}
              register={register("newPassword", { validate: validateMinimumLength(8) })}
            />
            <TextField
              type="password"
              name="confirmPassword"
              label="Repeat password"
              leading={<MdPassword />}
              error={fieldError.confirmPassword}
              register={register("confirmPassword", { validate: validateMinimumLength(8) })}
            />
            <GridBox half />
            <BlockButton tertiary loading={changePasswordResult.loading} type="submit" onClick={handleSubmit(onSubmit)}>
              Set new password
            </BlockButton>
          </Form>
        </Panel>
      </Column>
    </View>
  );
};
