import { JSONSchema7 } from "json-schema";

export const BlogMetadataSchema: JSONSchema7 = {
  description: "Link to a blog post.",
  type: "object",
  properties: {
    authorName: {
      description: "Author name",
      minLength: 1,
      type: "string",
    },
    authorAvatarUrl: {
      description: "Avatar URL of the author",
      minLength: 1,
      type: "string",
    },
    blogPostId: {
      description: "Unique identifier of the blog post",
      minLength: 1,
      type: "string",
    },
    blogPostTitle: {
      description: "Blog post title",
      minLength: 1,
      type: "string",
    },
    blogPostDescription: {
      description: "Blog post description",
      minLength: 1,
      type: "string",
    },
    blogPostLengthInMinutes: {
      description: "Blog post length in minutes",
      type: "number",
    },
    blogPostImageUrl: {
      description: "Blog post image url",
      minLength: 1,
      type: "string",
    },
  },
  required: [
    "authorAvatarUrl",
    "authorName",
    "blogPostDescription",
    "blogPostId",
    "blogPostImageUrl",
    "blogPostLengthInMinutes",
    "blogPostTitle",
  ],
  $schema: "http://json-schema.org/draft-07/schema#",
};
