import { AdminFeedItemTypeEnum } from "./schema";

export const fieldLength = {
  uuid: 36,
  email: 255,
  hash: 128,
  url: 2000,
  short: 36,
  medium: 255,
  long: 4048,
};

export enum Locale {
  "EN" = "EN",
  "ET" = "ET",
}

export const defaultLocale = Locale.EN;

export type TranslationsMap = { [locale in keyof typeof Locale]?: string | null };

export enum Topic {
  HAPPINESS = "HAPPINESS",
  POSITIVITY = "POSITIVITY",
  SLEEP_QUALITY = "SLEEP_QUALITY",
  ENERGY = "ENERGY",
  MENTAL_POWER = "MENTAL_POWER",
  CONFIDENCE = "CONFIDENCE",
  GRATITUDE = "GRATITUDE",
  LAW_OF_ATTRACTION = "LAW_OF_ATTRACTION",
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Metadata {}

// POLL is separate view
// TODO: Probably this should be shared between server and client
export type AdminCreatableFeedItemType = Extract<
  AdminFeedItemTypeEnum,
  | AdminFeedItemTypeEnum.EXTERNAL_LINK
  | AdminFeedItemTypeEnum.FACT
  | AdminFeedItemTypeEnum.IMAGE_CAROUSEL
  | AdminFeedItemTypeEnum.QUOTE
  | AdminFeedItemTypeEnum.VIDEO
  | AdminFeedItemTypeEnum.REVIEW
>;

export const adminCreatableFeedItems: Array<AdminCreatableFeedItemType> = [
  AdminFeedItemTypeEnum.EXTERNAL_LINK,
  AdminFeedItemTypeEnum.FACT,
  AdminFeedItemTypeEnum.IMAGE_CAROUSEL,
  AdminFeedItemTypeEnum.QUOTE,
  AdminFeedItemTypeEnum.VIDEO,
  AdminFeedItemTypeEnum.REVIEW,
];
