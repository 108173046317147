import { noCase } from "change-case";
import classNames from "classnames";
import { diff, Diff as DiffResult } from "deep-diff";
import React from "react";
import { formatValue } from "../../services/formatValue";
import { Column } from "../Column/Column";
import { FlexProps } from "../Flex/Flex";
import { P } from "../Paragraph/Paragraph";
import { Span } from "../Span/Span";
import { Title } from "../Title/Title";
import styles from "./Diff.module.scss";

type DiffValue = string | number | boolean | null | undefined;

interface EditInfo {
  path: string[];
  lhs: DiffValue;
  rhs: DiffValue;
}

interface NewInfo {
  path: string[];
  rhs: DiffValue;
}

interface DeleteInfo {
  path: string[];
  lhs: DiffValue;
}

export interface DiffProps extends FlexProps {
  left: DiffValue;
  right: DiffValue;
}

export const Diff: React.FC<DiffProps> = ({ left, right, className, ...rest }) => {
  const differences = diff<DiffValue, DiffValue>(left, right);

  if (!differences || differences.length === 0) {
    return (
      <P italic tiny>
        No changes were made
      </P>
    );
  }

  return (
    <Column className={classNames(styles.diff, className)} {...rest}>
      <Title sub>
        {differences.length > 1 ? `There were ${differences.length} changes made` : "One change was made"}
      </Title>
      {differences.map((difference, index) => renderDifference(difference, index))}
    </Column>
  );
};

function renderDifference(difference: DiffResult<DiffValue, DiffValue>, index: number) {
  const { path } = difference;

  if (!path) {
    return null;
  }

  switch (difference.kind) {
    case "E":
      return renderEditDifference(
        {
          path,
          lhs: difference.lhs,
          rhs: difference.rhs,
        },
        index,
      );

    case "N":
      return renderNewDifference(
        {
          path,
          rhs: difference.rhs,
        },
        index,
      );

    case "D":
      return renderDeleteDifference(
        {
          path,
          lhs: difference.lhs,
        },
        index,
      );

    default:
      console.warn("missing diff type renderer", difference);
  }
}

function renderEditDifference({ path, lhs, rhs }: EditInfo, index: number) {
  return (
    <P small key={index}>
      Updated <Span darker>{renderPath(path)}</Span> from <Span darker>{formatValue(lhs)}</Span> to{" "}
      <Span darker>{formatValue(rhs)}</Span>
    </P>
  );
}

function renderNewDifference({ path, rhs }: NewInfo, index: number) {
  return (
    <P small key={index}>
      Added <Span darker>{renderPath(path)}</Span> with value <Span darker>{formatValue(rhs)}</Span>
    </P>
  );
}

function renderDeleteDifference({ path, lhs }: DeleteInfo, index: number) {
  return (
    <P small key={index}>
      Removed <Span darker>{renderPath(path)}</Span> with value <Span darker>{formatValue(lhs)}</Span>
    </P>
  );
}

function renderPath(path: (string | number)[]) {
  return path.map((item) => noCase(item.toString())).join(" > ");
}
