import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ViewerInfo } from "../../routes";
import {
  CollaborationByIdQuery,
  CollaborationTypeEnum,
  useAddCollaborationCommentMutation,
  useCollaborationByIdQuery,
  UserScopeEnum,
} from "../../schema";
import { getFieldErrors } from "../../services/getFieldErrors";
import { hasAnyScopes } from "../../services/hasAnyScopes";
import { isAuthorizationError } from "../../services/isAuthorizationErrors";
import { ErrorView } from "../../views/ErrorView/ErrorView";
import { Avatar } from "../Avatar/Avatar";
import { BlockButton } from "../BlockButton/BlockButton";
import { Column } from "../Column/Column";
import { Container } from "../Container/Container";
import { Diff } from "../Diff/Diff";
import { Expanded } from "../Expanded/Expanded";
import { FlexProps } from "../Flex/Flex";
import { Form } from "../Form/Form";
import { GridBox } from "../GridBox/GridBox";
import { Loading } from "../Loading/Loading";
import { Markdown } from "../Markdown/Markdown";
import { P } from "../Paragraph/Paragraph";
import { Row } from "../Row/Row";
import { TextArea } from "../TextArea/TextArea";
import { TimeAgo } from "../TimeAgo/TimeAgo";
import { Title } from "../Title/Title";

export type CollaborationInfo = CollaborationByIdQuery["collaboration"];

export interface CollaborationProps extends FlexProps {
  referenceId: string;
  entries: CollaborationInfo;
  type: CollaborationTypeEnum;
  viewer: ViewerInfo;
}

interface CollaborationFormValues {
  collaborationComment: string;
}

export const Collaboration: React.FC<CollaborationProps> = ({ referenceId, entries, type, viewer, ...rest }) => {
  const [addCollaborationComment, addCollaborationCommentResult] = useAddCollaborationCommentMutation({
    refetchQueries: ["CollaborationById"],
    awaitRefetchQueries: true,
  });

  const { register, handleSubmit, formState, reset } = useForm<CollaborationFormValues>();

  const { error, loading } = addCollaborationCommentResult;

  if (error && isAuthorizationError(error)) {
    return <ErrorView error={error} />;
  }

  // get combined client and server side field errors
  const fieldError = getFieldErrors(error, formState.errors);

  // check whether the user is allowed to add collaboration entries
  const canAddCollaborationEntry = hasAnyScopes(
    [UserScopeEnum.SUPERADMIN, UserScopeEnum.COLLABORATION, UserScopeEnum.COLLABORATION_ADD_COMMENT],
    viewer.scopes,
  );

  // login user on submit
  const onSubmit: SubmitHandler<CollaborationFormValues> = async ({ collaborationComment }) => {
    const response = await addCollaborationComment({
      variables: {
        referenceId,
        type,
        comment: collaborationComment,
      },
    });

    // reset form on success
    if (response.data) {
      reset();
    }
  };

  return (
    <Column {...rest}>
      <Title>Collaboration</Title>
      <GridBox half />
      <Column scrollable expanded padRight="half">
        {entries.length === 0 && (
          <Column expanded>
            <P small lighter>
              No collaboration comments have been added yet
            </P>
          </Column>
        )}

        {entries.map((collaboration) => (
          <Column key={collaboration.id} marginBottom="half">
            <Row>
              <Avatar small image={collaboration.creator.avatarUrl} />
              <Column marginLeft={2}>
                <P small darker>
                  {collaboration.creator.name}
                </P>
                <P tiny>{collaboration.creator.email}</P>
              </Column>
              <Expanded />
              <TimeAgo tiny date={collaboration.createdDate} />
            </Row>
            <Markdown marginTop="half">{collaboration.comment}</Markdown>
            {collaboration.changes && (
              <Diff left={collaboration.changes.before} right={collaboration.changes.after} marginTop="half" />
            )}
          </Column>
        ))}
      </Column>

      {canAddCollaborationEntry && (
        <Form padTop="half" onSubmit={handleSubmit(onSubmit)}>
          <Container>
            <TextArea
              name="collaborationComment"
              // label="Comment to add (supports markdown)"
              placeholder="Comment to add (supports markdown)"
              error={fieldError.collaborationComment}
              register={register("collaborationComment", { required: "Comment is required" })}
            />
          </Container>
          <BlockButton secondary type="submit" loading={loading}>
            Add comment
          </BlockButton>
        </Form>
      )}
    </Column>
  );
};

export interface WithCollaborationProps extends FlexProps {
  referenceId: string;
  type: CollaborationTypeEnum;
  viewer: ViewerInfo;
}

export const WithCollaboration: React.FC<WithCollaborationProps> = ({
  referenceId,
  type,
  viewer,
  children,
  ...rest
}) => {
  const collaborationAuthorizedScopes = [UserScopeEnum.COLLABORATION, UserScopeEnum.COLLABORATION_ENTRIES];
  const viewerHasCollaborationPermissions = hasAnyScopes(collaborationAuthorizedScopes, viewer.scopes);

  // load collaboration entries
  const { data, loading, error } = useCollaborationByIdQuery({
    variables: {
      referenceId,
    },
    // TODO: use subscriptions instead
    pollInterval: 10000,
    // only attempt to fetch if logged in user has required scopes
    skip: !viewerHasCollaborationPermissions,
  });

  // handle missing authorization
  if (!viewerHasCollaborationPermissions || isAuthorizationError(error)) {
    return <Column scrollable>{children}</Column>;
  }

  return (
    <Column expanded {...rest}>
      <Row expanded>
        <Column scrollable flex="3" padRight="half">
          {children}
        </Column>
        <GridBox />
        {error ? (
          <Column flex="1">
            <Title>Collaboration</Title>
            <Container expanded center>
              <ErrorView error={error} />
            </Container>
          </Column>
        ) : !data || loading ? (
          <Column flex="1">
            <Title>Collaboration</Title>
            <Container expanded center>
              <Loading />
            </Container>
          </Column>
        ) : (
          <Collaboration flex="1" referenceId={referenceId} entries={data.collaboration} type={type} viewer={viewer} />
        )}
      </Row>
    </Column>
  );
};
