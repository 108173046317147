import React from "react";
import { RiEmotionSadLine } from "react-icons/ri";
import { P } from "../../components/Paragraph/Paragraph";
import { View } from "../../components/View/View";

export const NotImplementedView: React.FC = () => (
  <View pad center>
    <RiEmotionSadLine size={100} />
    <P marginTop>This view has not been implemented yet</P>
  </View>
);
