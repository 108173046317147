import { sentenceCase } from "change-case";
import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { Action } from "../../components/ActionBar/ActionBar";
import { Column } from "../../components/Column/Column";
import { DataTable, DataTableAction, DataTableHeader, DataTableRow } from "../../components/DataTable/DataTable";
import { Filter, FilterBaseData } from "../../components/Filter/Filter";
import { FormFieldOptions } from "../../components/GeneratedForm/GeneratedForm";
import { Pagination } from "../../components/Pagination/Pagination";
import { Title } from "../../components/Title/Title";
import { toastState, ToastType } from "../../components/Toast/Toast";
import { View } from "../../components/View/View";
import { useUrlParams } from "../../hooks/useUrlParams";
import { CreateQuestionModal } from "../../modals/CreateQuestionModal/CreateQuestionModal";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import {
  ConditionModeEnum,
  MatchModeEnum,
  QuestionTypeEnum,
  SortingInput,
  TopicTypeEnum,
  useQuestionsQuery,
  UserScopeEnum,
} from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getEnumFormOptions } from "../../services/getEnumFormOptions";
import { getPageCount } from "../../services/getPageCount";
import { getSkipTake } from "../../services/getSkipTake";
import { getSortDirectionFromParams } from "../../services/getSortDirectionFromParams";
import { getSortOptionsFromSearchParams } from "../../services/getSortOptionsFromSearchParams";
import { getUpdatedSortOptions } from "../../services/getUpdatedSortOptions";
import { getUrlSearchParamsString } from "../../services/getUrlSearchParamsString";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";

interface QuestionFilterData extends FilterBaseData {
  question: string;
  topic: TopicTypeEnum[];
  type: QuestionTypeEnum[];
  sort: SortingInput[] | null;
}

export const TestQuestionListView: React.FC<AdminViewProps> = () => {
  const navigate = useNavigate();
  const setToast = useSetRecoilState(toastState);
  const [showCreateQuestionModal, setShowCreateQuestionModal] = useState(false);

  // parse filter url parameters
  const params = useUrlParams<QuestionFilterData>((params) => ({
    question: params.question ?? "",
    topic: params.topic ? (params.topic.split(",") as TopicTypeEnum[]) : [],
    type: params.type ? (params.type.split(",") as QuestionTypeEnum[]) : [],
    matchMode: params.matchMode ? (params.matchMode as MatchModeEnum) : MatchModeEnum.STARTS_WITH,
    conditionMode: params.conditionMode ? (params.conditionMode as ConditionModeEnum) : ConditionModeEnum.AND,
    page: params.page ? parseInt(params.page, 10) : 1,
    sort: params.sort ? getSortOptionsFromSearchParams(params.sort) : null,
  }));

  const { data, loading, error } = useQuestionsQuery({
    variables: {
      filter: {
        question: params.question,
        topic: params.topic.length > 0 ? params.topic : undefined,
        type: params.type.length > 0 ? params.type : undefined,
      },
      pagination: {
        ...getSkipTake(params.page),
      },
      match: {
        matchMode: params.matchMode,
        conditionMode: params.conditionMode,
      },
      sort: params.sort ?? null,
    },
  });

  // get items
  const questions = useMemo(() => data?.questions.questions ?? [], [data?.questions.questions]);

  const total = data?.questions.total ?? 0;
  const pageCount = getPageCount(total);

  // // filters configuration
  const filters = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "text",
        type: "text",
        name: "question",
        label: "question",
        defaultValue: params.question,
      },
      {
        field: "checkbox",
        name: "topic",
        label: "Topic",
        options: getEnumFormOptions(TopicTypeEnum),
        defaultValue: params.topic,
      },
      {
        field: "checkbox",
        name: "type",
        label: "Type",
        options: getEnumFormOptions(QuestionTypeEnum),
        defaultValue: params.type,
      },
    ],
    [params.question, params.topic, params.type],
  );

  // handle sorting by column
  const onHeaderClick = useCallback(
    (databaseColumnName: string) =>
      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "clarity-score", page: "questions" }),
        search: getUrlSearchParamsString({ ...params, sort: getUpdatedSortOptions(databaseColumnName, params.sort) }),
      }),
    [navigate, params],
  );

  // data table headers
  const headers = useMemo<DataTableHeader[]>(
    () => [
      {
        label: "Question",
        onClick: () => onHeaderClick("question"),
        sortdirection: getSortDirectionFromParams("question", params.sort),
      },
      {
        label: "Topic",
        onClick: () => onHeaderClick("topic"),
        sortdirection: getSortDirectionFromParams("topic", params.sort),
      },
      {
        label: "Type",
        onClick: () => onHeaderClick("type"),
        sortdirection: getSortDirectionFromParams("type", params.sort),
      },
    ],
    [onHeaderClick, params.sort],
  );

  // data table rows
  const rows = useMemo<DataTableRow[]>(
    () =>
      questions.map((question) => {
        return {
          id: question.id,
          cells: [
            {
              content: question.question.EN,
            },
            {
              content: sentenceCase(question.topic),
            },
            {
              content: sentenceCase(question.type),
            },
          ],
          actions: [
            {
              label: "View details",
              authorizedScopes: [
                UserScopeEnum.SUPERADMIN,
                UserScopeEnum.CLARITY_TEST,
                UserScopeEnum.CLARITY_TEST_UPDATE_INFO,
              ],
              onClick: (questionId) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "clarity-score",
                    page: "questions",
                    id: questionId,
                  }),
                }),
            },
            {
              label: "Edit",
              authorizedScopes: [
                UserScopeEnum.SUPERADMIN,
                UserScopeEnum.CLARITY_TEST,
                UserScopeEnum.CLARITY_TEST_UPDATE_INFO,
              ],
              onClick: (questionId) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "clarity-score",
                    page: "questions",
                    id: questionId,
                    modifier: "edit",
                  }),
                }),
            },
          ],
        };
      }),
    [navigate, questions],
  );

  // data table bulk actions
  const bulkActions = useMemo<DataTableAction<string[]>[]>(() => [], []);

  // handle filters submit
  const onFilterSubmit = useCallback(
    (data: QuestionFilterData) => {
      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "clarity-score", page: "questions" }),
        search: getUrlSearchParamsString(data),
      });
    },
    [navigate],
  );

  // header buttons configuration
  const viewActions: Action[] = useMemo(
    () => [
      {
        label: "Create Question",
        authorizedScopes: [
          UserScopeEnum.SUPERADMIN,
          UserScopeEnum.CLARITY_TEST,
          UserScopeEnum.CLARITY_TEST_CREATE_QUESTION,
        ],
        onClick: () => setShowCreateQuestionModal(true),
      },
    ],
    [],
  );

  // handle errors
  if (error) {
    return <ErrorView title="Fetching questions failed" error={error} />;
  }

  return (
    <>
      <View scrollable>
        <Column pad="half">
          <Title actions={viewActions}>Questions</Title>
        </Column>
        <Filter
          fields={filters}
          loading={loading}
          matchMode={params.matchMode}
          conditionMode={params.conditionMode}
          onSubmit={onFilterSubmit}
        />

        <DataTable
          headers={headers}
          rows={rows}
          loading={loading}
          bulkActions={bulkActions}
          openAuthorizedScopes={[
            UserScopeEnum.SUPERADMIN,
            UserScopeEnum.CLARITY_TEST,
            UserScopeEnum.CLARITY_TEST_UPDATE_INFO,
          ]}
          onOpen={(row) =>
            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                menu: "clarity-score",
                page: "questions",
                modifier: row.id,
              }),
            })
          }
        />
        <Pagination
          sticky
          pageCount={pageCount}
          currentPage={params.page}
          onPageChange={(page) =>
            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "clarity-score", page: "questions" }),
              search: getUrlSearchParamsString({ ...params, page }),
            })
          }
        />
      </View>
      <CreateQuestionModal
        open={showCreateQuestionModal}
        onClickOutside={() => setShowCreateQuestionModal(false)}
        onCompleted={(data) => {
          // show success toast message
          setToast({
            type: ToastType.SUCCESS,
            title: `Created question ${data.createQuestion.question.EN}`,
            message: ``,
            isOpen: true,
          });

          // navigate to user details view
          navigate({
            pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
              menu: "clarity-score",
              page: "questions",
              id: data.createQuestion.id,
            }),
          });
        }}
      />
    </>
  );
};
