import React, { useMemo } from "react";
import { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { WithCollaboration } from "../../components/Collaboration/Collaboration";
import { FormFieldOptions, GeneratedForm } from "../../components/GeneratedForm/GeneratedForm";
import { toastState, ToastType } from "../../components/Toast/Toast";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import { CollaborationTypeEnum, TranslationMap, useUpdateFeelingMutation } from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getTranslationInput } from "../../services/getTranslationInput";
import { round } from "../../services/round";
import { NumericOptionDecimalPrecision, validateNumeric } from "../../validators/validateNumeric";
import { FeelingDetailsProps } from "./FeelingDetailsView";

interface EditFeelingFormData {
  name: TranslationMap;
  scoreModifierHappiness: string;
  scoreModifierPositivity: string;
  scoreModifierEnergy: string;
  scoreModifierSleepQuality: string;
  scoreModifierMentalPower: string;
  scoreModifierConfidence: string;
  scoreModifierGratitude: string;
  scoreModifierAttraction: string;
  comment: string;
}

export const FeelingDetailsEdit: React.FC<FeelingDetailsProps> = ({ feeling, viewer }) => {
  const navigate = useNavigate();
  const setToast = useSetRecoilState(toastState);
  const [updateAdminInfo, updateAdminInfoResult] = useUpdateFeelingMutation({
    refetchQueries: ["CollaborationById"],
    awaitRefetchQueries: true,
    onCompleted: ({ updateFeeling }) => {
      setToast({
        type: ToastType.SUCCESS,
        title: "Feeling has been updated",
        message: `Updated feeling "${updateFeeling.name}" details.`,
        isOpen: true,
      });

      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
          menu: "clarity-score",
          page: "feelings",
          id: feeling.id,
        }),
      });
    },
  });

  // form configuration
  const fields = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "translation",
        name: "name",
        label: "Name",
        rules: { required: "Please provide name" },
        defaultValue: feeling.nameTranslations,
      },
      {
        field: "text",
        type: "number",
        name: "scoreModifierHappiness",
        label: "Happyness score modifer percentage",
        rules: {
          required: "Please provide score modifer value",
          validate: validateNumeric({
            min: -1.0,
            max: 1.0,
            maxDecimalPrecision: NumericOptionDecimalPrecision.HUNDRETHS,
          }),
        },
        defaultValue: round(feeling.scoreModifierHappiness).toString(),
      },
      {
        field: "text",
        type: "number",
        name: "scoreModifierPositivity",
        label: "Positivity score modifer percentage",
        rules: {
          required: "Please provide score modifer value",
          validate: validateNumeric({
            min: -1.0,
            max: 1.0,
            maxDecimalPrecision: NumericOptionDecimalPrecision.HUNDRETHS,
          }),
        },
        defaultValue: round(feeling.scoreModifierPositivity).toString(),
      },
      {
        field: "text",
        type: "number",
        name: "scoreModifierEnergy",
        label: "Energy score modifer percentage",
        rules: {
          required: "Please provide score modifer value",
          validate: validateNumeric({
            min: -1.0,
            max: 1.0,
            maxDecimalPrecision: NumericOptionDecimalPrecision.HUNDRETHS,
          }),
        },
        defaultValue: round(feeling.scoreModifierEnergy).toString(),
      },
      {
        field: "text",
        type: "number",
        name: "scoreModifierSleepQuality",
        label: "Sleep quality score modifer percentage",
        rules: {
          required: "Please provide score modifer value",
          validate: validateNumeric({
            min: -1.0,
            max: 1.0,
            maxDecimalPrecision: NumericOptionDecimalPrecision.HUNDRETHS,
          }),
        },
        defaultValue: round(feeling.scoreModifierSleepQuality).toString(),
      },
      {
        field: "text",
        type: "number",
        name: "scoreModifierMentalPower",
        label: "Mental power score modifer percentage",
        rules: {
          required: "Please provide score modifer value",
          validate: validateNumeric({
            min: -1.0,
            max: 1.0,
            maxDecimalPrecision: NumericOptionDecimalPrecision.HUNDRETHS,
          }),
        },
        defaultValue: round(feeling.scoreModifierMentalPower).toString(),
      },
      {
        field: "text",
        type: "number",
        name: "scoreModifierConfidence",
        label: "Confidence score modifer percentage",
        rules: {
          required: "Please provide score modifer value",
          validate: validateNumeric({
            min: -1.0,
            max: 1.0,
            maxDecimalPrecision: NumericOptionDecimalPrecision.HUNDRETHS,
          }),
        },
        defaultValue: round(feeling.scoreModifierConfidence).toString(),
      },
      {
        field: "text",
        type: "number",
        name: "scoreModifierGratitude",
        label: "Gratitude score modifer percentage",
        rules: {
          required: "Please provide score modifer value",
          validate: validateNumeric({
            min: -1.0,
            max: 1.0,
            maxDecimalPrecision: NumericOptionDecimalPrecision.HUNDRETHS,
          }),
        },
        defaultValue: round(feeling.scoreModifierGratitude).toString(),
      },
      {
        field: "text",
        type: "number",
        name: "scoreModifierAttraction",
        label: "Attraction score modifer percentage",
        rules: {
          required: "Please provide score modifer value",
          validate: validateNumeric({
            min: -1.0,
            max: 1.0,
            maxDecimalPrecision: NumericOptionDecimalPrecision.HUNDRETHS,
          }),
        },
        defaultValue: round(feeling.scoreModifierAttraction).toString(),
      },
      {
        field: "textarea",
        type: "text",
        name: "comment",
        label: "Collaboration comment",
        rules: {
          required: "Please describe what and why was updated",
        },
        defaultValue: "Updated feeling info",
      },
    ],
    [
      feeling.nameTranslations,
      feeling.scoreModifierAttraction,
      feeling.scoreModifierConfidence,
      feeling.scoreModifierEnergy,
      feeling.scoreModifierGratitude,
      feeling.scoreModifierHappiness,
      feeling.scoreModifierMentalPower,
      feeling.scoreModifierPositivity,
      feeling.scoreModifierSleepQuality,
    ],
  );

  const onSubmit: SubmitHandler<EditFeelingFormData> = async (data) =>
    updateAdminInfo({
      variables: {
        ...data,
        feelingId: feeling.id,
        name: getTranslationInput("name", { ...data }),
        scoreModifierHappiness: parseFloat(data.scoreModifierHappiness),
        scoreModifierPositivity: parseFloat(data.scoreModifierPositivity),
        scoreModifierEnergy: parseFloat(data.scoreModifierEnergy),
        scoreModifierSleepQuality: parseFloat(data.scoreModifierSleepQuality),
        scoreModifierMentalPower: parseFloat(data.scoreModifierMentalPower),
        scoreModifierConfidence: parseFloat(data.scoreModifierConfidence),
        scoreModifierGratitude: parseFloat(data.scoreModifierGratitude),
        scoreModifierAttraction: parseFloat(data.scoreModifierAttraction),
      },
    });

  return (
    <WithCollaboration referenceId={feeling.id} type={CollaborationTypeEnum.USER} viewer={viewer}>
      <GeneratedForm
        title="Feeling"
        error={updateAdminInfoResult.error}
        loading={updateAdminInfoResult.loading}
        submitText="Update"
        onSubmit={onSubmit}
      >
        <GeneratedForm.Fields fields={fields} />
      </GeneratedForm>
    </WithCollaboration>
  );
};
