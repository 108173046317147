export type GridSize = boolean | "half" | number | undefined;

export function grid(size: GridSize) {
  if (size === undefined || size === false) {
    return undefined;
  }

  if (size === true) {
    // should match $layout-view-padding in _variables.scss
    return `6rem`;
  } else if (size === "half") {
    // should match half of $layout-view-padding in _variables.scss
    return `3rem`;
  }

  return `${size}rem`;
}
