import React, { useMemo } from "react";
import { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { WithCollaboration } from "../../components/Collaboration/Collaboration";
import { FormFieldOptions, GeneratedForm } from "../../components/GeneratedForm/GeneratedForm";
import { toastState, ToastType } from "../../components/Toast/Toast";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import { CollaborationTypeEnum, InformativeContentEnum, useUpdateInformativeContentMutation } from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getEnumFormOptions } from "../../services/getEnumFormOptions";
import { getFileUploadById } from "../../services/getFileUploadById";
import { getTranslationInput } from "../../services/getTranslationInput";
import { InformativeContentDetailsProps } from "./InformativeContentDetailsView";

interface EditInformativeContentFormData {
  title: string;
  subTitle: string;
  text: string;
  type: InformativeContentEnum;
  ordinal: string;
  comment: string;
}

export const TestInformativeContentDetailsEdit: React.FC<InformativeContentDetailsProps> = ({
  informativeContent,
  viewer,
}) => {
  const navigate = useNavigate();
  const setToast = useSetRecoilState(toastState);
  const [updateQuestionInfo, updateQuestionInfoResult] = useUpdateInformativeContentMutation({
    refetchQueries: ["CollaborationById"],
    awaitRefetchQueries: true,
    onCompleted: ({ updateInformativeContent }) => {
      setToast({
        type: ToastType.SUCCESS,
        title: "Informative content has been updated",
        message: `Updated question "${updateInformativeContent.id}" details.`,
        isOpen: true,
      });

      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
          menu: "clarity-score",
          page: "informative-content",
          id: informativeContent.id,
        }),
      });
    },
  });

  // form configuration
  const fields = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "upload",
        type: "text",
        name: "image",
        label: "Informative content image",
      },
      {
        field: "translation",
        name: "title",
        label: "Title",
        defaultValue: informativeContent.title,
      },
      {
        field: "translation",
        name: "subTitle",
        label: "Subtitle",
        defaultValue: informativeContent.subTitle,
      },
      {
        field: "translation",
        name: "text",
        label: "Text",
        defaultValue: informativeContent.text,
      },
      {
        field: "radio",
        options: getEnumFormOptions(InformativeContentEnum),
        name: "type",
        label: "Type",
        rules: { required: "Please provide question type" },
        defaultValue: informativeContent.type,
      },
      {
        field: "text",
        type: "number",
        name: "ordinal",
        label: "Ordinal",
        rules: { required: "Please provide informative content ordinal value" },
        defaultValue: informativeContent.ordinal ? informativeContent.ordinal.toString() : "",
      },
      {
        field: "textarea",
        type: "text",
        name: "comment",
        label: "Collaboration comment",
        rules: {
          required: "Please describe what and why was updated",
        },
        defaultValue: "Updated informative content info",
      },
    ],
    [
      informativeContent.title,
      informativeContent.subTitle,
      informativeContent.text,
      informativeContent.type,
      informativeContent.ordinal,
    ],
  );

  const onSubmit: SubmitHandler<EditInformativeContentFormData> = async (data) => {
    updateQuestionInfo({
      variables: {
        ...data,
        informativeContentId: informativeContent.id,
        image: getFileUploadById("image"),
        title: getTranslationInput("title", { ...data }),
        subTitle: getTranslationInput("subTitle", { ...data }),
        text: getTranslationInput("text", { ...data }),
        type: data.type,
        ordinal: parseInt(data.ordinal, 10),
      },
    });
  };

  return (
    <WithCollaboration
      referenceId={informativeContent.id}
      type={CollaborationTypeEnum.INFORMATIVE_CONTENT}
      viewer={viewer}
    >
      <GeneratedForm
        title="Question"
        error={updateQuestionInfoResult.error}
        loading={updateQuestionInfoResult.loading}
        submitText="Update"
        onSubmit={onSubmit}
      >
        <GeneratedForm.Fields fields={fields} />
      </GeneratedForm>
    </WithCollaboration>
  );
};
