import React, { useMemo } from "react";
import { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { WithCollaboration } from "../../components/Collaboration/Collaboration";
import { FormFieldOptions, GeneratedForm } from "../../components/GeneratedForm/GeneratedForm";
import { toastState, ToastType } from "../../components/Toast/Toast";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import { CollaborationTypeEnum, QuestionTypeEnum, TopicTypeEnum, useUpdateQuestionMutation } from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getEnumFormOptions } from "../../services/getEnumFormOptions";
import { getTranslationInput } from "../../services/getTranslationInput";
import { QuestionDetailsProps } from "./TestQuestionDetailsView";

interface EditQuestionFormData {
  question: string;
  type: QuestionTypeEnum;
  topic: TopicTypeEnum;
  comment: string;
}

export const TestQuestionDetailsEdit: React.FC<QuestionDetailsProps> = ({ question, viewer }) => {
  const navigate = useNavigate();
  const setToast = useSetRecoilState(toastState);
  const [updateQuestionInfo, updateQuestionInfoResult] = useUpdateQuestionMutation({
    refetchQueries: ["CollaborationById"],
    awaitRefetchQueries: true,
    onCompleted: ({ updateQuestion }) => {
      setToast({
        type: ToastType.SUCCESS,
        title: "Question Option has been updated",
        message: `Updated question "${updateQuestion.id}" details.`,
        isOpen: true,
      });

      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
          menu: "clarity-score",
          page: "questions",
          id: question.id,
        }),
      });
    },
  });

  // Add edit item mutation

  // form configuration
  const fields = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "translation",
        name: "question",
        label: "Question",
        rules: { required: "Please provide question" },
        defaultValue: question.question,
      },
      {
        field: "radio",
        options: getEnumFormOptions(TopicTypeEnum),
        name: "topic",
        label: "Topic",
        rules: { required: "Please provide question topic" },
        defaultValue: question.topic,
      },
      {
        field: "radio",
        options: getEnumFormOptions(QuestionTypeEnum),
        name: "type",
        label: "Type",
        rules: { required: "Please provide question type" },
        defaultValue: question.type,
      },
      {
        field: "textarea",
        type: "text",
        name: "comment",
        label: "Collaboration comment",
        rules: {
          required: "Please describe what and why was updated",
        },
        defaultValue: "Updated question option info",
      },
    ],
    [question.question, question.topic, question.type],
  );

  const onSubmit: SubmitHandler<EditQuestionFormData> = async (data) => {
    updateQuestionInfo({
      variables: {
        ...data,
        questionId: question.id,
        question: getTranslationInput("question", { ...data }),
        topic: data.topic,
        type: data.type,
      },
    });
  };

  return (
    <WithCollaboration referenceId={question.id} type={CollaborationTypeEnum.QUESTION} viewer={viewer}>
      <GeneratedForm
        title="Question"
        error={updateQuestionInfoResult.error}
        loading={updateQuestionInfoResult.loading}
        submitText="Update"
        onSubmit={onSubmit}
      >
        <GeneratedForm.Fields fields={fields} />
      </GeneratedForm>
    </WithCollaboration>
  );
};
