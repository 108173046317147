import colorInterpolate from "color-interpolate";
import palettes from "nice-color-palettes";

export const getChartPalette = (numberOfColors = 10, paletteIndex = 1) => {
  const interpolator = colorInterpolate(palettes[paletteIndex]);

  const stepSize = 1 / numberOfColors;
  const palette = [];

  let step = stepSize;
  while (step < 1) {
    palette.push(interpolator(step));

    step += stepSize;
  }

  return palette;
};
