import { sentenceCase } from "change-case";
import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { Action } from "../../components/ActionBar/ActionBar";
import { Column } from "../../components/Column/Column";
import { DataTable, DataTableAction, DataTableHeader, DataTableRow } from "../../components/DataTable/DataTable";
import { Filter, FilterBaseData } from "../../components/Filter/Filter";
import { FormFieldOptions } from "../../components/GeneratedForm/GeneratedForm";
import { Pagination } from "../../components/Pagination/Pagination";
import { Title } from "../../components/Title/Title";
import { toastState, ToastType } from "../../components/Toast/Toast";
import { View } from "../../components/View/View";
import { useUrlParams } from "../../hooks/useUrlParams";
import { CreateInformativeContentModal } from "../../modals/CreateInformativeContentModal/CreateInformativeContentModal";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import {
  ConditionModeEnum,
  InformativeContentEnum,
  MatchModeEnum,
  SortingInput,
  useInformativeContentQuery,
  UserScopeEnum,
} from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getEnumFormOptions } from "../../services/getEnumFormOptions";
import { getPageCount } from "../../services/getPageCount";
import { getSkipTake } from "../../services/getSkipTake";
import { getSortDirectionFromParams } from "../../services/getSortDirectionFromParams";
import { getSortOptionsFromSearchParams } from "../../services/getSortOptionsFromSearchParams";
import { getUpdatedSortOptions } from "../../services/getUpdatedSortOptions";
import { getUrlSearchParamsString } from "../../services/getUrlSearchParamsString";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";

interface InformativeContentFilterData extends FilterBaseData {
  title: string;
  subTitle: string;
  text: string;
  type: InformativeContentEnum[];
  sort: SortingInput[] | null;
}

export const InformativeContentListView: React.FC<AdminViewProps> = () => {
  const navigate = useNavigate();
  const setToast = useSetRecoilState(toastState);

  const [showCreateInformativeContentModal, setShowInformativeContentModal] = useState(false);

  // parse filter url parameters
  const params = useUrlParams<InformativeContentFilterData>((params) => ({
    title: params.title ?? "",
    subTitle: params.subTitle ?? "",
    text: params.text ?? "",
    type: params.type ? (params.type.split(",") as InformativeContentEnum[]) : [],
    matchMode: params.matchMode ? (params.matchMode as MatchModeEnum) : MatchModeEnum.STARTS_WITH,
    conditionMode: params.conditionMode ? (params.conditionMode as ConditionModeEnum) : ConditionModeEnum.AND,
    page: params.page ? parseInt(params.page, 10) : 1,
    sort: params.sort ? getSortOptionsFromSearchParams(params.sort) : null,
  }));

  const { data, loading, error } = useInformativeContentQuery({
    variables: {
      filter: {
        title: params.title,
        subTitle: params.subTitle,
        text: params.text,
        type: params.type.length > 0 ? params.type : undefined,
      },
      pagination: {
        ...getSkipTake(params.page),
      },
      match: {
        matchMode: params.matchMode,
        conditionMode: params.conditionMode,
      },
      sort: params.sort ?? null,
    },
  });

  // get translations
  const informativeContent = useMemo(
    () => data?.informativeContent.informativeContent ?? [],
    [data?.informativeContent.informativeContent],
  );

  const total = data?.informativeContent.total ?? 0;
  const pageCount = getPageCount(total);

  // // filters configuration
  const filters = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "text",
        type: "text",
        name: "title",
        label: "Title",
        defaultValue: params.title,
      },
      {
        field: "text",
        type: "text",
        name: "subTitle",
        label: "Sub title",
        defaultValue: params.subTitle,
      },
      {
        field: "text",
        type: "text",
        name: "text",
        label: "Text",
        defaultValue: params.text,
      },
      {
        field: "checkbox",
        name: "type",
        label: "Type",
        options: getEnumFormOptions(InformativeContentEnum),
        defaultValue: params.type,
      },
    ],
    [params.title, params.subTitle, params.text, params.type],
  );

  // handle sorting by column
  const onHeaderClick = useCallback(
    (databaseColumnName: string) =>
      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "clarity-score", page: "informative-content" }),
        search: getUrlSearchParamsString({ ...params, sort: getUpdatedSortOptions(databaseColumnName, params.sort) }),
      }),
    [navigate, params],
  );

  // data table headers
  const headers = useMemo<DataTableHeader[]>(
    () => [
      {
        label: "Image",
        // no point to sort this column
      },
      {
        label: "Title",
        onClick: () => onHeaderClick("title"),
        sortdirection: getSortDirectionFromParams("title", params.sort),
      },
      {
        label: "Sub Title",
        onClick: () => onHeaderClick("subTitle"),
        sortdirection: getSortDirectionFromParams("subTitle", params.sort),
      },
      {
        label: "Text",
        // no point to sort this column
      },
      {
        label: "Type",
        onClick: () => onHeaderClick("type"),
        sortdirection: getSortDirectionFromParams("type", params.sort),
      },
      {
        label: "Ordinal",
        onClick: () => onHeaderClick("ordinal"),
        sortdirection: getSortDirectionFromParams("ordinal", params.sort),
      },
    ],
    [onHeaderClick, params.sort],
  );

  // data table rows
  const rows = useMemo<DataTableRow[]>(
    () =>
      informativeContent.map((content) => {
        return {
          id: content.id,
          cells: [
            {
              content: <img src={content.imageSmall} alt="content" style={{ width: "8rem" }} />,
            },
            {
              content: content.title.EN,
            },
            {
              content: content.subTitle.EN,
            },
            {
              content: content.text.EN,
            },
            {
              content: sentenceCase(content.type),
            },
            {
              content: content.ordinal,
            },
          ],
          actions: [
            {
              label: "View details",
              authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.CLARITY_TEST_INFORMATIVE_CONTENT],
              onClick: (contentId) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "clarity-score",
                    page: "informative-content",
                    id: contentId,
                  }),
                }),
            },
            {
              label: "Edit",
              authorizedScopes: [
                UserScopeEnum.SUPERADMIN,
                UserScopeEnum.CLARITY_TEST_INFORMATIVE_CONTENT,
                UserScopeEnum.CLARITY_TEST_UPDATE_INFORMATIVE_CONTENT,
              ],
              onClick: (contentId) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "clarity-score",
                    page: "informative-content",
                    id: contentId,
                    modifier: "edit",
                  }),
                }),
            },
          ],
        };
      }),
    [navigate, informativeContent],
  );

  // data table bulk actions
  const bulkActions = useMemo<DataTableAction<string[]>[]>(() => [], []);

  // handle filters submit
  const onFilterSubmit = useCallback(
    (data: InformativeContentFilterData) => {
      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "clarity-score", page: "informative-content" }),
        search: getUrlSearchParamsString(data),
      });
    },
    [navigate],
  );

  // header buttons configuration
  const viewActions: Action[] = useMemo(
    () => [
      {
        label: "Create Informative Content",
        authorizedScopes: [
          UserScopeEnum.SUPERADMIN,
          UserScopeEnum.CLARITY_TEST_INFORMATIVE_CONTENT,
          UserScopeEnum.CLARITY_TEST_CREATE_INFORMATIVE_CONTENT,
        ],
        onClick: () => setShowInformativeContentModal(true),
      },
    ],
    [],
  );

  // handle errors
  if (error) {
    return <ErrorView title="Fetching informative content failed" error={error} />;
  }

  return (
    <>
      <View scrollable>
        <Column pad="half">
          <Title actions={viewActions}>Informative Content</Title>
        </Column>
        <Filter
          fields={filters}
          loading={loading}
          matchMode={params.matchMode}
          conditionMode={params.conditionMode}
          onSubmit={onFilterSubmit}
        />

        <DataTable
          headers={headers}
          rows={rows}
          loading={loading}
          bulkActions={bulkActions}
          openAuthorizedScopes={[UserScopeEnum.SUPERADMIN, UserScopeEnum.CLARITY_TEST_INFORMATIVE_CONTENT]}
          onOpen={(row) =>
            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                menu: "clarity-score",
                page: "informative-content",
                modifier: row.id,
              }),
            })
          }
        />
        <Pagination
          sticky
          pageCount={pageCount}
          currentPage={params.page}
          onPageChange={(page) =>
            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                menu: "clarity-score",
                page: "informative-content",
              }),
              search: getUrlSearchParamsString({ ...params, page }),
            })
          }
        />
      </View>
      <CreateInformativeContentModal
        open={showCreateInformativeContentModal}
        onClickOutside={() => setShowInformativeContentModal(false)}
        onCompleted={(data) => {
          // show success toast message
          setToast({
            type: ToastType.SUCCESS,
            title: "Created a new informative content",
            message: `Type ${sentenceCase(data.createInformativeContent.type)} informative content created`,
            isOpen: true,
          });

          // navigate to user details view
          navigate({
            pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
              menu: "clarity-score",
              page: "informative-content",
              id: data.createInformativeContent.id,
            }),
          });
        }}
      />
    </>
  );
};
