import React, { useMemo } from "react";
import { CgFeed, CgOptions, CgClipboard, CgImage } from "react-icons/cg";
import { GrUserAdmin } from "react-icons/gr";
import {
  MdMood,
  MdOutlineTopic,
  MdOutlinePoll,
  MdOutlineTranslate,
  MdOutlineCloudUpload,
  MdEmojiPeople,
  MdSettings,
  MdFactCheck,
} from "react-icons/md";
import { Navigate, Routes, Route, useLocation, Location } from "react-router-dom";
import { AppHeader } from "../../components/AppHeader/AppHeader";
import { Column } from "../../components/Column/Column";
import { isMainMenuItem, MainMenu, MainMenuItem, MainMenuItemInfo } from "../../components/MainMenu/MainMenu";
import { Row } from "../../components/Row/Row";
import { AdminViewParams, ADMIN_VIEW_PATH, ViewerInfo } from "../../routes";
import { UserScopeEnum } from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { AdminDetailsView } from "../AdminDetailsView/AdminDetailsView";
import { AdminFeedItemCreateView } from "../AdminFeedItemCreateView/AdminFeedItemCreateView";
import { AdminFeedItemDetailsView } from "../AdminFeedItemDetailsView/AdminFeedItemDetailsView";
import { AdminFeedItemListView } from "../AdminFeedItemListView/AdminFeedItemListView";
import { AdminFeedPollCreateView } from "../AdminFeedPollCreateView/AdminFeedPollCreateView";
import { AdminFeedPollDetailsView } from "../AdminFeedPollDetailsView/AdminFeedPollDetailsView";
import { AdminFeedPollListView } from "../AdminFeedPollListView/AdminFeedPollListView";
import { AdminFeedTopicDetailsView } from "../AdminFeedTopicDetailsView/AdminFeedTopicDetailsView";
import { AdminFeedTopicListView } from "../AdminFeedTopicListView/AdminFeedTopicListView";
import { AdminListView } from "../AdminListView/AdminListView";
import { ClarityTestConclusionImageDetailsView } from "../ClarityTestConclusionImageDetailsView/ClarityTestConclusionImageDetailsView";
import { ClarityTestConclusionImageListView } from "../ClarityTestConclusionImageListView/ClarityTestConclusionImageListView";
import { ConclusionOptionDetailsView } from "../ConclusionOptionDetailsView/ConclusionOptionDetailsView";
import { ConclusionOptionsListView } from "../ConclusionOptionListView/ConclusionOptionListView";
import { EventSettingDetailsView } from "../EventSettingsDetailsView/EventSettingDetailsView";
import { EventSettingsListView } from "../EventSettingsListView/EventSettingsListView";
import { FeedTestUserDetailsView } from "../FeedTestUserDetailsView/FeedTestUserDetailsView";
import { FeedTestUserListView } from "../FeedTestUsersListView/FeedTestUsersListView";
import { FeelingDetailsView } from "../FeelingDetailsView/FeelingDetailsView";
import { FeelingListView } from "../FeelingListView/FeelingListView";
import { FileUploadDetailsView } from "../FileUploadDetailsView/FileUploadDetailsView";
import { FileUploadListView } from "../FileUploadListView/FileUploadListView";
import { InformativeContentDetailsView } from "../InformativeContentDetailsView/InformativeContentDetailsView";
import { InformativeContentListView } from "../InformativeContentListView/InformativeContentListView";
import { NotFoundView } from "../NotFoundView/NotFoundView";
import { NotImplementedView } from "../NotImplementedView/NotImplementedView";
import { TestQuestionDetailsView } from "../TestQuestionDetailsView/TestQuestionDetailsView";
import { TestQuestionListView } from "../TestQuestionListView/TestQuestionListView";
import { TestQuestionOptionDetailView } from "../TestQuestionOptionDetailsView/TestQuestionOptionDetailsView";
import { TestQuestionOptionListView } from "../TestQuestionOptionListView/TestQuestionOptionListView";
import { UserFeedItemMetadataSettingDetailsView } from "../UserFeedItemMetadataSettingsDetailsView/UserFeedItemMetadataSettingView";
import { UserFeedItemMetadataSettingsListView } from "../UserFeedItemMetadataSettingsListView/UserFeedItemMetadataSettingsListView";
import styles from "./AdminView.module.scss";

export interface AdminViewProps {
  viewer: ViewerInfo;
}

export const AdminView: React.FC<AdminViewProps> = (props) => {
  const location = useLocation();

  // list of main menu items
  const menus: MainMenuItem<AdminViewProps>[] = useMemo(
    () => [
      {
        title: "Users",
      },
      {
        title: "Admins",
        icon: <GrUserAdmin />,
        url: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "users", page: "admins" }),
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMINS, UserScopeEnum.ADMINS_LIST],
        content: ({ viewer }) => (
          <Routes>
            <Route path="admins" element={<AdminListView viewer={viewer} />} />
            <Route path="admins/:id/*" element={<AdminDetailsView viewer={viewer} />} />
            <Route path="*" element={<NotFoundView />} />
          </Routes>
        ),
      },

      {
        title: "Admin feed",
      },
      {
        title: "Topics",
        icon: <MdOutlineTopic />,
        url: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "admin-feed", page: "topics" }),
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_FEED, UserScopeEnum.ADMIN_FEED_TOPIC_LIST],
        content: ({ viewer }) => (
          <Routes>
            <Route path="topics" element={<AdminFeedTopicListView viewer={viewer} />} />
            <Route path="topics/:id/*" element={<AdminFeedTopicDetailsView viewer={viewer} />} />
            <Route path="*" element={<NotFoundView />} />
          </Routes>
        ),
      },
      {
        title: "Items",
        icon: <CgFeed />,
        url: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "admin-feed", page: "items" }),
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_FEED, UserScopeEnum.ADMIN_FEED_ITEM_LIST],
        content: ({ viewer }) => (
          <Routes>
            <Route path="items" element={<AdminFeedItemListView viewer={viewer} />} />
            <Route path="items/new" element={<AdminFeedItemCreateView viewer={viewer} />} />
            <Route path="items/:id/*" element={<AdminFeedItemDetailsView viewer={viewer} />} />
            <Route path="*" element={<NotFoundView />} />
          </Routes>
        ),
      },
      {
        title: "Polls",
        icon: <MdOutlinePoll />,
        url: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "admin-feed", page: "polls" }),
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_FEED, UserScopeEnum.ADMIN_FEED_POLL_LIST],
        content: ({ viewer }) => (
          <Routes>
            <Route path="polls" element={<AdminFeedPollListView viewer={viewer} />} />
            <Route path="polls/new" element={<AdminFeedPollCreateView viewer={viewer} />} />
            <Route path="polls/:id/*" element={<AdminFeedPollDetailsView viewer={viewer} />} />
            <Route path="*" element={<NotFoundView />} />
          </Routes>
        ),
      },
      {
        title: "Test users",
        icon: <MdEmojiPeople />,
        url: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "admin-feed", page: "test-users" }),
        authorizedScopes: [UserScopeEnum.SUPERADMIN],
        content: ({ viewer }) => (
          <Routes>
            <Route path="test-users" element={<FeedTestUserListView viewer={viewer} />} />
            <Route path="test-users/:id/*" element={<FeedTestUserDetailsView viewer={viewer} />} />
          </Routes>
        ),
      },
      // {
      //   title: "Comments",
      //   icon: <MdOutlineComment />,
      //   url: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "admin-feed", page: "comments" }),
      //   authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_FEED, UserScopeEnum.ADMIN_FEED_COMMENT_LIST],
      // },

      {
        title: "User feed",
      },
      {
        title: "Settings",
        icon: <MdSettings />,
        url: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "user-feed", page: "settings" }),
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.SETTINGS],
        content: ({ viewer }) => (
          <Routes>
            <Route path="settings" element={<EventSettingsListView viewer={viewer} />} />
            <Route path="settings/:id/*" element={<EventSettingDetailsView viewer={viewer} />} />
            <Route path="*" element={<NotFoundView />} />
          </Routes>
        ),
      },
      {
        title: "Metadata",
        icon: <MdFactCheck />,
        url: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "user-feed", page: "metadata" }),
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.USER_FEED],
        content: ({ viewer }) => (
          <Routes>
            <Route path="metadata" element={<UserFeedItemMetadataSettingsListView viewer={viewer} />} />
            <Route path="metadata/:id/*" element={<UserFeedItemMetadataSettingDetailsView viewer={viewer} />} />
            <Route path="*" element={<NotFoundView />} />
          </Routes>
        ),
      },
      // {
      //   title: "Events",
      //   icon: <RiSignalTowerLine />,
      //   url: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "user-feed", page: "events" }),
      //   authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.USER_FEED, UserScopeEnum.USER_FEED_EVENT_LIST],
      //   content: ({ viewer }) => (
      //     <Routes>
      //       <Route path="events" element={<UserFeedEventListView viewer={viewer} />} />
      //       <Route path="events/:id/*" element={<UserFeedEventDetailsView viewer={viewer} />} />
      //       <Route path="*" element={<NotFoundView />} />
      //     </Routes>
      //   ),
      // },
      // {
      //   title: "Posts",
      //   icon: <CgUserList />,
      //   url: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "user-feed", page: "posts" }),
      //   // TODO: check scopes
      //   authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.USER_FEED, UserScopeEnum.USER_FEED_EVENT_LIST],
      // },

      {
        title: "Clarity score",
      },
      {
        title: "Feelings",
        icon: <MdMood />,
        url: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "clarity-score", page: "feelings" }),
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.FEELINGS, UserScopeEnum.FEELINGS_LIST],
        content: ({ viewer }) => (
          <Routes>
            <Route path="feelings" element={<FeelingListView viewer={viewer} />} />
            <Route path="feelings/:id/*" element={<FeelingDetailsView viewer={viewer} />} />
            <Route path="*" element={<NotFoundView />} />
          </Routes>
        ),
      },
      {
        title: "Test questions",
        icon: <CgOptions />,
        url: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "clarity-score", page: "questions" }),
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.CLARITY_TEST, UserScopeEnum.CLARITY_TEST_LIST],
        content: ({ viewer }) => (
          <Routes>
            <Route path="questions" element={<TestQuestionListView viewer={viewer} />} />
            <Route path="questions/:id/*" element={<TestQuestionDetailsView viewer={viewer} />} />
            <Route path="*" element={<NotFoundView />} />
          </Routes>
        ),
      },
      {
        title: "Question options",
        icon: <CgClipboard />,
        url: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "clarity-score", page: "question-options" }),
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.CLARITY_TEST, UserScopeEnum.CLARITY_TEST_LIST],
        content: ({ viewer }) => (
          <Routes>
            <Route path="question-options" element={<TestQuestionOptionListView viewer={viewer} />} />
            <Route path="question-options/:id/*" element={<TestQuestionOptionDetailView viewer={viewer} />} />
            <Route path="*" element={<NotFoundView />} />
          </Routes>
        ),
      },
      {
        title: "Conclusion",
        icon: <CgImage />,
        url: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "clarity-score", page: "conclusion-images" }),
        authorizedScopes: [
          UserScopeEnum.SUPERADMIN,
          UserScopeEnum.CLARITY_TEST,
          UserScopeEnum.CLARITY_TEST_CONCLUSION_IMAGE,
        ],
        content: ({ viewer }) => (
          <Routes>
            <Route path="conclusion-images" element={<ClarityTestConclusionImageListView viewer={viewer} />} />
            <Route path="conclusion-images/:id/*" element={<ClarityTestConclusionImageDetailsView viewer={viewer} />} />
            <Route path="*" element={<NotFoundView />} />
          </Routes>
        ),
      },
      {
        title: "Conclusion options",
        url: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "clarity-score", page: "conclusion-options" }),
        authorizedScopes: [
          UserScopeEnum.SUPERADMIN,
          UserScopeEnum.CLARITY_TEST,
          UserScopeEnum.CLARITY_TEST_CONCLUSION_OPTION,
        ],
        content: ({ viewer }) => (
          <Routes>
            <Route path="conclusion-options" element={<ConclusionOptionsListView viewer={viewer} />} />
            <Route path="conclusion-options/:id/*" element={<ConclusionOptionDetailsView viewer={viewer} />} />
            <Route path="*" element={<NotFoundView />} />
          </Routes>
        ),
      },
      {
        title: "Content",
        icon: <MdOutlineTranslate />,
        url: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "clarity-score", page: "informative-content" }),
        authorizedScopes: [UserScopeEnum.SUPERADMIN],
        content: ({ viewer }) => (
          <Routes>
            <Route path="informative-content" element={<InformativeContentListView viewer={viewer} />} />
            <Route path="informative-content/:id/*" element={<InformativeContentDetailsView viewer={viewer} />} />
            <Route path="*" element={<NotFoundView />} />
          </Routes>
        ),
      },

      {
        title: "Media",
      },
      {
        title: "File uploads",
        icon: <MdOutlineCloudUpload />,
        url: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "media", page: "uploads" }),
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.FILES],
        content: ({ viewer }) => (
          <Routes>
            <Route path="uploads" element={<FileUploadListView viewer={viewer} />} />
            <Route path="uploads/:id/*" element={<FileUploadDetailsView viewer={viewer} />} />
            <Route path="*" element={<NotFoundView />} />
          </Routes>
        ),
      },
    ],
    [],
  );

  // TODO: choose first default menu that the user has permissions for
  // resolve default and active menu items
  const defaultMenuItem = menus[1] as MainMenuItemInfo<AdminViewProps>;
  const activeMenuItem = getActiveMenuItem(menus, location);

  // redirect to default menu if none could by found by name
  if (!activeMenuItem) {
    return <Navigate to={defaultMenuItem.url} />;
  }

  // find active menu item index<
  const activeItemIndex = menus.indexOf(activeMenuItem);

  // get logged in user
  const { viewer } = props;

  return (
    <Column cover>
      <AppHeader viewer={viewer} />
      <Row expanded crossAxisAlignment="stretch">
        <Column overflow className={styles.menu}>
          <MainMenu expanded items={menus} activeItemIndex={activeItemIndex} />
        </Column>
        <Column expanded className={styles.content}>
          {activeMenuItem.content ? activeMenuItem.content(props) : <NotImplementedView />}
        </Column>
      </Row>
    </Column>
  );
};

function getActiveMenuItem<T>(menus: MainMenuItem<T>[], location: Location): MainMenuItemInfo<T> | undefined {
  const item = menus.find((menuItem) => {
    if (!isMainMenuItem(menuItem)) {
      return false;
    }

    return location.pathname.includes(menuItem.url);
  });

  if (isMainMenuItem(item)) {
    return item;
  }
}
