import React from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { GridBox } from "../../components/GridBox/GridBox";
import { View } from "../../components/View/View";
import { AdminViewParams } from "../../routes";
import { AdminByIdQueryResult, useAdminByIdQuery } from "../../schema";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";
import { AdminDetailsEdit } from "./AdminDetailsEdit";
import { AdminDetailsHeader } from "./AdminDetailsHeader";
import { AdminDetailsInfo } from "./AdminDetailsInfo";

export interface AdminDetailsProps extends AdminViewProps {
  user: NonNullable<AdminByIdQueryResult["data"]>["admin"];
}

export const AdminDetailsView: React.FC<AdminViewProps> = ({ viewer }) => {
  // get user identifier from url parameters
  const { id } = useParams<AdminViewParams>();

  // fetch user info
  const { data, loading, error } = useAdminByIdQuery({
    variables: {
      userId: id ?? "",
    },
  });

  // expecting the id parameter to be provided
  if (!id) {
    return <ErrorView error="Expected admin identifier is not provided" />;
  }

  // get user info
  const user = data?.admin;

  // handle error
  if (error) {
    return <ErrorView title="Loading admin info failed" error={error} />;
  }

  // handle loading
  if (loading || !user) {
    return <LoadingView />;
  }

  // render details
  return (
    <View pad="half">
      <AdminDetailsHeader user={user} viewer={viewer} />
      <GridBox />
      <Routes>
        <Route index element={<AdminDetailsInfo user={user} viewer={viewer} />} />
        <Route path="edit" element={<AdminDetailsEdit user={user} viewer={viewer} />} />
      </Routes>
    </View>
  );
};
