export function validateEmail(email: string) {
  const emailRegexp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const isValid = emailRegexp.test(email.toLowerCase());

  if (!isValid) {
    return "Invalid email";
  }

  return true;
}

export function validateEmailIfProvided(email: string) {
  if (email.length === 0) {
    return true;
  }

  return validateEmail(email);
}
