import React from "react";
import { useViewer } from "../../hooks/useViewer";
import { UserScopeEnum } from "../../schema";
import { hasAnyScopes } from "../../services/hasAnyScopes";
import { ButtonGroup, ButtonGroupButton, ButtonGroupButtonProps, ButtonGroupProps } from "../ButtonGroup/ButtonGroup";

// null is also a valid action (useful for conditionally building action array)
export type Action =
  | ({
      label: React.ReactNode;
      authorizedScopes?: UserScopeEnum[];
      onClick?(): void;
    } & ButtonGroupButtonProps)
  | null;

export interface ActionBarProps extends ButtonGroupProps {
  actions: Action[];
}

export const ActionBar: React.FC<ActionBarProps> = ({ actions, secondary, ...rest }) => {
  // get viewer scopes
  const viewerScopes = useViewer()?.scopes;

  if (actions.length === 0) {
    return null;
  }

  const validActions = actions.filter((item) => {
    // skip null actions
    if (item == null) {
      return false;
    }

    // throw if authorizedScopes is set but not viewerScopes
    if (item.authorizedScopes !== undefined && viewerScopes === undefined) {
      throw new Error(
        `Action bar item "${item.label}" has authorizedScopes of ${item.authorizedScopes.join(
          ", ",
        )} but viewerScopes is not set, this is a programmer mistake`,
      );
    }

    if (item.authorizedScopes !== undefined && viewerScopes !== undefined) {
      return hasAnyScopes(item.authorizedScopes, viewerScopes);
    }

    return true;
  });

  // don't render the button group if there are no valid actions
  if (validActions.length === 0) {
    return null;
  }

  return (
    <ButtonGroup secondary={secondary} {...rest}>
      {validActions.map((action, index) => {
        if (action == null) {
          return null;
        }

        const { label, onClick, authorizedScopes: _authorizedScopes, ...buttonRest } = action;

        return (
          <ButtonGroupButton key={index} secondary={secondary} onClick={onClick} {...buttonRest}>
            {label}
          </ButtonGroupButton>
        );
      })}
    </ButtonGroup>
  );
};
