import MarkdownEditor, { MDEditorProps } from "@uiw/react-md-editor";
import classNames from "classnames";
import React, { useState, useEffect } from "react";
import { FieldError, FieldErrors, Merge } from "react-hook-form";
import { Flex } from "../Flex/Flex";
import { P } from "../Paragraph/Paragraph";
import { Row } from "../Row/Row";
import styles from "./MarkdownField.module.scss";

export interface MarkdownFieldProps extends MDEditorProps {
  name: string;
  label?: string;
  defaultValue?: string;
  error?: FieldError | Merge<FieldError, FieldErrors<any>>;
  maxCharacters?: number;
  onClear?(): void;
}

export const MarkdownField: React.FC<MarkdownFieldProps> = ({
  name,
  label,
  defaultValue,
  error,
  maxCharacters,
  onClear: _onClear, // TODO: implement clearing?
  ...rest
}) => {
  const [hasValue, setHasValue] = useState(typeof defaultValue === "string" && defaultValue.length > 0);
  const [hasFocus, setHasFocus] = useState(false);
  const [charactersRemaining, setCharactersRemaining] = useState(maxCharacters ?? 0);
  const [value, setValue] = useState<string>();

  useEffect(() => {
    if (maxCharacters) {
      const defaultValueLength = defaultValue ? defaultValue.length : 0;

      setCharactersRemaining(maxCharacters - defaultValueLength);
    }
  }, [maxCharacters, defaultValue]);

  const handleValueChange = (value: string) => {
    const hasValueNow = value.length > 0;

    // only update state if having value has changed
    if (hasValueNow !== hasValue) {
      setHasValue(hasValueNow);
    }

    if (maxCharacters && maxCharacters >= value.length) {
      setCharactersRemaining(maxCharacters - value.length);
      setValue(value);
    }
  };

  return (
    <Flex
      column
      className={classNames({
        [styles["markdown-field--has-value"]]: hasValue,
        [styles["markdown-field--has-focus"]]: hasFocus,
        [styles["markdown-field--has-error"]]: error !== undefined,
      })}
    >
      <label htmlFor={name} className={styles.label}>
        {label}
      </label>
      <MarkdownEditor
        value={value}
        defaultValue={defaultValue}
        onChange={(value) => handleValueChange(value ?? "")}
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
        height={300}
        {...rest}
      />
      <Row mainAxisAlignment="space-between">
        <P inline tiny className={styles.error}>
          {error && error.message}
        </P>
        {maxCharacters && <P>{charactersRemaining.toString()}</P>}
      </Row>
    </Flex>
  );
};
