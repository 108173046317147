import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { MdClose, MdErrorOutline, MdOutlineWarningAmber, MdOutlineCheck } from "react-icons/md";
import { atom, useRecoilState } from "recoil";
import { useOnAnimationEnd } from "../../hooks/useOnAnimationEnd";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { Column } from "../Column/Column";
import { Flex } from "../Flex/Flex";
import { P } from "../Paragraph/Paragraph";
import styles from "./Toast.module.scss";

export enum ToastType {
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
  ERROR = "ERROR",
}

export interface ToastInfo {
  type: ToastType;
  title?: String;
  message: String;
  isOpen: boolean;
}

export const toastState = atom<ToastInfo>({
  key: "showToast",
  default: {
    type: ToastType.SUCCESS,
    message: "Operation successful",
    isOpen: false,
  },
});

export const Toast: React.FC = () => {
  const toastRef = useRef<HTMLDivElement>(null);
  const [toastInfo, setToastInfo] = useRecoilState(toastState);
  const [wasOpen, setWasOpen] = useState(toastInfo.isOpen);
  const [isClosed, setIsClosed] = useState(!toastInfo.isOpen);

  // set was open if the dropdown was ever opened
  useEffect(() => {
    if (toastInfo.isOpen && !wasOpen) {
      setWasOpen(true);
    }

    if (toastInfo.isOpen) {
      setIsClosed(false);
    }
  }, [toastInfo.isOpen, wasOpen]);

  // handle animation ending
  useOnAnimationEnd(toastRef, () => {
    if (!toastInfo.isOpen && !isClosed) {
      setIsClosed(true);
    }
  });

  // close toast when clicked outside
  useOnClickOutside(toastRef, () => {
    if (!toastInfo.isOpen) {
      return;
    }

    setToastInfo({ ...toastInfo, isOpen: false });
  });

  // automatically close after a timeout
  useEffect(() => {
    if (!toastInfo.isOpen) {
      return;
    }

    const timeout = setTimeout(() => setToastInfo({ ...toastInfo, isOpen: false }), 10000);

    return () => {
      clearTimeout(timeout);
    };
  }, [setToastInfo, toastInfo, toastInfo.isOpen]);

  const isClosing = !toastInfo.isOpen && wasOpen;

  return (
    <Flex
      row
      ref={toastRef}
      className={classNames(styles.toast, {
        [styles["toast--success"]]: toastInfo.type === ToastType.SUCCESS,
        [styles["toast--warning"]]: toastInfo.type === ToastType.WARNING,
        [styles["toast--error"]]: toastInfo.type === ToastType.ERROR,
        [styles["toast--open"]]: toastInfo.isOpen,
        [styles["toast--closing"]]: isClosing,
        [styles["toast--closed"]]: isClosed,
      })}
      onClick={() => setToastInfo({ ...toastInfo, isOpen: false })}
      title="Click to dismiss"
    >
      {getToastIconByType(toastInfo.type)}
      <Column expanded>
        <P compact>{toastInfo.title ?? getDefaultToastTitleByType(toastInfo.type)}</P>
        <P small compact>
          {toastInfo.message}
        </P>
      </Column>
      <MdClose className={styles["close-icon"]} />
    </Flex>
  );
};

function getDefaultToastTitleByType(type: ToastType) {
  switch (type) {
    case ToastType.SUCCESS:
      return "Success";

    case ToastType.WARNING:
      return "Warning";

    case ToastType.ERROR:
      return "Error";
  }
}

function getToastIconByType(type: ToastType) {
  switch (type) {
    case ToastType.SUCCESS:
      return <MdOutlineCheck className={styles["status-icon"]} />;

    case ToastType.WARNING:
      return <MdOutlineWarningAmber className={styles["status-icon"]} />;

    case ToastType.ERROR:
      return <MdErrorOutline className={styles["status-icon"]} />;
  }
}
