import classNames from "classnames";
import React, { useState } from "react";
import { MdExpandMore, MdPassword } from "react-icons/md";
import { MdOutlineLogout } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useLogoutMutation } from "../../schema";
import { Avatar } from "../Avatar/Avatar";
import { Column } from "../Column/Column";
import { Container } from "../Container/Container";
import { DropdownMenu, DropdownItem } from "../DropdownMenu/DropdownMenu";
import { FlexProps } from "../Flex/Flex";
import { Loading } from "../Loading/Loading";
import { P } from "../Paragraph/Paragraph";
import { Row } from "../Row/Row";
import styles from "./ViewerMenu.module.scss";

export interface ViewerMenuProps extends FlexProps {
  viewer: {
    email: string;
    name: string;
    avatarUrl?: string | null;
  };
}

export const ViewerMenu: React.FC<ViewerMenuProps> = ({ viewer, ...rest }) => {
  const navigate = useNavigate();

  const [isOptionsDropdownOpen, setIsOptionsDropdownOpen] = useState(false);

  const [logout, logoutResult] = useLogoutMutation({
    refetchQueries: ["Viewer"],
    awaitRefetchQueries: true,
  });

  const handleLogout = () => {
    logout({
      onCompleted: () => {
        navigate({
          pathname: "/login",
        });
      },
    });
    localStorage.removeItem("jwt");
  };

  return (
    <Row overflow relative crossAxisAlignment="center" {...rest}>
      <Avatar small image={viewer.avatarUrl} />
      <Column padLeft={2} mainAxisAlignment="center">
        <P compact small darker>
          {viewer.name}
        </P>
        <P compact tiny>
          {viewer.email}
        </P>
      </Column>
      <Container
        title="Options"
        className={classNames(styles.options, { [styles["options--open"]]: isOptionsDropdownOpen })}
        onClick={() => setIsOptionsDropdownOpen(!isOptionsDropdownOpen)}
      >
        <MdExpandMore />
      </Container>
      <DropdownMenu
        right={1}
        top={8}
        open={isOptionsDropdownOpen}
        onClickOutside={() => setIsOptionsDropdownOpen(false)}
      >
        <DropdownItem
          trailing={<MdPassword />}
          onClick={() =>
            navigate({
              pathname: "/change-password",
            })
          }
        >
          Change password
        </DropdownItem>
        <DropdownItem trailing={logoutResult.loading ? <Loading small /> : <MdOutlineLogout />} onClick={handleLogout}>
          Logout
        </DropdownItem>
      </DropdownMenu>
    </Row>
  );
};
