import React, { useState, useRef } from "react";
import { FieldValues, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { BlockButton } from "../../components/BlockButton/BlockButton";
import { Column } from "../../components/Column/Column";
import { Container } from "../../components/Container/Container";
import { Flex } from "../../components/Flex/Flex";
import { Form } from "../../components/Form/Form";
import { FormField, FormFieldOptions } from "../../components/GeneratedForm/GeneratedForm";
import { TextButton } from "../../components/TextButton/TextButton";
import { Title } from "../../components/Title/Title";
import { toastState, ToastType } from "../../components/Toast/Toast";
import { View } from "../../components/View/View";
import { TranslationsMap } from "../../constants";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import {
  AdminFeedItemAudienceEnum,
  AdminFeedTopic,
  useAdminFeedTopicsQuery,
  useCreateAdminFeedPollMutation,
} from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { generateRandomString } from "../../services/generateRandomString";
import formOptions from "../../services/getEnumFormOptions";
import { getFileUploadById } from "../../services/getFileUploadById";
import { getTranslationInput } from "../../services/getTranslationInput";
import { validateNumeric } from "../../validators/validateNumeric";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";

interface CreateAdminFeedItemData extends FieldValues {
  question: TranslationsMap;
  audience: AdminFeedItemAudienceEnum[];
  isPinned: "true" | "false";
  isRecommended: "true" | "false";
  startDate: string | null;
  endDate: string | null;
  startDay: string;
  endDay: string;
  likeCount: string;
  shareCount: string;
  version: string;
  topicIds: string[];
}

const initialOptionsFormFields: FormFieldOptions[] = [];

export const AdminFeedPollCreateView: React.FC<AdminViewProps> = () => {
  const navigate = useNavigate();
  const setToast = useSetRecoilState(toastState);
  const [optionIds, setOptionIds] = useState<string[]>([]);
  const [fields, setFields] = useState<FormFieldOptions[]>(initialOptionsFormFields);
  const formRef = useRef<HTMLFormElement>(null);
  const [createAdminFeedPoll, _createAdminFeedPollResult] = useCreateAdminFeedPollMutation({
    refetchQueries: ["AdminFeedPolls"],
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      // show success toast message
      setToast({
        type: ToastType.SUCCESS,
        title: "Created a new admin feed poll",
        message: `Admin feed poll was created`,
        isOpen: true,
      });

      // navigate to item details view
      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
          menu: "admin-feed",
          page: "polls",
          id: data.createAdminFeedPoll.id,
        }),
      });
    },
  });

  const formMethods = useForm<CreateAdminFeedItemData>();

  const { data, loading, error } = useAdminFeedTopicsQuery();

  const { handleSubmit } = formMethods;

  const onSubmit: SubmitHandler<CreateAdminFeedItemData> = (data) => {
    // collect poll answer options to an array
    const options = optionIds.map((id) => ({
      answer: getTranslationInput(id, data),
    }));

    createAdminFeedPoll({
      variables: {
        question: getTranslationInput("question", { ...data }),
        audience: data.audience,
        isPinned: data.isPinned === "true",
        isRecommended: data.isRecommended === "true",
        likeCount: parseInt(data.likeCount),
        shareCount: parseInt(data.shareCount),
        startDate: data.startDate,
        endDate: data.endDate,
        startDay: parseInt(data.startDay),
        endDay: parseInt(data.endDay),
        version: parseInt(data.version),
        topicIds: data.topicIds,
        options: options.map((option, index) => ({ ...option, ordinal: index + 1 })),
        image: getFileUploadById("image"),
      },
    });
  };

  const onAddNewOption = () => {
    setFields(() => {
      const optionId = generateRandomString(5);

      // keep track of dynamic poll option ids
      setOptionIds((currentOptionIds) => [...currentOptionIds, optionId]);

      const optionIndex = optionIds.length;

      return [
        ...fields,
        {
          field: "translation",
          name: optionId,
          label: `Option ${optionIndex + 1}`,
          rules: {
            required: "Please provide poll option",
          },
          defaultValue: { EN: "Poll option", ET: "Küsimuse vastusevariant" },
        } as FormFieldOptions,
      ];
    });
  };

  const onRemoveOption = (optionId: string) => {
    setFields(() => {
      // remove given field ID
      setOptionIds((currentOptionIds) => [...currentOptionIds].filter((option) => option !== optionId));

      // remove field and remap field labels
      return [
        ...fields
          .filter((field) => field.name !== optionId)
          .map((field, index) => {
            field.label = `Option ${index + 1}`;
            return field;
          }),
      ];
    });
  };

  if (loading) {
    return <LoadingView />;
  }

  // handle errors
  if (error || !data?.adminFeedTopics || data?.adminFeedTopics.length === 0) {
    return (
      <ErrorView
        title="Fetching admin feed topics failed"
        error={error || "No topics were found for creating admin feed poll"}
      />
    );
  }

  return (
    <View pad="half" scrollable>
      <Title>Create new Poll item</Title>
      <FormProvider {...formMethods}>
        <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <Flex overflow row={false} expanded={false}>
            <h3>Poll feed item data</h3>
            {getFormFields(data?.adminFeedTopics).map((field) => (
              <Container overflow key={field.name} expanded>
                <FormField field={field} />
              </Container>
            ))}

            <h3>Options</h3>

            {fields.map((field, index) => (
              <Container overflow expanded key={field.name}>
                <FormField field={field} />
                <BlockButton inline secondary small onClick={() => onRemoveOption(field.name)}>
                  Remove option {index + 1}
                </BlockButton>
              </Container>
            ))}

            <TextButton onClick={onAddNewOption}>Add new option</TextButton>
          </Flex>

          <Column marginTop="half" crossAxisAlignment="flex-end">
            <BlockButton inline tertiary loading={false} type="submit">
              Create new item
            </BlockButton>
          </Column>
        </Form>
      </FormProvider>
    </View>
  );
};

const getFormFields = (adminFeedTopics: AdminFeedTopic[]) => {
  return [
    {
      field: "translation",
      name: "question",
      label: "Question",
      rules: {
        required: "Please provide question",
      },
      defaultValue: { EN: "Poll title", ET: "Küsimus" },
    },
    {
      field: "upload",
      type: "text",
      name: "image",
      label: "Image",
      rules: { required: "Please upload a picture for the Poll" },
    },
    {
      field: "checkbox",
      name: "audience",
      label: "Audience who will see this item",
      options: formOptions.fromEnum(AdminFeedItemAudienceEnum),
      rules: { required: "Please choose at least one audience type" },
    },
    {
      field: "radio",
      name: "isPinned",
      label: "Is pinned to the top",
      options: [
        { value: "true", label: "Yes" },
        { value: "false", label: "No" },
      ],
      rules: { required: "Please choose whether the item is pinned to the top" },
      defaultValue: "false",
    },
    {
      field: "radio",
      name: "isRecommended",
      label: "Is feed item recommended",
      options: [
        { value: "true", label: "Yes" },
        { value: "false", label: "No" },
      ],
      rules: { required: "Please choose whether the item is recommended" },
      defaultValue: "false",
    },
    {
      field: "date",
      name: "startDate",
      label: "Show item from given date",
      defaultValue: new Date(),
    },
    {
      field: "date",
      name: "endDate",
      label: "Show item until given date (leave empty to not use)",
    },
    {
      field: "text",
      type: "text",
      name: "startDay",
      label: "Show item starting from given days after registration (0 for first day, empty to not use)",
      rules: {
        validate: validateNumeric({
          min: 0,
          optional: true,
          message: "Expected positive start day number",
        }),
      },
    },
    {
      field: "text",
      type: "text",
      name: "endDay",
      label: "Show item starting until given days after registration (leave empty to not use)",
      rules: {
        validate: validateNumeric({
          min: 0,
          optional: true,
          message: "Expected positive end day number",
        }),
      },
    },
    {
      field: "text",
      type: "number",
      name: "likeCount",
      label: "Initial like count",
      rules: {
        validate: validateNumeric({
          min: 0,
          optional: true,
          message: "Expected positive like count",
        }),
      },
      defaultValue: "0",
    },
    {
      field: "text",
      type: "number",
      name: "shareCount",
      label: "Initial share count",
      rules: {
        validate: validateNumeric({
          min: 0,
          optional: true,
          message: "Expected positive share count",
        }),
      },
      defaultValue: "0",
    },
    {
      field: "text",
      type: "text",
      name: "version",
      label: "Metadata version",
      rules: { required: "Please provide metadata version" },
      defaultValue: "1",
    },
    {
      field: "checkbox",
      name: "topicIds",
      label: "Admin feed item topics",
      options: adminFeedTopics.map((topic) => ({ value: topic.id, label: topic.name })),
      rules: { required: "Please choose at least one topic" },
    },
  ] as FormFieldOptions[];
};
