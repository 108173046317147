import React, { useMemo } from "react";
import { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { WithCollaboration } from "../../components/Collaboration/Collaboration";
import { FormFieldOptions, GeneratedForm } from "../../components/GeneratedForm/GeneratedForm";
import { toastState, ToastType } from "../../components/Toast/Toast";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import {
  CollaborationTypeEnum,
  LocaleEnum,
  useUpdateAdminInfoMutation,
  UserRoleEnum,
  UserStatusEnum,
} from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getEnumFormOptions } from "../../services/getEnumFormOptions";
import { validateEmail } from "../../validators/validateEmail";
import { AdminDetailsProps } from "./AdminDetailsView";

interface EditAdminFormData {
  email: string;
  name: string;
  status: UserStatusEnum;
  locale: LocaleEnum;
  isBabyBorn: "true" | "false";
  isBabyLost: "true" | "false";
  roles: UserRoleEnum[];
  dueDate: Date | null;
  babyBirthdayDate: Date | null;
  comment: string;
}

export const AdminDetailsEdit: React.FC<AdminDetailsProps> = ({ user, viewer }) => {
  const navigate = useNavigate();
  const setToast = useSetRecoilState(toastState);
  const [updateAdminInfo, updateAdminInfoResult] = useUpdateAdminInfoMutation({
    refetchQueries: ["CollaborationById"],
    awaitRefetchQueries: true,
    onCompleted: ({ updateAdminInfo }) => {
      setToast({
        type: ToastType.SUCCESS,
        title: "User info has been updated",
        message: `Updated admin user "${updateAdminInfo.name}" details.`,
        isOpen: true,
      });

      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
          menu: "users",
          page: "admins",
          id: user.id,
        }),
      });
    },
  });

  // form configuration
  const fields = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "text",
        type: "text",
        name: "email",
        label: "Email",
        rules: {
          required: "Please provide email address",
          validate: validateEmail,
        },
        clearable: true,
        defaultValue: user.email,
      },
      {
        field: "text",
        type: "text",
        name: "name",
        label: "Name",
        rules: {
          required: "Please provide name",
        },
        defaultValue: user.name,
      },
      {
        field: "radio",
        name: "status",
        label: "Status",
        options: getEnumFormOptions(UserStatusEnum),
        rules: {
          required: "Please choose status",
        },
        defaultValue: user.status,
      },
      {
        field: "checkbox",
        name: "roles",
        label: "Roles",
        options: getEnumFormOptions(UserRoleEnum),
        rules: {
          required: "Please choose at least one role",
        },
        defaultValue: user.roles,
      },
      {
        field: "textarea",
        type: "text",
        name: "comment",
        label: "Collaboration comment",
        rules: {
          required: "Please describe what and why was updated",
        },
        defaultValue: "Updated user info",
      },
    ],
    [user.email, user.name, user.roles, user.status],
  );

  const onSubmit: SubmitHandler<EditAdminFormData> = async (data) =>
    updateAdminInfo({
      variables: {
        ...data,
        userId: user.id,
      },
    });

  return (
    <WithCollaboration referenceId={user.id} type={CollaborationTypeEnum.USER} viewer={viewer}>
      <GeneratedForm
        title="Profile"
        error={updateAdminInfoResult.error}
        loading={updateAdminInfoResult.loading}
        submitText="Update"
        onSubmit={onSubmit}
      >
        <GeneratedForm.Fields fields={fields} />
      </GeneratedForm>
    </WithCollaboration>
  );
};
