import React, { useMemo } from "react";
import { SubmitHandler } from "react-hook-form";
import { FormFieldOptions, GeneratedForm } from "../../components/GeneratedForm/GeneratedForm";
import { Modal, ModalProps } from "../../components/Modal/Modal";
import { useCreateFeedTestUserMutation, CreateFeedTestUserMutation } from "../../schema";
import { validateNumeric } from "../../validators/validateNumeric";

export interface CreateFeedTestUserModalProps extends ModalProps {
  onCompleted: (data: CreateFeedTestUserMutation) => void;
}

interface CreateFeedTestUserData {
  userId: string;
}

export const CreateFeedTestUserModal: React.FC<CreateFeedTestUserModalProps> = ({
  onCompleted,
  onClickOutside,
  ...rest
}) => {
  const [createFeedTestUserModal, createFeedTestUserModalResult] = useCreateFeedTestUserMutation({
    refetchQueries: ["CollaborationById", "FeedTestUsers"],
    awaitRefetchQueries: true,
    onCompleted,
  });

  const fields = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "text",
        name: "userId",
        label: "User ID",
        rules: {
          required: "Please provide test user ID",
          validate: validateNumeric(),
        },
      },
    ],
    [],
  );

  const onSubmit: SubmitHandler<CreateFeedTestUserData> = (data) => {
    const userId = data.userId ? Number.parseInt(data.userId, 10) : undefined;

    if (!userId) {
      // this should not happen, fields should block user from entering NaN values
      return;
    }

    createFeedTestUserModal({
      variables: {
        userId,
      },
    });
  };

  return (
    <Modal
      title={"Create new feed test user"}
      error={createFeedTestUserModalResult.error}
      onClickOutside={onClickOutside}
      {...rest}
    >
      <GeneratedForm
        loading={createFeedTestUserModalResult.loading}
        error={createFeedTestUserModalResult.error}
        onSubmit={onSubmit}
      >
        <GeneratedForm.Fields fields={fields} />
      </GeneratedForm>
    </Modal>
  );
};
