import { sentenceCase } from "change-case";
import React from "react";
import { WithCollaboration } from "../../components/Collaboration/Collaboration";
import { NameValueList, NameValueListItem } from "../../components/NameValuePair/NameValuePair";
import { ScoreModifiers } from "../../components/ScoreModifier/ScoreModifier";
import { CollaborationTypeEnum, FeelingInfoFragment } from "../../schema";
import { renderTranslationMap } from "../../services/renderTranslationMap";
import { FeelingDetailsProps } from "./FeelingDetailsView";

export const FeelingDetailsInfo: React.FC<FeelingDetailsProps> = ({ feeling, viewer }) => (
  <WithCollaboration referenceId={feeling.id} type={CollaborationTypeEnum.USER} viewer={viewer}>
    <NameValueList items={getFeelingNameValueList(feeling)} />
  </WithCollaboration>
);

export function getFeelingNameValueList(feeling: FeelingInfoFragment): NameValueListItem[] {
  return [
    {
      name: "Feeling unique id",
      value: feeling.id,
    },
    {
      name: "Type",
      value: sentenceCase(feeling.type),
    },
    {
      name: "Name",
      value: renderTranslationMap(feeling.nameTranslations),
    },
    {
      name: "Modifiers",
      value: <ScoreModifiers feeling={feeling} />,
    },
    {
      name: "Happyness score modifier percentage",
      value: `${feeling.scoreModifierHappiness > 0 ? "+" : ""}${feeling.scoreModifierHappiness}%`,
    },
    {
      name: "Positivity score modifier percentage",
      value: `${feeling.scoreModifierPositivity > 0 ? "+" : ""}${feeling.scoreModifierPositivity}%`,
    },
    {
      name: "Energy score modifier percentage",
      value: `${feeling.scoreModifierEnergy > 0 ? "+" : ""}${feeling.scoreModifierEnergy}%`,
    },
    {
      name: "Sleep quality score modifier percentage",
      value: `${feeling.scoreModifierSleepQuality > 0 ? "+" : ""}${feeling.scoreModifierSleepQuality}%`,
    },
    {
      name: "Mental power score modifier percentage",
      value: `${feeling.scoreModifierMentalPower > 0 ? "+" : ""}${feeling.scoreModifierMentalPower}%`,
    },
    {
      name: "Confidence score modifier percentage",
      value: `${feeling.scoreModifierConfidence > 0 ? "+" : ""}${feeling.scoreModifierConfidence}%`,
    },

    {
      name: "Gratitude score modifier percentage",
      value: `${feeling.scoreModifierGratitude > 0 ? "+" : ""}${feeling.scoreModifierGratitude}%`,
    },
    {
      name: "Attraction score modifier percentage",
      value: `${feeling.scoreModifierAttraction > 0 ? "+" : ""}${feeling.scoreModifierAttraction}%`,
    },
    {
      name: "Created",
      value: feeling.createdDate,
    },
    {
      name: "Updated",
      value: feeling.updatedDate,
    },
  ];
}
