import React from "react";
import { BlockButton } from "../../components/BlockButton/BlockButton";
import { Column } from "../../components/Column/Column";
import { Debug } from "../../components/Debug/Debug";
import { Expanded } from "../../components/Expanded/Expanded";
import { GridBox } from "../../components/GridBox/GridBox";
import { P } from "../../components/Paragraph/Paragraph";
import { TextButton } from "../../components/TextButton/TextButton";
import { View } from "../../components/View/View";
import { config } from "../../config";
import { getFieldErrors } from "../../services/getFieldErrors";
import { isAuthorizationError } from "../../services/isAuthorizationErrors";
import { isServerDownError } from "../../services/isServerDownError";
import { NotAuthorizedView } from "../NotAuthorizedView/NotAuthorizedView";
import { ServerDownView } from "../ServerDownView/ServerDownView";
import styles from "./ErrorView.module.scss";

export interface ErrorViewProps {
  error: Error | string;
  title?: string;
}

export const ErrorView: React.FC<ErrorViewProps> = ({ error, title }) => {
  const message =
    error instanceof Error
      ? config.debug
        ? error.message
        : "System error occured, we're working on the issue. Please try again later"
      : error;

  const fieldError = error instanceof Error ? getFieldErrors(error) : {};
  const containsValidationErrors = Object.keys(fieldError).length > 0;

  if (isServerDownError(error)) {
    return <ServerDownView error={error} />;
  }

  if (isAuthorizationError(error)) {
    return <NotAuthorizedView />;
  }

  return (
    <View scrollable>
      <Column pad expanded className={styles["error-view"]}>
        <GridBox />
        <P darker>{title || containsValidationErrors ? "Validation failed" : "Oops, something went wrong!"}</P>
        <GridBox />

        {containsValidationErrors ? (
          <Column>
            {Object.keys(fieldError).map((fieldName, index) => (
              <React.Fragment key={index}>
                <P small strong>
                  {fieldName}
                </P>
                <P small>{fieldError[fieldName]?.message}</P>
              </React.Fragment>
            ))}
          </Column>
        ) : config.debug ? (
          <Debug title={message}>{error}</Debug>
        ) : (
          <>
            <P center small className={styles.message}>
              {message}
            </P>
            <GridBox />
            <P center small>
              The issue has been logged and our team is working on solving the problem, please try again later
            </P>
          </>
        )}

        <GridBox />
        <Expanded />
        <GridBox />
        <BlockButton inline onClick={() => (window.location.href = "/")}>
          Start over
        </BlockButton>
        <GridBox half />
        <TextButton onClick={() => window.location.reload()}>Attempt to reload</TextButton>
      </Column>
    </View>
  );
};
