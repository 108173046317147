import React, { useMemo } from "react";
import { Modal, ModalProps, ModalAction } from "../../components/Modal/Modal";
import { P } from "../../components/Paragraph/Paragraph";
import { LoginAsAdminMutation, useLoginAsAdminMutation } from "../../schema";

export interface LoginAsUserInfo {
  id: string;
  name: string;
}

export interface LoginAsUserModalProps extends ModalProps {
  user: LoginAsUserInfo;
  onCompleted: (data: LoginAsAdminMutation) => void;
}

export const LoginAsUserModal: React.FC<LoginAsUserModalProps> = ({ user, onCompleted, onClickOutside, ...rest }) => {
  const [loginAsAdmin, loginAsAdminResult] = useLoginAsAdminMutation({
    onCompleted,
  });

  const actions = useMemo<ModalAction[]>(
    () => [
      {
        label: "Cancel",
        onClick: onClickOutside,
      },
      {
        label: "Login as user",
        danger: true,
        loading: loginAsAdminResult.loading,
        onClick: () =>
          loginAsAdmin({
            variables: {
              userId: user.id,
            },
          }),
      },
    ],
    [loginAsAdmin, loginAsAdminResult.loading, onClickOutside, user.id],
  );

  return (
    <Modal
      title="Confirm login as user"
      error={loginAsAdminResult.error}
      actions={actions}
      onClickOutside={onClickOutside}
      {...rest}
    >
      <P center>
        Are you sure you want to login as <strong>{user.name}</strong>?
      </P>
      <P center small padTop="half">
        You will be logged out from your current account and logged in as requested user.
      </P>
    </Modal>
  );
};
