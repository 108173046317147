import React from "react";
import { WithCollaboration } from "../../components/Collaboration/Collaboration";
import { NameValueList, NameValueListItem } from "../../components/NameValuePair/NameValuePair";
import { CollaborationTypeEnum, ClarityTestConclusionImageInfoFragment } from "../../schema";
import { ClarityTestConclusionImageDetailsProps } from "./ClarityTestConclusionImageDetailsView";

export const ClarityTestConclusionImageDetailsInfo: React.FC<ClarityTestConclusionImageDetailsProps> = ({
  clarityTestConclusionImage,
  viewer,
}) => (
  <WithCollaboration
    referenceId={clarityTestConclusionImage.id}
    type={CollaborationTypeEnum.CLARITY_TEST_CONCLUSION_IMAGE}
    viewer={viewer}
  >
    <NameValueList items={getAdminFeedItemNameValueList(clarityTestConclusionImage)} />
  </WithCollaboration>
);

export function getAdminFeedItemNameValueList(
  clarityTestConclusionImage: ClarityTestConclusionImageInfoFragment,
): NameValueListItem[] {
  return [
    {
      name: "Unique id",
      value: clarityTestConclusionImage.id,
    },
    {
      name: "Min score",
      value: clarityTestConclusionImage.minScoreValue,
    },
    {
      name: "Max score",
      value: clarityTestConclusionImage.maxScoreValue,
    },
    {
      name: "Topic",
      value: clarityTestConclusionImage.topic,
    },
    {
      name: "Image",
      value: clarityTestConclusionImage.imageLarge ? (
        <img src={clarityTestConclusionImage.imageLarge} alt="Conclusion" style={{ width: "50rem" }} />
      ) : (
        "n/a"
      ),
    },
  ];
}
