import { sentenceCase } from "change-case";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Action } from "../../components/ActionBar/ActionBar";
import { Header } from "../../components/Header/Header";
import { Title } from "../../components/Title/Title";
import { useAdminUrlInfo } from "../../hooks/useAdminUrlInfo";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import { UserScopeEnum } from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { UserFeedItemMetadataSettingDetailsProps } from "./UserFeedItemMetadataSettingView";

export const UserFeedItemMetadataSettingDetailsHeader: React.FC<UserFeedItemMetadataSettingDetailsProps> = ({
  userFeedItemMetadataSetting,
  ...rest
}) => {
  const navigate = useNavigate();
  const { modifier } = useAdminUrlInfo();

  const isEditMode = modifier === "edit";

  const actions: Action[] = useMemo(
    () => [
      isEditMode
        ? {
            label: "Details",
            onClick: () =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "user-feed",
                  page: "metadata",
                  id: userFeedItemMetadataSetting.id,
                }),
              }),
          }
        : {
            label: "Edit",
            authorizedScopes: [
              UserScopeEnum.SUPERADMIN,
              UserScopeEnum.USER_FEED_ITEM_METADATA_INFO,
              UserScopeEnum.USER_FEED_ITEM_METADATA_UPDATE,
            ],
            onClick: () =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "user-feed",
                  page: "metadata",
                  id: userFeedItemMetadataSetting.id,
                  modifier: "edit",
                }),
              }),
          },
    ],
    [isEditMode, navigate, userFeedItemMetadataSetting.id],
  );

  const navigation: Action[] = useMemo(() => [], []);

  return (
    <Header actions={actions} navigation={navigation} {...rest}>
      <Title>{sentenceCase(userFeedItemMetadataSetting.type)}</Title>
    </Header>
  );
};
