import React, { useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { Action } from "../../components/ActionBar/ActionBar";
import { Avatar } from "../../components/Avatar/Avatar";
import { Column } from "../../components/Column/Column";
import { Header } from "../../components/Header/Header";
import { P } from "../../components/Paragraph/Paragraph";
import { Row } from "../../components/Row/Row";
import { Title } from "../../components/Title/Title";
import { toastState, ToastType } from "../../components/Toast/Toast";
import { useAdminUrlInfo } from "../../hooks/useAdminUrlInfo";
import { DisableAdminUserModal } from "../../modals/DisableAdminUserModal/DisableAdminUserModal";
import { LoginAsUserModal } from "../../modals/LoginAsUserModal/LoginAsUserModal";
import { ResetUserPasswordModal } from "../../modals/ResetUserPasswordModal/ResetUserPasswordModal";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import {
  useUpdateAdminAvatarMutation,
  useUpdateAdminStatusMutation,
  UserScopeEnum,
  UserStatusEnum,
} from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { AdminDetailsProps } from "./AdminDetailsView";

export const AdminDetailsHeader: React.FC<AdminDetailsProps> = ({ user, viewer }) => {
  const navigate = useNavigate();
  const setToast = useSetRecoilState(toastState);
  const { modifier } = useAdminUrlInfo();
  const [showDisableAdminUserModal, setShowDisableAdminUserModal] = useState(false);
  const [showLoginAsUserModal, setShowLoginAsUserModal] = useState(false);
  const [showResetUserPasswordModal, setShowResetUserPasswordModal] = useState(false);

  // detect edit mode
  const isEditMode = modifier === "edit";

  // update user status and avatar mutations
  const [updateAdminStatus, updateAdminStatusResult] = useUpdateAdminStatusMutation({
    refetchQueries: ["CollaborationById"],
    awaitRefetchQueries: true,
  });
  const [updateAdminAvatar, updateAdminAvatarResult] = useUpdateAdminAvatarMutation({
    refetchQueries: ["AdminById"],
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      setToast({
        type: ToastType.SUCCESS,
        title: "Avatar updated",
        message: `Avatar of "${data.updateAdminAvatar.name}" has been updated.`,
        isOpen: true,
      });
    },
  });

  // file input for updating avatar image
  const avatarFileInputRef = useRef<HTMLInputElement>(null);

  // header buttons configuration
  const actions: Action[] = useMemo(
    () => [
      isEditMode
        ? {
            label: "Details",
            onClick: () =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "users",
                  page: "admins",
                  id: user.id,
                }),
              }),
          }
        : {
            label: "Edit",
            authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMINS, UserScopeEnum.ADMINS_UPDATE_INFO],
            onClick: () =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "users",
                  page: "admins",
                  id: user.id,
                  modifier: "edit",
                }),
              }),
          },
      {
        label: "Update avatar",
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMINS, UserScopeEnum.ADMINS_UPDATE_INFO],
        loading: updateAdminAvatarResult.loading,
        onClick: () => triggerUpdateAvatar(),
      },
      {
        label: "Reset password",
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMINS, UserScopeEnum.ADMINS_UPDATE_INFO],
        onClick: () => setShowResetUserPasswordModal(true),
      },
      user.id !== viewer.id
        ? {
            label: "Login as",
            authorizedScopes: [UserScopeEnum.ADMINS_LOGIN_AS_USER],
            onClick: () => setShowLoginAsUserModal(true),
          }
        : null,
      user.status !== UserStatusEnum.ACTIVE
        ? {
            label: "Activate",
            authorizedScopes: [
              UserScopeEnum.SUPERADMIN,
              UserScopeEnum.ADMINS,
              UserScopeEnum.ADMINS_UPDATE_INFO,
              UserScopeEnum.ADMINS_UPDATE_STATUS,
            ],
            loading: updateAdminStatusResult.loading,
            onClick: () =>
              updateAdminStatus({
                variables: {
                  userIds: [user.id],
                  status: UserStatusEnum.ACTIVE,
                },
                onCompleted: () =>
                  setToast({
                    type: ToastType.SUCCESS,
                    title: "User has been activated",
                    message: "The admin user account has been activated",
                    isOpen: true,
                  }),
              }),
          }
        : null,
      user.status !== UserStatusEnum.DISABLED
        ? {
            label: "Disable",
            authorizedScopes: [
              UserScopeEnum.SUPERADMIN,
              UserScopeEnum.ADMINS,
              UserScopeEnum.ADMINS_UPDATE_INFO,
              UserScopeEnum.ADMINS_UPDATE_STATUS,
            ],
            onClick: () => setShowDisableAdminUserModal(true),
          }
        : null,
    ],
    [
      updateAdminAvatarResult.loading,
      updateAdminStatus,
      updateAdminStatusResult.loading,
      isEditMode,
      navigate,
      setToast,
      user.id,
      user.status,
      viewer.id,
    ],
  );

  // header navigation configuration
  const navigation: Action[] = useMemo(
    () => [
      // {
      //   label: "Payments",
      //   authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.PAYMENTS, UserScopeEnum.PAYMENTS_LIST],
      //   onClick: () =>
      //     navigate({
      //       pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "payments" }),
      //       search: getUrlSearchParamsString({
      //         userEmail: user.email,
      //       }),
      //     }),
      // },
    ],
    [],
  );

  // triggers avatar file upload
  const triggerUpdateAvatar = () => {
    const fileInput = avatarFileInputRef.current;

    if (!fileInput) {
      return;
    }

    fileInput.click();
  };

  // uploads user avatar image
  const handleAvatarUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }

    const avatar = event.target.files[0];

    if (!avatar) {
      return;
    }

    await updateAdminAvatar({
      variables: {
        userId: user.id,
        avatar,
      },
    });
  };

  return (
    <>
      <Header actions={actions} navigation={navigation}>
        <Row>
          <Avatar image={user.avatarUrl} loading={updateAdminAvatarResult.loading} />
          <Column padLeft={2} mainAxisAlignment="center">
            <Title>{user.name}</Title>
            <P small>{user.email}</P>
          </Column>
        </Row>
      </Header>

      <DisableAdminUserModal
        user={user}
        open={showDisableAdminUserModal}
        onClickOutside={() => setShowDisableAdminUserModal(false)}
        onCompleted={() => {
          // show success toast message
          setToast({
            type: ToastType.SUCCESS,
            title: "User has been disabled",
            message: "The user account has been disabled and the user was logged out from every active session.",
            isOpen: true,
          });

          // hide modal
          setShowDisableAdminUserModal(false);
        }}
      />

      <ResetUserPasswordModal
        user={user}
        open={showResetUserPasswordModal}
        onClickOutside={() => setShowResetUserPasswordModal(false)}
        onCompleted={(data) => {
          // show success toast message
          setToast({
            type: ToastType.SUCCESS,
            title: `Password of ${data.resetAdminPassword.name} has been reset`,
            message: `Generated a new random password which was sent to ${data.resetAdminPassword.email}.`,
            isOpen: true,
          });

          // hide modal
          setShowResetUserPasswordModal(false);
        }}
      />

      <LoginAsUserModal
        user={user}
        open={showLoginAsUserModal}
        onClickOutside={() => setShowLoginAsUserModal(false)}
        onCompleted={() => window.location.reload()}
      />

      <input
        type="file"
        name="avatar"
        ref={avatarFileInputRef}
        onChange={handleAvatarUpload}
        style={{ display: "none" }}
      />
    </>
  );
};
