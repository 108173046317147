import { JSONSchema7 } from "json-schema";

export const QuoteMetadataSchema: JSONSchema7 = {
  description: "Quote image or image with text added by admin.",
  type: "object",
  properties: {
    imageUrl: {
      description: "Quote image URL",
      minLength: 1,
      type: "string",
    },
    quoteAuthorName: {
      description: "Optional quote author name",
      type: ["null", "string"],
    },
    quoteText: {
      description: "Optional quote text",
      type: ["null", "string"],
    },
  },
  required: ["imageUrl"],
  $schema: "http://json-schema.org/draft-07/schema#",
};
