import React from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { GridBox } from "../../components/GridBox/GridBox";
import { View } from "../../components/View/View";
import { AdminViewParams } from "../../routes";
import { InformativeContent, useInformativeContentByIdQuery } from "../../schema";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";
import { TestInformativeContentDetailsEdit } from "./InformativeContentDetailsEdit";
import { InformativeContentDetailsHeader } from "./InformativeContentDetailsHeader";
import { InformativeContentDetailsInfo } from "./InformativeContentDetailsInfo";

export interface InformativeContentDetailsProps extends AdminViewProps {
  informativeContent: InformativeContent;
}

export const InformativeContentDetailsView: React.FC<AdminViewProps> = ({ viewer }) => {
  // get identifier from url parameters
  const { id } = useParams<AdminViewParams>();

  // fetch info
  const { data, loading, error } = useInformativeContentByIdQuery({
    variables: {
      filter: {
        informativeContentId: id,
      },
    },
  });

  // expecting the id parameter to be provided
  if (!id) {
    return <ErrorView error="Expected identifier is not provided" />;
  }

  // get item info
  const informativeContent = data?.informativeContent.informativeContent[0] || undefined;

  // handle error
  if (error) {
    return <ErrorView title="Loading info failed" error={error} />;
  }

  // handle loading
  if (loading || !informativeContent) {
    return <LoadingView />;
  }

  // render details
  return (
    <View pad="half">
      <InformativeContentDetailsHeader informativeContent={informativeContent} viewer={viewer} />
      <GridBox />
      {
        <Routes>
          <Route
            index
            element={<InformativeContentDetailsInfo informativeContent={informativeContent} viewer={viewer} />}
          />
          {
            <Route
              path="edit"
              element={<TestInformativeContentDetailsEdit informativeContent={informativeContent} viewer={viewer} />}
            />
          }
        </Routes>
      }
    </View>
  );
};
