import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Action } from "../../components/ActionBar/ActionBar";
import { Header } from "../../components/Header/Header";
import { Title } from "../../components/Title/Title";
import { useAdminUrlInfo } from "../../hooks/useAdminUrlInfo";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import { UserScopeEnum } from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { FeelingDetailsProps } from "./FeelingDetailsView";

export const FeelingDetailsHeader: React.FC<FeelingDetailsProps> = ({ feeling, ...rest }) => {
  const navigate = useNavigate();
  const { modifier } = useAdminUrlInfo();

  // detect edit mode
  const isEditMode = modifier === "edit";

  // header buttons configuration
  const actions: Action[] = useMemo(
    () => [
      isEditMode
        ? {
            label: "Details",
            onClick: () =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "clarity-score",
                  page: "feelings",
                  id: feeling.id,
                }),
              }),
          }
        : {
            label: "Edit",
            authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMINS, UserScopeEnum.ADMINS_UPDATE_INFO],
            onClick: () =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "clarity-score",
                  page: "feelings",
                  id: feeling.id,
                  modifier: "edit",
                }),
              }),
          },
    ],
    [isEditMode, navigate, feeling.id],
  );

  // header navigation configuration
  const navigation: Action[] = useMemo(() => [], []);

  return (
    <Header actions={actions} navigation={navigation} {...rest}>
      <Title>Feeling "{feeling.name}"</Title>
    </Header>
  );
};
