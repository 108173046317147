import { useMemo } from "react";
import { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { WithCollaboration } from "../../components/Collaboration/Collaboration";
import { FormFieldOptions, GeneratedForm } from "../../components/GeneratedForm/GeneratedForm";
import { toastState, ToastType } from "../../components/Toast/Toast";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import { CollaborationTypeEnum, useUpdateEventSettingMutation } from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { EventSettingDetailsProps } from "./EventSettingDetailsView";

interface EditEventSettingFormData {
  value: string;
  comment: string;
  collaborationComment: string;
}

export const EventSettingDetailsEdit: React.FC<EventSettingDetailsProps> = ({ eventSetting, viewer }) => {
  const navigate = useNavigate();
  const setToast = useSetRecoilState(toastState);
  const [updateEventSetting, updateEventSettingResult] = useUpdateEventSettingMutation({
    refetchQueries: ["CollaborationById"],
    awaitRefetchQueries: true,
    onCompleted: () => {
      setToast({
        type: ToastType.SUCCESS,
        title: "Event setting has been updated",
        message: "Updated event setting details",
        isOpen: true,
      });

      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
          menu: "user-feed",
          page: "settings",
          id: eventSetting.id,
        }),
      });
    },
  });

  // form configuration
  const fields = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "text",
        type: "text",
        name: "value",
        label: "Value (If value has to be numeric, please make sure to use correct value)",
        defaultValue: eventSetting.value,
      },
      {
        field: "text",
        type: "text",
        name: "comment",
        label: "Comment",
        defaultValue: eventSetting.comment ?? "Setting description",
      },
      {
        field: "textarea",
        name: "collaborationComment",
        label: "Collaboration comment",
        rules: {
          required: "Please describe what and why was updated",
        },
        defaultValue: "Updated admin feed item info",
      },
    ],
    [eventSetting.value, eventSetting.comment],
  );

  const onSubmit: SubmitHandler<EditEventSettingFormData> = (data) => {
    updateEventSetting({
      variables: {
        ...eventSetting,
        ...data,
        eventSettingId: eventSetting.id,
      },
    });
  };

  return (
    <WithCollaboration referenceId={eventSetting.id} type={CollaborationTypeEnum.EVENT_SETTING} viewer={viewer}>
      <GeneratedForm
        title="Event setting"
        error={updateEventSettingResult.error}
        loading={updateEventSettingResult.loading}
        submitText="Update"
        onSubmit={onSubmit}
      >
        <GeneratedForm.Fields fields={fields} />
      </GeneratedForm>
    </WithCollaboration>
  );
};
