import { JSONSchema7 } from "json-schema";

export const ExternalLinkMetadataSchema: JSONSchema7 = {
  description: "Link that opens external webpage in app webview.",
  type: "object",
  properties: {
    imageUrl: {
      description: "Feed item image url",
      minLength: 1,
      type: "string",
    },
    postTitle: {
      description: "Feed item title",
      minLength: 1,
      type: "string",
    },
    postText: {
      description: "Feed item text",
      minLength: 1,
      type: "string",
    },
    linkText: {
      description: 'Link text ("Visit the link" etc)',
      minLength: 1,
      type: "string",
    },
    linkUrl: {
      description: "Link url to open when clicked",
      minLength: 1,
      type: "string",
    },
  },
  required: ["imageUrl", "linkText", "linkUrl", "postText", "postTitle"],
  $schema: "http://json-schema.org/draft-07/schema#",
};
