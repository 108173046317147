import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { Action } from "../../components/ActionBar/ActionBar";
import { DataTable, DataTableHeader, DataTableRow } from "../../components/DataTable/DataTable";
import { Filter, FilterBaseData } from "../../components/Filter/Filter";
import { FormFieldOptions } from "../../components/GeneratedForm/GeneratedForm";
import { Pagination } from "../../components/Pagination/Pagination";
import { toastState, ToastType } from "../../components/Toast/Toast";
import { View } from "../../components/View/View";
import { useUrlParams } from "../../hooks/useUrlParams";
import { UploadImageModal } from "../../modals/UploadImageModal/UploadImageModal";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import { ConditionModeEnum, MatchModeEnum, SortingInput, useFilesQuery, UserScopeEnum } from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { formatApiDate } from "../../services/formatApiDate";
import { formatDate } from "../../services/formatDate";
import { getPageCount } from "../../services/getPageCount";
import { getSkipTake } from "../../services/getSkipTake";
import { getSortDirectionFromParams } from "../../services/getSortDirectionFromParams";
import { getSortOptionsFromSearchParams } from "../../services/getSortOptionsFromSearchParams";
import { getUpdatedSortOptions } from "../../services/getUpdatedSortOptions";
import { getUrlSearchParamsString } from "../../services/getUrlSearchParamsString";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";

interface FilesFilterData extends FilterBaseData {
  key: string;
  dateFrom: Date | null;
  dateTo: Date | null;
  name: string;
  email: string;
  sort: SortingInput[] | null;
}

export const FileUploadListView: React.FC<AdminViewProps> = () => {
  const navigate = useNavigate();
  const setToast = useSetRecoilState(toastState);
  const [showUploadImageModal, setShowUploadImageModal] = useState(false);

  const params = useUrlParams<FilesFilterData>((params) => ({
    key: params.key ?? "",
    dateFrom: params.dateFrom ? new Date(params.dateFrom) : null,
    dateTo: params.dateTo ? new Date(params.dateTo) : null,
    name: params.name ?? "",
    email: params.email ?? "",
    matchMode: params.matchMode ? (params.matchMode as MatchModeEnum) : MatchModeEnum.STARTS_WITH,
    conditionMode: params.conditionMode ? (params.conditionMode as ConditionModeEnum) : ConditionModeEnum.AND,
    page: params.page ? parseInt(params.page, 10) : 1,
    sort: params.sort ? getSortOptionsFromSearchParams(params.sort) : null,
  }));

  const { data, loading, error } = useFilesQuery({
    variables: {
      filter: {
        key: params.key.length > 0 ? params.key : undefined,
        dateFrom: params.dateFrom ? formatApiDate(params.dateFrom) : undefined,
        dateTo: params.dateTo ? formatApiDate(params.dateTo) : undefined,
        name: params.name ? params.name : undefined,
        email: params.email ? params.email : undefined,
      },
      pagination: {
        ...getSkipTake(params.page),
      },
      match: {
        matchMode: params.matchMode,
        conditionMode: params.conditionMode,
      },
      sort: params.sort ?? null,
    },
  });

  const files = useMemo(() => data?.files.files ?? [], [data?.files.files]);

  const total = data?.files.total ?? 0;
  const pageCount = getPageCount(total);

  const filters = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "text",
        type: "text",
        name: "key",
        label: "File key",
        defaultValue: params.key,
      },
      {
        field: "text",
        type: "text",
        name: "name",
        label: "Admin user name",
        defaultValue: params.name,
      },
      {
        field: "text",
        type: "text",
        name: "email",
        label: "Admin user email",
        defaultValue: params.email,
      },
      {
        field: "date",
        name: "dateFrom",
        label: "Newer than",
        defaultValue: params.dateFrom,
      },
      {
        field: "date",
        name: "dateTo",
        label: "Older than",
        defaultValue: params.dateTo,
      },
    ],
    [params.key, params.dateFrom, params.dateTo, params.name, params.email],
  );

  // handle sorting by column
  const onHeaderClick = useCallback(
    (databaseColumnName: string) =>
      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "media", page: "uploads" }),
        search: getUrlSearchParamsString({ ...params, sort: getUpdatedSortOptions(databaseColumnName, params.sort) }),
      }),
    [navigate, params],
  );

  const headers = useMemo<DataTableHeader[]>(
    () => [
      {
        label: "Key",
        onClick: () => onHeaderClick("key"),
        sortdirection: getSortDirectionFromParams("key", params.sort),
      },
      {
        label: "Uploaded by",
        onClick: () => onHeaderClick("name"),
        sortdirection: getSortDirectionFromParams("name", params.sort),
      },
      {
        label: "Created date",
        onClick: () => onHeaderClick("createdDate"),
        sortdirection: getSortDirectionFromParams("createdDate", params.sort),
      },
    ],
    [onHeaderClick, params.sort],
  );

  const rows = useMemo<DataTableRow[]>(
    () =>
      files.map((file) => ({
        id: file.id,
        cells: [
          {
            content: file.key,
          },
          {
            content: file.adminUser.name,
          },
          {
            content: formatDate(file.createdDate),
          },
        ],
      })),
    [files],
  );

  const viewActions: Action[] = useMemo(
    () => [
      {
        label: "Upload image",
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.FILES],
        onClick: () => setShowUploadImageModal(true),
      },
    ],
    [],
  );

  const onFilterSubmit = useCallback(
    (data: FilesFilterData) => {
      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "media", page: "uploads" }),
        search: getUrlSearchParamsString(data),
      });
    },
    [navigate],
  );

  if (error) {
    return <ErrorView title="Fetching files failed" error={error} />;
  }

  return (
    <>
      <View scrollable>
        <Filter
          title="Files"
          fields={filters}
          loading={loading}
          actions={viewActions}
          matchMode={params.matchMode}
          conditionMode={params.conditionMode}
          onSubmit={onFilterSubmit}
        />
        <DataTable
          headers={headers}
          rows={rows}
          loading={loading}
          stats={{ resultCount: total, pageCount }}
          openAuthorizedScopes={[UserScopeEnum.SUPERADMIN, UserScopeEnum.FILES]}
          onOpen={(row) => {
            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                menu: "media",
                page: "uploads",
                modifier: row.id,
              }),
            });
          }}
        />
        <Pagination
          sticky
          pageCount={pageCount}
          currentPage={params.page}
          onPageChange={(page) => {
            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "media", page: "uploads" }),
              search: getUrlSearchParamsString({ ...params, page }),
            });
          }}
        />
      </View>
      <UploadImageModal
        open={showUploadImageModal}
        onClickOutside={() => setShowUploadImageModal(false)}
        onCompleted={(data) => {
          setToast({
            type: ToastType.SUCCESS,
            title: "Uploaded new image",
            message: `Image was uploaded`,
            isOpen: true,
          });

          navigate({
            pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
              menu: "media",
              page: "uploads",
              id: data.uploadImage.id,
            }),
          });
        }}
      />
    </>
  );
};
