import { JSONSchema7 } from "json-schema";
import { UserFeedItemType } from "../common/user-feed";
import { FormFieldOptions } from "../components/GeneratedForm/GeneratedForm";
import { NameValueListItem } from "../components/NameValuePair/NameValuePair";
import { AllJourneyLevelsCompletedMetadataSettingSchema } from "../generated/AllJourneyLevelsCompletedMetadataSettingSchema";
import { BackgroundMusicMetadataSchema } from "../generated/BackgroundMusicMetadataSchema";
import { BirthdayMetadataSettingSchema } from "../generated/BirthdayMetadataSettingSchema";
import { BlogMetadataSchema } from "../generated/BlogMetadataSchema";
import { ClaritySummaryMetadataSettingSchema } from "../generated/ClaritySummaryMetadataSettingSchema";
import { ClarityTopicScoreChangedMetadataSettingSchema } from "../generated/ClarityTopicScoreChangedMetadataSettingSchema";
import { ClarityTotalSchoreChangedMetadataSettingSchema } from "../generated/ClarityTotalSchoreChangedMetadataSettingSchema";
import { ConsultationMetadataSchema } from "../generated/ConsultationMetadataSchema";
import { CourseMetadataSchema } from "../generated/CourseMetadataSchema";
import { CoursesSummaryMetadataSettingSchema } from "../generated/CoursesSummaryMetadataSettingSchema";
import { ExplainAudioMetadataSchema } from "../generated/ExplainAudioMetadataSchema";
import { ExplainClarityMetadataSchema } from "../generated/ExplainClarityMetadataSchema";
import { ExplainClarityTestMetadataSchema } from "../generated/ExplainClarityTestMetadataSchema";
import { ExplainCustomizeFeedMetadataSchema } from "../generated/ExplainCustomizeFeedMetadataSchema";
import { ExplainMoreMetadataSchema } from "../generated/ExplainMoreMetadataSchema";
import { ExternalLinkMetadataSchema } from "../generated/ExternalLinkMetadataSchema";
import { FactMetadataSchema } from "../generated/FactMetadataSchema";
import { FeelingsSummaryMetadataSettingSchema } from "../generated/FeelingsSummaryMetadataSettingSchema";
import { FirstReviewMetadataSettingSchema } from "../generated/FirstReviewMetadataSettingSchema";
import { HighClarityScoreMetadataSettingSchema } from "../generated/HighClarityScoreMetadataSettingSchema";
import { ImageCarouselMetadataSchema } from "../generated/ImageCarouselMetadataSchema";
import { IntroductionMetadataSchema } from "../generated/IntroductionMetadataSchema";
import { JourneySummaryMetadataSettingSchema } from "../generated/JourneySummaryMetadataSettingSchema";
import { LowClarityScoreMetadataSettingSchema } from "../generated/LowClarityScoreMetadataSettingSchema";
import { MindfulMinutesMilestoneMetadataSettingSchema } from "../generated/MindfulMinutesMilestoneMetadataSettingSchema";
import { NewLevelAvailableMetadataSchema } from "../generated/NewLevelAvailableMetadataSchema";
import { NextJourneyPreviewMetadataSchema } from "../generated/NextJourneyPreviewMetadataSchema";
import { NextJourneyPreviewMetadataSettingSchema } from "../generated/NextJourneyPreviewMetadataSettingSchema";
import { PollMetadataSchema } from "../generated/PollMetadataSchema";
import { QuestionAnsweredMetadataSettingSchema } from "../generated/QuestionAnsweredMetadataSettingSchema";
import { QuoteMetadataSchema } from "../generated/QuoteMetadataSchema";
import { RecommendMovieMetadataSettingSchema } from "../generated/RecommendMovieMetadataSettingSchema";
import { RemindBirthdayMetadataSettingSchema } from "../generated/RemindBirthdayMetadataSettingSchema";
import { RemindClarityTestMetadataSchema } from "../generated/RemindClarityTestMetadataSchema";
import { RemindClarityTestMetadataSettingSchema } from "../generated/RemindClarityTestMetadataSettingSchema";
import { RemindCustomizeFeedMetadataSettingSchema } from "../generated/RemindCustomizeFeedMetadataSettingSchema";
import { RemindDiaryMetadataSettingSchema } from "../generated/RemindDiaryMetadataSettingSchema";
import { RemindGiftCardMetadataSettingSchema } from "../generated/RemindGiftCardMetadataSettingSchema";
import { RemindMovieMetadataSettingSchema } from "../generated/RemindMovieMetadataSettingSchema";
import { RemindPushNotificationMetadataSettingSchema } from "../generated/RemindPushNotificationMetadataSettingSchema";
import { RemindRateAppMetadataSettingSchema } from "../generated/RemindRateAppMetadataSettingSchema";
import { RemindRecordVoiceMetadataSettingSchema } from "../generated/RemindRecordVoiceMetadataSettingSchema";
import { RemindReviewMetadataSettingSchema } from "../generated/RemindReviewMetadataSettingSchema";
import { RemindShareStoryMetadataSettingSchema } from "../generated/RemindShareStoryMetadataSettingSchema";
import { RemindShareTrialMetadataSettingSchema } from "../generated/RemindShareTrialMetadataSettingSchema";
import { RemindUnlockMetadataSettingSchema } from "../generated/RemindUnlockMetadataSettingSchema";
import { ReviewMetadataSchema } from "../generated/ReviewMetadataSchema";
import { SaleMetadataSchema } from "../generated/SaleMetadataSchema";
import { SubscribeNewsletterMetadataSchema } from "../generated/SubscribeNewsletterMetadataSchema";
import { SubscriptionEndingMetadataSettingSchema } from "../generated/SubscriptionEndingMetadataSettingSchema";
import { SurveyMetadataSchema } from "../generated/SurveyMetadataSchema";
import { TestTakenMetadataSettingSchema } from "../generated/TestTakenMetadataSettingSchema";
import { UserJourneyLevelCompletedMetadataSettingSchema } from "../generated/UserJourneyLevelCompletedMetadataSettingSchema";
import { VideoMetadataSchema } from "../generated/VideoMetadataSchema";
import { WelcomeMetadataSchema } from "../generated/WelcomeMetadataSchema";
import { AdminFeedItemTypeEnum, UserFeedItemTypeEnum } from "../schema";
import { validateNumeric } from "../validators/validateNumeric";

export const metadataSchemaMap: Record<AdminFeedItemTypeEnum, JSONSchema7> = {
  BACKGROUND_MUSIC: BackgroundMusicMetadataSchema,
  BLOG: BlogMetadataSchema,
  CONSULTATION: ConsultationMetadataSchema,
  COURSE: CourseMetadataSchema,
  EXPLAIN_AUDIO: ExplainAudioMetadataSchema,
  EXPLAIN_CLARITY: ExplainClarityMetadataSchema,
  EXPLAIN_CLARITY_TEST: ExplainClarityTestMetadataSchema,
  EXPLAIN_CUSTOMIZE_FEED: ExplainCustomizeFeedMetadataSchema,
  EXPLAIN_MORE: ExplainMoreMetadataSchema,
  EXTERNAL_LINK: ExternalLinkMetadataSchema,
  FACT: FactMetadataSchema,
  IMAGE_CAROUSEL: ImageCarouselMetadataSchema,
  INTRODUCTION: IntroductionMetadataSchema,
  NEW_LEVEL_AVAILABLE: NewLevelAvailableMetadataSchema,
  NEXT_JOURNEY_PREVIEW: NextJourneyPreviewMetadataSchema,
  POLL: PollMetadataSchema,
  QUOTE: QuoteMetadataSchema,
  REMIND_CLARITY_TEST: RemindClarityTestMetadataSchema,
  REVIEW: ReviewMetadataSchema,
  SALE: SaleMetadataSchema,
  SUBSCRIBE_NEWSLETTER: SubscribeNewsletterMetadataSchema,
  SURVEY: SurveyMetadataSchema,
  VIDEO: VideoMetadataSchema,
  WELCOME: WelcomeMetadataSchema,
};

export const userFeedItemMetadataSettingsSchemaMap: Record<UserFeedItemType, JSONSchema7> = {
  CLARITY_TOPIC_SCORE_CHANGED: ClarityTopicScoreChangedMetadataSettingSchema,
  ALL_JOURNEY_LEVELS_COMPLETED: AllJourneyLevelsCompletedMetadataSettingSchema,
  BIRTHDAY: BirthdayMetadataSettingSchema,
  CLARITY_SUMMARY: ClaritySummaryMetadataSettingSchema,
  CLARITY_TOTAL_SCORE_CHANGED: ClarityTotalSchoreChangedMetadataSettingSchema,
  COURSES_SUMMARY: CoursesSummaryMetadataSettingSchema,
  FEELINGS_SUMMARY: FeelingsSummaryMetadataSettingSchema,
  FIRST_REVIEW: FirstReviewMetadataSettingSchema,
  HIGH_CLARITY_SCORE: HighClarityScoreMetadataSettingSchema,
  JOURNEYS_SUMMARY: JourneySummaryMetadataSettingSchema,
  LOW_CLARITY_SCORE: LowClarityScoreMetadataSettingSchema,
  MINDFUL_MINUTES_MILESTONE: MindfulMinutesMilestoneMetadataSettingSchema,
  NEXT_JOURNEY_PREVIEW: NextJourneyPreviewMetadataSettingSchema,
  QUESTION_ANSWERED: QuestionAnsweredMetadataSettingSchema,
  RECOMMEND_MOVIE: RecommendMovieMetadataSettingSchema,
  REMIND_BIRTHDAY: RemindBirthdayMetadataSettingSchema,
  REMIND_CLARITY_TEST: RemindClarityTestMetadataSettingSchema,
  REMIND_CUSTOMIZE_FEED: RemindCustomizeFeedMetadataSettingSchema,
  REMIND_DIARY: RemindDiaryMetadataSettingSchema,
  REMIND_GIFT_CARD: RemindGiftCardMetadataSettingSchema,
  REMIND_MOVIE: RemindMovieMetadataSettingSchema,
  REMIND_PUSH_NOTIFICATIONS: RemindPushNotificationMetadataSettingSchema,
  REMIND_RATE_APP: RemindRateAppMetadataSettingSchema,
  REMIND_RECORD_VOICE: RemindRecordVoiceMetadataSettingSchema,
  REMIND_REVIEW: RemindReviewMetadataSettingSchema,
  REMIND_SHARE_STORY: RemindShareStoryMetadataSettingSchema,
  REMIND_SHARE_TRIAL: RemindShareTrialMetadataSettingSchema,
  REMIND_UNLOCK: RemindUnlockMetadataSettingSchema,
  SUBSCRIPTION_ENDING: SubscriptionEndingMetadataSettingSchema,
  TEST_TAKEN: TestTakenMetadataSettingSchema,
  USER_JOURNEY_LEVEL_COMPLETED: UserJourneyLevelCompletedMetadataSettingSchema,
};

export const unifiedMetadataSchemaMap = { ...metadataSchemaMap, ...userFeedItemMetadataSettingsSchemaMap };

export const getMetadataNameValuePairs = (
  type: AdminFeedItemTypeEnum | UserFeedItemTypeEnum,
  defaultValues: Record<string, unknown> = {},
): NameValueListItem[] => {
  const schema = unifiedMetadataSchemaMap[type];

  if (!schema || !schema.properties) {
    return [];
  }

  return Object.entries(schema.properties).map(([propertyName, property]) => {
    if (typeof property === "boolean") {
      return null;
    }

    return {
      name: property.description,
      value: defaultValues[propertyName] ?? "",
    };
  }) as NameValueListItem[];
};

export const getMetadataFormFields = (
  type: AdminFeedItemTypeEnum | UserFeedItemTypeEnum,
  defaultValues: Record<string, unknown> = {},
): FormFieldOptions[] => {
  const schema = unifiedMetadataSchemaMap[type];

  if (!schema || !schema.properties) {
    return [];
  }

  return Object.entries(schema.properties).map(([propertyName, property], index) => {
    if (typeof property === "boolean") {
      return null;
    }

    const name = `metadata.${index}.${propertyName}`;

    if (property.type === "number") {
      return {
        field: "text",
        type: "text",
        name,
        label: property.description,
        rules: {
          validate: validateNumeric({
            min: 0,
            optional: schema.required?.includes(propertyName),
            disableEmptyValue: true,
          }),
        },
        defaultValue: defaultValues[propertyName] ?? "0",
      };
    }

    // handle array
    if (property.type === "array") {
      //@ts-ignore
      const values = defaultValues[propertyName] ?? [];

      //@ts-ignore
      const fields = values.map((value) => ({ value }));

      return {
        field: "array",
        type: "text",
        name,
        label: property.description,
        rules: schema.required?.includes(propertyName) ? { required: "Field must not be empty" } : undefined,
        defaultValue: fields,
      };
    }

    return {
      field: "text",
      type: "text",
      name,
      label: property.description,
      rules: schema.required?.includes(propertyName) ? { required: "Field must not be empty" } : undefined,
      defaultValue: defaultValues[propertyName] ?? "",
    };
  }) as FormFieldOptions[];
};
