import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { Action } from "../../components/ActionBar/ActionBar";
import { WithCollaboration } from "../../components/Collaboration/Collaboration";
import { Column } from "../../components/Column/Column";
import { DataTable, DataTableHeader, DataTableRow } from "../../components/DataTable/DataTable";
import { NameValueList, NameValueListItem } from "../../components/NameValuePair/NameValuePair";
import { Title } from "../../components/Title/Title";
import { toastState, ToastType } from "../../components/Toast/Toast";
import { CreateQuestionOptionModal } from "../../modals/CreateQuestionOption/CreateQuestionOption";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import { CollaborationTypeEnum, UserScopeEnum } from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { QuestionDetailsProps, QuestionType } from "./TestQuestionDetailsView";

export const TestQuestionDetailsInfo: React.FC<QuestionDetailsProps> = ({ question, viewer, refetch }) => {
  const navigate = useNavigate();
  const [showCreateQuestionOption, setShowCreateQuestionOption] = useState(false);
  const setToast = useSetRecoilState(toastState);

  // data table headers
  const headers = useMemo<DataTableHeader[]>(
    () => [
      {
        label: "Answer",
      },
      {
        label: "Conclusion",
      },
      {
        label: "Score",
      },
      {
        label: "Weight",
      },
    ],
    [],
  );

  // data table rows
  const rows = useMemo<DataTableRow[]>(
    () =>
      question.questionOption.map((questionOption) => {
        return {
          id: questionOption.id,
          cells: [
            {
              content: questionOption.answer.EN,
            },
            {
              content: questionOption.conclusionText.EN,
            },
            {
              content: questionOption.score,
            },
            {
              content: questionOption.weight,
            },
          ],
          actions: [
            {
              label: "View details",
              authorizedScopes: [
                UserScopeEnum.SUPERADMIN,
                UserScopeEnum.CLARITY_TEST,
                UserScopeEnum.CLARITY_TEST_UPDATE_INFO,
              ],
              onClick: (questionOptionId) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "clarity-score",
                    page: "question-options",
                    id: questionOptionId,
                  }),
                }),
            },
          ],
        };
      }),
    [navigate, question],
  );

  // header buttons configuration
  const viewActions: Action[] = useMemo(
    () => [
      {
        label: "Create question option",
        authorizedScopes: [
          UserScopeEnum.SUPERADMIN,
          UserScopeEnum.CLARITY_TEST,
          UserScopeEnum.CLARITY_TEST_CREATE_QUESTION_OPTION,
        ],
        onClick: () => setShowCreateQuestionOption(true),
      },
    ],
    [],
  );

  return (
    <WithCollaboration referenceId={question.id} type={CollaborationTypeEnum.QUESTION} viewer={viewer}>
      <NameValueList items={getTestQuestionNameValueList(question)} />

      <Column pad="half">
        <Title actions={viewActions}>Question Options</Title>
      </Column>
      <DataTable
        headers={headers}
        rows={rows}
        openAuthorizedScopes={[
          UserScopeEnum.SUPERADMIN,
          UserScopeEnum.CLARITY_TEST,
          UserScopeEnum.CLARITY_TEST_UPDATE_INFO,
        ]}
        onOpen={(row) =>
          navigate({
            pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
              menu: "clarity-score",
              page: "question-options",
              modifier: row.id,
            }),
          })
        }
      />
      <CreateQuestionOptionModal
        questionId={question.id}
        open={showCreateQuestionOption}
        onClickOutside={() => setShowCreateQuestionOption(false)}
        onCompleted={(data) => {
          // show success toast message
          setToast({
            type: ToastType.SUCCESS,
            title: `Created question option ${data.createQuestionOption.answer.EN}`,
            message: ``,
            isOpen: true,
          });

          // refetch question
          if (refetch) {
            refetch();
          }

          // close modal
          setShowCreateQuestionOption(false);

          // navigate to user details view
          navigate({
            pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
              menu: "clarity-score",
              page: "questions",
              id: data.createQuestionOption.questionId,
            }),
          });
        }}
      />
    </WithCollaboration>
  );
};

export function getTestQuestionNameValueList(question: QuestionType): NameValueListItem[] {
  return [
    {
      name: "Question",
      value: question.question.EN,
    },
    {
      name: "Topic",
      value: question.topic,
    },
    {
      name: "Type",
      value: question.type,
    },
  ];
}
