import { sentenceCase } from "change-case";
import React from "react";
import { WithCollaboration } from "../../components/Collaboration/Collaboration";
import { NameValueList, NameValueListItem } from "../../components/NameValuePair/NameValuePair";
import { CollaborationTypeEnum, UserInfoFragment } from "../../schema";
import { AdminDetailsProps } from "./AdminDetailsView";

export const AdminDetailsInfo: React.FC<AdminDetailsProps> = ({ user, viewer }) => (
  <WithCollaboration referenceId={user.id} type={CollaborationTypeEnum.USER} viewer={viewer}>
    <NameValueList items={getAdminUserNameValueList(user)} />
  </WithCollaboration>
);

export function getAdminUserNameValueList(user: UserInfoFragment): NameValueListItem[] {
  return [
    {
      name: "User unique id",
      value: user.id,
    },
    {
      name: "Name",
      value: user.name,
    },
    {
      name: "Email",
      value: user.email,
    },
    {
      name: "Avatar",
      value: user.avatarUrl ? <img src={user.avatarUrl} alt="User avatar" style={{ width: "50rem" }} /> : "n/a",
    },
    {
      name: "Status",
      value: user.status ? sentenceCase(user.status) : "n/a",
    },
    {
      name: "Roles",
      value: user.roles.map((role) => sentenceCase(role)),
    },
    {
      name: "Scopes",
      value: user.scopes.map((scope) => sentenceCase(scope)),
    },
    {
      name: "Registered",
      value: user.createdDate,
    },
    {
      name: "Created",
      value: user.createdDate,
    },
    {
      name: "Updated",
      value: user.updatedDate,
    },
  ];
}
