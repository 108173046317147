import React from "react";
import { RiEmotionSadLine } from "react-icons/ri";
import { View } from "../../components/View/View";

export const NotFoundView: React.FC = () => (
  <View pad center>
    <RiEmotionSadLine size={100} />
    <p>Requested view does not exist or you do not have the required permissions</p>
  </View>
);
