import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { Action } from "../../components/ActionBar/ActionBar";
import { Header } from "../../components/Header/Header";
import { Title } from "../../components/Title/Title";
import { toastState, ToastType } from "../../components/Toast/Toast";
import { useAdminUrlInfo } from "../../hooks/useAdminUrlInfo";
import { AdminFeedItemStatusInteractionModal } from "../../modals/AdminFeedItemStatusModal/AdminFeedItemStatusInteractionModal";
import { AdminFeedItemStatusRejectionModal } from "../../modals/AdminFeedItemStatusModal/AdminFeedItemStatusRejectionModal";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import {
  UpdateAdminFeedItemStatusInteractionMutation,
  UserScopeEnum,
  useUserAdminFeedItemStatusInteractionQuery,
} from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { isFeedItemApproved } from "../../services/isFeedItemApproved";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";
import { AdminFeedPollDetailsProps } from "./AdminFeedPollDetailsView";

export const AdminFeedPollDetailsHeader: React.FC<AdminFeedPollDetailsProps> = ({ adminFeedPoll, ...rest }) => {
  const navigate = useNavigate();
  const { modifier } = useAdminUrlInfo();
  const setToast = useSetRecoilState(toastState);
  const [showAdminFeedStatusInteractionModal, setShowAdminFeedStatusInteractionModal] = useState(false);
  const [showAdminFeedStatusRejectionModal, setShowAdminFeedStatusRejectionModal] = useState(false);

  // detect edit mode
  const isEditMode = modifier === "edit";

  const { data, loading, error } = useUserAdminFeedItemStatusInteractionQuery({
    variables: {
      adminFeedItemId: adminFeedPoll.adminFeedItem.id,
    },
  });

  const isItemApproved = isFeedItemApproved(adminFeedPoll.adminFeedItem);

  const showReviewButton = data && !data.userAdminFeedItemStatusInteraction.isCreator && !isItemApproved;

  // header buttons configuration
  const actions: Action[] = useMemo(
    () => [
      isEditMode
        ? {
            label: "Details",
            onClick: () =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "admin-feed",
                  page: "polls",
                  id: adminFeedPoll.id,
                }),
              }),
          }
        : !isItemApproved // cannot edit approved items
        ? {
            label: "Edit",
            authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMINS, UserScopeEnum.ADMINS_UPDATE_INFO],
            onClick: () =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "admin-feed",
                  page: "polls",
                  id: adminFeedPoll.id,
                  modifier: "edit",
                }),
              }),
          }
        : {
            label: "Reject item",
            authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMINS, UserScopeEnum.ADMINS_UPDATE_INFO],
            onClick: () => setShowAdminFeedStatusRejectionModal(true),
          },
      showReviewButton
        ? {
            label: "Review",
            authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_FEED_ITEM_REVIEW],
            onClick: () => {
              setShowAdminFeedStatusInteractionModal(true);
            },
          }
        : null,
    ],
    [isEditMode, navigate, adminFeedPoll.id, showReviewButton, isItemApproved],
  );

  // header navigation configuration
  const navigation: Action[] = useMemo(() => [], []);

  if (loading) {
    return <LoadingView />;
  }

  // handle error
  if (error) {
    return <ErrorView title="Loading admin feed poll status data failed" error={error} />;
  }

  return (
    <>
      <Header actions={actions} navigation={navigation} {...rest}>
        <Title>Admin feed poll</Title>
      </Header>

      <AdminFeedItemStatusInteractionModal
        previousStatus={data?.userAdminFeedItemStatusInteraction.userInteraction?.status}
        adminFeedItemId={adminFeedPoll.adminFeedItem.id}
        open={showAdminFeedStatusInteractionModal}
        onClickOutside={() => setShowAdminFeedStatusInteractionModal(false)}
        onCompleted={(data: UpdateAdminFeedItemStatusInteractionMutation) => {
          // show success toast message
          setToast({
            type: ToastType.SUCCESS,
            title: "Status review has been submitted",
            message: `Your review status: ${data.updateAdminFeedItemStatusInteraction.status}`,
            isOpen: true,
          });

          // hide modal
          setShowAdminFeedStatusInteractionModal(false);
        }}
      />

      <AdminFeedItemStatusRejectionModal
        adminFeedItemId={adminFeedPoll.adminFeedItem.id}
        open={showAdminFeedStatusRejectionModal}
        onClickOutside={() => setShowAdminFeedStatusRejectionModal(false)}
        onCompleted={(data: UpdateAdminFeedItemStatusInteractionMutation) => {
          // show success toast message
          setToast({
            type: ToastType.SUCCESS,
            title: "Status review has been submitted",
            message: `Your review status: ${data.updateAdminFeedItemStatusInteraction.status}`,
            isOpen: true,
          });

          // hide modal
          setShowAdminFeedStatusRejectionModal(false);
        }}
      />
    </>
  );
};
