import React, { useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { DataTable, DataTableAction, DataTableHeader, DataTableRow } from "../../components/DataTable/DataTable";
import { Filter, FilterBaseData } from "../../components/Filter/Filter";
import { FormFieldOptions } from "../../components/GeneratedForm/GeneratedForm";
import { Pagination } from "../../components/Pagination/Pagination";
import { View } from "../../components/View/View";
import { useUrlParams } from "../../hooks/useUrlParams";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import {
  ConditionModeEnum,
  MatchModeEnum,
  SortingInput,
  TopicTypeEnum,
  useClarityTestConclusionImagesQuery,
  UserScopeEnum,
} from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getEnumFormOptions } from "../../services/getEnumFormOptions";
import { getPageCount } from "../../services/getPageCount";
import { getSkipTake } from "../../services/getSkipTake";
import { getSortDirectionFromParams } from "../../services/getSortDirectionFromParams";
import { getSortOptionsFromSearchParams } from "../../services/getSortOptionsFromSearchParams";
import { getUpdatedSortOptions } from "../../services/getUpdatedSortOptions";
import { getUrlSearchParamsString } from "../../services/getUrlSearchParamsString";

import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";

interface ClarityTestConclusionImageFilterData extends FilterBaseData {
  minScoreValue: string;
  maxScoreValue: string | null;
  topic: TopicTypeEnum[];
  sort: SortingInput[] | null;
}

export const ClarityTestConclusionImageListView: React.FC<AdminViewProps> = () => {
  const navigate = useNavigate();

  // parse filter url parameters
  const params = useUrlParams<ClarityTestConclusionImageFilterData>((params) => ({
    minScoreValue: params.minScoreValue ?? "",
    maxScoreValue: params.maxScoreValue ? params.maxScoreValue : null,
    topic: params.topic ? (params.topic.split(",") as TopicTypeEnum[]) : [],
    matchMode: params.matchMode ? (params.matchMode as MatchModeEnum) : MatchModeEnum.STARTS_WITH,
    conditionMode: params.conditionMode ? (params.conditionMode as ConditionModeEnum) : ConditionModeEnum.AND,
    page: params.page ? parseInt(params.page, 10) : 1,
    sort: params.sort ? getSortOptionsFromSearchParams(params.sort) : null,
  }));

  // fetch items
  const { data, loading, error } = useClarityTestConclusionImagesQuery({
    variables: {
      filter: {
        minScoreValue: params.minScoreValue ? Number.parseFloat(params.minScoreValue) : undefined,
        maxScoreValue: params.maxScoreValue ? Number.parseFloat(params.maxScoreValue) : undefined,
        topic: params.topic.length > 0 ? params.topic : undefined,
      },
      pagination: {
        ...getSkipTake(params.page),
      },
      match: {
        matchMode: params.matchMode,
        conditionMode: params.conditionMode,
      },
      sort: params.sort ?? null,
    },
  });

  // get items
  const clarityTestConclusionImages = useMemo(
    () => data?.clarityTestConclusionImages.clarityTestConclusionImages ?? [],
    [data?.clarityTestConclusionImages.clarityTestConclusionImages],
  );
  const total = data?.clarityTestConclusionImages.total ?? 0;
  const pageCount = getPageCount(total);

  // filters configuration
  const filters = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "text",
        type: "text",
        name: "minScoreValue",
        label: "Minimum score value",
        defaultValue: params.minScoreValue,
      },
      {
        field: "text",
        type: "text",
        name: "maxScoreValue",
        label: "Maximum score value",
        defaultValue: params.maxScoreValue ?? "",
      },
      {
        field: "checkbox",
        name: "topic",
        label: "Topic",
        options: getEnumFormOptions(TopicTypeEnum),
        defaultValue: params.topic ?? [],
      },
    ],
    [params.minScoreValue, params.maxScoreValue, params.topic],
  );

  // handle sorting by column
  const onHeaderClick = useCallback(
    (databaseColumnName: string) =>
      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "clarity-score", page: "conclusion-images" }),
        search: getUrlSearchParamsString({ ...params, sort: getUpdatedSortOptions(databaseColumnName, params.sort) }),
      }),
    [navigate, params],
  );

  // data table headers
  const headers = useMemo<DataTableHeader[]>(
    () => [
      {
        label: "Minimum score value",
        onClick: () => onHeaderClick("minScoreValue"),
        sortdirection: getSortDirectionFromParams("minScoreValue", params.sort),
      },
      {
        label: "Maximum score value",
        onClick: () => onHeaderClick("maxScoreValue"),
        sortdirection: getSortDirectionFromParams("maxScoreValue", params.sort),
      },
      {
        label: "Topic",
        onClick: () => onHeaderClick("topic"),
        sortdirection: getSortDirectionFromParams("topic", params.sort),
      },
    ],
    [onHeaderClick, params.sort],
  );

  // data table rows
  const rows = useMemo<DataTableRow[]>(
    () =>
      clarityTestConclusionImages.map((conclusionImage) => {
        return {
          id: conclusionImage.id,
          cells: [
            {
              content: conclusionImage.minScoreValue,
            },
            {
              content: conclusionImage.maxScoreValue,
            },
            {
              content: conclusionImage.topic,
            },
          ],
          actions: [
            {
              label: "View details",
              authorizedScopes: [
                UserScopeEnum.SUPERADMIN,
                UserScopeEnum.CLARITY_TEST,
                UserScopeEnum.CLARITY_TEST_CONCLUSION_IMAGE,
              ],
              onClick: (conclusionImageId) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "clarity-score",
                    page: "conclusion-images",
                    id: conclusionImageId,
                  }),
                }),
            },
            {
              label: "Edit",
              authorizedScopes: [
                UserScopeEnum.SUPERADMIN,
                UserScopeEnum.CLARITY_TEST,
                UserScopeEnum.CLARITY_TEST_CONCLUSION_IMAGE,
                UserScopeEnum.CLARITY_TEST_UPDATE_CONCLUSION_IMAGE,
              ],
              onClick: (conclusionImageId) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "clarity-score",
                    page: "conclusion-images",
                    id: conclusionImageId,
                    modifier: "edit",
                  }),
                }),
            },
          ],
        };
      }),
    [navigate, clarityTestConclusionImages],
  );

  // data table bulk actions
  const bulkActions = useMemo<DataTableAction<string[]>[]>(() => [], []);

  // handle filters submit
  const onFilterSubmit = useCallback(
    (data: ClarityTestConclusionImageFilterData) => {
      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "clarity-score", page: "conclusion-images" }),
        search: getUrlSearchParamsString(data),
      });
    },
    [navigate],
  );

  // handle errors
  if (error) {
    return <ErrorView title="Fetching Clarity test conclusion images failed" error={error} />;
  }

  // render details
  return (
    <View scrollable>
      <Filter
        title="Clarity test conclusion images"
        fields={filters}
        loading={loading}
        matchMode={params.matchMode}
        conditionMode={params.conditionMode}
        onSubmit={onFilterSubmit}
      />
      <DataTable
        headers={headers}
        rows={rows}
        bulkActions={bulkActions}
        loading={loading}
        openAuthorizedScopes={[
          UserScopeEnum.SUPERADMIN,
          UserScopeEnum.CLARITY_TEST,
          UserScopeEnum.CLARITY_TEST_CONCLUSION_IMAGE,
        ]}
        onOpen={(row) =>
          navigate({
            pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
              menu: "clarity-score",
              page: "conclusion-images",
              modifier: row.id,
            }),
          })
        }
      />
      <Pagination
        sticky
        pageCount={pageCount}
        currentPage={params.page}
        onPageChange={(page) =>
          navigate({
            pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "clarity-score", page: "conclusion-images" }),
            search: getUrlSearchParamsString({ ...params, page }),
          })
        }
      />
    </View>
  );
};
