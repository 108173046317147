import { useMemo } from "react";
import { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { WithCollaboration } from "../../components/Collaboration/Collaboration";
import { FormFieldOptions, GeneratedForm } from "../../components/GeneratedForm/GeneratedForm";
import { toastState, ToastType } from "../../components/Toast/Toast";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import { AdminFeedItemAudienceEnum, CollaborationTypeEnum, useUpdateAdminFeedPollMutation } from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getEnumFormOptions } from "../../services/getEnumFormOptions";
import { getFileUploadById } from "../../services/getFileUploadById";
import { getTranslationInput } from "../../services/getTranslationInput";
import { validateNumeric } from "../../validators/validateNumeric";
import { AdminFeedPollDetailsProps } from "./AdminFeedPollDetailsView";

interface EditAdminFeedPollFormData {
  createdDate: string | null;
  question: string;
  comment: string;
  audience: AdminFeedItemAudienceEnum[];
  isPinned: string;
  isRecommended: string;
  startDate: string;
  endDate: string | null;
  startDay: string | null;
  endDay: string | null;
  likeCount: string;
  shareCount: string;
  version: string;
  topics: string[];
}

export const AdminFeedPollDetailsEdit: React.FC<AdminFeedPollDetailsProps> = ({ adminFeedPoll, viewer, topics }) => {
  const navigate = useNavigate();
  const setToast = useSetRecoilState(toastState);
  const [updateAdminFeedPoll, updateAdminFeedPollResult] = useUpdateAdminFeedPollMutation({
    refetchQueries: ["CollaborationById", "AdminFeedPollById"],
    awaitRefetchQueries: true,
    onCompleted: () => {
      setToast({
        type: ToastType.SUCCESS,
        title: "Admin feed poll has been updated",
        message: `Updated admin feed poll details`,
        isOpen: true,
      });

      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
          menu: "admin-feed",
          page: "polls",
          id: adminFeedPoll.id,
        }),
      });
    },
  });

  const fields = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "translation",
        name: "question",
        label: "Question",
        rules: {
          required: "Please provide question",
        },
        defaultValue: adminFeedPoll.question,
      },
      {
        field: "upload",
        type: "text",
        name: "image",
        label: "Image",
      },
      {
        field: "checkbox",
        name: "audience",
        label: "Audience who will see this item",
        options: getEnumFormOptions(AdminFeedItemAudienceEnum),
        rules: { required: "Please choose at least one audience type" },
        defaultValue: adminFeedPoll.adminFeedItem.audience,
      },
      {
        field: "radio",
        name: "isPinned",
        label: "Is pinned to the top",
        options: [
          { value: "true", label: "Yes" },
          { value: "false", label: "No" },
        ],
        rules: { required: "Please choose whether the item is pinned to the top" },
        defaultValue: adminFeedPoll.adminFeedItem.isPinned ? "true" : "false",
      },
      {
        field: "radio",
        name: "isRecommended",
        label: "Is feed item recommended",
        options: [
          { value: "true", label: "Yes" },
          { value: "false", label: "No" },
        ],
        rules: { required: "Please choose whether the item is recommended" },
        defaultValue: adminFeedPoll.adminFeedItem.isRecommended ? "true" : "false",
      },
      {
        field: "date",
        name: "startDate",
        label: "Show item from given date",
        defaultValue: new Date(adminFeedPoll.adminFeedItem.startDate),
      },
      {
        field: "date",
        name: "endDate",
        label: "Show item until given date (leave empty to not use)",
        defaultValue: adminFeedPoll.adminFeedItem.endDate ? new Date(adminFeedPoll.adminFeedItem.endDate) : undefined,
      },
      {
        field: "text",
        type: "text",
        name: "startDay",
        label: "Show item starting from given days after registration (0 for first day, empty to not use)",
        rules: {
          validate: validateNumeric({
            min: 0,
            optional: true,
            message: "Expected positive start day number",
          }),
        },
        defaultValue: adminFeedPoll.adminFeedItem.startDay
          ? adminFeedPoll.adminFeedItem.startDay.toString()
          : undefined,
      },
      {
        field: "text",
        type: "text",
        name: "endDay",
        label: "Show item starting until given days after registration (leave empty to not use)",
        rules: {
          validate: validateNumeric({
            min: 0,
            optional: true,
            message: "Expected positive end day number",
          }),
        },
        defaultValue: adminFeedPoll.adminFeedItem.endDay ? adminFeedPoll.adminFeedItem.endDay.toString() : undefined,
      },
      {
        field: "text",
        type: "text",
        name: "likeCount",
        label: "Like count",
        rules: {
          validate: validateNumeric({
            min: 0,
            optional: true,
            message: "Expected positive like count",
          }),
        },
        defaultValue: adminFeedPoll.adminFeedItem.likeCount.toString(),
      },
      {
        field: "text",
        type: "text",
        name: "shareCount",
        label: "Share count",
        rules: {
          validate: validateNumeric({
            min: 0,
            optional: true,
            message: "Expected positive share count",
          }),
        },
        defaultValue: adminFeedPoll.adminFeedItem.shareCount.toString(),
      },
      {
        field: "checkbox",
        name: "topics",
        label: "Feed item topics",
        options: (topics && topics.map((topic) => ({ label: topic.name, value: topic.id }))) || [],
        rules: { required: "Please choose at least one topic type" },
        defaultValue: adminFeedPoll.adminFeedItem.topics.map((topic) => topic.id),
      },
      {
        field: "text",
        type: "text",
        name: "version",
        label: "Metadata version",
        rules: { required: "Please provide metadata version" },
        defaultValue: adminFeedPoll.adminFeedItem.version.toString(),
      },
      {
        field: "textarea",
        type: "text",
        name: "comment",
        label: "Collaboration comment",
        rules: {
          required: "Please describe what and why was updated",
        },
        defaultValue: "Updated admin feed poll info",
      },
      ...adminFeedPoll.adminFeedPollOptions.map(
        (adminFeedPollOption, index) =>
          ({
            field: "translation",
            name: adminFeedPollOption.id,
            label: `Option ${index + 1}`,
            rules: {
              required: "Please provide option",
            },
            defaultValue: adminFeedPollOption.answer,
          } as FormFieldOptions),
      ),
    ],
    [adminFeedPoll.question, adminFeedPoll.adminFeedPollOptions, topics, adminFeedPoll.adminFeedItem],
  );

  const onSubmit: SubmitHandler<EditAdminFeedPollFormData> = async (data) => {
    // collect poll answer options by id
    const options = adminFeedPoll.adminFeedPollOptions.map(({ id }) => ({
      id,
      answer: getTranslationInput(id, { ...data }),
    }));

    updateAdminFeedPoll({
      variables: {
        comment: data.comment,
        question: getTranslationInput("question", { ...data }),
        adminFeedPollId: adminFeedPoll.id,
        isPinned: data.isPinned === "true",
        isRecommended: data.isRecommended === "true",
        startDay: data.startDay ? parseInt(data.startDay, 10) : null,
        endDay: data.endDay ? parseInt(data.endDay, 10) : null,
        likeCount: parseInt(data.likeCount, 10),
        shareCount: parseInt(data.shareCount, 10),
        version: parseInt(data.version, 10),
        topicIds: data.topics,
        startDate: data.startDate,
        endDate: data.endDate,
        audience: data.audience,
        image: getFileUploadById("image"),
        options: options.map((option, index) => ({ ...option, ordinal: index + 1 })),
      },
    });
  };

  return (
    <WithCollaboration
      referenceId={adminFeedPoll.adminFeedItemId}
      type={CollaborationTypeEnum.ADMIN_FEED_ITEM}
      viewer={viewer}
    >
      <GeneratedForm
        title="Admin feed poll"
        error={updateAdminFeedPollResult.error}
        loading={updateAdminFeedPollResult.loading}
        submitText="Update"
        onSubmit={onSubmit}
      >
        <GeneratedForm.Fields fields={fields} />
      </GeneratedForm>
    </WithCollaboration>
  );
};
