import React from "react";
import ReactDOM from "react-dom";
import { Root } from "./Root";
import * as serviceWorker from "./serviceWorker";

// make viewport size constant (mobile soft keyboard can otherwise reduces it)
// applyConstantViewportSize();

ReactDOM.render(<Root />, document.getElementById("root"));

// learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
serviceWorker.unregister();

// use hot reload if available
if (module.hot) {
  module.hot.accept("./Root", () => {
    // clear old console messages and notify about hot-reload
    console.clear();
    console.log("-- Hot reloaded the app (press F5 for full reload) --");

    // get updated root component
    const NextRoot = require("./Root").Root;

    // render updated root
    ReactDOM.render(<NextRoot />, document.getElementById("root"));
  });
}
