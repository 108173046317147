import { WithCollaboration } from "../../components/Collaboration/Collaboration";
import { Container } from "../../components/Container/Container";
import { NameValueList, NameValueListItem } from "../../components/NameValuePair/NameValuePair";
import { Thumbnail } from "../../components/Thumbnail/Thumbnail";
import { CollaborationTypeEnum, FileInfoFragment } from "../../schema";
import { FileUploadDetailsProps } from "./FileUploadDetailsView";

export const FileUploadDetailsInfo: React.FC<FileUploadDetailsProps> = ({ file, viewer }) => (
  <WithCollaboration referenceId={file.id} type={CollaborationTypeEnum.FILE} viewer={viewer}>
    <NameValueList items={getFileInfoNameValueList(file)} />
    <Container center>
      <Thumbnail src={file.url} />
    </Container>
  </WithCollaboration>
);

export function getFileInfoNameValueList(fileInfo: FileInfoFragment): NameValueListItem[] {
  return [
    {
      name: "Unique id",
      value: fileInfo.id,
    },
    {
      name: "URL",
      value: fileInfo.url,
    },
    {
      name: "Key",
      value: fileInfo.key,
    },
    {
      name: "Created",
      value: fileInfo.createdDate,
    },
    {
      name: "Updated",
      value: fileInfo.updatedDate,
    },
  ];
}
