export const changelog = [
  "Admins can now edit user feed items metadata settings",
  "User feed item events view is now visible to users",
  "Admin feed item description is now enabled",
  "Admin feed item rejection logic now works correctly",
  "Questions view now displays special characters correctly",
  "Quote metadata now allows quote author or quote text to be left empty",
  "Fixed admin feed poll adding",
  "Implemented table sorting",
  "Implemented feed item status flow",
  "Implemented file uploads list view",
  "Implemented creating question and question option",
  "Implemented Informative content add new Modal",
  "Implemented Informative content view",
  "Implemented admin feed poll creation",
  "Implemented admin feed poll edit view",
  "Implemented admin feed poll detail view",
  "Implemented admin feed poll list view",
  "Implemented viewing and changing clarity score change event thresholds",
  "Implemented Question and Question option views for clarity score",
  "Implemented updating admin feed items",
  "Implemented create admin feed item modal",
  "Improved collaboration, diffing, dates displaying",
  "Made the main menu scrollable",
  "Implemented admin feed item details view",
  "Implemented formatting values and improved name value pair component",
  "Implemented admin feed items list filtering",
  "Implemented admin feed items list pagination",
  "Implemented admin feed items view",
  "Implemented admin feed topic edit view",
  "Implemented admin feed topic details view",
  "Implemented create admin feed topic modal",
  "Implemented admin feed topic list view",
  "Implemented user feed event edit view",
  "Implemented user feed event details view",
  "Improved buttons",
  "Improved modals",
  "Implemented viewer hook",
  "Implemented title component actions support",
  "Implemented user feed event list view",
  "Improved modal scrollability",
  "Implemented edit feeling view",
  "Improved forms and error handling",
  "Split admin details view into separate components",
  "Added placeholder main menu items",
  "Improved navigation and urls logic",
  "Implemented main menu groups support",
  "Implemented feelings details view",
  "Implemented feelings list view",
  "Implemented score modifier visualization component",
  "Implemented logic that forces new admins to change password on first login",
  "Implemented change password view",
  "Implemented app header component",
  "Refactored modals to use new declarative actions",
  "Refactored changelog to use modal component",
  "Implemented modal header and actions support",
  "Implemented toast component for showing notifications",
  "Refactored graphql queries to use separate files not gql tags",
  "Refactored existing modals to be self-contained",
  "Implemented create admin user modal",
  "Improved changelog, implemented click outside",
  "Improved main menu",
  "Replaced custom icons with icon pack icons",
  "Implemented not implemented view",
  "Improved color scheme",
  "Improved main menu",
  "Added support for icon packs",
  "Refactored user scopes",
  "Implemented resetting admin password",
  "Implemented activating/deactivating admin accounts",
  "Implemented logging in as admin user",
  "Implemented updating admin details",
  "Implemented admin details view",
  "Implemented admin list view",
  "Updated logo, favicons, names",
  "Setup base logic",
];
