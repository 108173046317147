import React from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { GridBox } from "../../components/GridBox/GridBox";
import { View } from "../../components/View/View";
import { AdminViewParams } from "../../routes";
import { ConclusionOptionByIdQueryResult, useConclusionOptionByIdQuery } from "../../schema";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";
import { ConclusionOptionDetailsHeader } from "./ConclusionOptionDetailsHeader";
import { ConclusionOptionDetailsInfo } from "./ConclusionOptionDetailsInfo";
import { ConclusionOptionDetailsEdit } from "./ConclusionOptionEdit";

export type ConclusionOptionInfo = NonNullable<ConclusionOptionByIdQueryResult["data"]>["conclusionOption"];

export interface ConclusionOptionDetailsProps extends AdminViewProps {
  conclusionOption: ConclusionOptionInfo;
}

export const ConclusionOptionDetailsView: React.FC<AdminViewProps> = ({ viewer }) => {
  // get identifier from url parameters
  const { id } = useParams<AdminViewParams>();

  // fetch info
  const { data, loading, error } = useConclusionOptionByIdQuery({
    variables: {
      conclusionOptionId: id ?? "",
    },
  });

  // expecting the id parameter to be provided
  if (!id) {
    return <ErrorView error="Expected identifier is not provided" />;
  }

  // get item info
  const conclusionOption = data?.conclusionOption;

  // handle error
  if (error) {
    return <ErrorView title="Loading info failed" error={error || "Something went wrong, this should not happen"} />;
  }

  // handle loading
  if (loading || !conclusionOption) {
    return <LoadingView />;
  }

  // render details
  return (
    <View pad="half">
      <ConclusionOptionDetailsHeader conclusionOption={conclusionOption} viewer={viewer} />
      <GridBox />
      <Routes>
        <Route index element={<ConclusionOptionDetailsInfo conclusionOption={conclusionOption} viewer={viewer} />} />
        <Route
          path="edit"
          element={<ConclusionOptionDetailsEdit conclusionOption={conclusionOption} viewer={viewer} />}
        />
      </Routes>
    </View>
  );
};
