import { JSONSchema7 } from "json-schema";

export const FactMetadataSchema: JSONSchema7 = {
  description: "Did you know that.. facts that opens journeys.",
  type: "object",
  properties: {
    imageUrl: {
      description: "Quote image URL",
      minLength: 1,
      type: "string",
    },
    factText: {
      description: "Fact text to show",
      minLength: 1,
      type: "string",
    },
    mediaCategoryId: {
      description: "mediaCategoryId where to redirect user to",
      type: ["null", "string"],
    },
    productCode: {
      description: "product under media category where to redirect user to",
      type: ["null", "string"],
    },
    consumableCode: {
      description: "consumable under product where to redirect user to",
      type: ["null", "string"],
    },
  },
  required: ["factText", "imageUrl"],
  $schema: "http://json-schema.org/draft-07/schema#",
};
