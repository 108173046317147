import { sentenceCase } from "change-case";
import React, { useCallback, useState } from "react";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { Action } from "../../components/ActionBar/ActionBar";
import { DataTable, DataTableHeader, DataTableRow, DataTableAction } from "../../components/DataTable/DataTable";
import { Filter, FilterBaseData } from "../../components/Filter/Filter";
import { FormFieldOptions } from "../../components/GeneratedForm/GeneratedForm";
import { Pagination } from "../../components/Pagination/Pagination";
import { TimeAgo } from "../../components/TimeAgo/TimeAgo";
import { toastState, ToastType } from "../../components/Toast/Toast";
import { View } from "../../components/View/View";
import { useUrlParams } from "../../hooks/useUrlParams";
import { CreateAdminUserModal } from "../../modals/CreateAdminUserModal/CreateAdminUserModal";
import { DisableAdminUserInfo, DisableAdminUserModal } from "../../modals/DisableAdminUserModal/DisableAdminUserModal";
import { LoginAsUserInfo, LoginAsUserModal } from "../../modals/LoginAsUserModal/LoginAsUserModal";
import {
  ResetUserPasswordInfo,
  ResetUserPasswordModal,
} from "../../modals/ResetUserPasswordModal/ResetUserPasswordModal";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import {
  UserStatusEnum,
  MatchModeEnum,
  ConditionModeEnum,
  useUpdateAdminStatusMutation,
  UserRoleEnum,
  UserScopeEnum,
  useAdminsQuery,
  SortingInput,
} from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { formatApiDate } from "../../services/formatApiDate";
import { getEnumFormOptions } from "../../services/getEnumFormOptions";
import { getPageCount } from "../../services/getPageCount";
import { getSkipTake } from "../../services/getSkipTake";
import { getSortDirectionFromParams } from "../../services/getSortDirectionFromParams";
import { getSortOptionsFromSearchParams } from "../../services/getSortOptionsFromSearchParams";
import { getUpdatedSortOptions } from "../../services/getUpdatedSortOptions";
import { getUrlSearchParamsString } from "../../services/getUrlSearchParamsString";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";

interface AdminsFilterData extends FilterBaseData {
  userId: string;
  email: string;
  name: string;
  status: UserStatusEnum[];
  role: UserRoleEnum[];
  registeredAfter: Date | null;
  registeredBefore: Date | null;
  sort: SortingInput[] | null;
}

export const AdminListView: React.FC<AdminViewProps> = ({ viewer }) => {
  const navigate = useNavigate();

  const [disableUserInfo, setDisableUserInfo] = useState<DisableAdminUserInfo>();
  const [loginAsUserInfo, setLoginAsUserInfo] = useState<LoginAsUserInfo>();
  const [resetPasswordUserInfo, setResetPasswordUserInfo] = useState<ResetUserPasswordInfo>();
  const [showCreateAdminUserModal, setShowCreateAdminUserModal] = useState(false);
  const setToast = useSetRecoilState(toastState);

  // parse filter url parameters
  const params = useUrlParams<AdminsFilterData>((params) => ({
    userId: params.userId ?? "",
    email: params.email ?? "",
    name: params.name ?? "",
    registeredAfter: params.registeredAfter ? new Date(params.registeredAfter) : null,
    registeredBefore: params.registeredBefore ? new Date(params.registeredBefore) : null,
    status: params.status ? (params.status.split(",") as UserStatusEnum[]) : [],
    role: params.role ? (params.role.split(",") as UserRoleEnum[]) : [],
    matchMode: params.matchMode ? (params.matchMode as MatchModeEnum) : MatchModeEnum.STARTS_WITH,
    conditionMode: params.conditionMode ? (params.conditionMode as ConditionModeEnum) : ConditionModeEnum.AND,
    page: params.page ? parseInt(params.page, 10) : 1,
    sort: params.sort ? getSortOptionsFromSearchParams(params.sort) : null,
  }));

  // load list of filtered and paginated entries
  const { data, loading, error } = useAdminsQuery({
    variables: {
      filter: {
        userId: params.userId.length > 0 ? params.userId : undefined,
        email: params.email.length > 0 ? params.email : undefined,
        name: params.name.length > 0 ? params.name : undefined,
        registeredAfter: params.registeredAfter ? formatApiDate(params.registeredAfter) : undefined,
        registeredBefore: params.registeredBefore ? formatApiDate(params.registeredBefore) : undefined,
        status: params.status.length > 0 ? params.status : undefined,
        role: params.role.length > 0 ? params.role : undefined,
      },
      pagination: {
        ...getSkipTake(params.page),
      },
      match: {
        matchMode: params.matchMode,
        conditionMode: params.conditionMode,
      },
      sort: params.sort ?? null,
    },
  });

  // update user status, separated to be able to show correct loading state (mutation defined in UserDetailsView)
  const [adminUpdateUserActiveStatus, adminUpdateUserActiveStatusResult] = useUpdateAdminStatusMutation();
  const [adminUpdateUserDisableStatus, adminUpdateUserDisableStatusResult] = useUpdateAdminStatusMutation();

  // get items
  const users = useMemo(() => data?.admins.users ?? [], [data?.admins.users]);
  const total = data?.admins.total ?? 0;
  const pageCount = getPageCount(total);

  // filters configuration
  const filters = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "text",
        type: "text",
        name: "userId",
        label: "User id",
        defaultValue: params.userId,
      },
      {
        field: "text",
        type: "text",
        name: "email",
        label: "Email",
        defaultValue: params.email,
      },
      {
        field: "text",
        type: "text",
        name: "name",
        label: "Name",
        defaultValue: params.name,
      },
      {
        field: "checkbox",
        name: "status",
        label: "Status",
        options: getEnumFormOptions(UserStatusEnum),
        defaultValue: params.status,
      },
      {
        field: "checkbox",
        name: "role",
        label: "Role",
        options: getEnumFormOptions(UserRoleEnum),
        defaultValue: params.role,
      },
    ],
    [params.userId, params.email, params.name, params.role, params.status],
  );

  // handle sorting by column
  const onHeaderClick = useCallback(
    (databaseColumnName: string) =>
      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "users", page: "admins" }),
        search: getUrlSearchParamsString({ ...params, sort: getUpdatedSortOptions(databaseColumnName, params.sort) }),
      }),
    [navigate, params],
  );

  // data table headers
  const headers = useMemo<DataTableHeader[]>(
    () => [
      {
        label: "Name",
        onClick: () => onHeaderClick("name"),
        sortdirection: getSortDirectionFromParams("name", params.sort),
      },
      {
        label: "Email",
        onClick: () => onHeaderClick("email"),
        sortdirection: getSortDirectionFromParams("email", params.sort),
      },
      {
        label: "Status",
        onClick: () => onHeaderClick("status"),
        sortdirection: getSortDirectionFromParams("status", params.sort),
      },
      {
        label: "Roles",
        onClick: () => onHeaderClick("roles"),
        sortdirection: getSortDirectionFromParams("roles", params.sort),
      },
      {
        label: "Created",
        onClick: () => onHeaderClick("createdDate"),
        sortdirection: getSortDirectionFromParams("createdDate", params.sort),
      },
    ],
    [onHeaderClick, params.sort],
  );

  // data table rows
  const rows = useMemo<DataTableRow[]>(
    () =>
      users.map((user) => {
        return {
          id: user.id,
          cells: [
            {
              content: user.name,
            },
            {
              content: user.email,
            },
            {
              content: sentenceCase(user.status),
            },
            {
              content: user.roles.map((role) => sentenceCase(role)).join(", "),
            },
            {
              content: <TimeAgo date={user.createdDate} />,
            },
          ],
          actions: [
            {
              label: "View details",
              authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMINS, UserScopeEnum.ADMINS_INFO],
              onClick: (userId) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "users", page: "admins", id: userId }),
                }),
            },
            {
              label: "Edit",
              authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMINS, UserScopeEnum.ADMINS_UPDATE_INFO],
              onClick: (userId) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "users",
                    page: "admins",
                    id: userId,
                    modifier: "edit",
                  }),
                }),
            },
            user.status !== UserStatusEnum.DISABLED
              ? {
                  label: "Disable",
                  authorizedScopes: [
                    UserScopeEnum.SUPERADMIN,
                    UserScopeEnum.ADMINS,
                    UserScopeEnum.ADMINS_UPDATE_INFO,
                    UserScopeEnum.ADMINS_UPDATE_STATUS,
                  ],
                  loading: adminUpdateUserDisableStatusResult.loading,
                  onClick: () => setDisableUserInfo(user),
                }
              : null,
            user.status !== UserStatusEnum.ACTIVE
              ? {
                  label: "Activate",
                  authorizedScopes: [
                    UserScopeEnum.SUPERADMIN,
                    UserScopeEnum.ADMINS,
                    UserScopeEnum.ADMINS_UPDATE_INFO,
                    UserScopeEnum.ADMINS_UPDATE_STATUS,
                  ],
                  loading: adminUpdateUserActiveStatusResult.loading,
                  onClick: () =>
                    adminUpdateUserActiveStatus({
                      variables: {
                        userIds: [user.id],
                        status: UserStatusEnum.ACTIVE,
                      },
                    }),
                }
              : null,
            user.id !== viewer.id
              ? {
                  label: "Login as",
                  authorizedScopes: [UserScopeEnum.ADMINS_LOGIN_AS_USER],
                  onClick: () => setLoginAsUserInfo(user),
                }
              : null,
            {
              label: "Reset password",
              authorizedScopes: [
                UserScopeEnum.SUPERADMIN,
                UserScopeEnum.ADMINS,
                UserScopeEnum.ADMINS_UPDATE_INFO,
                UserScopeEnum.ADMINS_RESET_PASSWORD,
              ],
              onClick: () => setResetPasswordUserInfo(user),
            },
          ],
        };
      }),
    [
      adminUpdateUserActiveStatus,
      adminUpdateUserActiveStatusResult.loading,
      adminUpdateUserDisableStatusResult.loading,
      navigate,
      users,
      viewer.id,
    ],
  );

  // data table bulk actions
  const bulkActions = useMemo<DataTableAction<string[]>[]>(
    () => [
      {
        label: "Disable",
        authorizedScopes: [
          UserScopeEnum.SUPERADMIN,
          UserScopeEnum.ADMINS,
          UserScopeEnum.ADMINS_UPDATE_INFO,
          UserScopeEnum.ADMINS_UPDATE_STATUS,
        ],
        loading: adminUpdateUserDisableStatusResult.loading,
        onClick: (userIds) =>
          adminUpdateUserDisableStatus({
            variables: {
              userIds,
              status: UserStatusEnum.DISABLED,
            },
          }),
      },
      {
        label: "Activate",
        authorizedScopes: [
          UserScopeEnum.SUPERADMIN,
          UserScopeEnum.ADMINS,
          UserScopeEnum.ADMINS_UPDATE_INFO,
          UserScopeEnum.ADMINS_UPDATE_STATUS,
        ],
        loading: adminUpdateUserActiveStatusResult.loading,
        onClick: (userIds) =>
          adminUpdateUserActiveStatus({
            variables: {
              userIds,
              status: UserStatusEnum.ACTIVE,
            },
          }),
      },
    ],
    [
      adminUpdateUserDisableStatus,
      adminUpdateUserDisableStatusResult.loading,
      adminUpdateUserActiveStatus,
      adminUpdateUserActiveStatusResult.loading,
    ],
  );

  // header buttons configuration
  const viewActions: Action[] = useMemo(
    () => [
      {
        label: "Create admin",
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMINS, UserScopeEnum.ADMINS_CREATE_ADMIN_USER],
        onClick: () => setShowCreateAdminUserModal(true),
      },
    ],
    [],
  );

  // handle filters submit
  const onFilterSubmit = useCallback(
    (data: AdminsFilterData) => {
      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "users", page: "admins" }),
        search: getUrlSearchParamsString(data),
      });
    },
    [navigate],
  );

  // handle errors
  if (error) {
    return <ErrorView title="Fetching users failed" error={error} />;
  }

  // render view
  return (
    <>
      <View scrollable>
        <Filter
          title="Admin users"
          fields={filters}
          actions={viewActions}
          loading={loading}
          matchMode={params.matchMode}
          conditionMode={params.conditionMode}
          onSubmit={onFilterSubmit}
        />
        <DataTable
          headers={headers}
          rows={rows}
          loading={loading}
          stats={{ resultCount: total, pageCount }}
          bulkActions={bulkActions}
          openAuthorizedScopes={[UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMINS, UserScopeEnum.ADMINS_INFO]}
          onOpen={(row) =>
            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "users", page: "admins", modifier: row.id }),
            })
          }
        />
        <Pagination
          sticky
          pageCount={pageCount}
          currentPage={params.page}
          onPageChange={(page) =>
            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "users", page: "admins" }),
              search: getUrlSearchParamsString({ ...params, page }),
            })
          }
        />
      </View>

      {disableUserInfo && (
        <DisableAdminUserModal
          open
          user={disableUserInfo}
          onClickOutside={() => setDisableUserInfo(undefined)}
          onCompleted={() => {
            // show success toast message
            setToast({
              type: ToastType.SUCCESS,
              title: "User has been disabled",
              message: "The user account has been disabled and the user was logged out from every active session.",
              isOpen: true,
            });

            // hide modal
            setDisableUserInfo(undefined);
          }}
        />
      )}

      {loginAsUserInfo && (
        <LoginAsUserModal
          open
          user={loginAsUserInfo}
          onClickOutside={() => setLoginAsUserInfo(undefined)}
          onCompleted={() => window.location.reload()}
        />
      )}

      {resetPasswordUserInfo && (
        <ResetUserPasswordModal
          open
          user={resetPasswordUserInfo}
          onClickOutside={() => setResetPasswordUserInfo(undefined)}
          onCompleted={(data) => {
            // show success toast message
            setToast({
              type: ToastType.SUCCESS,
              title: `Password of ${data.resetAdminPassword.name} has been reset`,
              message: `Generated a new random password which was sent to ${data.resetAdminPassword.email}.`,
              isOpen: true,
            });

            // hide modal
            setResetPasswordUserInfo(undefined);
          }}
        />
      )}

      <CreateAdminUserModal
        open={showCreateAdminUserModal}
        onClickOutside={() => setShowCreateAdminUserModal(false)}
        onCompleted={(data) => {
          // show success toast message
          setToast({
            type: ToastType.SUCCESS,
            title: `Created a new admin user ${data.createAdminUser.name}`,
            message: `Generated a random password which was sent to ${data.createAdminUser.email} with login details.`,
            isOpen: true,
          });

          // navigate to user details view
          navigate({
            pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
              menu: "users",
              page: "admins",
              id: data.createAdminUser.id,
            }),
          });
        }}
      />
    </>
  );
};
