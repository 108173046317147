import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { MdAlternateEmail, MdPassword } from "react-icons/md";
import { BlockButton } from "../../components/BlockButton/BlockButton";
import { Form } from "../../components/Form/Form";
import { GridBox } from "../../components/GridBox/GridBox";
import { Logo } from "../../components/Logo/Logo";
import { Panel } from "../../components/Panel/Panel";
import { TextField } from "../../components/TextField/TextField";
import { View } from "../../components/View/View";
import { useReturnToStoredPath } from "../../hooks/useReturnToStoredPath";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import { useLoginMutation } from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getFieldErrors } from "../../services/getFieldErrors";
import { validateEmail } from "../../validators/validateEmail";
import { validateMinimumLength } from "../../validators/validateMinimumLength";
import styles from "./LoginView.module.scss";

interface LoginFormValues {
  email: string;
  password: string;
}

export const LoginView: React.FC = () => {
  const { register, handleSubmit, formState } = useForm<LoginFormValues>();

  // setup next page url and use it if no previous route path has been stored to return to
  const nextPageUrl = buildUrl<AdminViewParams>(ADMIN_VIEW_PATH);
  const returnToStoredPath = useReturnToStoredPath(nextPageUrl);

  const refreshAndRedirect = () => {
    returnToStoredPath();
    window.location.reload();
  };

  // setup login mutation
  const [login, loginResult] = useLoginMutation({
    refetchQueries: ["Viewer"],
    awaitRefetchQueries: true,
    onCompleted: refreshAndRedirect,
  });

  if (loginResult.data && loginResult.data.login !== undefined) {
    // Store the JWT value in the local storage
    localStorage.setItem("jwt", loginResult.data.login.jwt);
  }

  // get combined client and server side field errors
  const fieldError = getFieldErrors(loginResult.error, formState.errors);

  // login user on submit
  const onSubmit: SubmitHandler<LoginFormValues> = async (variables) =>
    login({
      variables,
    });

  return (
    <View center className={styles.view}>
      <Panel pad overflow raised className={styles.panel}>
        <Logo small />
        <Form padTop="half" onSubmit={handleSubmit(onSubmit)}>
          <TextField
            type="email"
            name="email"
            label={"Email"}
            leading={<MdAlternateEmail />}
            error={fieldError.email}
            register={register("email", { required: "Email is required", validate: validateEmail })}
          />
          <TextField
            type="password"
            name="password"
            label="Password"
            autoComplete="current-password"
            leading={<MdPassword />}
            error={fieldError.password}
            register={register("password", { validate: validateMinimumLength(8) })}
          />
          <GridBox half />
          <BlockButton tertiary loading={loginResult.loading} type="submit" onClick={handleSubmit(onSubmit)}>
            Log In
          </BlockButton>
        </Form>
      </Panel>
    </View>
  );
};
