import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Action } from "../../components/ActionBar/ActionBar";
import { Header } from "../../components/Header/Header";
import { Title } from "../../components/Title/Title";
import { useAdminUrlInfo } from "../../hooks/useAdminUrlInfo";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import { UserScopeEnum } from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { ConclusionOptionDetailsProps } from "./ConclusionOptionDetailsView";

export const ConclusionOptionDetailsHeader: React.FC<ConclusionOptionDetailsProps> = ({
  conclusionOption,
  ...rest
}) => {
  const navigate = useNavigate();
  const { modifier } = useAdminUrlInfo();

  // detect edit mode
  const isEditMode = modifier === "edit";

  // header buttons configuration
  const actions: Action[] = useMemo(
    () => [
      isEditMode
        ? {
            label: "Details",
            onClick: () =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "clarity-score",
                  page: "conclusion-options",
                  id: conclusionOption.id,
                }),
              }),
          }
        : {
            label: "Edit",
            authorizedScopes: [
              UserScopeEnum.SUPERADMIN,
              UserScopeEnum.CLARITY_TEST,
              UserScopeEnum.CLARITY_TEST_CONCLUSION_OPTION,
              UserScopeEnum.CLARITY_TEST_UPDATE_CONCLUSION_OPTION,
            ],
            onClick: () =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "clarity-score",
                  page: "conclusion-options",
                  id: conclusionOption.id,
                  modifier: "edit",
                }),
              }),
          },
    ],
    [isEditMode, navigate, conclusionOption.id],
  );

  // header navigation configuration
  const navigation: Action[] = useMemo(() => [], []);

  return (
    <>
      <Header actions={actions} navigation={navigation} {...rest}>
        <Title>Conclusion option for "{conclusionOption.text}"</Title>
      </Header>
    </>
  );
};
