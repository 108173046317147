import React from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { GridBox } from "../../components/GridBox/GridBox";
import { View } from "../../components/View/View";
import { AdminViewParams } from "../../routes";
import { FeedTestUserQueryHookResult, useFeedTestUserQuery } from "../../schema";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";
import { FeedTestUserDetailsHeader } from "./FeedTestUserDetailsHeader";
import { FeedTestUserDetailsInfo } from "./FeedTestUserDetailsInfo";

export type FeedTestUserInfo = NonNullable<FeedTestUserQueryHookResult["data"]>["feedTestUser"];

export interface FeedTestUserDetailsProps extends AdminViewProps {
  feedTestUser: FeedTestUserInfo;
}

export const FeedTestUserDetailsView: React.FC<AdminViewProps> = ({ viewer }) => {
  // get identifier from url parameters
  const { id } = useParams<AdminViewParams>();

  // fetch info
  const { data, loading, error } = useFeedTestUserQuery({
    variables: {
      id: id ?? "",
    },
  });

  // expecting the id parameter to be provided
  if (!id) {
    return <ErrorView error="Expected identifier is not provided" />;
  }

  // handle error
  if (error) {
    return <ErrorView title="Loading info failed" error={error || "Something went wrong, this should not happen"} />;
  }

  // get item info
  const feedTestUser = data?.feedTestUser;

  // handle loading
  if (loading || !feedTestUser) {
    return <LoadingView />;
  }

  //   render details
  return (
    <View pad="half">
      <FeedTestUserDetailsHeader feedTestUser={feedTestUser} viewer={viewer} />
      <GridBox />
      <Routes>
        <Route index element={<FeedTestUserDetailsInfo feedTestUser={feedTestUser} viewer={viewer} />} />
      </Routes>
    </View>
  );
};
