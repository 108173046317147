import classNames from "classnames";
import React from "react";
import { FlexProps } from "../Flex/Flex";
import styles from "./SingleRow.module.scss";

export const SingleRow: React.FC<FlexProps> = ({ className, children, ...rest }) => (
  <div className={classNames(styles.wrap, className)} {...rest}>
    {children}
  </div>
);
