import { JSONSchema7 } from "json-schema";

export const ImageCarouselMetadataSchema: JSONSchema7 = {
  description: "Carousel of images with title and description.",
  type: "object",
  properties: {
    title: {
      description: "Carousel title",
      minLength: 1,
      type: "string",
    },
    description: {
      description: "Carousel description text",
      minLength: 1,
      type: "string",
    },
    imageUrls: {
      description: "List of carousel image URLs to show",
      items: {
        type: "string",
        minLength: 1,
      },
      minItems: 2,
      type: "array",
    },
  },
  required: ["description", "imageUrls", "title"],
  $schema: "http://json-schema.org/draft-07/schema#",
};
