import React, { useMemo } from "react";
import { FormFieldOptions, GeneratedForm } from "../../components/GeneratedForm/GeneratedForm";
import { Modal, ModalProps } from "../../components/Modal/Modal";
import { CreateQuestionOptionMutation, TranslationMap, useCreateQuestionOptionMutation } from "../../schema";
import { getTranslationInput } from "../../services/getTranslationInput";

type CreateQuestionOptionData = {
  answer: TranslationMap;
  score: string;
  weight: string;
};

export interface CreateQuestionOptionModalProps extends ModalProps {
  questionId: string;
  onCompleted: (data: CreateQuestionOptionMutation) => void;
}

export const CreateQuestionOptionModal: React.FC<CreateQuestionOptionModalProps> = ({
  questionId,
  onCompleted,
  ...rest
}) => {
  const [createQuestionOption, createQuestionOptionResult] = useCreateQuestionOptionMutation({
    onCompleted,
  });

  const fields = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "translation",
        type: "text",
        name: "answer",
        label: "Answer",
        rules: { required: "Please provide question answer" },
      },
      {
        field: "translation",
        type: "text",
        name: "conclusionText",
        label: "Conclusion text",
        rules: { required: "Please provide question answer conclusion" },
      },
      {
        field: "text",
        type: "number",
        name: "score",
        label: "Score",
        rules: { required: "Please provide answer score value" },
      },
      {
        field: "text",
        type: "number",
        name: "weight",
        label: "Weight (determines the order of questions in test)",
        rules: { required: "Please provide answer weight" },
      },
    ],
    [],
  );

  return (
    <Modal title="Create new Question Option" error={createQuestionOptionResult.error} {...rest}>
      <GeneratedForm
        loading={createQuestionOptionResult.loading}
        error={createQuestionOptionResult.error}
        onSubmit={(data: CreateQuestionOptionData) => {
          createQuestionOption({
            variables: {
              ...data,
              questionId,
              answer: getTranslationInput("answer", data),
              conclusionText: getTranslationInput("conclusionText", data),
              score: parseInt(data.score),
              weight: parseInt(data.weight),
            },
          });
        }}
      >
        <GeneratedForm.Fields fields={fields} />
      </GeneratedForm>
    </Modal>
  );
};
