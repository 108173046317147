import React from "react";
import { WithCollaboration } from "../../components/Collaboration/Collaboration";
import { NameValueList, NameValueListItem } from "../../components/NameValuePair/NameValuePair";
import { CollaborationTypeEnum, InformativeContent } from "../../schema";
import { InformativeContentDetailsProps } from "./InformativeContentDetailsView";

export const InformativeContentDetailsInfo: React.FC<InformativeContentDetailsProps> = ({
  informativeContent,
  viewer,
}) => {
  return (
    <WithCollaboration
      referenceId={informativeContent.id}
      type={CollaborationTypeEnum.INFORMATIVE_CONTENT}
      viewer={viewer}
    >
      <NameValueList items={getTestQuestionNameValueList(informativeContent)} />
    </WithCollaboration>
  );
};

export function getTestQuestionNameValueList(informativeContent: InformativeContent): NameValueListItem[] {
  return [
    {
      name: "Image",
      value: <img src={informativeContent.imageSmall} alt="content" style={{ width: "8rem" }} />,
    },
    {
      name: "Title",
      value: informativeContent.title.EN,
    },
    {
      name: "Sub Title",
      value: informativeContent.subTitle.EN,
    },
    {
      name: "Text",
      value: informativeContent.text.EN,
    },
    {
      name: "Type",
      value: informativeContent.type,
    },
    {
      name: "Ordinal",
      value: informativeContent.ordinal,
    },
  ];
}
