import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { DataTable, DataTableAction, DataTableHeader, DataTableRow } from "../../components/DataTable/DataTable";
import { Filter, FilterBaseData } from "../../components/Filter/Filter";
import { FormFieldOptions } from "../../components/GeneratedForm/GeneratedForm";
import { Pagination } from "../../components/Pagination/Pagination";
import { View } from "../../components/View/View";
import { useUrlParams } from "../../hooks/useUrlParams";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import {
  ConditionModeEnum,
  MatchModeEnum,
  QuestionOption,
  SortingInput,
  TranslationMap,
  useQuestionOptionsQuery,
  UserScopeEnum,
} from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getPageCount } from "../../services/getPageCount";
import { getSkipTake } from "../../services/getSkipTake";
import { getSortDirectionFromParams } from "../../services/getSortDirectionFromParams";
import { getSortOptionsFromSearchParams } from "../../services/getSortOptionsFromSearchParams";
import { getUpdatedSortOptions } from "../../services/getUpdatedSortOptions";
import { getUrlSearchParamsString } from "../../services/getUrlSearchParamsString";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";

export type QuestionOptionType = Omit<QuestionOption, "question"> & {
  question: { id: string; question: TranslationMap };
};

export interface QuestionOptionDetailsProps extends AdminViewProps {
  questionOption: QuestionOptionType;
}

interface QuestionOptionFilterData extends FilterBaseData {
  answer: string;
  score: number | null;
  weight: number | null;
  conclusionText: string;
  sort: SortingInput[] | null;
}

export const TestQuestionOptionListView: React.FC<AdminViewProps> = () => {
  const navigate = useNavigate();

  // parse filter url parameters
  const params = useUrlParams<QuestionOptionFilterData>((params) => ({
    answer: params.answer ?? "",
    conclusionText: params.conclusionText ?? "",
    score: params.score ? parseInt(params.score) : null,
    weight: params.weight ? parseInt(params.weight) : null,
    matchMode: params.matchMode ? (params.matchMode as MatchModeEnum) : MatchModeEnum.STARTS_WITH,
    conditionMode: params.conditionMode ? (params.conditionMode as ConditionModeEnum) : ConditionModeEnum.AND,
    page: params.page ? parseInt(params.page, 10) : 1,
    sort: params.sort ? getSortOptionsFromSearchParams(params.sort) : null,
  }));

  const { data, loading, error } = useQuestionOptionsQuery({
    variables: {
      filter: {
        answer: params.answer,
        conclusionText: params.conclusionText,
        score: params.score || undefined,
        weight: params.weight || undefined,
      },
      pagination: {
        ...getSkipTake(params.page),
      },
      match: {
        matchMode: params.matchMode,
        conditionMode: params.conditionMode,
      },
      sort: params.sort ?? null,
    },
  });

  // get items
  const questionOptions = useMemo(
    () => data?.questionOptions.questionOptions ?? [],
    [data?.questionOptions.questionOptions],
  );

  const total = data?.questionOptions.total ?? 0;
  const pageCount = getPageCount(total);

  // // filters configuration
  const filters = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "text",
        type: "text",
        name: "answer",
        label: "Answer",
        defaultValue: params.answer,
      },
      {
        field: "text",
        type: "text",
        name: "conclusionText",
        label: "Conclusion text",
        defaultValue: params.conclusionText,
      },
      {
        field: "text",
        type: "text",
        name: "score",
        label: "Score",
        defaultValue: params.score ? params.score.toString() : "",
      },
      {
        field: "text",
        type: "text",
        name: "weight",
        label: "Weight",
        defaultValue: params.weight ? params.weight.toString() : "",
      },
    ],
    [params.answer, params.score, params.weight, params.conclusionText],
  );

  // handle sorting by column
  const onHeaderClick = useCallback(
    (databaseColumnName: string) =>
      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "clarity-score", page: "question-options" }),
        search: getUrlSearchParamsString({ ...params, sort: getUpdatedSortOptions(databaseColumnName, params.sort) }),
      }),
    [navigate, params],
  );

  // data table headers
  const headers = useMemo<DataTableHeader[]>(
    () => [
      {
        label: "Question",
        // for sorting should remove nested objects from according .graphql and add resolver questionsOption(s)
      },
      {
        label: "Answer",
        onClick: () => onHeaderClick("answer"),
        sortdirection: getSortDirectionFromParams("answer", params.sort),
      },
      {
        label: "Conclusion text",
        // no point to order by this column
      },
      {
        label: "Score",
        onClick: () => onHeaderClick("score"),
        sortdirection: getSortDirectionFromParams("score", params.sort),
      },
      {
        label: "Weight",
        onClick: () => onHeaderClick("weight"),
        sortdirection: getSortDirectionFromParams("weight", params.sort),
      },
    ],
    [onHeaderClick, params.sort],
  );

  // data table rows
  const rows = useMemo<DataTableRow[]>(
    () =>
      questionOptions.map((questionOption) => {
        return {
          id: questionOption.id,
          cells: [
            {
              content: questionOption.question.question.EN,
              linkUrl: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                menu: "clarity-score",
                page: "questions",
                id: questionOption.questionId,
              }),
            },
            {
              content: questionOption.answer.EN,
            },
            {
              content: questionOption.conclusionText.EN,
            },
            {
              content: questionOption.score,
            },
            {
              content: questionOption.weight,
            },
          ],
          actions: [
            {
              label: "View details",
              authorizedScopes: [
                UserScopeEnum.SUPERADMIN,
                UserScopeEnum.CLARITY_TEST,
                UserScopeEnum.CLARITY_TEST_UPDATE_INFO,
              ],
              onClick: (questionOptionId) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "clarity-score",
                    page: "question-options",
                    id: questionOptionId,
                  }),
                }),
            },
            {
              label: "Edit",
              authorizedScopes: [
                UserScopeEnum.SUPERADMIN,
                UserScopeEnum.CLARITY_TEST,
                UserScopeEnum.CLARITY_TEST_UPDATE_INFO,
              ],
              onClick: (questionOptionId) =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "clarity-score",
                    page: "question-options",
                    id: questionOptionId,
                    modifier: "edit",
                  }),
                }),
            },
            {
              label: "Open Question",
              authorizedScopes: [
                UserScopeEnum.SUPERADMIN,
                UserScopeEnum.CLARITY_TEST,
                UserScopeEnum.CLARITY_TEST_UPDATE_INFO,
              ],
              onClick: () =>
                navigate({
                  pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                    menu: "clarity-score",
                    page: "questions",
                    id: questionOption.questionId,
                  }),
                }),
            },
          ],
        };
      }),
    [navigate, questionOptions],
  );

  // data table bulk actions
  const bulkActions = useMemo<DataTableAction<string[]>[]>(() => [], []);

  // handle filters submit
  const onFilterSubmit = useCallback(
    (data: QuestionOptionFilterData) => {
      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "clarity-score", page: "question-options" }),
        search: getUrlSearchParamsString(data),
      });
    },
    [navigate],
  );

  // handle errors
  if (error) {
    return <ErrorView title="Fetching question options failed" error={error} />;
  }

  // render details
  return (
    <View scrollable>
      <Filter
        title="Question Options"
        fields={filters}
        loading={loading}
        matchMode={params.matchMode}
        conditionMode={params.conditionMode}
        onSubmit={onFilterSubmit}
      />
      <DataTable
        headers={headers}
        rows={rows}
        bulkActions={bulkActions}
        loading={loading}
        openAuthorizedScopes={[
          UserScopeEnum.SUPERADMIN,
          UserScopeEnum.CLARITY_TEST,
          UserScopeEnum.CLARITY_TEST_UPDATE_INFO,
        ]}
        onOpen={(row) =>
          navigate({
            pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
              menu: "clarity-score",
              page: "question-options",
              modifier: row.id,
            }),
          })
        }
      />
      <Pagination
        sticky
        pageCount={pageCount}
        currentPage={params.page}
        onPageChange={(page) =>
          navigate({
            pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "clarity-score", page: "question-options" }),
            search: getUrlSearchParamsString({ ...params, page }),
          })
        }
      />
    </View>
  );
};
