import React from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { GridBox } from "../../components/GridBox/GridBox";
import { View } from "../../components/View/View";
import { AdminViewParams } from "../../routes";
import { Question, QuestionOption, useQuestionsByIdQuery } from "../../schema";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";
import { TestQuestionDetailsEdit } from "./TestQuestionDetailsEdit";
import { TestQuestionDetailsHeader } from "./TestQuestionDetailsHeader";
import { TestQuestionDetailsInfo } from "./TestQuestionDetailsInfo";

export type QuestionType = Omit<Question, "questionOption"> & { questionOption: Omit<QuestionOption, "question">[] };

export interface QuestionDetailsProps extends AdminViewProps {
  question: QuestionType;
  refetch?: () => void;
}

export const TestQuestionDetailsView: React.FC<AdminViewProps> = ({ viewer }) => {
  // get identifier from url parameters
  const { id } = useParams<AdminViewParams>();

  // fetch info
  const { data, loading, error, refetch } = useQuestionsByIdQuery({
    variables: {
      filter: {
        questionId: id,
      },
    },
  });

  // expecting the id parameter to be provided
  if (!id) {
    return <ErrorView error="Expected identifier is not provided" />;
  }

  // get item info
  const question = data?.questions.questions[0] || undefined;

  // handle error
  if (error) {
    return <ErrorView title="Loading info failed" error={error} />;
  }

  // handle loading
  if (loading || !question) {
    return <LoadingView />;
  }

  // render details
  return (
    <View pad="half">
      <TestQuestionDetailsHeader question={question} viewer={viewer} />
      <GridBox />
      <Routes>
        <Route index element={<TestQuestionDetailsInfo question={question} viewer={viewer} refetch={refetch} />} />
        <Route path="edit" element={<TestQuestionDetailsEdit question={question} viewer={viewer} />} />
      </Routes>
    </View>
  );
};
