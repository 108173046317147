import classNames from "classnames";
import React from "react";
import { Container } from "../Container/Container";
import { FlexProps } from "../Flex/Flex";
import { Row } from "../Row/Row";
import styles from "./Pagination.module.scss";

export type OnPageChangeFn = (page: number) => void;

export interface PaginationProps extends FlexProps {
  pageCount: number;
  currentPage: number;
  sticky?: boolean;
  maxPagesToDisplay?: number;
  onPageChange?: OnPageChangeFn;
}

export const Pagination: React.FC<PaginationProps> = ({
  pageCount: providedPageCount,
  currentPage,
  sticky,
  maxPagesToDisplay = 9,
  className,
  onPageChange,
  ...rest
}) => {
  // user might provide calculated page number with decimal points (4.5 etc), round it up (to 5 etc)
  const pageCount = Math.ceil(providedPageCount);

  // no point to render pagination with fewer than two pages
  if (pageCount < 2) {
    return null;
  }

  // get page numbers to render
  const pageRange = getPageRange(pageCount, maxPagesToDisplay, currentPage);

  return (
    <Row
      mainAxisAlignment="center"
      className={classNames(styles.wrap, { [styles["wrap--sticky"]]: sticky }, className)}
      {...rest}
    >
      <Row className={styles.pagination}>
        <Container
          className={classNames(styles.page, styles["page--first"], { [styles["page--clickable"]]: currentPage > 1 })}
          onClick={currentPage > 1 && onPageChange ? () => onPageChange(1) : undefined}
        >
          First
        </Container>
        <Container
          className={classNames(styles.page, { [styles["page--clickable"]]: currentPage > 1 })}
          onClick={currentPage > 1 && onPageChange ? () => onPageChange(currentPage - 1) : undefined}
        >
          Previous
        </Container>
        {pageRange.map((pageNumber, index) => {
          const isSkippedPage = pageNumber === -1;
          const skippedPageCount = isSkippedPage ? pageRange[index + 1] - pageRange[index - 1] - 1 : 0;

          return (
            <Container
              key={pageNumber}
              title={isSkippedPage ? `Skipped ${skippedPageCount} pages` : undefined}
              className={classNames(styles.page, {
                [styles["page--current"]]: currentPage === pageNumber,
                [styles["page--clickable"]]: !isSkippedPage && onPageChange !== undefined,
              })}
              onClick={!isSkippedPage && onPageChange ? () => onPageChange(pageNumber) : undefined}
            >
              {isSkippedPage ? "..." : pageNumber}
            </Container>
          );
        })}
        <Container
          className={classNames(styles.page, { [styles["page--clickable"]]: currentPage < pageCount })}
          onClick={currentPage < pageCount && onPageChange ? () => onPageChange(currentPage + 1) : undefined}
        >
          Next
        </Container>
        <Container
          className={classNames(styles.page, styles["page--last"], {
            [styles["page--clickable"]]: currentPage < pageCount,
          })}
          onClick={currentPage < pageCount && onPageChange ? () => onPageChange(pageCount) : undefined}
        >
          Last
        </Container>
      </Row>
    </Row>
  );
};

function getPageRange(pageCount: number, maxPagesToDisplay: number, currentPage: number) {
  const allPages = new Array(pageCount).fill(null).map((_, index) => index + 1);

  if (pageCount <= maxPagesToDisplay) {
    return allPages;
  }

  const halfVisiblePages = Math.floor(maxPagesToDisplay / 2);

  let startPage = Math.max(currentPage - halfVisiblePages, 1);
  const missingFromStart = Math.max(halfVisiblePages - currentPage + 1, 0);
  const endPage = Math.min(currentPage + halfVisiblePages + missingFromStart, pageCount);

  const pagesFromEnd = pageCount - currentPage;
  const missingFromEnd = Math.max(halfVisiblePages - pagesFromEnd, 0);

  startPage = Math.max(startPage - missingFromEnd, 1);

  const pageRange = allPages.filter((pageNumber) => pageNumber >= startPage && pageNumber <= endPage);

  // show last page if not visible
  if (pageRange[pageRange.length - 1] !== pageCount) {
    // page value -1 means skipped page
    pageRange[pageRange.length - 2] = -1;
    pageRange[pageRange.length - 1] = pageCount;
  }

  return pageRange;
}
