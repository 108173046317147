import React, { useState } from "react";
import { useInterval } from "react-interval-hook";
import timeAgo from "s-ago";
import { formatDatetime } from "../../services/formatDatetime";
import { FlexProps } from "../Flex/Flex";
import { Span } from "../Span/Span";

export interface TimeAgoProps extends FlexProps {
  date: Date | string;
  interval?: number;
}

export const TimeAgo: React.FC<TimeAgoProps> = ({ date, interval = 30000, ...rest }) => {
  const useDate = typeof date === "string" ? new Date(date) : date;
  const [updateCount, setUpdateCount] = useState(0);

  // trigger re-render every interval seconds (defaults to 30 seconds)
  useInterval(() => {
    setUpdateCount(updateCount + 1);
  }, interval);

  return (
    <Span title={formatDatetime(useDate)} {...rest}>
      {timeAgo(useDate)}
    </Span>
  );
};
