import classNames from "classnames";
import React from "react";
import { Container } from "../Container/Container";
import { FlexProps } from "../Flex/Flex";
import { Loading } from "../Loading/Loading";
import { Row } from "../Row/Row";
import { Stack } from "../Stack/Stack";
import styles from "./ButtonGroup.module.scss";

export interface ButtonGroupProps extends FlexProps {
  equalWidth?: boolean;
}

export const ButtonGroup: React.FC<ButtonGroupProps> = ({ secondary, equalWidth, children, className, ...rest }) => (
  <Row
    className={classNames(
      styles["button-group"],
      {
        [styles["button-group--equal-width"]]: equalWidth,
        [styles["button-group--secondary"]]: secondary,
      },
      className,
    )}
    {...rest}
  >
    {children}
  </Row>
);

export interface ButtonGroupButtonProps extends FlexProps {
  danger?: boolean;
  loading?: boolean;
  leading?: React.ReactNode;
}

export const ButtonGroupButton: React.FC<ButtonGroupButtonProps> = ({
  secondary,
  danger,
  loading,
  leading,
  className,
  children,
  ...rest
}) => (
  <Row
    center
    className={classNames(
      styles["button-group-button"],
      {
        [styles["button-group-button--secondary"]]: secondary,
        [styles["button-group-button--danger"]]: danger,
        [styles["button-group-button--loading"]]: loading,
      },
      className,
    )}
    {...rest}
  >
    {leading && <div className={styles["leading-icon"]}>{leading}</div>}
    <Stack cover referenceFirstChild>
      <Container cover center className={styles["children"]}>
        {children}
      </Container>
      <Container center className={styles["loading"]}>
        <Loading />
      </Container>
    </Stack>
  </Row>
);
