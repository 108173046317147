import React, { useMemo } from "react";
import { Modal, ModalAction, ModalProps } from "../../components/Modal/Modal";
import { P } from "../../components/Paragraph/Paragraph";
import { UpdateAdminStatusMutation, useUpdateAdminStatusMutation, UserStatusEnum } from "../../schema";

export interface DisableAdminUserInfo {
  id: string;
  name: string;
}

export interface DisableAdminUserModalProps extends ModalProps {
  user: DisableAdminUserInfo;
  onCompleted: (data: UpdateAdminStatusMutation) => void;
}

export const DisableAdminUserModal: React.FC<DisableAdminUserModalProps> = ({
  user,
  onCompleted,
  onClickOutside,
  ...rest
}) => {
  const [updateAdminStatus, updateAdminStatusResult] = useUpdateAdminStatusMutation({
    refetchQueries: ["CollaborationById"],
    awaitRefetchQueries: true,
    onCompleted,
  });

  const actions = useMemo<ModalAction[]>(
    () => [
      {
        label: "Cancel",
        onClick: onClickOutside,
      },
      {
        label: "Disable user",
        danger: true,
        loading: updateAdminStatusResult.loading,
        onClick: () =>
          updateAdminStatus({
            variables: {
              userIds: [user.id],
              status: UserStatusEnum.DISABLED,
            },
          }),
      },
    ],
    [updateAdminStatus, updateAdminStatusResult.loading, onClickOutside, user.id],
  );

  return (
    <Modal
      title="Confirm disable user"
      error={updateAdminStatusResult.error}
      actions={actions}
      onClickOutside={onClickOutside}
      {...rest}
    >
      <P center>
        Are you sure you want to disable user <strong>{user.name}</strong>?
      </P>
      <P center small padTop="half">
        The disabled user is logged out and is unable to log back in. The user can be re-activated in the future.
      </P>
    </Modal>
  );
};
