import React from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { GridBox } from "../../components/GridBox/GridBox";
import { View } from "../../components/View/View";
import { AdminViewParams } from "../../routes";
import { AdminFeedItemByIdQueryResult, useAdminFeedItemByIdQuery, useAdminFeedTopicsQuery } from "../../schema";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";
import { AdminFeedItemDetailsEdit } from "./AdminFeedItemDetailsEdit";
import { AdminFeedItemDetailsHeader } from "./AdminFeedItemDetailsHeader";
import { AdminFeedItemDetailsInfo } from "./AdminFeedItemDetailsInfo";

export type AdminFeedItemInfo = NonNullable<AdminFeedItemByIdQueryResult["data"]>["adminFeedItem"];

export interface AdminFeedItemDetailsProps extends AdminViewProps {
  adminFeedItem: AdminFeedItemInfo;
}

export const AdminFeedItemDetailsView: React.FC<AdminViewProps> = ({ viewer }) => {
  // get identifier from url parameters
  const { id } = useParams<AdminViewParams>();

  // fetch info
  const { data, loading, error } = useAdminFeedItemByIdQuery({
    variables: {
      adminFeedItemId: id ?? "",
    },
  });

  const { data: topics, loading: topicsLoading, error: topicsError } = useAdminFeedTopicsQuery();

  // expecting the id parameter to be provided
  if (!id) {
    return <ErrorView error="Expected identifier is not provided" />;
  }

  // get item info
  const adminFeedItem = data?.adminFeedItem;

  // handle error
  if (error || topicsError) {
    return (
      <ErrorView
        title="Loading info failed"
        error={error || topicsError || "Something went wrong, this should not happen"}
      />
    );
  }

  // handle loading
  if (loading || !adminFeedItem || topicsLoading || !topics) {
    return <LoadingView />;
  }

  // render details
  return (
    <View pad="half">
      <AdminFeedItemDetailsHeader adminFeedItem={adminFeedItem} viewer={viewer} />
      <GridBox />
      <Routes>
        <Route index element={<AdminFeedItemDetailsInfo adminFeedItem={adminFeedItem} viewer={viewer} />} />
        <Route
          path="edit"
          element={
            <AdminFeedItemDetailsEdit adminFeedItem={adminFeedItem} topics={topics.adminFeedTopics} viewer={viewer} />
          }
        />
      </Routes>
    </View>
  );
};
