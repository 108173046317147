import { JSONSchema7 } from "json-schema";

export const ConsultationMetadataSchema: JSONSchema7 = {
  description: "Recommends the user to book a doctor's constultation.",
  type: "object",
  properties: {
    title: {
      description: "Title",
      minLength: 1,
      type: "string",
    },
    videoImageUrl: {
      description: "The video placeholder image url",
      minLength: 1,
      type: "string",
    },
    videoId: {
      description: "The vimeo video id to show",
      minLength: 1,
      type: "string",
    },
    consultationId: {
      description: "Unique identifier of the consultation",
      minLength: 1,
      type: "string",
    },
  },
  required: ["consultationId", "title", "videoId", "videoImageUrl"],
  $schema: "http://json-schema.org/draft-07/schema#",
};
