import classNames from "classnames";
import React from "react";
import { formatValue } from "../../services/formatValue";
import { Column } from "../Column/Column";
import { FlexProps } from "../Flex/Flex";
import { P } from "../Paragraph/Paragraph";
import { Row } from "../Row/Row";
import styles from "./NameValuePair.module.scss";

export interface NameValuePairProps extends FlexProps {
  name: React.ReactNode;
  vertical?: boolean;
}

export const NameValuePair: React.FC<NameValuePairProps> = ({ name, vertical, className, children, ...rest }) => {
  if (vertical) {
    return (
      <Column className={classNames(styles["name-value-pair"], className)} {...rest}>
        {typeof name === "string" ? (
          <P darker marginBottom={1}>
            {name}
          </P>
        ) : (
          name
        )}
        {formatValue(children)}
      </Column>
    );
  }

  return (
    <Row
      mainAxisAlignment="space-between"
      crossAxisAlignment="center"
      className={classNames(styles["name-value-pair"], className)}
      {...rest}
    >
      {typeof name === "string" ? <P darker>{name}</P> : name}
      {formatValue(children)}
    </Row>
  );
};

export interface NameValueListItem {
  name: React.ReactNode;
  value: React.ReactNode;
}

export interface NameValueListProps extends FlexProps {
  items: NameValueListItem[];
}

export const NameValueList: React.FC<NameValueListProps> = ({ items, ...rest }) => (
  <Column {...rest}>
    {items.map((item, index) => (
      <NameValuePair vertical key={index} name={item.name}>
        {item.value}
      </NameValuePair>
    ))}
  </Column>
);
