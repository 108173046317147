import { format as doFormat } from "date-fns";

export function formatDatetime(date: number | Date | string | undefined | null, format = "dd.MM.yyyy HH:mm") {
  if (date === null || date === undefined) {
    return "n/a";
  }

  const useDate: number | Date = typeof date === "string" ? new Date(date) : date;

  return doFormat(useDate, format);
}
