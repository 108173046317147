import classNames from "classnames";
import React, { useMemo, useRef } from "react";
import { useEffect } from "react";
import {
  useForm,
  Controller,
  SubmitHandler,
  FieldValues,
  useFieldArray,
  FormProvider,
  useFormContext,
} from "react-hook-form";
import { LocaleEnum, TranslationMap, useMediaCategoryQuery } from "../../schema";
import assertNever from "../../services/assertNever";
import { assertUnreachable } from "../../services/assertUnreachable";
import { getFieldErrors } from "../../services/getFieldErrors";
import { getLocaleName } from "../../services/getLocaleName";
import { isAuthorizationError } from "../../services/isAuthorizationErrors";
import { DateRange } from "../../services/parseDateRange";
import { toggleOption } from "../../services/toggleOption";
import { ErrorView } from "../../views/ErrorView/ErrorView";
import { LoadingView } from "../../views/LoadingView/LoadingView";
import { NotAuthorizedView } from "../../views/NotAuthorizedView/NotAuthorizedView";
import { Alert } from "../Alert/Alert";
import { BlockButton } from "../BlockButton/BlockButton";
import { Column } from "../Column/Column";
import { Container } from "../Container/Container";
import { DateField } from "../DateField/DateField";
import { Flex } from "../Flex/Flex";
import { Form } from "../Form/Form";
import { MarkdownField } from "../MarkdownField/MarkdownField";
import { Row } from "../Row/Row";
import { SelectField } from "../SelectField/SelectField";
import { TextArea } from "../TextArea/TextArea";
import { TextButton } from "../TextButton/TextButton";
import { TextField } from "../TextField/TextField";
import { Title } from "../Title/Title";
import styles from "./GeneratedForm.module.scss";
import {
  ArrayFieldOptions,
  CheckboxFieldOptions,
  DateFieldOptions,
  GeneratedFormProps,
  MarkdownFieldOptions,
  RadioFieldOptions,
  RenderFieldOptions,
  TextareaFieldOptions,
  TextFieldOptions,
  TranslationFieldOptions,
  UploadFieldOptions,
} from "./GeneratedForm.types";

enum MediaCategoryFields {
  MEDIA_CATEGORY_ID = "mediaCategoryId",
  PRODUCT_CODE = "productCode",
  CONSUMABLE_CODE = "consumableCode",
}

export type FormFieldOptions =
  | TextFieldOptions
  | TextareaFieldOptions
  | MarkdownFieldOptions
  | DateFieldOptions
  | CheckboxFieldOptions
  | RadioFieldOptions
  | TranslationFieldOptions
  | UploadFieldOptions
  | ArrayFieldOptions;

export function GeneratedForm({
  loading,
  clearable,
  error: serverError,
  title,
  actions,
  sidebar,
  layout = "column",
  submitText = "Save",
  onSubmit,
  children,
  ...rest
}: GeneratedFormProps) {
  const formMethods = useForm();
  const { formState, handleSubmit, clearErrors, reset, setError } = formMethods;
  const formRef = useRef<HTMLFormElement>(null);

  // handle validation errors from server
  useEffect(() => {
    const errors = getFieldErrors(serverError, formState.errors);

    if (errors) {
      Object.keys(errors).forEach((name) => {
        if (errors[name]?.type === "validate") {
          setError(name, { type: errors[name]?.type || "validate", message: errors[name]?.message });
        }
      });
    }
  }, [serverError, formState.errors, setError]);

  // handles form submit
  const onFormSubmit: SubmitHandler<FieldValues> = useMemo(
    () => (data) => {
      if (onSubmit) {
        onSubmit(data);

        reset();
      }
    },
    [onSubmit, reset],
  );

  // clears all clearable fields
  const clearAllFields = useMemo(
    () => () => {
      reset();
      clearErrors();

      // submit form after clearing form
      if (onSubmit) {
        handleSubmit(onSubmit)();
      }
    },
    [clearErrors, reset, handleSubmit, onSubmit],
  );

  // handle authorization errors
  if (isAuthorizationError(serverError)) {
    return <NotAuthorizedView />;
  }

  // combine server and validation errors
  const errors = getFieldErrors(serverError, formState.errors);

  // handle server errors
  if (serverError?.networkError || (serverError && Object.keys(errors).length === 0)) {
    return <ErrorView title="Oops! Something went wrong." error={serverError} />;
  }

  // expecting title when using actions
  if (actions && !title) {
    throw new Error("When providing generated form actions, please also provide a title");
  }

  // build main form body
  const formBody = (
    <Flex
      overflow
      row={layout === "grid"}
      expanded={layout === "grid"}
      className={classNames({ [styles["layout-grid"]]: layout === "grid" })}
    >
      {React.Children.map(children, (child) => (
        <Container overflow expanded={layout === "column"}>
          {child}
        </Container>
      ))}
    </Flex>
  );

  const formSubmitAndClear = (
    <>
      <BlockButton inline tertiary loading={loading} type="submit">
        {submitText}
      </BlockButton>
      {clearable && (
        <TextButton tiny onClick={() => clearAllFields()}>
          clear
        </TextButton>
      )}
    </>
  );

  return (
    <FormProvider {...formMethods}>
      <Form ref={formRef} onSubmit={handleSubmit(onFormSubmit)} {...rest}>
        <Title actions={actions}>{title}</Title>

        {sidebar ? (
          <Row>
            <Column expanded>{formBody}</Column>
            <Column padLeft="half">{sidebar}</Column>
          </Row>
        ) : (
          formBody
        )}

        {layout === "grid" && <Column style={{ gridColumnEnd: 9 }}>{formSubmitAndClear}</Column>}

        {layout === "column" && (
          <Column marginTop="half" crossAxisAlignment="flex-end">
            {formSubmitAndClear}
          </Column>
        )}
      </Form>
    </FormProvider>
  );
}

export const FormField: React.FC<RenderFieldOptions> = (props) => {
  const { clearErrors, setValue } = useFormContext();
  const fieldType = props.field.field;

  // clears given field
  const clearField = useMemo(
    () => (field: FormFieldOptions) => {
      const clearedValue = field.field === "checkbox" ? [] : "";

      setValue(field.name, clearedValue);
    },
    [setValue],
  );

  const onClear = props.field.clearable
    ? () => {
        clearField(props.field);
        clearErrors();
      }
    : undefined;

  const field = () => {
    switch (fieldType) {
      case "text":
        if (props.field.name.split(".").find((name) => name === MediaCategoryFields.MEDIA_CATEGORY_ID)) {
          return (
            <MediaCategoryFormRadioField
              onClear={onClear}
              {...(props as RenderFieldOptions<RadioFieldOptions>)}
              categoryType={MediaCategoryFields.MEDIA_CATEGORY_ID}
            />
          );
        }
        if (props.field.name.split(".").find((name) => name === MediaCategoryFields.PRODUCT_CODE)) {
          return (
            <MediaCategoryFormRadioField
              onClear={onClear}
              {...(props as RenderFieldOptions<RadioFieldOptions>)}
              categoryType={MediaCategoryFields.PRODUCT_CODE}
            />
          );
        }
        if (props.field.name.split(".").find((name) => name === MediaCategoryFields.CONSUMABLE_CODE)) {
          return (
            <MediaCategoryFormRadioField
              onClear={onClear}
              {...(props as RenderFieldOptions<RadioFieldOptions>)}
              categoryType={MediaCategoryFields.CONSUMABLE_CODE}
            />
          );
        }
        return <FormTextField onClear={onClear} {...(props as RenderFieldOptions<TextFieldOptions>)} />;

      case "textarea":
        return <FormTextAreaField onClear={onClear} {...(props as RenderFieldOptions<TextareaFieldOptions>)} />;

      case "markdown":
        return <FormMarkdownField onClear={onClear} {...(props as RenderFieldOptions<MarkdownFieldOptions>)} />;

      case "date":
        return <FormDateField onClear={onClear} {...(props as RenderFieldOptions<DateFieldOptions>)} />;

      case "checkbox":
        return <FormCheckboxField onClear={onClear} {...(props as RenderFieldOptions<CheckboxFieldOptions>)} />;

      case "radio":
        return <FormRadioField onClear={onClear} {...(props as RenderFieldOptions<RadioFieldOptions>)} />;

      case "translation":
        return <FormTranslationField onClear={onClear} {...(props as RenderFieldOptions<TranslationFieldOptions>)} />;

      case "upload":
        return <FormUploadField onClear={onClear} {...(props as RenderFieldOptions<UploadFieldOptions>)} />;

      case "array":
        return <FormArrayField onClear={onClear} {...(props as RenderFieldOptions<ArrayFieldOptions>)} />;
      default:
        assertUnreachable(fieldType, `Unexpected field type "${fieldType}" not handled`);
    }
  };

  return field();
};

const FormFields: React.FC<{ fields: FormFieldOptions[] }> = ({ fields }) => {
  return (
    <>
      {fields.map((field) => (
        <GeneratedForm.Field field={field} key={field.name} />
      ))}
    </>
  );
};

GeneratedForm.Field = FormField;
GeneratedForm.Fields = FormFields;

export const FormTextField: React.FC<RenderFieldOptions<TextFieldOptions>> = ({ field, onClear }) => {
  const { control, getFieldState } = useFormContext();
  const { clearable: _clearable, defaultValue, onChange: onFieldChange, ...fieldRest } = field;

  return (
    <Controller
      name={field.name}
      control={control}
      rules={field.rules}
      defaultValue={defaultValue ?? ""}
      render={({ field }) => (
        <TextField
          {...fieldRest}
          {...field}
          error={getFieldState(field.name).error}
          onClear={onClear}
          onChange={(e) => {
            if (field.onChange) {
              field.onChange(e);
            }

            if (onFieldChange) {
              onFieldChange(e);
            }
          }}
        />
      )}
    />
  );
};

const FormTextAreaField: React.FC<RenderFieldOptions<TextareaFieldOptions>> = ({ field, onClear }) => {
  const { control, formState } = useFormContext();
  const { clearable: _clearable, defaultValue, onChange: onFieldChange, ...fieldRest } = field;

  return (
    <Controller
      name={field.name}
      control={control}
      rules={field.rules}
      defaultValue={defaultValue ?? ""}
      render={({ field }) => (
        <TextArea
          {...fieldRest}
          {...field}
          error={formState.errors[field.name]}
          onClear={onClear}
          onChange={(e) => {
            if (field.onChange) {
              field.onChange(e);
            }

            if (onFieldChange) {
              onFieldChange(e);
            }
          }}
        />
      )}
    />
  );
};

const FormMarkdownField: React.FC<RenderFieldOptions<MarkdownFieldOptions>> = ({ field, onClear }) => {
  const { control, formState } = useFormContext();
  const { clearable: _clearable, defaultValue, onChange: onFieldChange, ...fieldRest } = field;

  return (
    <Controller
      name={field.name}
      control={control}
      rules={field.rules}
      defaultValue={defaultValue ?? ""}
      render={({ field }) => (
        <MarkdownField
          {...fieldRest}
          {...field}
          error={formState.errors[field.name]}
          onClear={onClear}
          onChange={(e) => {
            if (field.onChange) {
              field.onChange(e);
            }

            if (onFieldChange) {
              onFieldChange(e);
            }
          }}
        />
      )}
    />
  );
};

const FormDateField: React.FC<RenderFieldOptions<DateFieldOptions>> = ({ field, onClear }) => {
  const { control, formState } = useFormContext();
  const { clearable: _clearable, defaultValue, range, onChange: onFieldChange, ...fieldRest } = field;

  return (
    <Controller
      name={field.name}
      control={control}
      rules={field.rules}
      defaultValue={defaultValue}
      render={({ field }) => {
        const selected = (range && Array.isArray(field.value) ? field.value[0] : field.value) as Date | null;

        return (
          <DateField
            {...fieldRest}
            {...field}
            error={formState.errors[field.name]}
            // TODO: for some reason the typing requires selectsRange to be undefined
            selectsRange={range as any}
            shouldCloseOnSelect={!range}
            selected={selected}
            startDate={range && Array.isArray(field.value) ? field.value[0] : undefined}
            endDate={range && Array.isArray(field.value) ? field.value[1] : undefined}
            onChange={(date) => {
              if (field.onChange) {
                field.onChange(date as Date);
              }

              if (onFieldChange) {
                onFieldChange(date as (Date & DateRange) | null);
              }
            }}
            onClear={onClear}
          />
        );
      }}
    />
  );
};

const FormCheckboxField: React.FC<RenderFieldOptions<CheckboxFieldOptions>> = ({ field, onClear }) => {
  const { control, formState } = useFormContext();
  const { clearable: _clearable, defaultValue: defaultValues, ...fieldRest } = field;

  return (
    <Controller
      name={field.name}
      control={control}
      rules={field.rules}
      defaultValue={defaultValues ?? []}
      render={({ field }) => (
        <SelectField
          {...fieldRest}
          {...field}
          multi
          selected={field.value}
          error={formState.errors[field.name]}
          onToggle={(option) => field.onChange(toggleOption(field.value, option.value))}
          onClear={onClear}
        />
      )}
    />
  );
};

// hanfle media category field and its subfields
const MediaCategoryFormRadioField: React.FC<
  RenderFieldOptions<RadioFieldOptions> & { categoryType: MediaCategoryFields }
> = ({ field, categoryType, onClear }) => {
  const { data, loading, error } = useMediaCategoryQuery();

  const { control, setError, watch, resetField, getFieldState } = useFormContext();
  const { clearable: _clearable, onChange: onFieldChange, ...fieldRest } = field;

  useEffect(() => {
    if (error) {
      setError(categoryType, {
        type: "required",
        message: error?.message,
      });
    }
  }, [data, error, setError, field.name, categoryType]);

  if (loading) {
    return <LoadingView />;
  }

  if (error && !data) {
    return <ErrorView error={error} />;
  }

  // keep track of metadata changes
  // flat removes 1 empty slot item from list
  // TODO: investigate why this happens
  const formValue = watch("metadata").flat();

  let options: { label: string; value: string }[] = [];

  // get selected media category id
  const mediaCategoryId = formValue.find((r: Object) => Object.keys(r).includes("mediaCategoryId"))?.mediaCategoryId;
  const productId = formValue.find((r: Object) => Object.keys(r).includes("productCode"))?.productCode;

  switch (categoryType) {
    case MediaCategoryFields.MEDIA_CATEGORY_ID:
      options =
        data?.adminFeedMediaCategory.map((category) => ({
          label: category.mediaCategoryCode,
          value: category.mediaCategoryCode,
        })) || [];
      break;
    case MediaCategoryFields.PRODUCT_CODE:
      // filter product codes
      const products = data?.adminFeedMediaCategory.find((category) => category.mediaCategoryCode === mediaCategoryId);

      // construct product options
      options =
        products?.product.map((product) => ({
          label: product.productCode,
          value: product.productCode,
        })) || [];

      break;
    case MediaCategoryFields.CONSUMABLE_CODE:
      // filter consumable codes
      const consumableData = data?.adminFeedMediaCategory
        .find((category) => category.mediaCategoryCode === mediaCategoryId)
        ?.product.find((product) => product.productCode === productId);

      // construct consumables options
      options =
        consumableData?.consumableCode.map((consumableCode) => ({
          label: consumableCode,
          value: consumableCode,
        })) || [];

      break;
    default:
      assertNever(categoryType);
  }

  if (data && data.adminFeedMediaCategory.length < 1 && categoryType === MediaCategoryFields.MEDIA_CATEGORY_ID) {
    return (
      <Alert severity={"warning"} marginTop={"half"}>
        No media categories found. Feed item can still be created but without media linking.{" "}
        <b>Plese notify mindspa team of this.</b>
      </Alert>
    );
  }

  return (
    <Controller
      name={field.name}
      control={control}
      rules={field.rules}
      defaultValue={undefined}
      render={({ field }) => (
        <SelectField
          {...fieldRest}
          options={options}
          hidden={options.length < 1}
          selected={field.value}
          error={getFieldState(field.name).error}
          onToggle={(option) => {
            // on field change reset sub fields
            if (categoryType === MediaCategoryFields.MEDIA_CATEGORY_ID) {
              resetField("metadata.3.productCode", { defaultValue: null });
              resetField("metadata.4.consumableCode", { defaultValue: null });
            }

            // on field change reset sub fields
            if (categoryType === MediaCategoryFields.PRODUCT_CODE) {
              resetField("metadata.4.consumableCode", { defaultValue: null });
            }

            if (onFieldChange) {
              onFieldChange(option);
            }

            field.onChange(option.value === field.value ? null : option.value);
          }}
          onClear={onClear}
        />
      )}
    />
  );
};

const FormRadioField: React.FC<RenderFieldOptions<RadioFieldOptions>> = ({ field, onClear }) => {
  const { control, formState } = useFormContext();
  const { clearable: _clearable, defaultValue, onChange: onFieldChange, ...fieldRest } = field;

  return (
    <Controller
      name={field.name}
      control={control}
      rules={field.rules}
      defaultValue={defaultValue}
      render={({ field }) => (
        <SelectField
          {...fieldRest}
          {...field}
          selected={field.value}
          error={formState.errors[field.name]}
          onToggle={(option) => {
            if (onFieldChange) {
              onFieldChange(option);
            }

            field.onChange(option.value === field.value ? null : option.value);
          }}
          onClear={onClear}
        />
      )}
    />
  );
};

const FormTranslationField: React.FC<RenderFieldOptions<TranslationFieldOptions>> = ({ field, onClear }) => {
  const { control, formState } = useFormContext();
  const { clearable: _clearable, defaultValue, label, onChange: onFieldChange, ...fieldRest } = field;

  // render separate text field for every locale
  return (
    <>
      {Object.keys(LocaleEnum).map((_locale) => {
        const locale = _locale as keyof TranslationMap;
        const name = `${field.name}-${locale}`;

        return (
          <Controller
            key={name}
            name={name}
            control={control}
            rules={field.rules}
            defaultValue={defaultValue ? defaultValue[locale] ?? "" : ""}
            render={({ field }) => (
              <TextField
                {...fieldRest}
                {...field}
                label={`${getLocaleName(locale as LocaleEnum)} ${label.toLowerCase()}`}
                error={formState.errors[name]}
                onClear={onClear}
                onChange={(e) => {
                  if (field.onChange) {
                    field.onChange(e);
                  }

                  if (onFieldChange) {
                    onFieldChange(locale as LocaleEnum, e.target.value);
                  }
                }}
              />
            )}
          />
        );
      })}
    </>
  );
};

const FormUploadField: React.FC<RenderFieldOptions<UploadFieldOptions>> = ({ field, onClear }) => {
  const { control, formState } = useFormContext();
  const { clearable: _clearable, defaultValue, onChange: onFieldChange, ...fieldRest } = field;

  const useDefaultValue = defaultValue instanceof File ? defaultValue.name : "";

  return (
    <Controller
      name={field.name}
      control={control}
      rules={field.rules}
      defaultValue={useDefaultValue}
      render={({ field }) => (
        <TextField
          {...fieldRest}
          {...field}
          type="file"
          error={formState.errors[field.name]}
          onClear={onClear}
          onChange={(e) => {
            if (field.onChange) {
              field.onChange(e);
            }

            if (onFieldChange) {
              onFieldChange(e.target.files && e.target.files.length > 0 ? e.target.files[0] : null);
            }
          }}
        />
      )}
    />
  );
};

const FormArrayField: React.FC<RenderFieldOptions<ArrayFieldOptions>> = ({ field }) => {
  const { control, getFieldState } = useFormContext();
  const { clearable: _clearable, defaultValue: _defaultValue, ...fieldRest } = field;
  const { fields, append, remove } = useFieldArray({ control, name: field.name });

  useEffect(() => {
    // make sure array has at least one item
    if (fields.length === 0) {
      append({ value: "" });
    }
  }, [fields, append]);

  const addItem = () => append({ value: "" });

  const removeItem = (index: number) => () => remove(index);

  const fieldName = (index: number) => `${field.name}.${index}.value`;

  return (
    <Container>
      <h5>{field.label}</h5>
      {fields.map((arrayField, index) => (
        <Row key={arrayField.id} mainAxisAlignment="space-between">
          <Flex flex={1}>
            <Controller
              name={fieldName(index)}
              control={control}
              rules={field.rules}
              //@ts-ignore
              defaultValue={arrayField.value}
              render={({ field }) => (
                <TextField {...fieldRest} {...field} type="text" error={getFieldState(field.name).error} />
              )}
            />
          </Flex>
          <BlockButton inline secondary small onClick={removeItem(index)}>
            Remove
          </BlockButton>
        </Row>
      ))}
      <BlockButton onClick={addItem}>Add</BlockButton>
    </Container>
  );
};
