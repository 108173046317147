import React, { useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { BlockButton } from "../../components/BlockButton/BlockButton";
import { WithCollaboration } from "../../components/Collaboration/Collaboration";
import { Column } from "../../components/Column/Column";
import { Container } from "../../components/Container/Container";
import { Flex } from "../../components/Flex/Flex";
import { Form } from "../../components/Form/Form";
import { FormField } from "../../components/GeneratedForm/GeneratedForm";
import { toastState, ToastType } from "../../components/Toast/Toast";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import { CollaborationTypeEnum, useUpdateUserFeedItemMetadataSettingMutation } from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getMetadataFromFields } from "../../services/getMetadataFromFields";
import { getMetadataFormFields } from "../../services/getMetadataSchema";
import { UserFeedItemMetadataSettingDetailsProps } from "./UserFeedItemMetadataSettingView";

export const UserFeedItemMetadataSettingDetailsEdit: React.FC<UserFeedItemMetadataSettingDetailsProps> = ({
  userFeedItemMetadataSetting,
  viewer,
}) => {
  const navigate = useNavigate();
  const setToast = useSetRecoilState(toastState);
  const [updateUserFeedItemMetadataSetting] = useUpdateUserFeedItemMetadataSettingMutation({
    refetchQueries: ["CollaborationById"],
    awaitRefetchQueries: true,
    onCompleted: ({ updateUserFeedItemMetadataSetting }) => {
      setToast({
        type: ToastType.SUCCESS,
        title: "User feed item metadata settings has been updated",
        message: `Updated user feed item "${updateUserFeedItemMetadataSetting.type}" settings.`,
        isOpen: true,
      });

      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
          menu: "user-feed",
          page: "metadata",
          id: userFeedItemMetadataSetting.id,
        }),
      });
    },
  });

  // metadata form configuration
  const metadataFormFields = getMetadataFormFields(
    userFeedItemMetadataSetting.type,
    userFeedItemMetadataSetting.metadata,
  );

  const formRef = useRef<HTMLFormElement>(null);

  const formMethods = useForm({
    defaultValues: {
      metadata: metadataFormFields.reduce((acc, cur) => {
        const name = cur.name.split(".")[2];

        acc.push({ [name]: cur.defaultValue });

        return acc;
      }, [] as Record<string, any>[]),
    },
  });
  const { handleSubmit } = formMethods;

  const onSubmit = async (data: any) =>
    updateUserFeedItemMetadataSetting({
      variables: {
        userFeedItemMetadataSettingId: userFeedItemMetadataSetting.id,
        type: userFeedItemMetadataSetting.type,
        metadata: getMetadataFromFields(data.metadata, userFeedItemMetadataSetting.type),
      },
    });

  return (
    <WithCollaboration
      referenceId={userFeedItemMetadataSetting.id}
      type={CollaborationTypeEnum.USER_FEED_ITEM_METADATA_SETTING}
      viewer={viewer}
    >
      <FormProvider {...formMethods}>
        <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <Flex overflow row={false} expanded={false}>
            {metadataFormFields.map((field) => (
              <Container overflow key={field.name} expanded>
                <FormField field={field} />
              </Container>
            ))}
          </Flex>

          <Column marginTop="half" crossAxisAlignment="flex-end">
            <BlockButton inline tertiary loading={false} type="submit">
              Update item
            </BlockButton>
          </Column>
        </Form>
      </FormProvider>
    </WithCollaboration>
  );
};
