import { ApolloProvider } from "@apollo/client";
import React from "react";
import { IconContext } from "react-icons";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { App } from "./App";
import { Changelog } from "./components/Changelog/Changelog";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import { Toast } from "./components/Toast/Toast";
import { Version } from "./components/Version/Version";
import { setupApolloClient } from "./services/setupApolloClient";
import { LoadingView } from "./views/LoadingView/LoadingView";
import "./theme/main.scss";

const apolloClient = setupApolloClient();

export const Root: React.FC = () => (
  // test with strict mode at some points but disable during normal developmet
  // <React.StrictMode>
  <RecoilRoot>
    <ErrorBoundary>
      <ApolloProvider client={apolloClient}>
        <React.Suspense fallback={<LoadingView />}>
          <IconContext.Provider value={{ className: "icon" }}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </IconContext.Provider>
          <Version />
          <Toast />
          <Changelog />
        </React.Suspense>
      </ApolloProvider>
    </ErrorBoundary>
  </RecoilRoot>
  // </React.StrictMode>
);
