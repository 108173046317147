import React from "react";
import { MatchModeEnum, ConditionModeEnum } from "../../schema";
import { getEnumFormOptions } from "../../services/getEnumFormOptions";
import { UrlSearchParam } from "../../services/getUrlSearchParamsString";
import { Column } from "../Column/Column";
import { FormFieldOptions, GeneratedForm } from "../GeneratedForm/GeneratedForm";
import { GeneratedFormProps } from "../GeneratedForm/GeneratedForm.types";

export type FilterFieldType = "text" | "date" | "checkbox" | "radio";

export interface FilterBaseData extends Record<string, UrlSearchParam> {
  matchMode: MatchModeEnum;
  conditionMode: ConditionModeEnum;
  page: number;
}

export interface FilterProps extends GeneratedFormProps {
  fields: FormFieldOptions[];
  matchMode: MatchModeEnum;
  conditionMode: ConditionModeEnum;
}

export const Filter: React.FC<FilterProps> = ({ matchMode, conditionMode, fields, ...rest }) => {
  // add additional filter fields
  const augmentedFields: FormFieldOptions[] = [
    ...fields.map((field) => ({
      ...field,
      clearable: true,
    })),
    {
      field: "radio",
      name: "matchMode",
      label: "Match mode",
      options: getEnumFormOptions(MatchModeEnum),
      defaultValue: matchMode.toString(),
    },
    {
      field: "radio",
      name: "conditionMode",
      label: "Condition mode",
      options: [
        { value: ConditionModeEnum.AND.toString(), label: "Require all conditions" },
        { value: ConditionModeEnum.OR.toString(), label: "Require any condition" },
      ],
      defaultValue: conditionMode.toString(),
    },
  ];

  return (
    <Column overflow pad="half">
      <GeneratedForm clearable submitText="Search" layout="grid" {...rest}>
        {augmentedFields.map((field) => (
          <GeneratedForm.Field field={field} key={field.name} />
        ))}
      </GeneratedForm>
    </Column>
  );
};
