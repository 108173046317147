import React, { useMemo } from "react";
import { Modal, ModalAction, ModalProps } from "../../components/Modal/Modal";
import { P } from "../../components/Paragraph/Paragraph";
import { ResetAdminPasswordMutation, useResetAdminPasswordMutation } from "../../schema";

export interface ResetUserPasswordInfo {
  id: string;
  name: string;
}

export interface ResetUserPasswordModalProps extends ModalProps {
  user: ResetUserPasswordInfo;
  onCompleted: (data: ResetAdminPasswordMutation) => void;
}

export const ResetUserPasswordModal: React.FC<ResetUserPasswordModalProps> = ({
  user,
  onCompleted,
  onClickOutside,
  ...rest
}) => {
  const [resetAdminPassword, resetAdminPasswordResult] = useResetAdminPasswordMutation({
    onCompleted,
  });

  const actions = useMemo<ModalAction[]>(
    () => [
      {
        label: "Cancel",
        onClick: onClickOutside,
      },
      {
        label: "Reset password",
        danger: true,
        loading: resetAdminPasswordResult.loading,
        onClick: () =>
          resetAdminPassword({
            variables: {
              userId: user.id,
            },
          }),
      },
    ],
    [onClickOutside, resetAdminPasswordResult.loading, resetAdminPassword, user.id],
  );

  return (
    <Modal
      title="Confirm reset user password"
      error={resetAdminPasswordResult.error}
      actions={actions}
      onClickOutside={onClickOutside}
      {...rest}
    >
      <P center>
        Are you sure you want to reset password for <strong>{user.name}</strong>?
      </P>
    </Modal>
  );
};
