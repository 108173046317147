import classNames from "classnames";
import React from "react";
import { Action, ActionBar } from "../ActionBar/ActionBar";
import { FlexProps } from "../Flex/Flex";
import { P } from "../Paragraph/Paragraph";
import { Row } from "../Row/Row";
import styles from "./Title.module.scss";

export interface TitleProps extends FlexProps {
  sub?: boolean;
  actions?: Action[];
}

export const Title: React.FC<TitleProps> = ({ sub, actions, className, children, ...rest }) => {
  // render just the title if there are no actions
  if (!actions) {
    return (
      <P
        className={classNames(
          styles.title,
          {
            [styles["title--sub"]]: sub,
          },
          className,
        )}
        {...rest}
      >
        {children}
      </P>
    );
  }

  return (
    <Row marginBottom="half" {...rest}>
      <P
        expanded
        className={classNames(
          styles.title,
          {
            [styles["title--sub"]]: sub,
          },
          className,
        )}
      >
        {children}
      </P>
      {actions && <ActionBar actions={actions} />}
    </Row>
  );
};
