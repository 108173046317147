import classNames from "classnames";
import React, { useState, useEffect, useRef } from "react";
import { useOnAnimationEnd } from "../../hooks/useOnAnimationEnd";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { GridSize, grid } from "../../services/grid";
import { Column } from "../Column/Column";
import { Container } from "../Container/Container";
import { FlexProps, Flex } from "../Flex/Flex";
import { Loading } from "../Loading/Loading";
import { Row } from "../Row/Row";
import styles from "./DropdownMenu.module.scss";

export interface DropdownMenuProps extends FlexProps {
  open: boolean;
  top?: GridSize;
  right?: GridSize;
  onClickOutside?: VoidFunction;
}

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
  open,
  top,
  right,
  className,
  children,
  onClickOutside,
  ...rest
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [wasOpen, setWasOpen] = useState(false);
  const [isClosed, setIsClosed] = useState(true);

  // set was open if the dropdown was ever opened
  useEffect(() => {
    if (open && !wasOpen) {
      setWasOpen(true);
    }

    if (open) {
      setIsClosed(false);
    }
  }, [open, wasOpen]);

  // handle clicking outside the dropdown
  useOnClickOutside(dropdownRef, () => {
    if (!onClickOutside || !open) {
      return;
    }

    onClickOutside();
  });

  // handle animation ending
  useOnAnimationEnd(dropdownRef, () => {
    if (!open && !isClosed) {
      setIsClosed(true);
    }
  });

  return (
    <Flex
      overflow
      ref={dropdownRef}
      className={classNames(
        styles["dropdown-menu"],
        {
          [styles["dropdown-menu--open"]]: open,
          [styles["dropdown-menu--closing"]]: !open && wasOpen,
          [styles["dropdown-menu--closed"]]: isClosed,
        },
        className,
      )}
      style={{
        right: grid(right),
      }}
      {...rest}
    >
      <Container
        className={styles.spacer}
        style={{
          height: grid(top),
        }}
        onClick={onClickOutside}
      />
      <Column className={styles.contents}>{children}</Column>
    </Flex>
  );
};

export interface DropdownItemProps extends FlexProps {
  leading?: React.ReactNode;
  trailing?: React.ReactNode;
  loading?: boolean;
}

export const DropdownItem: React.FC<DropdownItemProps> = ({
  leading,
  trailing,
  loading,
  className,
  children,
  onClick,
  ...rest
}) => (
  <Row
    crossAxisAlignment="center"
    className={classNames(styles["dropdown-item"], { [styles["dropdown-item--loading"]]: loading }, className)}
    onClick={(e) => (!loading && onClick !== undefined ? onClick(e) : void 0)}
    {...rest}
  >
    {leading && <Container className={styles["dropdown-item-leading"]}>{leading}</Container>}
    <Container expanded>{children}</Container>
    {loading ? (
      <Container className={styles["dropdown-item-trailing"]}>
        <Loading small />
      </Container>
    ) : (
      trailing && <Container className={styles["dropdown-item-trailing"]}>{trailing}</Container>
    )}
  </Row>
);
