import { useViewerQuery, ViewerQueryResult } from "../schema";

type ViewerQueryInfo = NonNullable<ViewerQueryResult["data"]>["viewer"];

export function useViewer(): ViewerQueryInfo | undefined {
  // get viewer info from cache
  const { data: viewerData } = useViewerQuery({
    fetchPolicy: "cache-only",
  });

  // get viewer scopes
  return viewerData?.viewer;
}
