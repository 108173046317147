import React from "react";
import { Action, ActionBar } from "../ActionBar/ActionBar";
import { Alert, AlertSeverity } from "../Alert/Alert";
import { Column } from "../Column/Column";
import { Container } from "../Container/Container";
import { FlexProps } from "../Flex/Flex";
import { Row } from "../Row/Row";

export interface HeaderAlert {
  severity: AlertSeverity;
  message: React.ReactNode;
}

export interface HeaderProps extends FlexProps {
  actions?: Action[];
  navigation?: Action[];
  alerts?: HeaderAlert[];
}

export const Header: React.FC<HeaderProps> = ({ actions, navigation, alerts, children, ...rest }) => (
  <>
    <Row {...rest}>
      <Container expanded>{children}</Container>
      <Column crossAxisAlignment="flex-end">
        {actions && <ActionBar actions={actions} />}
        {navigation && <ActionBar secondary selfAlignment="flex-end" marginTop={2} actions={navigation} />}
      </Column>
    </Row>

    {alerts && (
      <Column padTop="half">
        {alerts.map((alert, index) => (
          <Alert key={index} severity={alert.severity} marginTop={index > 0 && "half"}>
            {alert.message}
          </Alert>
        ))}
      </Column>
    )}
  </>
);
