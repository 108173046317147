import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Action } from "../../components/ActionBar/ActionBar";
import { Header } from "../../components/Header/Header";
import { Title } from "../../components/Title/Title";
import { useAdminUrlInfo } from "../../hooks/useAdminUrlInfo";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import { UserScopeEnum } from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { QuestionOptionDetailsProps } from "../TestQuestionOptionListView/TestQuestionOptionListView";

export const TestQuestionOptionsDetailsHeader: React.FC<QuestionOptionDetailsProps> = ({ questionOption }) => {
  const navigate = useNavigate();
  const { modifier } = useAdminUrlInfo();

  // detect edit mode
  const isEditMode = modifier === "edit";
  // header buttons configuration
  const actions: Action[] = useMemo(
    () => [
      isEditMode
        ? {
            label: "Details",
            onClick: () =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "clarity-score",
                  page: "question-options",
                  id: questionOption.id,
                }),
              }),
          }
        : {
            label: "Edit",
            authorizedScopes: [
              UserScopeEnum.SUPERADMIN,
              UserScopeEnum.CLARITY_TEST,
              UserScopeEnum.CLARITY_TEST_UPDATE_INFO,
            ],
            onClick: () =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "clarity-score",
                  page: "question-options",
                  id: questionOption.id,
                  modifier: "edit",
                }),
              }),
          },
    ],
    [isEditMode, navigate, questionOption.id],
  );
  // header navigation configuration
  const navigation: Action[] = useMemo(() => [], []);
  return (
    <Header actions={actions} navigation={navigation}>
      <Title>{questionOption.answer.EN}</Title>
    </Header>
  );
};
