import { JSONSchema7 } from "json-schema";

export const CourseMetadataSchema: JSONSchema7 = {
  description: "Link to a course.",
  type: "object",
  properties: {
    authorName: {
      description: "Author name",
      minLength: 1,
      type: "string",
    },
    authorAvatarUrl: {
      description: "Avatar URL of the author",
      minLength: 1,
      type: "string",
    },
    courseId: {
      description: "Unique identifier of the course",
      minLength: 1,
      type: "string",
    },
    courseDescription: {
      description: "Course title",
      minLength: 1,
      type: "string",
    },
    lessonCount: {
      description: "Number of lessons in the course",
      minimum: 0,
      type: "number",
    },
    videoImageUrl: {
      description: "The video placeholder image url",
      minLength: 1,
      type: "string",
    },
    videoId: {
      description: "The vimeo video id",
      minLength: 1,
      type: "string",
    },
  },
  required: [
    "authorAvatarUrl",
    "authorName",
    "courseDescription",
    "courseId",
    "lessonCount",
    "videoId",
    "videoImageUrl",
  ],
  $schema: "http://json-schema.org/draft-07/schema#",
};
