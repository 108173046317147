import React from "react";
import { P } from "../../components/Paragraph/Paragraph";
import { View } from "../../components/View/View";

export const NotAuthorizedView: React.FC = () => (
  <View center pad>
    <P>You are not authorized access this content</P>
    <P small>Please ask other admins for permissions if you think this is a mistake</P>
  </View>
);
