import { sentenceCase } from "change-case";
import { SelectFieldOption } from "../components/SelectField/SelectField";

export function getEnumFormOptions(obj: Record<string, string>): SelectFieldOption[] {
  return Object.values(obj).map((value) => ({
    value,
    label: sentenceCase(value),
  }));
}

const fromArray: (arr: string[]) => SelectFieldOption[] = (arr) =>
  arr.map((item) => ({ value: item, label: sentenceCase(item) }));

const formOptions = {
  fromArray,
  fromEnum: getEnumFormOptions,
};

export default formOptions;
