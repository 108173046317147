import classNames from "classnames";
import React from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import { FieldError, FieldErrors, Merge } from "react-hook-form";
import { MdClear } from "react-icons/md";
import { formatDate } from "../../services/formatDate";
import { Container } from "../Container/Container";
import { Flex, FlexProps } from "../Flex/Flex";
import { Row } from "../Row/Row";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./DateField.module.scss";

export interface DateFieldProps extends Omit<ReactDatePickerProps, "value"> {
  name: string;
  label: string;
  error?: FieldError | Merge<FieldError, FieldErrors<any>>;
  value?: unknown;
  onClear?(): void;
}

export const DateField = React.forwardRef<HTMLDivElement, DateFieldProps>(function DateField(
  { label, error, startDate, endDate, value: _value, onClear, ...rest },
  ref,
) {
  return (
    <DatePicker
      showYearDropdown
      popperPlacement="bottom-end"
      dateFormat="dd.MM.yyyy"
      customInput={
        <DatepickerCustomInput
          ref={ref}
          label={label}
          error={error}
          value=""
          startDate={startDate}
          endDate={endDate}
          onClear={onClear}
        />
      }
      startDate={startDate}
      endDate={endDate}
      {...rest}
    />
  );
});

interface DatepickerCustomInputProps extends FlexProps {
  label: string;
  startDate?: Date | null;
  endDate?: Date | null;
  error?: FieldError | Merge<FieldError, FieldErrors<any>>;
  value: string;
  onClear?(): void;
}

const DatepickerCustomInput = React.forwardRef<HTMLDivElement, DatepickerCustomInputProps>(
  function DatepickerCustomInput(
    { label, value, startDate, endDate, error, className, onClick, onClear, ...rest },
    ref,
  ) {
    const hasValue = value.length > 0;
    const clearButton = <MdClear title="Clear" className={styles["clear-icon"]} onClick={onClear} />;
    const useValue = startDate && endDate ? `${formatDate(startDate)} - ${formatDate(endDate)}` : value;

    return (
      <Flex
        ref={ref}
        className={classNames(
          styles.field,
          {
            [styles["field--has-value"]]: hasValue,
            [styles["field--has-error"]]: error !== undefined,
          },
          className,
        )}
        {...rest}
      >
        <label className={styles.label}>{label}</label>
        <Row>
          <Container className={styles.value} onClick={onClick}>
            {useValue}
          </Container>
          {onClear && clearButton}
        </Row>
        <div className={styles.border} />
        <Container className={styles.error}>{error && error.message}</Container>
      </Flex>
    );
  },
);
