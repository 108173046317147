import React from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { GridBox } from "../../components/GridBox/GridBox";
import { View } from "../../components/View/View";
import { AdminViewParams } from "../../routes";
import { FeelingByIdQueryResult, useFeelingByIdQuery } from "../../schema";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";
import { FeelingDetailsEdit } from "./FeelingDetailsEdit";
import { FeelingDetailsHeader } from "./FeelingDetailsHeader";
import { FeelingDetailsInfo } from "./FeelingDetailsInfo";

export interface FeelingDetailsProps extends AdminViewProps {
  feeling: NonNullable<FeelingByIdQueryResult["data"]>["feeling"];
}

export const FeelingDetailsView: React.FC<AdminViewProps> = ({ viewer }) => {
  // get identifier from url parameters
  const { id } = useParams<AdminViewParams>();

  // fetch info
  const { data, loading, error } = useFeelingByIdQuery({
    variables: {
      feelingId: id ?? "",
    },
  });

  // expecting the id parameter to be provided
  if (!id) {
    return <ErrorView error="Expected identifier is not provided" />;
  }

  // get item info
  const feeling = data?.feeling;

  // handle error
  if (error) {
    return <ErrorView title="Loading info failed" error={error} />;
  }

  // handle loading
  if (loading || !feeling) {
    return <LoadingView />;
  }

  // render details
  return (
    <View pad="half">
      <FeelingDetailsHeader feeling={feeling} viewer={viewer} />
      <GridBox />
      <Routes>
        <Route index element={<FeelingDetailsInfo feeling={feeling} viewer={viewer} />} />
        <Route path="edit" element={<FeelingDetailsEdit feeling={feeling} viewer={viewer} />} />
      </Routes>
    </View>
  );
};
