import { setContext } from "@apollo/client/link/context";
import { config } from "../config";

export const contextLink = setContext(({ operationName }, { headers }) => {
  // augment headers with client metadata
  return {
    headers: {
      ...headers,
      // provide additional debug headers
      "x-operation": operationName,
      "x-version": config.clientVersion,
      "x-path": window.location.pathname,
    },
  };
});
