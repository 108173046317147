import { LocaleEnum } from "../schema";
import { assertUnreachable } from "./assertUnreachable";

export function getLocaleName(locale: LocaleEnum | string) {
  switch (locale) {
    case LocaleEnum.EN:
      return "English";

    case LocaleEnum.ET:
      return "Estonian";

    default:
      if (typeof locale === "string") {
        return locale;
      }

      assertUnreachable(locale, `Unexpected locale "${locale}" not handled`);
  }
}
