import { Route, Routes, useParams } from "react-router-dom";
import { GridBox } from "../../components/GridBox/GridBox";
import { View } from "../../components/View/View";
import { AdminViewParams } from "../../routes";
import { UserFeedItemMetadataSettingByIdQueryResult, useUserFeedItemMetadataSettingByIdQuery } from "../../schema";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";
import { UserFeedItemMetadataSettingDetailsEdit } from "./UserFeedItemMetadataSettingDetailsEdit";
import { UserFeedItemMetadataSettingDetailsHeader } from "./UserFeedItemMetadataSettingDetailsHeader";
import { UserFeedItemMetadataSettingDetailsInfo } from "./UserFeedItemMetadataSettingDetailsInfo";

export interface UserFeedItemMetadataSettingDetailsProps extends AdminViewProps {
  userFeedItemMetadataSetting: NonNullable<
    UserFeedItemMetadataSettingByIdQueryResult["data"]
  >["userFeedItemMetadataSetting"];
}

export const UserFeedItemMetadataSettingDetailsView: React.FC<AdminViewProps> = ({ viewer }) => {
  const { id } = useParams<AdminViewParams>();

  const { data, loading, error } = useUserFeedItemMetadataSettingByIdQuery({
    variables: {
      userFeedItemMetadataSettingId: id ?? "",
    },
  });

  if (!id) {
    return <ErrorView error="Expected identifier is not provided" />;
  }

  const userFeedItemMetadataSetting = data?.userFeedItemMetadataSetting;

  if (error) {
    return <ErrorView title="Loading info failed" error={error} />;
  }

  if (loading || !userFeedItemMetadataSetting) {
    return <LoadingView />;
  }

  return (
    <View pad="half">
      <UserFeedItemMetadataSettingDetailsHeader
        userFeedItemMetadataSetting={userFeedItemMetadataSetting}
        viewer={viewer}
      />
      <GridBox />
      <Routes>
        <Route
          index
          element={
            <UserFeedItemMetadataSettingDetailsInfo
              userFeedItemMetadataSetting={userFeedItemMetadataSetting}
              viewer={viewer}
            />
          }
        />
        <Route
          path="edit"
          element={
            <UserFeedItemMetadataSettingDetailsEdit
              userFeedItemMetadataSetting={userFeedItemMetadataSetting}
              viewer={viewer}
            />
          }
        />
      </Routes>
    </View>
  );
};
