import { WithCollaboration } from "../../components/Collaboration/Collaboration";
import { NameValueList } from "../../components/NameValuePair/NameValuePair";
import { CollaborationTypeEnum } from "../../schema";
import { getMetadataNameValuePairs } from "../../services/getMetadataSchema";
import { UserFeedItemMetadataSettingDetailsProps } from "./UserFeedItemMetadataSettingView";

export const UserFeedItemMetadataSettingDetailsInfo: React.FC<UserFeedItemMetadataSettingDetailsProps> = ({
  userFeedItemMetadataSetting,
  viewer,
}) => {
  return (
    <WithCollaboration
      referenceId={userFeedItemMetadataSetting.id}
      type={CollaborationTypeEnum.USER_FEED_ITEM_METADATA_SETTING}
      viewer={viewer}
    >
      <NameValueList
        items={getMetadataNameValuePairs(userFeedItemMetadataSetting.type, userFeedItemMetadataSetting.metadata)}
      />
    </WithCollaboration>
  );
};
