import { sentenceCase } from "change-case";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Action } from "../../components/ActionBar/ActionBar";
import { DataTable, DataTableHeader, DataTableRow } from "../../components/DataTable/DataTable";
import { Filter, FilterBaseData } from "../../components/Filter/Filter";
import { FormFieldOptions } from "../../components/GeneratedForm/GeneratedForm";
import { Pagination } from "../../components/Pagination/Pagination";
import { View } from "../../components/View/View";
import { useUrlParams } from "../../hooks/useUrlParams";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import {
  AdminFeedItemAudienceEnum,
  AdminFeedItemStatusEnum,
  ConditionModeEnum,
  MatchModeEnum,
  SortingInput,
  useAdminFeedPollsQuery,
  UserScopeEnum,
} from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { formatApiDate } from "../../services/formatApiDate";
import { formatDate } from "../../services/formatDate";
import { getEnumFormOptions } from "../../services/getEnumFormOptions";
import { getPageCount } from "../../services/getPageCount";
import { getSkipTake } from "../../services/getSkipTake";
import { getSortDirectionFromParams } from "../../services/getSortDirectionFromParams";
import { getSortOptionsFromSearchParams } from "../../services/getSortOptionsFromSearchParams";
import { getUpdatedSortOptions } from "../../services/getUpdatedSortOptions";
import { getUrlSearchParamsString } from "../../services/getUrlSearchParamsString";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";

interface AdminFeedPollsFilterData extends FilterBaseData {
  adminFeedPollId: string;
  question: string;
  audience: AdminFeedItemAudienceEnum[];
  status: AdminFeedItemStatusEnum[];
  startDate: Date | null;
  endDate: Date | null;
  startDay: number | null;
  endDay: number | null;
  isPinned: boolean | null;
  sort: SortingInput[] | null;
}

export const AdminFeedPollListView: React.FC<AdminViewProps> = () => {
  const navigate = useNavigate();

  const params = useUrlParams<AdminFeedPollsFilterData>((params) => ({
    adminFeedPollId: params.adminFeedPollId ?? "",
    question: params.question ?? "",
    audience: params.audience ? (params.audience.split(",") as AdminFeedItemAudienceEnum[]) : [],
    status: params.status ? (params.status.split(",") as AdminFeedItemStatusEnum[]) : [],
    startDate: params.startDate ? new Date(params.startDate) : null,
    endDate: params.endDate ? new Date(params.endDate) : null,
    startDay: params.startDay ? parseInt(params.startDay, 10) : null,
    endDay: params.endDay ? parseInt(params.endDay, 10) : null,
    isPinned: params.isPinned !== undefined ? (params.isPinned === "true" ? true : false) : null,
    conditionMode: params.conditionMode ? (params.conditionMode as ConditionModeEnum) : ConditionModeEnum.AND,
    matchMode: params.matchMode ? (params.matchMode as MatchModeEnum) : MatchModeEnum.STARTS_WITH,
    page: params.page ? parseInt(params.page, 10) : 1,
    sort: params.sort ? getSortOptionsFromSearchParams(params.sort) : null,
  }));

  const { data, loading, error } = useAdminFeedPollsQuery({
    variables: {
      filter: {
        adminFeedPollId: params.adminFeedPollId.length > 0 ? params.adminFeedPollId : undefined,
        question: params.question.length > 0 ? params.question : undefined,
        audience: params.audience.length > 0 ? params.audience : undefined,
        status: params.status.length > 0 ? params.status : undefined,
        startDate: params.startDate ? formatApiDate(params.startDate) : undefined,
        endDate: params.endDate ? formatApiDate(params.endDate) : undefined,
        startDay: params.startDay ?? undefined,
        endDay: params.endDay ?? undefined,
        isPinned: params.isPinned ?? undefined,
      },
      pagination: {
        ...getSkipTake(params.page),
      },
      match: {
        matchMode: params.matchMode,
        conditionMode: params.conditionMode,
      },
      sort: params.sort ?? null,
    },
  });

  const adminFeedPolls = useMemo(
    () => data?.adminFeedPolls.adminFeedPolls ?? [],
    [data?.adminFeedPolls.adminFeedPolls],
  );

  const total = data?.adminFeedPolls.total ?? 0;
  const pageCount = getPageCount(total);

  const filters = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "text",
        type: "text",
        name: "adminFeedPollId",
        label: "Poll ID",
        defaultValue: params.adminFeedPollId,
      },
      {
        field: "text",
        type: "text",
        name: "question",
        label: "Question",
        defaultValue: params.question,
      },
      {
        field: "checkbox",
        name: "audience",
        label: "Audience",
        options: getEnumFormOptions(AdminFeedItemAudienceEnum),
        defaultValue: params.audience,
      },
      {
        field: "checkbox",
        name: "status",
        label: "Status",
        options: getEnumFormOptions(AdminFeedItemStatusEnum),
        defaultValue: params.audience,
      },
      {
        field: "date",
        name: "startDate",
        label: "Show start date",
        defaultValue: params.startDate,
      },
      {
        field: "date",
        name: "endDate",
        label: "Show end date",
        defaultValue: params.endDate,
      },
      {
        field: "text",
        type: "text",
        name: "startDay",
        label: "Show start day",
        defaultValue: params.startDay !== null ? params.startDay.toString() : "",
      },
      {
        field: "text",
        type: "text",
        name: "endDay",
        label: "Show end day",
        defaultValue: params.endDay !== null ? params.endDay.toString() : "",
      },
      {
        field: "radio",
        name: "isPinned",
        label: "Is pinned",
        options: [
          { value: "true", label: "Yes" },
          { value: "false", label: "No" },
        ],
        defaultValue: params.isPinned !== null ? (params.isPinned ? "true" : "false") : null,
      },
    ],
    [
      params.adminFeedPollId,
      params.question,
      params.audience,
      params.startDate,
      params.endDate,
      params.startDay,
      params.endDay,
      params.isPinned,
    ],
  );

  // handle sorting by column
  const onHeaderClick = useCallback(
    (databaseColumnName: string) =>
      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "admin-feed", page: "polls" }),
        search: getUrlSearchParamsString({ ...params, sort: getUpdatedSortOptions(databaseColumnName, params.sort) }),
      }),
    [navigate, params],
  );

  const headers = useMemo<DataTableHeader[]>(
    () => [
      {
        label: "Question",
        onClick: () => onHeaderClick("question"),
        sortdirection: getSortDirectionFromParams("question", params.sort),
      },
      {
        label: "Audience",
        onClick: () => onHeaderClick("audience"),
        sortdirection: getSortDirectionFromParams("audience", params.sort),
      },
      {
        label: "Start date",
        onClick: () => onHeaderClick("startDate"),
        sortdirection: getSortDirectionFromParams("startDate", params.sort),
      },
      {
        label: "End date",
        onClick: () => onHeaderClick("endDate"),
        sortdirection: getSortDirectionFromParams("endDate", params.sort),
      },
      {
        label: "Start day",
        onClick: () => onHeaderClick("startDay"),
        sortdirection: getSortDirectionFromParams("startDay", params.sort),
      },
      {
        label: "End day",
        onClick: () => onHeaderClick("endDay"),
        sortdirection: getSortDirectionFromParams("endDay", params.sort),
      },
      {
        label: "Is pinned",
        onClick: () => onHeaderClick("isPinned"),
        sortdirection: getSortDirectionFromParams("isPinned", params.sort),
      },
      {
        label: "Status",
        onClick: () => onHeaderClick("status"),
        sortdirection: getSortDirectionFromParams("status", params.sort),
      },
    ],
    [onHeaderClick, params.sort],
  );

  const rows = useMemo<DataTableRow[]>(
    () =>
      adminFeedPolls.map((adminFeedPoll) => ({
        id: adminFeedPoll.id,
        cells: [
          {
            content: adminFeedPoll.question["EN"],
          },
          {
            content: adminFeedPoll.adminFeedItem.audience.map((audience) => sentenceCase(audience)).join(", "),
          },
          {
            content: formatDate(adminFeedPoll.adminFeedItem.startDate),
          },
          {
            content: formatDate(adminFeedPoll.adminFeedItem.endDate) ?? "n/a",
          },
          {
            content: adminFeedPoll.adminFeedItem.startDay ?? "n/a",
          },
          {
            content: adminFeedPoll.adminFeedItem.endDay ?? "n/a",
          },
          {
            content: adminFeedPoll.adminFeedItem.isPinned ? "Yes" : "No",
          },
          {
            content: adminFeedPoll.adminFeedItem.status,
          },
        ],
        actions: [
          {
            label: "View details",
            onClick: (adminFeedPollId) =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "admin-feed",
                  page: "polls",
                  id: adminFeedPollId,
                }),
              }),
          },
          {
            label: "Edit",
            onClick: (adminFeedPollId) =>
              navigate({
                pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                  menu: "admin-feed",
                  page: "polls",
                  id: adminFeedPollId,
                  modifier: "edit",
                }),
              }),
          },
        ],
      })),
    [navigate, adminFeedPolls],
  );

  const viewActions: Action[] = useMemo(
    () => [
      {
        label: "Create poll",
        authorizedScopes: [UserScopeEnum.SUPERADMIN, UserScopeEnum.ADMIN_FEED_CREATE_POLL],
        onClick: () =>
          navigate({
            pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
              menu: "admin-feed",
              page: "polls",
              modifier: "new",
            }),
          }),
      },
    ],
    [navigate],
  );

  const onFilterSubmit = useCallback(
    (data: AdminFeedPollsFilterData) => {
      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, { menu: "admin-feed", page: "polls" }),
        search: getUrlSearchParamsString(data),
      });
    },
    [navigate],
  );

  if (error) {
    return <ErrorView title="Fetching admin feed polls failed" error={error} />;
  }

  return (
    <>
      <View scrollable>
        <Filter
          title="Admin feed polls"
          fields={filters}
          actions={viewActions}
          loading={loading}
          matchMode={params.matchMode}
          conditionMode={params.conditionMode}
          onSubmit={onFilterSubmit}
        />
        <DataTable
          headers={headers}
          rows={rows}
          loading={loading}
          openAuthorizedScopes={[
            UserScopeEnum.SUPERADMIN,
            UserScopeEnum.ADMIN_FEED_POLL_LIST,
            UserScopeEnum.ADMIN_FEED_POLL_INFO,
          ]}
          onOpen={(row) =>
            navigate({
              pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
                menu: "admin-feed",
                page: "polls",
                modifier: row.id,
              }),
            })
          }
        />
        <Pagination sticky pageCount={pageCount} currentPage={params.page} />
      </View>
    </>
  );
};
