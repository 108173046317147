import { UserScopeEnum } from "../schema";

export function hasAnyScopes(authorizedScopes: UserScopeEnum[] | undefined, viewerScopes: UserScopeEnum[] | undefined) {
  if (authorizedScopes !== undefined && viewerScopes === undefined) {
    throw new Error("Expected either both or neither of authorizedScopes and viewerScopes to be set");
  }

  // consider valid if neither is set
  if (!authorizedScopes || !viewerScopes) {
    return true;
  }

  // user needs to have at least one of the accepted scopes
  return viewerScopes.some((userScope) => authorizedScopes.includes(userScope));
}
