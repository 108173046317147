import React, { useMemo } from "react";
import { Modal, ModalAction, ModalProps } from "../../components/Modal/Modal";
import { P } from "../../components/Paragraph/Paragraph";
import { useUpdateFeedTestUserMutation, UpdateFeedTestUserMutation } from "../../schema";
import { FeedTestUserInfo } from "../../views/FeedTestUserDetailsView/FeedTestUserDetailsView";

export interface FeedTestUserModalProps extends ModalProps {
  feedTestUser: FeedTestUserInfo;
  onCompleted: (data: UpdateFeedTestUserMutation) => void;
}

export const FeedTestUserStatusModal: React.FC<FeedTestUserModalProps> = ({
  feedTestUser,
  onCompleted,
  onClickOutside,
  ...rest
}) => {
  const [updateFeedTestUser, updateFeedTestUserResult] = useUpdateFeedTestUserMutation({
    refetchQueries: ["CollaborationById"],
    awaitRefetchQueries: true,
    onCompleted,
  });

  const isActive = feedTestUser.isActive;

  const actions = useMemo<ModalAction[]>(
    () => [
      {
        label: "Cancel",
        onClick: onClickOutside,
      },
      {
        label: `${isActive ? "Disable" : "Enable"} user`,
        danger: true,
        loading: updateFeedTestUserResult.loading,
        onClick: () =>
          updateFeedTestUser({
            variables: {
              id: feedTestUser.id,
              comment: isActive ? "Feed test user was disabled" : "Feed test user was re-activated",
            },
          }),
      },
    ],
    [onClickOutside, feedTestUser.id, updateFeedTestUser, updateFeedTestUserResult.loading, isActive],
  );

  return (
    <Modal
      title={isActive ? "Confirm to disable user" : "Confirm to enable user"}
      error={updateFeedTestUserResult.error}
      actions={actions}
      onClickOutside={onClickOutside}
      {...rest}
    >
      <P center>Are you sure you want to {isActive ? "disable" : "enable"} test user?</P>
      <P center small padTop="half">
        {isActive && "Disabling the test user will prevent them from viewing the test feed in Mindspa app"}
        {!isActive && "Enabling the test user will allow them to view the test feed in Mindspa app"}
      </P>
    </Modal>
  );
};
