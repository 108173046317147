import React, { useMemo } from "react";
import { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { WithCollaboration } from "../../components/Collaboration/Collaboration";
import { FormFieldOptions, GeneratedForm } from "../../components/GeneratedForm/GeneratedForm";
import { toastState, ToastType } from "../../components/Toast/Toast";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import { CollaborationTypeEnum, useUpdateQuestionOptionMutation } from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getTranslationInput } from "../../services/getTranslationInput";
import { QuestionOptionDetailsProps } from "../TestQuestionOptionListView/TestQuestionOptionListView";

interface EditQuestionOptionFormData {
  answer: string;
  score: string;
  weight: string;
  comment: string;
}

export const TestQuestionOptionDetailsEdit: React.FC<QuestionOptionDetailsProps> = ({ questionOption, viewer }) => {
  const navigate = useNavigate();
  const setToast = useSetRecoilState(toastState);
  const [updateQuestionOptionInfo, updateQuestionOptionInfoResult] = useUpdateQuestionOptionMutation({
    refetchQueries: ["CollaborationById"],
    awaitRefetchQueries: true,
    onCompleted: ({ updateQuestionOption }) => {
      setToast({
        type: ToastType.SUCCESS,
        title: "Question Option has been updated",
        message: `Updated question "${updateQuestionOption.id}" details.`,
        isOpen: true,
      });

      navigate({
        pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
          menu: "clarity-score",
          page: "question-options",
          id: questionOption.id,
        }),
      });
    },
  });

  // Add edit item mutation

  // form configuration
  const fields = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "translation",
        name: "answer",
        label: "Answer",
        rules: { required: "Please provide question option answer" },
        defaultValue: questionOption.answer,
      },
      {
        field: "translation",
        name: "conclusionText",
        label: "Conclusion text",
        rules: { required: "Please provide question option conclusion text" },
        defaultValue: questionOption.conclusionText,
      },
      {
        field: "text",
        type: "number",
        name: "score",
        label: "Score",
        rules: { required: "Please provide question option score" },
        defaultValue: questionOption.score ? questionOption.score.toString() : "",
      },
      {
        field: "text",
        type: "number",
        name: "weight",
        label: "Weight",
        rules: { required: "Please provide question option weight" },
        defaultValue: questionOption.weight ? questionOption.weight.toString() : "",
      },
      {
        field: "textarea",
        type: "text",
        name: "comment",
        label: "Collaboration comment",
        rules: {
          required: "Please describe what and why was updated",
        },
        defaultValue: "Updated question option info",
      },
    ],
    [questionOption.answer, questionOption.weight, questionOption.score, questionOption.conclusionText],
  );

  const onSubmit: SubmitHandler<EditQuestionOptionFormData> = async (data) =>
    updateQuestionOptionInfo({
      variables: {
        ...data,
        questionOptionId: questionOption.id,
        answer: getTranslationInput("answer", { ...data }),
        conclusionText: getTranslationInput("conclusionText", { ...data }),
        score: parseInt(data.score, 10),
        weight: parseInt(data.weight, 10),
      },
    });

  return (
    <WithCollaboration referenceId={questionOption.id} type={CollaborationTypeEnum.QUESTION_OPTION} viewer={viewer}>
      <GeneratedForm
        title="Question Option"
        error={updateQuestionOptionInfoResult.error}
        loading={updateQuestionOptionInfoResult.loading}
        submitText="Update"
        onSubmit={onSubmit}
      >
        <GeneratedForm.Fields fields={fields} />
      </GeneratedForm>
    </WithCollaboration>
  );
};
