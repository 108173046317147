import React, { useMemo } from "react";
import { FormFieldOptions, GeneratedForm } from "../../components/GeneratedForm/GeneratedForm";
import { Modal, ModalProps } from "../../components/Modal/Modal";
import {
  CreateInformativeContentMutation,
  InformativeContentEnum,
  TranslationMap,
  useCreateInformativeContentMutation,
} from "../../schema";
import { getEnumFormOptions } from "../../services/getEnumFormOptions";
import { getFileUploadById } from "../../services/getFileUploadById";
import { getTranslationInput } from "../../services/getTranslationInput";

type CreateInformativeContentData = {
  title: TranslationMap;
  subTitle: TranslationMap;
  text: TranslationMap;
  type: InformativeContentEnum;
  ordinal: string;
};

export interface CreateInformativeContentModalProps extends ModalProps {
  onCompleted: (data: CreateInformativeContentMutation) => void;
}

export const CreateInformativeContentModal: React.FC<CreateInformativeContentModalProps> = ({
  onCompleted,
  ...rest
}) => {
  const [createInformativeContent, createInformativeContentResult] = useCreateInformativeContentMutation({
    refetchQueries: ["InformativeContent"],
    awaitRefetchQueries: true,
    onCompleted,
  });

  const fields = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "translation",
        type: "text",
        name: "title",
        label: "Title",
        rules: { required: "Please provide informative content title value" },
      },
      {
        field: "upload",
        type: "text",
        name: "image",
        label:
          "Informative content image (only choose to change, use square transparent PNG with size of 256x256 or greater)",
      },
      {
        field: "translation",
        type: "text",
        name: "subTitle",
        label: "Sub title",
        rules: { required: "Please provide informative content sub title value" },
      },
      {
        field: "translation",
        type: "text",
        name: "text",
        label: "Text",
        rules: { required: "Please provide informative content text value" },
      },
      {
        field: "radio",
        name: "type",
        label: "Type",
        options: getEnumFormOptions(InformativeContentEnum),
        rules: { required: "Please provide informative content type" },
      },
      {
        field: "text",
        type: "number",
        name: "ordinal",
        label: "Ordinal",
        rules: { required: "Please provide informative content ordinal value" },
      },
    ],
    [],
  );

  return (
    <Modal title="Create new informative content" error={createInformativeContentResult.error} {...rest}>
      <GeneratedForm
        loading={createInformativeContentResult.loading}
        error={createInformativeContentResult.error}
        onSubmit={(data: CreateInformativeContentData) =>
          createInformativeContent({
            variables: {
              ...data,
              title: getTranslationInput("title", data),
              image: getFileUploadById("image"),
              subTitle: getTranslationInput("subTitle", data),
              text: getTranslationInput("text", data),
              ordinal: parseInt(data.ordinal),
            },
          })
        }
      >
        <GeneratedForm.Fields fields={fields} />
      </GeneratedForm>
    </Modal>
  );
};
