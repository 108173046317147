import { Route, Routes, useParams } from "react-router-dom";
import { View } from "../../components/View/View";
import { AdminViewParams } from "../../routes";
import { FileByIdQueryResult, useFileByIdQuery } from "../../schema";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";
import { FileUploadDetailsInfo } from "./FileUploadDetailsInfo";

export interface FileUploadDetailsProps extends AdminViewProps {
  file: NonNullable<FileByIdQueryResult["data"]>["file"];
}

export const FileUploadDetailsView: React.FC<AdminViewProps> = ({ viewer }) => {
  const { id } = useParams<AdminViewParams>();

  const { data, loading, error } = useFileByIdQuery({
    variables: {
      fileId: id ?? "",
    },
  });

  if (!id) {
    return <ErrorView error="Expeected identifier is not provided" />;
  }

  const file = data?.file;

  if (error) {
    return <ErrorView title="Loading file info failed" error={error} />;
  }

  if (loading || !file) {
    return <LoadingView />;
  }

  return (
    <View pad="half">
      <Routes>
        <Route index element={<FileUploadDetailsInfo file={file} viewer={viewer} />} />
      </Routes>
    </View>
  );
};
