import React from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { GridBox } from "../../components/GridBox/GridBox";
import { View } from "../../components/View/View";
import { AdminViewParams } from "../../routes";
import { useQuestionOptionByIdQuery } from "../../schema";
import { AdminViewProps } from "../AdminView/AdminView";
import { ErrorView } from "../ErrorView/ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";
import { TestQuestionOptionDetailsEdit } from "./TestQuestionOptionDetailsEdit";
import { TestQuestionOptionsDetailsHeader } from "./TestQuestionOptionDetailsHeader";
import { TestQuestionOptionDetailsInfo } from "./TestQuestionOptionDetailsInfo";

export const TestQuestionOptionDetailView: React.FC<AdminViewProps> = ({ viewer }) => {
  // get identifier from url parameters
  const { id } = useParams<AdminViewParams>();

  const { data, loading, error } = useQuestionOptionByIdQuery({
    variables: {
      filter: {
        questionOptionId: id,
      },
    },
  });
  // expecting the id parameter to be provided
  if (!id) {
    return <ErrorView error="Expected identifier is not provided" />;
  }

  // get item info
  const questionOption = data?.questionOptions.questionOptions[0] || undefined;

  // handle error
  if (error) {
    return <ErrorView title="Loading info failed" error={error} />;
  }

  // handle loading
  if (loading || !questionOption) {
    return <LoadingView />;
  }

  // render details
  return (
    <View pad="half">
      <TestQuestionOptionsDetailsHeader questionOption={questionOption} viewer={viewer} />
      <GridBox />
      <Routes>
        <Route index element={<TestQuestionOptionDetailsInfo questionOption={questionOption} viewer={viewer} />} />
        <Route
          path="edit"
          element={<TestQuestionOptionDetailsEdit questionOption={questionOption} viewer={viewer} />}
        />
      </Routes>
    </View>
  );
};
