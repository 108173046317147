import { useMemo } from "react";
import { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { WithCollaboration } from "../../components/Collaboration/Collaboration";
import { FormFieldOptions, GeneratedForm } from "../../components/GeneratedForm/GeneratedForm";
import { toastState, ToastType } from "../../components/Toast/Toast";
import { AdminViewParams, ADMIN_VIEW_PATH } from "../../routes";
import { CollaborationTypeEnum, useUpdateClarityTestConclusionImageMutation } from "../../schema";
import { buildUrl } from "../../services/buildUrl";
import { getFileUploadById } from "../../services/getFileUploadById";
import { ClarityTestConclusionImageDetailsProps } from "./ClarityTestConclusionImageDetailsView";

interface EditClarityTestConclusionImageFormData {
  comment: string;
}

export const ClarityTestConclusionImageDetailsEdit: React.FC<ClarityTestConclusionImageDetailsProps> = ({
  clarityTestConclusionImage,
  viewer,
}) => {
  const navigate = useNavigate();
  const setToast = useSetRecoilState(toastState);

  const [updateClarityTestConclusionImage, updateClarityTestConclusionImageResult] =
    useUpdateClarityTestConclusionImageMutation({
      refetchQueries: ["CollaborationById", "ClarityTestConclusionImageById"],
      awaitRefetchQueries: true,
      onCompleted: () => {
        setToast({
          type: ToastType.SUCCESS,
          title: "Clarity test conclusion image has been updated",
          message: `Updated image for item ${clarityTestConclusionImage.id}`,
          isOpen: true,
        });

        navigate({
          pathname: buildUrl<AdminViewParams>(ADMIN_VIEW_PATH, {
            menu: "clarity-score",
            page: "conclusion-images",
            id: clarityTestConclusionImage.id,
          }),
        });
      },
    });

  const fields = useMemo<FormFieldOptions[]>(
    () => [
      {
        field: "upload",
        type: "text",
        name: "image",
        label: "Image",
      },
      {
        field: "textarea",
        type: "text",
        name: "comment",
        label: "Collaboration comment",
        rules: {
          required: "Please describe what and why was updated",
        },
        defaultValue: "Updated clarity test conclusion image",
      },
    ],
    [],
  );

  const onSubmit: SubmitHandler<EditClarityTestConclusionImageFormData> = async (data) => {
    updateClarityTestConclusionImage({
      variables: {
        ...clarityTestConclusionImage,
        ...data,
        conclusionImageId: clarityTestConclusionImage.id,
        comment: data.comment,
        image: getFileUploadById("image"),
      },
    });
  };

  return (
    <WithCollaboration
      referenceId={clarityTestConclusionImage.id}
      type={CollaborationTypeEnum.CLARITY_TEST_CONCLUSION_IMAGE}
      viewer={viewer}
    >
      <GeneratedForm
        title="Edit image"
        error={updateClarityTestConclusionImageResult.error}
        loading={updateClarityTestConclusionImageResult.loading}
        submitText="Update"
        onSubmit={onSubmit}
      >
        <GeneratedForm.Fields fields={fields} />
      </GeneratedForm>
    </WithCollaboration>
  );
};
